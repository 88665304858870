import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import { useFormContext } from 'react-hook-form'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormFieldArray from '../../components/FormFieldArray'
import FormSelect from '../../components/FormSelect'
import { CustomsOfficeOfExitForTransitDeclared } from '../../form/schemas/commonConsignmentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import useCodelist from '../../../common/hooks/useCodelist'

import { excludeDeleted } from '../../../common/utils/common-util'
import { CUSTOMS_OFFICES_OF_EXIT_FOR_TRANSIT_MULTIPLICITY } from '../../../common/utils/ncts-constants'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import { blankCustomsOfficeOfExitForTransitDeclared } from '../../form/blankItems'

const expectedScope = /customsOfficesOfTransitExit\.\d+/g
type CustomsOfficeOfExitForTransitItemScope = NestedFieldScope<`customsOfficesOfTransitExit.${number}`>
const isCustomsOfficeOfExitForTransitItemScope = (scope: ObjectScope):
  scope is `customsOfficesOfTransitExit.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function CustomsOfficeOfExitForTransit({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<CustomsOfficeOfExitForTransitDeclared>({
    blankItem: blankCustomsOfficeOfExitForTransitDeclared,
    name: scope,
  })

  const { getValues } = useFormContext<DeclarationForm>()
  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])

  const [, transitCustomsOfficesOptions] = useCodelist('TRANSIT_EXIT_CUSTOMS_OFFICES')

  const isAddButtonDisabled = arrayRule === 'NOT_ALLOWED'
    || getFields().filter(excludeDeleted).length >= CUSTOMS_OFFICES_OF_EXIT_FOR_TRANSIT_MULTIPLICITY

  return (['EE', 'PL', 'LT', 'LV'].includes(country) ? (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-offices-of-transit"
        headingLabel={t('declaration.p5.transitExitCustomsOffices')}
      >
        {getFields().map((transitCustomsOffices, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isCustomsOfficeOfExitForTransitItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`transitCustomsOffice_${transitCustomsOffices.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, transitCustomsOffices)}
              sequenceNumber={transitCustomsOffices.sequenceNumber + 1}
              hidden={transitCustomsOffices.deleted}
            >
              <FormField labelKey="declaration.p5.referenceNumber">
                <FormSelect
                  <CustomsOfficeOfExitForTransitItemScope>
                  field={`${innerItemScope}.referenceNumber`}
                  labelKey="declaration.p5.referenceNumber"
                  options={transitCustomsOfficesOptions}
                  type="sync"
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={isAddButtonDisabled}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewCustomsOfficeOfExitForTransit')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
    : null
  )
}

export default CustomsOfficeOfExitForTransit
