import React from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentResponse } from '../../../models'
import TransitSidebarDocumentSection from './TransitSidebarDocumentSection'
import { PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX, removePrefix } from '../../../utils/classifier-util'
import TransitSidebarField from '../TransitSidebarField'
import { hasText } from '../../../utils/common-util'

interface TransitSidebarPreviousDocumentsProps {
  previousDocuments: DocumentResponse[]
}

function TransitSidebarPreviousDocuments({
  previousDocuments,
}: TransitSidebarPreviousDocumentsProps) {
  const { t } = useTranslation()

  return (

    <div className="card p-2">
      <h5 className="text-secondary mb-2">
        {
          t('declaration.p5.previousDocuments')
        }
      </h5>
      {
        previousDocuments.map((document) => (
          <TransitSidebarDocumentSection
            key={document.id}
            document={document}
            documentTypePrefix={PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX}
          >
            <div className="col-12">
              <TransitSidebarField
                label={t('declaration.p5.supportingDocumentType')}
                value={removePrefix(document.documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) ?? ''}
              />
              <TransitSidebarField
                label={t('declaration.p5.previousDocumentReferenceNumber')}
                value={document.referenceNumber ?? ''}
              />
              <TransitSidebarField
                label={t('declaration.p5.complementOfInformation')}
                value={document.complementOfInformation ?? ''}
              />
              <TransitSidebarField
                label={t('declaration.p5.itemNumber')}
                value={document.documentLineItemNumber ?? ''}
              />
              {
                (hasText(document.transportDocumentReferenceNumber)
                    && (removePrefix(document.documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) === 'N355'
                      || removePrefix(document.documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) === 'N337'
                      || removePrefix(document.documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) === 'N785'
                    )
                ) && (
                  <TransitSidebarField
                    label={t('declaration.p5.transportDocumentReferenceNumber')}
                    value={document.transportDocumentReferenceNumber ?? ''}
                  />
                )
              }
            </div>
          </TransitSidebarDocumentSection>
        ))
      }
    </div>
  )
}

export default TransitSidebarPreviousDocuments
