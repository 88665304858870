import React from 'react'
import { useTranslation } from 'react-i18next'
import TransitSidebarDocumentSection from './TransitSidebarDocumentSection'
import { removePrefix, TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX } from '../../../utils/classifier-util'
import TransitSidebarField from '../TransitSidebarField'
import { DocumentResponse } from '../../../models'

interface TransitSidebarTransportDocumentsProps {
  transportDocuments: DocumentResponse[]
}
function TransitSidebarTransportDocuments({
  transportDocuments,
}: TransitSidebarTransportDocumentsProps) {
  const { t } = useTranslation()

  return (
    <div className="card p-2">
      <h5 className="text-secondary mb-2">
        {
          t('declaration.p5.transportDocuments')
        }
      </h5>
      {
        transportDocuments.map((document) => (
          <TransitSidebarDocumentSection
            key={document.id}
            document={document}
            documentTypePrefix={TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX}
          >
            <div className="col-12">
              <TransitSidebarField
                label={t('declaration.p5.transportDocumentType')}
                value={removePrefix(document.documentType, TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX) ?? ''}
              />
              <TransitSidebarField
                label={t('declaration.p5.transportDocumentReferenceNumber')}
                value={document.referenceNumber ?? ''}
              />
            </div>
          </TransitSidebarDocumentSection>
        ))

      }
    </div>

  )
}

export default TransitSidebarTransportDocuments
