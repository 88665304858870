import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { excludeDeleted } from '../../../../common/utils/common-util'
import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import { isTransitionalPeriodActive } from '../../../../common/utils/ncts-constants'
import B1805Validator from '../transitional/B1805'

const C0837Validator = {
  validateNetMassRequirement: (houseConsignment: HouseConsignmentType, reducedDatasetIndicator: 'true' | 'false' | null): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return B1805Validator.validateNetMassRequirement(reducedDatasetIndicator)
    }
    const hasExportDocument = houseConsignment.previousDocument
      .filter(excludeDeleted)
      .some((doc) => removePrefix(doc.documentType, 'PREVIOUS_DOCUMENT_TYPE_') === 'N830')

    if (hasExportDocument) {
      return 'REQUIRED'
    }
    if (reducedDatasetIndicator && reducedDatasetIndicator === 'true') {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },

} as const

export default C0837Validator
