import React from 'react'
import { DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarPreviousDocuments from './TransitSidebarPreviousDocuments'

export interface TransitSidebarConsignmentItemPreviousDocumentsProps {
  consignmentItemId: number | null
  previousDocuments: DocumentResponse[]
}

function TransitSidebarConsignmentItemPreviousDocuments(props: TransitSidebarConsignmentItemPreviousDocumentsProps) {
  const {
    consignmentItemId,
    previousDocuments,
  } = props

  const itemPreviousDocuments = previousDocuments
    .filter((transportDocument) => transportDocument.consignmentItemId === consignmentItemId)
    .sort(sortBySequenceNumber)

  if (itemPreviousDocuments.length === 0) {
    return null
  }

  return (
    <div className="col-6 p-2">
      <TransitSidebarPreviousDocuments previousDocuments={itemPreviousDocuments} />
    </div>
  )
}

export default TransitSidebarConsignmentItemPreviousDocuments
