import React from 'react'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope, ObjectScope, ScopeProps } from 'types/DeclarationP5'
import { useFormContext } from 'react-hook-form'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import Input from '../../components/Input'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import HouseConsignmentDetails from './HouseConsignmentDetails'

const expectedScope = /(houseConsignment\.\d+)/g
const isHouseConsignmentScope = (scope: ObjectScope): scope is `houseConsignment.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0
type HouseConsignmentScope = NestedFieldScope<`houseConsignment.${number}`>

function HouseConsignment({ scope }: ScopeProps<ObjectScope>) {
  if (!isHouseConsignmentScope(scope)) throw Error('Unable to narrow, invalid scope')

  const { t } = useTranslation()
  const { getValues } = useFormContext<DeclarationForm>()
  const sequenceNumber = getValues(`${scope}.sequenceNumber`) ?? 0

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-house-consignment"
        headingLabel={t('declaration.p5.houseConsignment')}
      >
        <FormField labelKey="declaration.p5.sequenceNumber">
          <span>{sequenceNumber + 1}</span>
        </FormField>
        <FormField labelKey="declaration.p5.grossMass">
          <Input
            <HouseConsignmentScope>
            field={`${scope}.grossMass`}
            labelKey="declaration.p5.grossMass"
            type="number"
            inputMode="decimal"
            step={0.001}
            placeholder={t('declaration.p5.grossWeight')}
          />
        </FormField>
        <HouseConsignmentDetails scope={scope} />
      </CollapsibleColumn>
    </section>
  )
}

export default HouseConsignment
