import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DeclarationCountry } from '../../context/DeclarationContext'
import { IGoodsItem } from '../../types/IGoodsItem'
import { IDeclarationInfo, IGuarantee, ITransport } from '../../types/IDeclaration'
import {
  getCountryCodeFromCustomsOffice,
  getRegionalBloc,
  getValue,
  isIrelandProtocol,
  isNullOrBlank,
} from '../../helpers'
import { FieldUsage, getFieldUsageAccordingToC60, isBulkPackage } from '../../helpers/condition.helper'
import { Document, DocumentType } from '../../types/Document'
import { IConsignmentDetail } from '../../types/IConsignmentDetail'
import i18n from '../../i18n'
import { IClassifier } from '../../types/IClassifier'
import { IStoreState } from '../../redux/store'
import { UserContext } from '../../context/UserContext'
import { CustomerRequirements } from '../../types/Customer'
import { NctsProfileNameOption } from '../../types/NctsProfileNameResponse'

const TRANSPORT_MODE_PREFIX = 'TRANSPORT_MODE_'
const getDocumentFilesLength = (document: Document) => document.files.filter((file) => file.deleted !== true).length

function useValidator() {
  const {
    t,
  } = useTranslation()

  const { user } = useContext(UserContext)

  const [invalidFormFields, setInvalidFormFields] = useState<Array<string>>([])

  function getGoodsItemsGrossWeightSum(goodsItems: IGoodsItem[]) {
    let goodsItemsGrossWeightSum = 0
    for (const goodsItem of goodsItems) {
      if (goodsItem.grossWeight !== null) {
        goodsItemsGrossWeightSum = Number((goodsItemsGrossWeightSum + Number(goodsItem.grossWeight)).toFixed(3))
      }
    }
    return goodsItemsGrossWeightSum
  }

  const isAtLeastOneTransitCustomsOfficeRequired = (transport: ITransport, countryClassifiers: Array<IClassifier>) => {
    if (transport.departureCustomsOffice === null || transport.destinationCustomsOffice === null) {
      return false
    }
    const departureCountry = getCountryCodeFromCustomsOffice(transport.departureCustomsOffice)
    const destinationCountry = getCountryCodeFromCustomsOffice(transport.destinationCustomsOffice)

    if (departureCountry === destinationCountry) {
      return false
    }
    if (isIrelandProtocol(departureCountry, destinationCountry)) {
      return false
    }
    if ((['AD', 'SM'].indexOf(departureCountry) !== -1) || (['AD', 'SM'].indexOf(destinationCountry) !== -1)) {
      return true
    }

    let areDifferentContractingParties = false
    const departureRegionalBloc = getRegionalBloc(countryClassifiers.find((country) => country.codeLabel === departureCountry))
    const destinationRegionalBloc = getRegionalBloc(countryClassifiers.find((country) => country.codeLabel === destinationCountry))

    if (departureRegionalBloc !== undefined && destinationRegionalBloc !== undefined) {
      if (departureRegionalBloc !== destinationRegionalBloc) {
        areDifferentContractingParties = true
      }
    } else {
      areDifferentContractingParties = true
    }

    return areDifferentContractingParties
  }

  const areRequiredDocumentsUploaded = (documents: Array<Document>, documentFilesRequired: boolean, state: IStoreState) => {
    if (!documentFilesRequired) {
      return true
    }

    let isLatvianDeclaration = false
    const transportForm = { ...state.transportReducer.transport }
    if (transportForm.departureCustomsOffice !== null && transportForm.departureCustomsOffice.startsWith('CUSTOMS_OFFICE_LV')) {
      isLatvianDeclaration = true
    }
    let isBorderTransportRail = false
    let isInlandTransportRail = false
    if (transportForm.borderTransportMode !== null) {
      isBorderTransportRail = transportForm.borderTransportMode.substring(TRANSPORT_MODE_PREFIX.length).startsWith('2')
    }
    if (transportForm.inlandTransportMode !== null) {
      isInlandTransportRail = transportForm.inlandTransportMode.substring(TRANSPORT_MODE_PREFIX.length).startsWith('2')
    }

    let hasProformaInvoice = false // 325
    let hasCommercialInvoice = false // 380
    let hasRoadConsignmentNote = false // 730
    let hasTrainConsignmentNote = false // 722
    let hasOtherInvoice = false // ZZZ

    for (const document of documents) {
      if (document.deleted) {
        // eslint-disable-next-line no-continue
        continue
      }
      const documentNumber = getValue(document.documentType, 'DOCUMENT_TYPE_')
      if (documentNumber === '325' && !hasProformaInvoice) {
        hasProformaInvoice = getDocumentFilesLength(document) > 0
      } else if (documentNumber === '380' && !hasCommercialInvoice) {
        hasCommercialInvoice = getDocumentFilesLength(document) > 0
      } else if (documentNumber === '730' && !hasRoadConsignmentNote) {
        hasRoadConsignmentNote = getDocumentFilesLength(document) > 0
      } else if (documentNumber === '722' && !hasTrainConsignmentNote) {
        hasTrainConsignmentNote = getDocumentFilesLength(document) > 0
      } else if (documentNumber === 'ZZZ' && !hasOtherInvoice) {
        hasOtherInvoice = getDocumentFilesLength(document) > 0
      } else if (!isLatvianDeclaration && document.files.length > 0) {
        document.files = document.files.map((file) => {
          // eslint-disable-next-line no-param-reassign
          file.deleted = true
          return file
        })
      }
    }

    const hasInvoiceFiles = hasProformaInvoice || hasCommercialInvoice || hasOtherInvoice
    // 722 && 380/325/ZZZ
    if (isBorderTransportRail && isInlandTransportRail) {
      return hasTrainConsignmentNote && hasInvoiceFiles
    }

    // 730 || 722 && 380/325/ZZZ >> Both CMRs are needed
    if (isBorderTransportRail || isInlandTransportRail) {
      return (hasRoadConsignmentNote || hasTrainConsignmentNote) && hasInvoiceFiles
    }

    // 730 && 380/325/ZZZ
    return hasRoadConsignmentNote && hasInvoiceFiles
  }

  function iterateOverGoodsAndValidateDocuments(
    goodsItems: IGoodsItem[],
    areGoodsDocumentsValid: boolean,
    areGoodsItemDocumentNumberValid: boolean,
    arePreviousDocumentsValid: boolean,
  ) {
    goodsItems.forEach((item, index) => {
      const goodsDocuments = item.documents.filter((document) => document.type === 'GOODS' && !document.deleted)
      if ((index === 0 && goodsDocuments.length === 0)) {
        // eslint-disable-next-line no-param-reassign
        areGoodsDocumentsValid = false
      }
      if (index > 0 && goodsDocuments.find((goodsDocument730) => goodsDocument730.documentType === 'DOCUMENT_TYPE_730') !== undefined) {
        // eslint-disable-next-line no-param-reassign
        areGoodsDocumentsValid = false
      }
      goodsDocuments.forEach((goodsItemDocument) => {
        if (goodsItemDocument.number.length > 20) {
          // eslint-disable-next-line no-param-reassign
          areGoodsItemDocumentNumberValid = false
        }
      })
      const previousDocuments = item.documents.filter((document) => document.type === 'PREVIOUS' && !document.deleted)
      previousDocuments.forEach((previousDocument) => {
        if ((previousDocument.documentType === null || previousDocument.documentType === '')
          || (previousDocument.number === null || previousDocument.number === '')) {
          // eslint-disable-next-line no-param-reassign
          arePreviousDocumentsValid = false
        }
      })
    })
    return {
      areGoodsDocumentsValid,
      areGoodsItemDocumentNumberValid,
      arePreviousDocumentsValid,
    }
  }

  function validateFinnishDocuments(country: DeclarationCountry | null, goodsItems: IGoodsItem[]) {
    if (country !== 'FI') return

    let areGoodsDocumentsValid = true
    let arePreviousDocumentsValid = true
    let areGoodsItemDocumentNumberValid = true
    const areValidDocuments = iterateOverGoodsAndValidateDocuments(
      goodsItems,
      areGoodsDocumentsValid,
      areGoodsItemDocumentNumberValid,
      arePreviousDocumentsValid,
    )
    areGoodsDocumentsValid = areValidDocuments.areGoodsDocumentsValid
    areGoodsItemDocumentNumberValid = areValidDocuments.areGoodsItemDocumentNumberValid
    arePreviousDocumentsValid = areValidDocuments.arePreviousDocumentsValid
    if (!areGoodsItemDocumentNumberValid) {
      setInvalidFormFields((prevState) => [...prevState, t('declaration.finnish.error.transportDocumentLength')])
    }
    if (!areGoodsDocumentsValid) {
      setInvalidFormFields((prevState) => [...prevState, t('declaration.finnish.error.transportDocument')])
    }

    if (!arePreviousDocumentsValid) {
      setInvalidFormFields((prevState) => [...prevState, t('declaration.finnish.error.previousDocumentMandatory')])
    }
  }

  const validateGoods = (
    goodsItems: IGoodsItem[],
    country: DeclarationCountry | null,
    customerRequirement: CustomerRequirements | null,
    state: IStoreState,
  ) => {
    validateFinnishDocuments(country, goodsItems)

    let i = 0
    let missingFields = false
    let oneGoodsItemHasRequiredDocumentWithFiles = false

    for (const element of goodsItems) {
      const goodsItem = element
      i += 1
      if ((isNullOrBlank(goodsItem.quantityUnit)
        || (goodsItem.grossWeight === null || goodsItem.grossWeight < 0)
        || isNullOrBlank(goodsItem.dispatchCountryCode)
        || isNullOrBlank(goodsItem.destinationCountryCode))
      ) {
        missingFields = true
        break
      }

      if (getFieldUsageAccordingToC60(goodsItem.quantityUnit, 'MARKS') === FieldUsage.REQUIRED) {
        if (isNullOrBlank(goodsItem.marks) || goodsItem.marks === '') {
          missingFields = true
          // eslint-disable-next-line no-loop-func,@typescript-eslint/no-loop-func
          setInvalidFormFields((prevState) => [...prevState, `Missing required Marks and Number in item no. ${i}`])
          break
        }
      } else {
        goodsItem.marks = ''
      }

      if (getFieldUsageAccordingToC60(goodsItem.quantityUnit, 'QUANTITY') === FieldUsage.REQUIRED) {
        if (goodsItem.quantity === null || goodsItem.quantity < 0) {
          // eslint-disable-next-line no-loop-func,@typescript-eslint/no-loop-func
          setInvalidFormFields((prevState) => [...prevState, `Missing required Quantity for item no. ${i}`])
          missingFields = true
          break
        }
      } else {
        goodsItem.quantity = null
      }
      if (getFieldUsageAccordingToC60(goodsItem.quantityUnit, 'PIECES') === FieldUsage.REQUIRED) {
        if (goodsItem.pieces === null || goodsItem.pieces < 0) {
          // eslint-disable-next-line no-loop-func,@typescript-eslint/no-loop-func
          setInvalidFormFields((prevState) => [...prevState, `Missing required Pieces for item no. ${i}`])
          missingFields = true
          break
        }
      } else {
        goodsItem.pieces = null
      }
      if ((goodsItem.documents === null
        || goodsItem.documents.filter((doc) => doc.type === DocumentType.GOODS && !doc.deleted).length === 0) && country !== 'FI') {
        missingFields = true
        setInvalidFormFields((prevState) => [...prevState, t('messages.missingGoodsDocuments')])
      }

      for (const document of goodsItem.documents) {
        if (document.type === DocumentType.GOODS) {
          if ((isNullOrBlank(document.number) || isNullOrBlank(document.documentType))) {
            missingFields = true
            // eslint-disable-next-line no-loop-func,@typescript-eslint/no-loop-func
            setInvalidFormFields((prevState) => [...prevState, `${t('messages.missingGoodsDocumentInfo')} ${i}`])
          }
        }
      }
      if (missingFields && country !== 'FI') {
        // eslint-disable-next-line no-loop-func,@typescript-eslint/no-loop-func
        setInvalidFormFields((prevState) => [...prevState, `${t('messages.missingGoodsInfo')} ${i}`])
      }

      if (user?.role !== 'ADMIN' && customerRequirement !== null && !oneGoodsItemHasRequiredDocumentWithFiles
        && areRequiredDocumentsUploaded(goodsItem.documents, customerRequirement.documentFilesRequired, state)) {
        oneGoodsItemHasRequiredDocumentWithFiles = true
      }
      if (user?.role !== 'ADMIN' && customerRequirement !== null
        && customerRequirement.documentFilesRequired && !oneGoodsItemHasRequiredDocumentWithFiles) {
        toast.error(t('messages.requiredGoodsDocumentsNotUploaded', { 0: i }))
      }
    }
  }

  const validateTransport = (
    transport: ITransport,
    country: DeclarationCountry | null,
    countryClassifiers: Array<IClassifier>,
  ) => {
    if (transport.loadingPlace === null || transport.loadingPlace === '') {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:loadingPlace`) },
      )])
    }
    if (transport.unloadingPlace === null || transport.unloadingPlace === '') {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:unloadingPlace`) },
      )])
    }

    if (transport.inlandTransportMode === null || transport.inlandTransportMode === '') {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:inlandTransportMode`) },
      )])
    }
    if (transport.borderTransportMode === null || transport.borderTransportMode === '') {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:borderTransportMode`) },
      )])
    }
    if (transport.borderCrosserTruckNo === null || transport.borderCrosserTruckNo === '') {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:borderCrosserTruckNo`) },
      )])
    }
    if (transport.borderCrosserNationality === null || transport.borderCrosserNationality === '') {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:borderCrosserNationality`) },
      )])
    }
    if (transport.departureNationality === null || transport.departureNationality === '') {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:departureNationality`) },
      )])
    }

    // eslint-disable-next-line no-control-regex
    const cyrillicRegex = /^[\x00-\xFF]{1,1000}$/
    if (transport.departureTruckNo
      && !cyrillicRegex.test(transport.departureTruckNo)) {
      setInvalidFormFields((prevState) => [...prevState, t('messages.forbiddenCharacter', { 0: transport.departureTruckNo })])
    }

    if (transport.borderCrosserTruckNo
      && !cyrillicRegex.test(transport.borderCrosserTruckNo)) {
      setInvalidFormFields((prevState) => [...prevState, t('messages.forbiddenCharacter', { 0: transport.borderCrosserTruckNo })])
    }

    if (country === DeclarationCountry.ROMANIA) {
      if (transport.departureTruckNo != null && !transport.departureTruckNo.includes(';')) {
        setInvalidFormFields((prevState) => [...prevState, t('messages.missingDepartureTrailerSeparator')])
      }
      if (transport.borderCrosserTruckNo != null && !transport.borderCrosserTruckNo.includes(';')) {
        setInvalidFormFields((prevState) => [...prevState, t('messages.missingDestinationTrailerSeparator')])
      }
    }
    if (transport.isIndicatorContainerised) {
      for (const transportContainer of transport.transportContainers) {
        if (isNullOrBlank(transportContainer.containerNumber) || transportContainer.containerNumber === '') {
          setInvalidFormFields((prevState) => [...prevState, t('messages.missingContainerNumbers')])
        }
      }
    }

    if (transport.transportCustomsOffices.length > 0) {
      const allEntriesHaveValues = transport.transportCustomsOffices
        .filter((office) => !office.deleted)
        .every((entry) => !isNullOrBlank(entry.transitCustomsOffice))

      if (!allEntriesHaveValues) {
        setInvalidFormFields((prevState) => [...prevState, t('transportCustomsOffice.containsBlanks')])
      }
    }

    const leastOneTransitCustomsOfficeRequired = isAtLeastOneTransitCustomsOfficeRequired(transport, countryClassifiers)
    if (leastOneTransitCustomsOfficeRequired
      && transport.transportCustomsOffices.filter((office) => !office.deleted).length === 0) {
      setInvalidFormFields((prevState) => [...prevState, t('transportCustomsOffice.required')])
    }
  }

  const validateCarrierFields = (carrier: IConsignmentDetail, isSecurityEnabled: boolean) => {
    if (!isSecurityEnabled) return

    if (isNullOrBlank(carrier.zip)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:carrier_zip`) },
      )])
    }

    if (isNullOrBlank(carrier.name)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:carrier_name`) },
      )])
    }

    if (isNullOrBlank(carrier.street)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:carrier_street`) },
      )])
    }

    if (isNullOrBlank(carrier.city)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:carrier_city`) },
      )])
    }
    if (isNullOrBlank(carrier.country)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:carrier_country`) },
      )])
    }
  }

  const validateConsigneeFields = (consignee: IConsignmentDetail) => {
    if (isNullOrBlank(consignee.zip)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignee_zip`) },
      )])
    }

    if (isNullOrBlank(consignee.name)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignee_name`) },
      )])
    }

    if (isNullOrBlank(consignee.street)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignee_street`) },
      )])
    }

    if (isNullOrBlank(consignee.city)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignee_city`) },
      )])
    }
    if (isNullOrBlank(consignee.country)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignee_country`) },
      )])
    }
  }

  const validateConsignorFields = (consignor: IConsignmentDetail) => {
    if (isNullOrBlank(consignor.zip)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignor_zip`) },
      )])
    }

    if (isNullOrBlank(consignor.name)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignor_name`) },
      )])
    }

    if (isNullOrBlank(consignor.street)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignor_street`) },
      )])
    }

    if (isNullOrBlank(consignor.city)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignor_city`) },
      )])
    }
    if (isNullOrBlank(consignor.country)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:consignor_country`) },
      )])
    }
  }

  const validateDeclarationInfo = (declarationInfo: IDeclarationInfo, country: DeclarationCountry | null, goodsItems: IGoodsItem[]) => {
    for (const transitCountry of declarationInfo.transitCountries) {
      if (isNullOrBlank(transitCountry.code)) {
        setInvalidFormFields((prevState) => [...prevState, t('messages.missingTransitCountry')])
      }
    }

    if (isNullOrBlank(declarationInfo.grossMass)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t('messages.blankTotalGrossMass') },
      )])
    }

    if (isNullOrBlank(declarationInfo.packagesCount)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t('messages.blankPackagesCount') },
      )])
    }

    if (isNullOrBlank(declarationInfo.grossMass)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:grossMass`) },
      )])
    }

    if (isNullOrBlank(declarationInfo.dispatchCountryCode)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:dispatchCountryCode`) },
      )])
    }

    if (isNullOrBlank(declarationInfo.destinationCountryCode)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t(`translations${i18n.language.toUpperCase()}:destinationCountryCode`) },
      )])
    }

    const goodsItemsGrossWeightSum = getGoodsItemsGrossWeightSum(goodsItems)

    if (declarationInfo.grossMass === null
      || (Number(Number(declarationInfo.grossMass)
        .toFixed(3))
        !== Number(goodsItemsGrossWeightSum.toFixed(3)))) {
      setInvalidFormFields((prevState) => [...prevState, `Gross mass of ${declarationInfo.grossMass
      } under declaration info does not match the weight sum ${
        goodsItemsGrossWeightSum
      } from goods items`])
    }

    if (declarationInfo.packagesCount) {
      let goodsItemsQuantity = 0
      for (const goodsItem of goodsItems) {
        if (goodsItem.quantityUnit !== null && isBulkPackage(goodsItem.quantityUnit)) {
          goodsItemsQuantity += 1
        } else {
          if (goodsItem.quantity !== null) {
            goodsItemsQuantity += Number(goodsItem.quantity)
          }
          if (goodsItem.pieces !== null) {
            goodsItemsQuantity += Number(goodsItem.pieces)
          }
        }
      }
      if (Number(declarationInfo.packagesCount) !== goodsItemsQuantity) {
        setInvalidFormFields((prevState) => [...prevState, t('messages.r105Invalid', {
          0: declarationInfo.packagesCount,
          1: goodsItemsQuantity,
        })])
      }
    } else {
      toast.warn('Number of packages faulty')
    }
  }

  const validateCountryFields = (country: DeclarationCountry | null, goodsItems: IGoodsItem[], state: IStoreState) => {
    const { declarationInfoReducer } = state
    if (country === 'LV') {
      for (const goodsItem of goodsItems) {
        if (goodsItem.documents == null
          || goodsItem.documents.filter((doc: Document) => doc.type === DocumentType.GOODS && !doc.deleted).length === 0) {
          setInvalidFormFields((prevState) => [...prevState, `Goods Item ${goodsItem.description} is missing a document!`])
        }
      }
    }

    if (country === 'FI') {
      if (declarationInfoReducer.declarationInfo.goodsLocation === null) {
        setInvalidFormFields((prevState) => [...prevState, t('messages.goodsLocationError')])
      }

      if (declarationInfoReducer.declarationInfo.goodsLocationQualifier === null) {
        setInvalidFormFields((prevState) => [...prevState, t('messages.goodsLocationQualifierError')])
      }

      if (declarationInfoReducer.declarationInfo.goodsLocationQualifier !== null
        && declarationInfoReducer.declarationInfo.goodsLocationQualifier === 'L'
        && user?.role === 'ADMIN'
        && (declarationInfoReducer.declarationInfo.transitDateLimit === null
          || declarationInfoReducer.declarationInfo.transitDateLimit === '')) {
        setInvalidFormFields((prevState) => [...prevState, t('declaration.finnish.error.transitDateLimit')])
      }
      if (declarationInfoReducer.declarationInfo.goodsLocationQualifier !== null
        && (declarationInfoReducer.declarationInfo.transitLoadingDate === null
          || declarationInfoReducer.declarationInfo.transitLoadingDate === '')) {
        setInvalidFormFields((prevState) => [...prevState, t('declaration.finnish.error.transitLoadingDate')])
      }
    }
  }

  const validateGoodsItemConsignmentDetails = (declarationGoodsItems: Array<IGoodsItem>) => {
    if (declarationGoodsItems.length === 0) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t('declaration.headers.goodsItems') },
      )])
    }

    declarationGoodsItems.forEach((goodsItem) => {
      if (isNullOrBlank(goodsItem.consignor.zip)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignor_zip`) },
        )])
      }

      if (isNullOrBlank(goodsItem.consignor.name)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignor_name`) },
        )])
      }

      if (isNullOrBlank(goodsItem.consignor.street)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignor_street`) },
        )])
      }

      if (isNullOrBlank(goodsItem.consignor.city)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignor_city`) },
        )])
      }
      if (isNullOrBlank(goodsItem.consignor.country)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignor_country`) },
        )])
      }

      if (isNullOrBlank(goodsItem.consignee.zip)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignee_zip`) },
        )])
      }

      if (isNullOrBlank(goodsItem.consignee.name)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignee_name`) },
        )])
      }

      if (isNullOrBlank(goodsItem.consignee.street) || goodsItem.consignee.street.length < 1) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignee_street`) },
        )])
      }

      if (isNullOrBlank(goodsItem.consignee.city)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignee_city`) },
        )])
      }
      if (isNullOrBlank(goodsItem.consignee.country)) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t(`translations${i18n.language.toUpperCase()}:consignee_country`) },
        )])
      }
    })
  }

  const validateGuarantee = (guarantee: IGuarantee) => {
    if (isNullOrBlank(guarantee.amount)) {
      setInvalidFormFields((prevState) => [...prevState, t(
        'messages.missingField',
        { field: t('declaration.guaranteeAmount') },
      )])
    }

    if (isNullOrBlank(guarantee.amount)
      || (guarantee.amount !== null && guarantee.amount <= 0)) {
      setInvalidFormFields((prevState) => [...prevState, t('messages.invalidGuaranteeAmount')])
    }
  }

  const validateCustomerRequirements = (
    customerRequirement: CustomerRequirements | null,
    nctsProfileNames: Array<NctsProfileNameOption>,
    state: IStoreState,
  ) => {
    const desiredGuaranteeAmount = state.guaranteeReducer.guarantee.amount
    if (customerRequirement?.maxGuaranteeAmount !== 0) {
      if (desiredGuaranteeAmount == null) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t('declaration.guaranteeAmount') },
        )])
      }
      const selectedProfile = state.declarationReducer.declaration.profile
      if (selectedProfile !== null
        && selectedProfile !== undefined && selectedProfile !== ''
        && (nctsProfileNames.find((item) => item.value === selectedProfile)?.isShared ?? false)
        && customerRequirement?.maxGuaranteeAmount !== null
        && Number(Number(desiredGuaranteeAmount)
          .toFixed(3)) > Number(customerRequirement?.maxGuaranteeAmount.toFixed(3))) {
        setInvalidFormFields((prevState) => [...prevState, t(
          'messages.missingField',
          { field: t('Guarantee amount exceeds limit') },
        )])
      }
    }
  }

  const validate = (
    goodsItems: IGoodsItem[],
    isDraft: boolean,
    country: DeclarationCountry | null,
    countryClassifiers: Array<IClassifier>,
    state: IStoreState,
    customerRequirements: CustomerRequirements | null,
    nctsProfileNames: Array<NctsProfileNameOption>,
  ) => {
    setInvalidFormFields([])
    if (isDraft) return
    validateCustomerRequirements(customerRequirements, nctsProfileNames, state)
    validateGoods(goodsItems, country, customerRequirements, state)
    if (!state.declarationReducer.declaration.isGoodsTradersEnabled) {
      validateConsigneeFields(state.consignmentDetailReducer.consignee)
      validateConsignorFields(state.consignmentDetailReducer.consignor)
    }
    validateCarrierFields(state.consignmentDetailReducer.carrier, state.declarationReducer.declaration.isSecurityEnabled!)
    validateTransport(state.transportReducer.transport, country, countryClassifiers)
    validateDeclarationInfo(state.declarationInfoReducer.declarationInfo, country, goodsItems)
    validateCountryFields(country, goodsItems, state)
    if (state.declarationReducer.declaration.isGoodsTradersEnabled) {
      validateGoodsItemConsignmentDetails(goodsItems)
    }
    validateGuarantee(state.guaranteeReducer.guarantee)
  }

  return {
    validate,
    invalidFormFields,
  }
}

export default useValidator
