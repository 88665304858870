import React from 'react'
import { DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarTransportDocuments from './TransitSidebarTransportDocuments'

export interface TransitSidebarConsignmentItemPreviousDocumentsProps {
  consignmentId: number | null
  transportDocuments: DocumentResponse[]
}

function TransitSidebarConsignmentTransportDocuments({
  consignmentId,
  transportDocuments,
}: TransitSidebarConsignmentItemPreviousDocumentsProps) {
  const consignmentTransportDocuments = transportDocuments
    .filter((transportDocument) => transportDocument.consignmentId === consignmentId)
    .sort(sortBySequenceNumber)

  if (consignmentTransportDocuments.length === 0) {
    return null
  }

  return (
    <div className="p-2">
      <TransitSidebarTransportDocuments transportDocuments={consignmentTransportDocuments} />
    </div>
  )
}

export default TransitSidebarConsignmentTransportDocuments
