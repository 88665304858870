import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import DeclarationApi from '../../apiConfig'
import { CustomsRequest } from '../../../../common/models'

const {
  baseUrl,
} = DeclarationApi.paths.transitOperation

function useGenerateSubmissionFormActionApi() {
  const generateXml = useMutation({
    mutationFn: async (request: CustomsRequest) => (await axios.post<Blob>(`${baseUrl}/customs/generate`, request, { responseType: 'blob' })),
  })

  return {
    generateXml,
  }
}

export default useGenerateSubmissionFormActionApi
