import { FieldPathRule, FormScope, RuleResult } from 'types/DeclarationP5'
import { ZodIssue } from 'zod'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { extractCountryFromDepartureOffice } from '../../../common/utils/classifier-util'
import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'
import {
  excludeDeleted, hasText, nonNullConsignee, nonNullConsignorIgnoringContactPerson,
} from '../../../common/utils/common-util'
import C0001Validator from './conditional/C0001'
import C0030Validator from './conditional/C0030'
import C0035Validator from './conditional/C0035'
import C0055Validator from './conditional/C0055'
import C0191Validator from './conditional/C0191'
import C0387Validator from './conditional/C0387'
import C0403Validator from './conditional/C0403'
import C0502Validator from './conditional/C0502'
import C0505Validator from './conditional/C0505'
import C0531Validator from './conditional/C0531'
import C0586Validator from './conditional/C0586'
import C0598Validator from './conditional/C0598'
import C0599Validator from './conditional/C0599'
import C0670Validator from './conditional/C0670'
import C0806Validator from './conditional/C0806'
import C0821Validator from './conditional/C0821'
import C0822Validator from './conditional/C0822'
import C0823Validator from './conditional/C0823'
import C0826Validator from './conditional/C0826'
import C0839Validator from './conditional/C0839'
import C0250Validator from './conditional/C0250'
import R0506Validator from './final-rules/R0506'
import R0905Validator from './final-rules/R0905'
import R0789Validator from './final-rules/R0789'
import R0855Validator from './final-rules/R0855'
import R0994Validator from './final-rules/R0994'
import B1877Validator from './transitional/B1877'
import B1892Validator from './transitional/B1892'
import B1897Validator from './transitional/B1897'
import C0394Validator from './conditional/C0394'
import C0382Validator from './conditional/C0382'
import NC9012Validator from './FI/NC9012'
import NC0035Validator from './FI/NC0035'
import C0671Validator from './conditional/C0671'
import C0710Validator from './conditional/C0710'
import { isTransportDocumentNumberUsable } from '../../components/PreviousDocuments'
import C0542Validator from './conditional/C0542'
import E1301Validator from './E1301'
import CustomValidators from './CustomValidators'
import B2400Validator from './transitional/B2400'
import R0003Validator from './final-rules/R0003'
import R0006Validator from './final-rules/R0006'
import R0107Validator from './final-rules/R0107'
import R0474Validator from './final-rules/R0474'
import B2101Validator from './transitional/B2101'
import R0476Validator from './final-rules/R0476'
import R0448Validator from './final-rules/R0448'
import R0472Validator from './final-rules/R0472'
import C0101Validator from './conditional/C0101'
import R0859Validator from './final-rules/R0859'
import R0904Validator from './final-rules/R0904'
import R0906Validator from './final-rules/R0906'
import R3060Validator from './final-rules/R3060'
import invokeHouseConsignmentValidators from './invokeHouseConsignmentValidators'
import C0587Validator from './conditional/C0587'
import R0103Validator from './final-rules/R0103'
import R0005Validator from './final-rules/R0005'

function coalesceByRuleSeverity(rules: RuleResult[]): RuleResult {
  if (rules.some((rule) => rule === 'NOT_ALLOWED')) {
    return 'NOT_ALLOWED'
  }
  if (rules.some((rule) => rule === 'REQUIRED')) {
    return 'REQUIRED'
  }

  return 'OPTIONAL'
}

const invokeValidators = (formData: DeclarationForm) => {
  const updatedRules: FieldPathRule[] = []
  const updatePathResult = (path: FormScope, ruleResult: RuleResult | null) => {
    const ruleAtPath = updatedRules.find((item) => item.path === path)
    if (ruleAtPath) {
      ruleAtPath.ruleResult = ruleResult
    } else {
      updatedRules.push(
        {
          ruleResult,
          path,
          issues: [],
        },
      )
    }
  }
  const addPathIssue = (path: FormScope, issue: ZodIssue) => {
    const ruleAtPath = updatedRules.find((item) => item.path === path)
    if (ruleAtPath) {
      ruleAtPath.issues.push(issue)
    } else {
      updatedRules.push(
        {
          ruleResult: null,
          path,
          issues: [issue],
        },
      )
    }
  }

  const country = extractCountryFromDepartureOffice(formData.departureCustomsOffice) ?? ''

  // Required fields
  formData.houseConsignment.forEach((houseConsignment, houseIndex) => {
    for (let i = 0; i < houseConsignment.consignmentItem.length; i += 1) {
      updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityDescriptionOfGoods`, 'REQUIRED')

      const {
        commodityHarmonizedSystemSubHeadingCode,
        commodityCombinedNomenclatureCode,
        previousDocument,
        supportingDocument,
        transportDocument,
      } = houseConsignment.consignmentItem[i]

      if (country === 'BG') {
        const notAllowedBulgarianCodes = [
          '870210', '870220', '870230', '870240', '870290', '870421', '870422', '870423', '870431', '870432',
          '870441', '870442', '870443', '870451', '870452', '870460', '870490', '871610', '871631', '871639', '871640',
          '871620', '840734', '840790', '840820',
        ]

        if (notAllowedBulgarianCodes.includes(commodityHarmonizedSystemSubHeadingCode)) {
          addPathIssue(`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityHarmonizedSystemSubHeadingCode`, {
            code: 'custom',
            path: [`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityHarmonizedSystemSubHeadingCode`],
            fatal: true,
            message: 'Contains Forbidden Codes',
          })
        }

        if (hasText(commodityCombinedNomenclatureCode)) {
          addPathIssue(`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityCombinedNomenclatureCode`, {
            code: 'custom',
            path: [`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityCombinedNomenclatureCode`],
            fatal: true,
            message: 'Maximum HS-Code length can only be 6 characters',
          })
        }

        if (commodityHarmonizedSystemSubHeadingCode.length > 6) {
          addPathIssue(`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityHarmonizedSystemSubHeadingCode`, {
            code: 'custom',
            path: [`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityHarmonizedSystemSubHeadingCode`],
            fatal: true,
            message: 'Maximum HS-Code length can only be 6 characters',
          })
        }
      }

      previousDocument.forEach((doc, docIndex) => {
        if ((hasText(doc.procedureType)) && country === 'EE') {
          return
        }
        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.previousDocument.${docIndex}.documentType`, 'REQUIRED')
        if (isTransportDocumentNumberUsable(doc.documentType, country ?? '')) {
          updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.previousDocument.${docIndex}.transportDocumentReferenceNumber`, 'REQUIRED')
        }

        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.previousDocument.${docIndex}.referenceNumber`, 'REQUIRED')
      })
      supportingDocument.forEach((doc, docIndex) => {
        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.supportingDocument.${docIndex}.documentType`, 'REQUIRED')
      })
      transportDocument.forEach((doc, docIndex) => {
        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.transportDocument.${docIndex}.documentType`, 'REQUIRED')
      })
    }
  })
  updatePathResult('security', 'REQUIRED')

  // After Transition Date
  // ToDo: Check with UK and / or other countries
  if (isTransitionalPeriodActive()) {
    updatePathResult('departureCustomsOffice', 'REQUIRED')
    updatePathResult('placeOfLoading', 'REQUIRED')
    updatePathResult('countryOfDispatch', 'NOT_ALLOWED')
    formData.activeBorderTransportMeans.filter(excludeDeleted).forEach((item, index) => {
      updatePathResult(`activeBorderTransportMeans.${index}.identificationNumber`, 'REQUIRED')
      updatePathResult(`activeBorderTransportMeans.${index}.customsOfficeAtBorderReferenceNumber`, 'REQUIRED')
      updatePathResult(`activeBorderTransportMeans.${index}.nationality`, 'REQUIRED')
      updatePathResult(`activeBorderTransportMeans.${index}.typeOfIdentification`, 'REQUIRED')
    })
    formData.departureTransportMeans.filter(excludeDeleted).forEach((item, index) => {
      updatePathResult(`departureTransportMeans.${index}.identificationNumber`, 'REQUIRED')
      updatePathResult(`departureTransportMeans.${index}.nationality`, 'REQUIRED')
      updatePathResult(`departureTransportMeans.${index}.typeOfIdentification`, 'REQUIRED')
    })

    formData.houseConsignment.filter(excludeDeleted).forEach((houseConsignment, houseIndex) => {
      houseConsignment.consignmentItem.filter(excludeDeleted).forEach((houseConsignmentItem, index) => {
        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${index}.goodsMeasureGrossMass`, 'REQUIRED')
      })
    })
  }

  if (formData.grossMass === null || formData.grossMass <= 0) {
    addPathIssue('grossMass', {
      code: 'custom', path: ['grossMass'], fatal: true, message: 'Required',
    })
  }

  updatePathResult('grossMass', 'REQUIRED')

  if (formData.departureCustomsOffice === null || formData.departureCustomsOffice === '') {
    addPathIssue('departureCustomsOffice', {
      code: 'custom', path: ['departureCustomsOffice'], fatal: true, message: 'Required',
    })
  }
  updatePathResult('departureCustomsOffice', 'REQUIRED')

  if (formData.destinationCustomsOffice === null || formData.destinationCustomsOffice === '') {
    addPathIssue('destinationCustomsOffice', {
      code: 'custom', path: ['destinationCustomsOffice'], fatal: true, message: 'Required',
    })
  }
  updatePathResult('destinationCustomsOffice', 'REQUIRED')
  updatePathResult('security', 'REQUIRED')
  updatePathResult('guarantees', 'REQUIRED')
  updatePathResult('guarantees.0.profileName', 'REQUIRED')
  updatePathResult('guarantees.0.amount', 'REQUIRED')
  formData.houseConsignment.filter(excludeDeleted).forEach((house, index) => {
    updatePathResult(`houseConsignment.${index}.grossMass`, 'REQUIRED')
    if (house.grossMass === null || house.grossMass <= 0) {
      addPathIssue(`houseConsignment.${index}.grossMass`, {
        code: 'custom', path: [`houseConsignment.${index}.grossMass`], fatal: true, message: 'Required',
      })
    }
  })

  const previousDocumentRequirementForAllItems = C0035Validator.isPreviousDocumentRequired(formData)
  const containerIndicatorRequirement = C0822Validator.validateContainerIndicatorRequirement(formData)
  updatePathResult('containerIndicator', containerIndicatorRequirement)
  const transportEquipmentRequirement = C0823Validator.validateTransportEquipmentRequirement(formData)
  updatePathResult('transportEquipment', transportEquipmentRequirement)
  const limitDateRequirement = C0839Validator.validateLimitDateRequirement(formData)
  updatePathResult('limitDate', limitDateRequirement)

  const goodsReferenceRequirement = C0670Validator.validateGoodsReferenceRequirement(formData)

  if (!R0107Validator.hasUniqueSealIdentifiers(formData.transportEquipment)) {
    addPathIssue('transportEquipment', {
      code: 'custom', path: ['transportEquipment'], fatal: true, message: 'R0107 : Seal identifiers must be unique',
    })
  }

  formData.transportEquipment.forEach((transportEquipment, transportEquipmentIndex) => {
    if (transportEquipment.deleted) {
      return
    }

    const containerIdentificationNumberRequired = C0055Validator
      .isContainerIdentificationNumberRequired(formData, transportEquipment.sequenceNumber)
    updatePathResult(`transportEquipment.${transportEquipmentIndex}.containerIdentificationNumber`, containerIdentificationNumberRequired)
    updatePathResult(`transportEquipment.${transportEquipmentIndex}.goodsReferences`, goodsReferenceRequirement)

    transportEquipment.goodsReferences.forEach((goodsReferences, goodsReferenceIndex) => {
      updatePathResult(
        `transportEquipment.${transportEquipmentIndex}.goodsReferences.${goodsReferenceIndex}.declarationGoodsItemNumber`,
        'REQUIRED',
      )
    })

    const sealsRequirement = R0448Validator.validateSealsRequirement(transportEquipment)
    updatePathResult(`transportEquipment.${transportEquipmentIndex}.seals`, sealsRequirement)

    transportEquipment.seals.forEach((seal, sealIndex) => {
      updatePathResult(
        `transportEquipment.${transportEquipmentIndex}.seals.${sealIndex}.identifier`,
        'REQUIRED',
      )
    })
  })

  if (country === 'FI' && !hasText(formData.referenceNumberUCR)) {
    updatePathResult('referenceNumberUCR', 'REQUIRED')
  }

  const unloadingRequirement = C0191Validator.validatePlaceOfUnloadingRequirement(formData.security)
  updatePathResult('placeOfUnloading', unloadingRequirement)

  const transportAtBorderRequirement = C0599Validator
    .validateModeOfTransportAtBorderRequirement(formData)
  updatePathResult('borderModeOfTransport', transportAtBorderRequirement)

  if (unloadingRequirement !== 'NOT_ALLOWED') {
    const unloadingCountryAndLocationRequirement = C0387Validator.validateCountryAndLocationRequirement(formData.placeOfUnloading.unLocode)
    updatePathResult('placeOfUnloading.location', coalesceByRuleSeverity([unloadingCountryAndLocationRequirement]))
    updatePathResult('placeOfUnloading.country', coalesceByRuleSeverity([unloadingCountryAndLocationRequirement]))
    if (country === 'FI') {
      updatePathResult('placeOfUnloading.unLocode', 'NOT_ALLOWED')
    }
  }

  const placeOfLoadingRequirement = C0403Validator.validatePlaceOfLoadingRequirement(formData)
  updatePathResult('placeOfLoading', placeOfLoadingRequirement)

  if (placeOfLoadingRequirement !== 'NOT_ALLOWED') {
    const loadingCountryAndLocationRequirement = C0387Validator.validateCountryAndLocationRequirement(formData.placeOfLoading.unLocode)
    updatePathResult('placeOfLoading.location', coalesceByRuleSeverity([loadingCountryAndLocationRequirement]))
    updatePathResult('placeOfLoading.country', coalesceByRuleSeverity([loadingCountryAndLocationRequirement]))
    if (country === 'FI') {
      updatePathResult('placeOfLoading.unLocode', 'NOT_ALLOWED')
    }
  }

  updatePathResult('locationOfGoods', C0710Validator.validateLocationOfGoodsRequirement(formData))

  updatePathResult('locationOfGoods.qualifierOfIdentification', 'NOT_ALLOWED')
  updatePathResult('locationOfGoods.additionalIdentifier', 'NOT_ALLOWED')
  if (hasText(formData.locationOfGoods?.typeOfLocation)) {
    updatePathResult('locationOfGoods.typeOfLocation', 'REQUIRED')
    updatePathResult('locationOfGoods', 'REQUIRED')
  }

  if (country !== 'FI') {
    if (hasText(formData.locationOfGoods?.typeOfLocation)) {
      updatePathResult('locationOfGoods.qualifierOfIdentification', 'REQUIRED')
      const unLocodeRequirement = C0394Validator.validateUnLocodeRequirement(formData)
      updatePathResult('locationOfGoods.unLocode', unLocodeRequirement)
    }

    updatePathResult('locationOfGoods.additionalIdentifier', C0671Validator.validateAdditionalIdentifierRequirement(formData))
  }

  const customsOfficeRequirement = C0394Validator.validateCustomsOfficeRequirement(formData)
  updatePathResult('locationOfGoods.referenceNumber', customsOfficeRequirement)

  const economicOperatorRequirement = country === 'FI'
    ? NC0035Validator.validateEconomicOperatorRequirement(formData) : C0394Validator.validateEconomicOperatorRequirement(formData)
  updatePathResult('locationOfGoods.economicOperatorIdentificationNumber', economicOperatorRequirement)

  const authorisationNumberRequirement = C0394Validator.validateAuthorisationNumberRequirement(formData)

  updatePathResult('locationOfGoods.authorisationNumber', authorisationNumberRequirement)
  const postCodeAddressRequirement = C0394Validator.validatePostCodeAddressRequirement(formData)
  updatePathResult('locationOfGoods.postcodeAddress', postCodeAddressRequirement)

  if (postCodeAddressRequirement !== 'NOT_ALLOWED') {
    const houseNumberRequirement = C0382Validator.validateHouseNumberRequirement(formData)
    updatePathResult('locationOfGoods.postcodeAddress.houseNumber', houseNumberRequirement)
  }

  const gnssRequirement = C0394Validator.validateGnssRequirement(formData)
  updatePathResult('locationOfGoods.gnss', gnssRequirement)
  const contactPersonRequirement = C0394Validator.validateContactPersonRequirement(formData)
  updatePathResult('locationOfGoods.contactPerson', contactPersonRequirement)
  const locationAddressRequirement = NC9012Validator.validateAddressRequirement(formData)
  updatePathResult('locationOfGoods.address', locationAddressRequirement)

  if (formData.locationOfGoods?.address !== null && locationAddressRequirement !== 'NOT_ALLOWED') {
    const locationOfGoodsPostcodeRequirement = C0505Validator.validatePostcodeRequirement(formData.locationOfGoods?.address?.country)
    updatePathResult('locationOfGoods.address.country', locationAddressRequirement)
    updatePathResult('locationOfGoods.address.streetAndNumber', locationAddressRequirement)
    updatePathResult('locationOfGoods.address.city', locationAddressRequirement)

    updatePathResult('locationOfGoods.address.postcode', locationOfGoodsPostcodeRequirement)
  }

  let consigneeRequirement: RuleResult = C0001Validator.validateConsignmentConsigneeRequirement(formData)
  let isHouseConsigneeAllowed = E1301Validator.isDataGroupAllowed()
  if (isHouseConsigneeAllowed) {
    isHouseConsigneeAllowed = consigneeRequirement !== 'NOT_ALLOWED'
  }

  const isConsignmentItemConsigneeAllowed = B2400Validator.isDataGroupAllowed()

  if (
    isHouseConsigneeAllowed
    && !nonNullConsignee(formData.consignee) // avoid lock
    && CustomValidators.hasAnyHouseConsignmentConsigneeNonNull(formData)
  ) {
    consigneeRequirement = 'NOT_ALLOWED'
  } else if (isConsignmentItemConsigneeAllowed && CustomValidators.hasAnyConsignmentItemConsigneeNonNull(formData)) {
    consigneeRequirement = 'NOT_ALLOWED'
  }

  updatePathResult('consignee', consigneeRequirement)
  updatePathResult('consignee.address', consigneeRequirement)
  if (consigneeRequirement !== 'NOT_ALLOWED') {
    const consigneeAddressRequirement: RuleResult = country === 'RO' ? C0250Validator.validateNameAndAddressRequirement(formData.consignee.identificationNumber) : consigneeRequirement
    // ToDo: Check with UK and / or other countries
    // updatePathResult('consignee.identificationNumber', consigneeAddressRequirement)
    updatePathResult('consignee.name', consigneeAddressRequirement)
    updatePathResult('consignee.address', consigneeAddressRequirement)
    if (consigneeAddressRequirement !== 'NOT_ALLOWED') {
      updatePathResult('consignee.address.country', consigneeAddressRequirement)
      updatePathResult('consignee.address.streetAndNumber', consigneeAddressRequirement)
      updatePathResult('consignee.address.city', consigneeAddressRequirement)

      const consigneePostcodeRequirement = consigneeAddressRequirement === 'REQUIRED'
        ? C0505Validator.validatePostcodeRequirement(formData.consignee.address?.country)
        : 'OPTIONAL'
      updatePathResult('consignee.address.postcode', consigneePostcodeRequirement)
    }
  }
  updatePathResult('carrier.carrierName', 'REQUIRED')
  const carrierContactRequirement = country === 'LT' && hasText(formData.carrier.identificationNumber) ? 'REQUIRED' : 'OPTIONAL'
  if (carrierContactRequirement === 'REQUIRED') {
    updatePathResult('carrier.contactPerson.name', 'REQUIRED')
    updatePathResult('carrier.contactPerson.telephone', 'REQUIRED')
    updatePathResult('carrier.contactPerson.email', 'REQUIRED')
  }

  let consignorRequirement: RuleResult = 'NOT_ALLOWED'
  let isHouseConsignorAllowed = E1301Validator.isDataGroupAllowed()
  if (isHouseConsignorAllowed) {
    consignorRequirement = C0542Validator.validateConsignmentConsignorRequirement(formData)

    isHouseConsignorAllowed = consignorRequirement !== 'NOT_ALLOWED'

    if (
      !nonNullConsignorIgnoringContactPerson(formData.consignor) // avoid lock
      && CustomValidators.hasAnyHouseConsignmentConsignorNonNull(formData)
    ) {
      consignorRequirement = 'NOT_ALLOWED'
    }
  }

  const consignorAddressRequirement: RuleResult = country === 'RO' ? C0250Validator.validateNameAndAddressRequirement(formData.consignor.identificationNumber) : 'REQUIRED'

  updatePathResult('consignor', consignorRequirement)
  updatePathResult('consignor.address', consignorRequirement)
  if (country === 'LT') {
    updatePathResult('consignor.contactPerson', consignorRequirement)
  }

  if (consignorRequirement !== 'NOT_ALLOWED') {
    // ToDo: Check with UK and / or other countries
    // updatePathResult('consignor.identificationNumber', consignorAddressRequirement)
    updatePathResult('consignor.name', consignorAddressRequirement)
    updatePathResult('consignor.address', consignorAddressRequirement)
    if (country === 'LT') {
      updatePathResult('consignor.contactPerson', consignorAddressRequirement)
    }
    if (consignorAddressRequirement !== 'NOT_ALLOWED') {
      updatePathResult('consignor.address.country', consignorAddressRequirement)
      updatePathResult('consignor.address.streetAndNumber', consignorAddressRequirement)
      updatePathResult('consignor.address.city', consignorAddressRequirement)
      const consignorPostcodeRequirement = C0505Validator.validatePostcodeRequirement(formData.consignor.address?.country)
      updatePathResult('consignor.address.postcode', consignorPostcodeRequirement)
      if (country === 'LT') {
        updatePathResult('consignor.contactPerson.name', consignorAddressRequirement)
        updatePathResult('consignor.contactPerson.telephone', consignorAddressRequirement)
        updatePathResult('consignor.contactPerson.email', consignorAddressRequirement)
      }
    }
  }

  const borderConveyanceNumberRequirement = C0531Validator.validateBorderTransportMeansConveyanceReferenceNumberRequirement(formData)
  const borderTransportMeansRequirement = C0806Validator.validateBorderTransportMeansRequirement(formData)
  updatePathResult('activeBorderTransportMeans', borderTransportMeansRequirement)
  if (borderTransportMeansRequirement !== 'NOT_ALLOWED') {
    formData.activeBorderTransportMeans.forEach((item, index) => {
      if (item.deleted) {
        return
      }
      updatePathResult(`activeBorderTransportMeans.${index}.conveyanceReferenceNumber`, borderConveyanceNumberRequirement)
    })
  }

  const countryOfRoutingRequirement = C0586Validator.validateCountryOfRoutingRequirement(formData)
  updatePathResult('countryOfRoutingOfConsignment', countryOfRoutingRequirement)
  formData.countryOfRoutingOfConsignment.forEach((item, index) => {
    if (item.deleted) {
      return
    }
    updatePathResult(`countryOfRoutingOfConsignment.${index}.country`, 'REQUIRED')
  })
  const departureMeansForConsignmentRequirement = C0826Validator.validateDepartureTransportMeansForConsignmentRequirement(formData)
  updatePathResult('departureTransportMeans', departureMeansForConsignmentRequirement)

  if (departureMeansForConsignmentRequirement !== 'NOT_ALLOWED') {
    let identifyRequirement: RuleResult = 'REQUIRED'
    if (!B2101Validator.isDataGroupRequired()) {
      identifyRequirement = B1892Validator.validateIdentificationRequirement(formData.containerIndicator)
    }

    formData.departureTransportMeans.forEach((item, index) => {
      if (item.deleted) {
        return
      }
      updatePathResult(`departureTransportMeans.${index}.typeOfIdentification`, identifyRequirement)
      updatePathResult(`departureTransportMeans.${index}.identificationNumber`, identifyRequirement)
      updatePathResult(
        `departureTransportMeans.${index}.nationality`,
        B1897Validator.validateDepartureTransportMeansNationalityRequirement(formData),
      )

      if (item.identificationNumber.match(/^[a-zA-Z0-9]+$/) === null) {
        addPathIssue(`departureTransportMeans.${index}.identificationNumber`, {
          code: 'custom',
          path: [`departureTransportMeans.${index}.identificationNumber`],
          fatal: true,
          message: 'Registration number must contain only letters and numbers. Trailer should be added as secondary item with type 31',
        })
      }

      if (!R0472Validator.isDepartureTransportMeansTypeOfIdentificationValid(formData, item)) {
        addPathIssue(`departureTransportMeans.${index}.typeOfIdentification`, {
          code: 'custom', path: [`departureTransportMeans.${index}.typeOfIdentification`], fatal: true, message: 'R0472',
        })
      }

      if (!R0474Validator.isFirstDepartureMeansTypeOfIdentificationCorrectForRoadTransport(formData, item, index)) {
        addPathIssue(`departureTransportMeans.${index}.typeOfIdentification`, {
          code: 'custom', path: [`departureTransportMeans.${index}.typeOfIdentification`], fatal: true, message: 'R0474 : First departure transport means type of identification must be 30',
        })
      }

      if (!R0476Validator.isOtherDepartureMeansTypeOfIdentificationCorrectForRoadTransport(formData, item, index)) {
        addPathIssue(`departureTransportMeans.${index}.typeOfIdentification`, {
          code: 'custom', path: [`departureTransportMeans.${index}.typeOfIdentification`], fatal: true, message: 'R0476 : Remaining departure transport means must have type of identification 31',
        })
      }
    })
  }

  if (!R0904Validator.isDestinationCountryValid(formData)) {
    addPathIssue('destinationCustomsOffice', {
      code: 'custom', path: ['destinationCustomsOffice'], fatal: true, message: 'R0904',
    })
  }

  if (!R0905Validator.hasNonSmOrAdDestinationForCtcCountry(formData)) {
    addPathIssue('destinationCustomsOffice', {
      code: 'custom', path: ['destinationCustomsOffice'], fatal: true, message: 'R0905',
    })
  }

  const minimumOverallGrossMass = R0994Validator.getMinimumConsignmentGrossMass(formData.houseConsignment)
  if (formData.grossMass < minimumOverallGrossMass) {
    addPathIssue('grossMass', {
      code: 'custom', path: ['grossMass'], fatal: true, message: 'R0994',
    })
  }

  const activeBorderTransportMeansMultiplicity = R0789Validator.getActiveBorderTransportMeansMultiplicity(formData.transitCustomsOffices)
  if (activeBorderTransportMeansMultiplicity < formData.activeBorderTransportMeans.filter(excludeDeleted).length) {
    addPathIssue('activeBorderTransportMeans', {
      code: 'custom', path: ['activeBorderTransportMeans'], fatal: true, message: 'R0789',
    })
  }

  const departureTransportMeansMultiplicity = R0855Validator.getDepartureTransportMeansMultiplicity(formData.inlandModeOfTransport)
  if (departureTransportMeansMultiplicity < formData.departureTransportMeans.filter(excludeDeleted).length) {
    addPathIssue('departureTransportMeans', {
      code: 'custom', path: ['departureTransportMeans'], fatal: true, message: 'R0855',
    })
  }

  // Rules that should run once for all house consignments:

  const nomenclatureCodeRequirement = C0821Validator.validateCombinedNomenclatureCodeRequirement(formData)
  const departureMeansForHouseRequirement = C0826Validator.validateDepartureTransportMeansForHouseConsignmentRequirement(formData)
  const isHouseDepartureMeansEnabled = (departureMeansForHouseRequirement !== 'NOT_ALLOWED')

  const hasUniqueHouseDepartureMeans = isHouseDepartureMeansEnabled
    ? R0506Validator.hasMultipleUniqueDepartureTransportMeansForHouseConsignment(formData) : true

  const hasUniqueHouseConsignors = isHouseConsignorAllowed
    ? R0506Validator.hasMultipleUniqueConsignorForHouseConsignment(formData) : true

  const hasUniqueHouseConsignees = isHouseConsigneeAllowed
    ? R0506Validator.hasMultipleUniqueConsigneeForHouseConsignment(formData) : false

  const hasUniqueItemConsignees = isTransitionalPeriodActive()
    ? B1877Validator.hasMultipleUniqueConsigneeForConsignmentItem(formData) : false

  formData.houseConsignment.forEach((houseConsignment, houseIndex) => {
    if (houseConsignment.deleted) {
      return
    }

    invokeHouseConsignmentValidators({
      formData,
      country,
      houseIndex,
      houseConsignment,
      updatePathResult,
      addPathIssue,
      hasUniqueHouseDepartureMeans,
      departureMeansForHouseRequirement,
      hasUniqueHouseConsignees,
      hasUniqueHouseConsignors,
      hasUniqueItemConsignees,
      isHouseConsigneeAllowed,
      isHouseConsignorAllowed,
      nomenclatureCodeRequirement,
      isHouseDepartureMeansEnabled,
      previousDocumentRequirementForAllItems,
    })
  })

  const transitOfficeRequirement = C0030Validator.validateOfficeOfTransitRequirement(formData)
  updatePathResult('transitCustomsOffices', transitOfficeRequirement)

  if (transitOfficeRequirement !== 'NOT_ALLOWED') {
    if (!R0003Validator.hasUniqueTransitCustomOfficeReferenceNumbers(formData)) {
      addPathIssue('transitCustomsOffices', {
        code: 'custom', path: ['transitCustomsOffices'], fatal: true, message: 'R0003 : Reference numbers of transit custom offices must be unique',
      })
    }

    if (R0006Validator.isDestinationCountryInTransitCountriesRequired(formData)) {
      addPathIssue('transitCustomsOffices', {
        code: 'custom', path: ['transitCustomsOffices'], fatal: true, message: 'R0006 : At least one customs office of transit must be in the same country with destination customs',
      })
    }

    if (R0006Validator.isTransitCountryInCommunityCountriesRequired(formData)) {
      addPathIssue('transitCustomsOffices', {
        code: 'custom', path: ['transitCustomsOffices'], fatal: true, message: 'R0006 : At least one customs office of transit must be in the Community country (CL010)',
      })
    }
  }

  formData.transitCustomsOffices.forEach((item, index) => {
    if (item.deleted) {
      return
    }
    const transitOfficeDateTimeRequirement = C0598Validator
      .validateTransitOfficeDateTimeRequirement(formData.departureCustomsOffice, formData.security, item)

    updatePathResult(`transitCustomsOffices.${index}.arrivalDateAndTime`, transitOfficeDateTimeRequirement)

    if (transitOfficeDateTimeRequirement !== 'NOT_ALLOWED') {
      if (!R0005Validator.isArrivalDateAndTimeEstimatedValid(item)) {
        addPathIssue(`transitCustomsOffices.${index}.arrivalDateAndTime`, {
          code: 'custom', path: [`transitCustomsOffices.${index}.arrivalDateAndTime`], fatal: true, message: 'Arrival date and time must be in the future',
        })
      }
    }

    if (!R0906Validator.isCustomsOfficeOfTransitValid(formData, item)) {
      addPathIssue(`transitCustomsOffices.${index}.referenceNumber`, {
        code: 'custom', path: [`transitCustomsOffices.${index}.referenceNumber`], fatal: true, message: 'R0906',
      })
    }
  })

  if (['EE', 'PL', 'LT', 'LV'].includes(country)) {
    const transitExitCustomsOfficesRequirement = C0587Validator.validateTransitExitCustomsOfficeRequirement(formData)
    updatePathResult('customsOfficesOfTransitExit', transitExitCustomsOfficesRequirement)

    if (transitExitCustomsOfficesRequirement !== 'NOT_ALLOWED') {
      formData.customsOfficesOfTransitExit.forEach((item, index) => {
        if (item.deleted) {
          return
        }

        updatePathResult(`customsOfficesOfTransitExit.${index}.referenceNumber`, 'REQUIRED')

        if (!R0103Validator.isTransitExitCustomsOfficeReferenceNumberValid(formData, item)) {
          addPathIssue(`customsOfficesOfTransitExit.${index}.referenceNumber`, {
            code: 'custom', path: [`customsOfficesOfTransitExit.${index}.referenceNumber`], fatal: true, message: 'R0103',
          })
        }
      })
    }
  } else {
    updatePathResult('customsOfficesOfTransitExit', 'NOT_ALLOWED')
  }

  updatePathResult('additionalReference', 'NOT_ALLOWED') // TODO: E1301

  const authorisationsRequirement = C0101Validator.validateAuthorisationRequirement(formData.reducedDatasetIndicator)
  updatePathResult('authorisations', authorisationsRequirement)

  if (authorisationsRequirement !== 'NOT_ALLOWED') {
    if (R0859Validator.isReducedDatasetAuthorisationTypeRequired(formData)) {
      addPathIssue('authorisations', {
        code: 'custom', path: ['authorisations'], fatal: true, message: 'R0859 : At least one authorisation must be C524',
      })
    }
    if (R0859Validator.isReducedDatasetAuthorisationTypeNotAllowed(formData)) {
      addPathIssue('authorisations', {
        code: 'custom', path: ['authorisations'], fatal: true, message: 'R0859 : Authorisation type C524 is not allowed',
      })
    }
  }

  // TODO: E1301
  const additionalInformationRequirement: RuleResult = 'NOT_ALLOWED' /* isTransitionalPeriodActive() ? 'NOT_ALLOWED' : 'OPTIONAL' */
  updatePathResult('additionalInformation', additionalInformationRequirement)

  if (additionalInformationRequirement !== 'NOT_ALLOWED') {
    formData.additionalInformation.filter(excludeDeleted).forEach((additionalInformationItem, index) => {
      if (!R3060Validator.isAdditionalInformationCodeValid(formData, additionalInformationItem.code)) {
        addPathIssue(`additionalInformation.${index}.code`, {
          code: 'custom', path: [`additionalInformation.${index}.code`], fatal: true, message: 'R3060',
        })
      }
    })
  }

  return updatedRules
}
export default invokeValidators
