import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useTranslation } from 'react-i18next'
import {
  ConsignmentResponse, DocumentRequestTypeEnum, DocumentResponse, GuaranteeResponse, TransitOperationResponse,
} from '../../models'
import TransitSidebarSection from './TransitSidebarSection'
import TransitSidebarField from './TransitSidebarField'
import useCodelist from '../../hooks/useCodelist'
import TransitSidebarArrayField from './TransitSidebarArrayField'
import TransitSidebarFeedbackSection from './TransitSidebarFeedbackSection'
import { sortBySequenceNumber } from '../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import useConsignmentApi from '../../../TransitOperationEditor/hooks/useConsignment/api'
import useGuaranteeApi from '../../../TransitOperationEditor/hooks/useGuarantee/api'
import useHouseConsignmentApi from '../../../TransitOperationEditor/hooks/useHouseConsignment/api'
import { hasText } from '../../utils/common-util'
import TransitSidebarAuditSection from './TransitSidebarAuditSection'
import DECLARATION_TYPE_OPTIONS from '../../config'
import { UserContext } from '../../../../../context/UserContext'
import TransitSidebarConsignmentPreviousDocuments from './documents/TransitSidebarConsignmentPreviousDocuments'
import TransitSidebarConsignmentTransportDocuments from './documents/TransitSidebarConsignmentTransportDocuments'
import TransitSidebarConsignmentSupportingDocuments from './documents/TransitSidebarConsignmentSupportingDocuments'
import TransitSidebarHouseConsignments from './TransitSidebarHouseConsignments'
import useDocumentApi from '../../../TransitOperationEditor/hooks/useDocument/api'

export interface SidebarTransitDetailsProps {
  transitOperationId: number
  isLoading: boolean
  transitOperationData: TransitOperationResponse | undefined
}

const securityOptions = [
  {
    value: 'S0',
    label: '0 - Not used for safety and security purposes',
  },
  {
    value: 'S1',
    label: '1 - ENS',
  },
  {
    value: 'S2',
    label: '2 - EXS',
  },
  {
    value: 'S3',
    label: '3 - ENS & EXS',
  },
]

function TransitSidebarDetails(props: Readonly<SidebarTransitDetailsProps>) {
  const { t } = useTranslation()
  // const { user } = useContext(UserContext)
  const {
    transitOperationId,
    isLoading,
    transitOperationData,
  } = props
  const {
    user,
  } = useContext(UserContext)

  const [consignmentData, setConsignmentData] = useState<ConsignmentResponse>()
  // const [authorisationsData, setAuthorisationsData] = useState<AuthorisationResponse[]>()
  const [guaranteeData, setGuaranteeData] = useState<GuaranteeResponse[]>()
  // const [directoEventData, setDirectoEventData] = useState<DirectoEventResponse>()
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [, departureCustomsOfficesOptions] = useCodelist('DEPARTURE_CUSTOMS_OFFICES', undefined, user)
  const [, destinationCustomsOfficesOptions] = useCodelist('DESTINATION_CUSTOMS_OFFICES')

  const [, transportModeOptions] = useCodelist('TRANSPORT_MODES')
  const { fetchConsignment } = useConsignmentApi(transitOperationId, isLoading)
  const { fetchGuarantee } = useGuaranteeApi(transitOperationId, isLoading)
  const { fetchHouseConsignments } = useHouseConsignmentApi(consignmentData?.id ?? null, fetchConsignment.isLoading)

  const {
    getDocuments: getTransportDocuments,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.TRANSPORT, isLoading)
  const {
    getDocuments: getSupportingDocuments,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.SUPPORTING, isLoading)
  const {
    getDocuments: getPreviousDocuments,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.PREVIOUS, isLoading)

  // const { fetchDirectoEvent } = useDirectoEventApi(transitOperationId, user?.role === 'ADMIN')
  useEffect(() => {
    setConsignmentData(fetchConsignment.data)
  }, [fetchConsignment.data])
  useEffect(() => {
    setGuaranteeData(fetchGuarantee.data)
  }, [fetchGuarantee.data])

  const houseConsignments = useMemo(
    () => {
      const houseConsignmentResponses = fetchHouseConsignments.data ?? []
      houseConsignmentResponses.sort(sortBySequenceNumber)
      return houseConsignmentResponses
    },
    [fetchHouseConsignments.dataUpdatedAt],
  )

  const previousDocuments = ((getPreviousDocuments.data ?? []) as DocumentResponse[])
  const transportDocuments = ((getTransportDocuments.data ?? []) as DocumentResponse[])
  const supportingDocuments = ((getSupportingDocuments.data ?? []) as DocumentResponse[])

  return (
    <Offcanvas.Body key="data-body">
      {
        hasText(transitOperationData?.auditReason) && (
          <TransitSidebarAuditSection auditReason={transitOperationData?.auditReason ?? ''} />
        )
      }
      {
        hasText(transitOperationData?.feedback) && (
          <TransitSidebarFeedbackSection feedback={transitOperationData?.feedback ?? ''} />
        )
      }
      <TransitSidebarSection
        columnId="general-info"
        headingLabel={t('declaration.navbar.general')}
      >
        <div className="col-6">
          <TransitSidebarField
            label={t('declaration.p5.security')}
            value={securityOptions.find((option) => option.value === transitOperationData?.security)?.label ?? '0'}
          />
          <TransitSidebarField
            label={t('declaration.p5.departureCustomsOffice')}
            value={departureCustomsOfficesOptions.find(
              (option) => option.value === transitOperationData?.customsOfficeOfDeparture,
            )?.label}
          />
          <TransitSidebarField
            label={t('declaration.p5.destinationCustomsOffice')}
            value={destinationCustomsOfficesOptions.find(
              (option) => option.value === transitOperationData?.customsOfficeOfDestination,
            )?.label}
          />
        </div>
        <div className="col-6">
          <TransitSidebarField
            label={t('declaration.p5.declarationType')}
            isLoading={fetchConsignment.isLoading}
            value={DECLARATION_TYPE_OPTIONS.find((option) => option.value === transitOperationData?.declarationType)?.label}
          />
          <TransitSidebarField
            label={t('declaration.p5.bindingItinerary')}
            value={transitOperationData?.bindingItinerary === false ? t('common.no') : t('common.yes')}
          />

          <TransitSidebarArrayField
            label={t('declaration.p5.countriesOfRouting')}
            isLoading={fetchConsignment.isLoading}
            items={consignmentData?.countryOfRoutings.sort(sortBySequenceNumber).map((routeCountry) => ({
              value: countriesOptions.find((option) => option.value === routeCountry.country)?.label,
              parenthesesValue: null,
            })) ?? []}
          />
        </div>
      </TransitSidebarSection>
      <TransitSidebarSection
        columnId="consignment"
        headingLabel={t('declaration.navbar.consignment')}
      >
        <div className="col-12 row m-0">
          <div className="col-6">
            <TransitSidebarField
              label={t('declaration.p5.placeOfLoading')}
              isLoading={fetchConsignment.isLoading}
              value={consignmentData?.loading.location ?? '-'}
              parenthesesValue={countriesOptions.find((option) => option.value === consignmentData?.loading.country)?.label ?? '-'}
            />
            <TransitSidebarField
              label={t('declaration.p5.placeOfUnLoading')}
              isLoading={fetchConsignment.isLoading}
              value={consignmentData?.unloading.location ?? '-'}
              parenthesesValue={countriesOptions.find((option) => option.value === consignmentData?.unloading.country)?.label ?? '-'}
            />
            <TransitSidebarField
              label={t('declaration.p5.grossWeight')}
              isLoading={fetchConsignment.isLoading}
              value={`${consignmentData?.grossMass ?? 0}`}
            />
            <TransitSidebarField
              label={t('declaration.guaranteeAmount')}
              isLoading={fetchGuarantee.isLoading}
              value={`${guaranteeData?.at(0)?.amount ?? '-'}`}
            />
          </div>
          <div className="col-6">
            <TransitSidebarField
              label={t('declaration.p5.inlandTransportMode')}
              isLoading={fetchConsignment.isLoading}
              value={transportModeOptions.find((option) => option.value === consignmentData?.inlandModeOfTransport)?.label ?? '-'}
            />
            <TransitSidebarArrayField
              label={t('declaration.p5.departureTransportMeans')}
              isLoading={fetchConsignment.isLoading}
              items={consignmentData?.departureTransportMeans.sort(sortBySequenceNumber).map((transportMeans) => ({
                value: transportMeans.identificationNumber,
                parenthesesValue: countriesOptions.find((option) => option.value === transportMeans.nationality)?.label,
              })) ?? []}
            />
            <TransitSidebarField
              label={t('declaration.p5.borderTransportMode')}
              isLoading={fetchConsignment.isLoading}
              value={transportModeOptions.find((option) => option.value === consignmentData?.borderModeOfTransport)?.label ?? '-'}
            />
            <TransitSidebarArrayField
              label={t('declaration.p5.borderTransportMeans')}
              isLoading={fetchConsignment.isLoading}
              items={consignmentData?.activeBorderTransportMeans.sort(sortBySequenceNumber).map((transportMeans) => ({
                value: transportMeans.identificationNumber,
                parenthesesValue: countriesOptions.find((option) => option.value === transportMeans.nationality)?.label,
              })) ?? []}
            />
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-6">
              {
                consignmentData?.id !== undefined && consignmentData?.id !== null && (
                  <TransitSidebarConsignmentTransportDocuments
                    consignmentId={consignmentData.id}
                    transportDocuments={transportDocuments}
                  />
                )
              }
              {
                consignmentData?.id !== undefined && consignmentData?.id !== null && (
                  <TransitSidebarConsignmentPreviousDocuments
                    consignmentId={consignmentData.id}
                    previousDocuments={previousDocuments}
                  />
                )
              }
            </div>
            <div className="col-6">
              {
                consignmentData?.id !== undefined && consignmentData?.id !== null && (
                  <TransitSidebarConsignmentSupportingDocuments
                    consignmentId={consignmentData.id}
                    supportingDocuments={supportingDocuments}
                  />
                )
              }
            </div>
          </div>
        </div>
      </TransitSidebarSection>
      <TransitSidebarSection
        columnId="transitParties"
        headingLabel={t('declaration.navbar.transitParties')}
      >
        <div className="col-6">
          <TransitSidebarArrayField
            label={t('declaration.p5.consignor')}
            isLoading={fetchConsignment.isLoading}
            items={[
              {
                value: consignmentData?.consignor?.name,
                parenthesesValue: consignmentData?.consignor?.identificationNumber ?? null,
              },
              {
                value: consignmentData?.consignor?.address?.street,
                parenthesesValue: consignmentData?.consignor?.address?.city,
              },
              {
                value: countriesOptions.find((option) => option.value === consignmentData?.consignor?.address?.country)?.label,
                parenthesesValue: consignmentData?.consignor?.address?.postcode,
              },
            ]}
          />
          <TransitSidebarArrayField
            label={t('declaration.p5.carrier')}
            isLoading={fetchConsignment.isLoading}
            items={[
              {
                value: consignmentData?.carrier?.carrierName,
                parenthesesValue: consignmentData?.carrier?.eori ?? null,
              },
            ]}
          />

        </div>
        <div className="col-6">
          <TransitSidebarArrayField
            label={t('declaration.p5.consignee')}
            isLoading={fetchConsignment.isLoading}
            items={[
              {
                value: consignmentData?.consignee?.name,
                parenthesesValue: consignmentData?.consignee?.identificationNumber ?? null,
              },
              {
                value: consignmentData?.consignee?.address?.street,
                parenthesesValue: consignmentData?.consignee?.address?.city,
              },
              {
                value: countriesOptions.find((option) => option.value === consignmentData?.consignee?.address?.country)?.label,
                parenthesesValue: consignmentData?.consignee?.address?.postcode,
              },
            ]}
          />
        </div>
      </TransitSidebarSection>
      <TransitSidebarSection
        columnId="houseConsignments"
        headingLabel={t('declaration.navbar.houseConsignments', { count: houseConsignments.length })}
      >
        <TransitSidebarHouseConsignments
          houseConsignments={houseConsignments}
          transitOperationId={transitOperationId}
          isLoading={
            fetchHouseConsignments.isLoading
            || getTransportDocuments.isLoading
            || getPreviousDocuments.isLoading
            || getSupportingDocuments.isLoading
          }
          previousDocuments={previousDocuments}
          transportDocuments={transportDocuments}
          supportingDocuments={supportingDocuments}
          initialOpenState={houseConsignments.length < 2}
        />
      </TransitSidebarSection>
    </Offcanvas.Body>
  )
}

export default TransitSidebarDetails
