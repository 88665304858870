import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { saveBlob } from 'routes/phase5/TransitOperationEditor/components/FilePreviewModal'
import useGenerateSubmissionFormActionApi from './api'
import { UseDeclarationFormReturn } from '../../../form'
import { isAxiosError } from '../../../../common/utils/api-util'

function useGenerateSubmissionFormAction(form: UseDeclarationFormReturn) {
  const {
    getValues,
  } = form

  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { generateXml } = useGenerateSubmissionFormActionApi()

  const generateAndDownloadForm = async () => {
    const transitOperationId = getValues('id')
    if (transitOperationId === null) throw Error('Unable to submit without id')

    try {
      await generateXml.mutateAsync({ id: transitOperationId, currencyExchangeRate: 0 }).then((response) => {
        const [, filename] = response.headers['content-disposition'].split('filename=')

        saveBlob(null, response.data, 'blob', filename)
      })
    } catch (error) {
      if (isAxiosError(error)) {
        navigate(`${location.pathname}#summary`)
        toast.error(
          t('errors.serverError', { traceId: error.response?.headers['x-trace-id'] ?? null }),
          { autoClose: false, closeOnClick: false, draggable: false },
        )
      } else {
        toast.error(t('common.submit', { context: 'error' }))
      }
    }
  }

  return {
    generateAndDownloadForm,
  }
}

export default useGenerateSubmissionFormAction
