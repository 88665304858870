const TRANSLATIONS_EN = {
  buttons: {
    transits: 'Transits',
    customers: 'Customers',
    settings: 'Settings',
    duplicate: 'Duplicate',
    viewLogs: '$t(common.view) logs',
    viewLogs_close: '$t(buttons.close) logs',
    reassignOwner: 'Reassign owner',
    delete: 'Delete',
    profile: 'Profile',
    logOut: 'Log out',
    logIn: 'Log in',
    register: 'Register',
    startPasswordRecovery: 'Can\'t access Your account?',
    resetPassword: 'Reset password',
    useDefault: 'Use default',
    recoveryEmail: 'Send recovery email',
    backToLogin: 'Back to login',
    remove: 'Remove',
    add: 'Add',
    addFiles: 'Add files',
    addNew: 'Add new',
    addNewExternal: 'Add directo customer',
    addGoods: 'Add goods',
    addUser: 'Add user',
    addProfile: 'Add guarantee Profile',
    saveGoods: 'Save',
    save: 'Save',
    saveDraft: 'Save as Draft',
    send: 'Send',
    submitCustoms: 'to customs',
    submitReview: 'to review',
    amendmentCustoms: 'amendment to customs?',
    cancelCustoms: 'cancellation to customs?',
    customs: 'Customs',
    confirm: 'Confirm',
    submit: 'Submit',
    createNew: 'Create new',
    amend: 'Change',
    summary: 'Summary',
    cancel: 'Cancel',
    change: 'Change',
    editDeclaration: '$t(buttons.change) declaration',
    cancelDeclaration: '$t(buttons.cancel) declaration',
    block: 'Block',
    activate: 'Activate',
    close: 'Close',
    sendInvite: 'Send invitation',
    review_approved: 'Approve',
    review_decline: 'Decline',
    rotate_left: '$t(common.rotate)  $t(common.left)',
    rotate_right: '$t(common.rotate) $t(common.right)',
    export: 'Export excel',
    decideAudit: 'Decide',
    decideAuditHeader: 'Decide declaration status',
    next: 'Next Step',
    previous: 'Previous Step',
    activateUser: 'Activate account',
    companyInfo: 'Company Info',
    requestAccess: 'Request Access',
    accept: 'Accept terms',
    feedback: 'Feedback',
    t1Closed: 'Change Status to T1 Transit Closed',
    notes: 'Notes',
    filter: 'Table Filters',
    filter_clear: 'Clear filters',
    downloadPdf: 'Download PDF',
    downloadXml: 'Download XML',
    downloadTaxesPdf: 'Taxes PDF',
    downloadTaxesXlsx: 'Taxes XLSX',
    generateXml: 'Download submission form (IE015)',
    importRestriction: 'Import Restriction',
    quickFiltersWithCount_zero: 'Quick filters',
    quickFiltersWithCount_one: 'Quick filters ({{count}})',
    quickFiltersWithCount_other: 'Quick filters ({{count}})',
  },
  transit: {
    title: 'transit',
    id: 'ID',
    mrnAndLrn: '$t(common.mrn)/$t(common.lrn)',
    consignor: 'Consignor',
    consignee: 'Consignee',
    carrier: 'Carrier',
    transport: 'Transp.',
    departureCustomsOffice: 'Dept.',
    destinationCustomsOffice: 'Dest.',
    customer: 'Customer',
    status: 'Status',
    createdByName: 'Created by',
    created: 'Created',
    modified: 'Modified',
    reviewer: 'Reviewer',
    lastReview: 'Last review',
    guaranteeAmount: 'Guarantee',
    directoStatus: '$t(declaration.headers.directoEvent) $t(transit.status)',
    directoClient: 'Directo client',
  },
  common: {
    new: 'New',
    edit: 'Edit',
    view: 'View',
    date: 'Date',
    type: 'Type',
    message: 'Message',
    user: 'User',
    yes: 'Yes',
    no: 'No',
    chooseCountry: 'Choose country',
    chooseField: 'Choose Field',
    chooseProfile: 'Choose profile',
    chooseGoodsItem: 'Choose goods item',
    chooseMode: 'Choose mode',
    fieldPlaceholder: 'Please fill in the form',
    customsPlaceholder: 'Search for customs office',
    name: 'name',
    name_capitalized: 'Name',
    typeToSearch: 'Type to search for a {{0}} or to add a new',
    loading: 'Loading…',
    saving: 'Saving…',
    street: 'Street',
    city: 'City',
    zip: 'Zip',
    grnCode: 'GRN code',
    guaranteeType: 'Guarantee type',
    value: 'Value',
    select: 'Select',
    confirmAction: 'Confirm action',
    error: 'Error!',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    phone: 'Phone',
    mrn: 'MRN',
    lrn: 'LRN',
    log: 'log',
    rotate: 'Rotate',
    left: 'left',
    right: 'right',
    show: 'Show',
    page: 'Page',
    pageSeparator: 'of',
    goToPage: 'Go to page',
    userInformation: 'Account information',
    companyInformation: 'Company information',
    registerSuccess: 'Registration success',
    customsProfileName: 'Profile Name',
    customsProfileAuthority: 'Authority office',
    customsProfileCountry: 'Authority country',
    customsProfileGrn: 'GRN',
    submit_success: 'Submission successful!',
    submit_error: 'Submission failed!',
    submit_service_unavailable: 'Submission error! Possible problems with departure country national NCTS customs system, try again later!',
  },
  declaration: {
    navbar: {
      general: 'General',
      consignment: 'Consignment',
      transitParties: 'Transit parties',
      houseConsignments_zero: 'House consignments',
      houseConsignments: 'House consignments ({{count}})',
      directo: 'Directo',
      goods: 'Goods',
      summary: 'Summary',
      logs: 'Event logs',
      additional: 'Important info!',
    },
    headers: {
      info: 'Transit info',
      declarationInfo: 'Declaration info',
      directoEvent: 'Directo',
      goods: 'Goods',
      goodsItem: 'Goods item',
      traders: 'Traders',
      carrier: 'Carrier',
      transport: 'Transport',
      transportAndLoading: 'Transport & loading',
      metaData: 'Metadata',
      goodsItems: 'Goods items',
      logs: 'Logs',
      validate: 'validate',
      goodsInfo: 'Goods info',
    },
    finnish: {
      geographical_location: 'Geographical Location',
      zip_code: 'Zip Code',
      warehouse_identifier: 'Warehouse Identifier',
      customs_office: 'Customs Office',
      doubleTransportMode: 'Finnish customs only accepts Transport Modes that are single digits',
      arexNumber: 'Arex Order Number',
      transportDocumentId: 'Transport Document ID',
      error: {
        transitDateLimit: 'Missing Transit Date Limit',
        transitLoadingDate: 'Missing Transit loading date',
        transportDocument: 'The transport document must always be declared with the first batch of goods',
        transportDocumentLength: 'Transport document number length can be 1 to 20 characters only',
        previousDocumentMandatory: 'Previous Documents are mandatory for Finnish Declarations! If there are no Previous documents then pick Document Type (40A) 00: No previous procedure, and enter 00 in Document Number(40B)',
      },
    },
    status: {
      draft: 'Draft',
      rejected: 'Rejected',
      accepted: 'Accepted',
      confirmed: 'Submission Confirmed',
      open: 'Transit open',
      declined: 'Declined',
      canceled: 'Canceled',
      ended: 'Transit ended',
      arrived: 'IE006 arrived',
      t1Closed: 'T1 Transit Ended',
      checkOk: 'Check Not Ok',
      control: 'Control Decision',
      discrepancies: 'Discrepancies',
      cancelRequested: 'Cancel Requested',
      goodsReleaseNotification: 'Goods release notif.',
    },
    p5: {
      sequenceNumber: 'No.',
      mrn: 'MRN',
      lrn: 'LRN',
      name: 'Name',
      declarationType: 'Declaration type',
      security: 'Security',
      additionalDeclarationType: 'Additional declaration type',
      bindingItinerary: 'Binding Itinerary',
      reducedDatasetIndicator: 'Reduced dataset',
      limitDate: 'Deadline',
      departureCustomsOffice: 'Departure office',
      destinationCustomsOffice: 'Destination office',
      dispatchCountry: 'Country of dispatch',
      destinationCountry: 'Destination country',
      totalNumberOfPackages: 'Total number of packages',
      grossWeight: 'Gross weight',
      grossWeight_current: 'Current goods weight',
      containerisedIndicator: 'Goods in containers',
      transportEquipment: 'Transport equipment',
      containers: 'Container',
      containerNumber: 'Container number',
      addNewContainer: 'Add new container',
      addNewSeal: 'Add new seal',
      seals: 'Seals',
      numberOfSeals: 'Number of seals',
      sealIdentifier: 'Seal identifier',
      goodsReferences: 'Goods item references',
      addNewGoodsReferences: 'Add new goods references',
      declarationGoodsItemNumber: 'Goods items no. in declaration',
      transport: 'Transport',
      borderTransportMode: 'Border transport mode',
      inlandTransportMode: 'Inland transport mode',
      departureTransportMeans: 'Departure transport means',
      typeOfIdentification: 'Type of identification of transport means',
      identificationNumber: 'Number of identification of transport means',
      nationality: 'Country',
      conveyanceReferenceNumber: 'Conveyance reference number',
      customsOfficeAtTheBorder: 'Customs office at the border',
      borderTransportMeans: 'Border transport means',
      addNewTransportMeans: 'New transport means',
      placeOfLoading: 'Place of loading',
      placeOfUnLoading: 'Place of unloading',
      locationOfGoods: 'Location of goods',
      typeOfLocation: 'Type of location',
      locationOfGoodsReferenceNumber: 'Customs Office',
      qualifierOfIdentification: 'Qualifier of identification',
      authorisations: 'Authorisations',
      addNewAuthorisation: 'Add Authorisations',
      authorisationNumber: 'Authorisations number',
      authorisationType: 'Authorisation type',
      authorisationReferenceNumber: 'Authorisation reference number',
      additionalIdentifier: 'Additional Identifier',
      economicOperatorIdentificationNumber: 'Economic operator Identification number (EORI)',
      unLocode: 'UN/LOCODE',
      placeOfLoadingLocation: 'Place of loading location',
      placeOfUnloadingLocation: 'Place of unloading location',
      countriesOfRouting: 'Countries of routing',
      countryOfRouting: 'Country of routing',
      addNewRoutingCountry: 'Add new country',
      customsOfficeOfTransit: 'Customs offices of transit',
      transitExitCustomsOffices: 'Customs offices of transit exit',
      referenceNumber: 'Reference number',
      arrivalDateAndTime: 'Arrival date and time',
      addNewCustomsOfficeOfTransit: 'New customs office of transit',
      addNewCustomsOfficeOfExitForTransit: 'New customs office of transit exit',
      transportDocuments: 'Transport documents',
      transportDocuments_singular: 'Transport document',
      transportDocumentType: 'Transport document type',
      transportDocumentReferenceNumber: 'Transport document reference number',
      addNewDocument: 'New document',
      previousDocuments: 'Previous documents',
      previousDocuments_singular: 'Previous document',
      previousDocumentType: 'Document type',
      procedureType: 'Procedure Type',
      previousDocumentReferenceNumber: 'Reference number',
      complementOfInformation: 'Complement of information',
      supportingDocuments: 'Supporting documents',
      supportingDocuments_singular: 'Supporting document',
      supportingDocumentType: 'Document type',
      supportingDocumentReferenceNumber: 'Reference number',
      documentType: 'Document type',
      referenceType: 'Reference type',
      type: 'Type',
      code: 'Code',
      text: 'Text',
      itemNumber: 'Item number',
      additionalReferences: 'Additional references',
      additionalReferenceType: 'Reference type',
      additionalReferenceNumber: 'Reference number',
      addNewReference: 'New reference',
      additionalInformations: 'Additional informations',
      additionalInformationCode: 'Additional informations code',
      additionalInformationText: 'Additional informations text',
      transitProcedureHolder: 'Holder of the transit procedure',
      procedureIdentificationNumber: 'Identification number',
      transitProcedureName: 'Name',
      country: 'Country',
      address: 'Address',
      city: 'City',
      postcode: 'Postcode',
      contactPersonName: 'Contact person\'s name',
      contactPersonEmail: 'Contact person\'s email',
      contactPersonPhone: 'Contact person\'s phone number',
      representative: 'Representative',
      representativeIdentificationNr: 'Representative identification number',
      representativeStatus: 'Representative status',
      guarantees: 'Guarantees',
      addNewGuarantee: 'Add new guarantee',
      guaranteeType: 'Guarantee type',
      guaranteeReference: 'Guarantee reference',
      grn: 'Guarantee reference number (GRN)',
      accessCode: 'Access code',
      amountToBeCovered: 'Amount to be covered',
      currency: 'Currency',
      consignor: 'Consignor',
      consignorIdentificationNr: 'Consignor identification number',
      consignorName: 'Consignor name',
      consignorCountry: 'Consignor country',
      consignee: 'Consignee',
      consigneeIdentificationNr: 'Consignee identification number',
      consigneeName: 'Consignee name',
      consigneeCountry: 'Consignee country',
      carrier: 'Carrier',
      carrierIdentificationNr: 'Carrier identification number',
      carrierName: 'Carrier name',
      additionalSupplyChainActor: 'Additional supply chain actor',
      role: 'Role',
      actorIdentificationNr: 'Identification number',
      addNewActor: 'New actor',
      addRow: 'New row',
      houseConsignment: 'House consignment',
      grossMass: 'Gross mass',
      referenceNumberUCR: 'Reference number/UCR',
      descriptionOfGoods: 'Description of goods',
      commodityCode: 'Commodity code',
      houseConsignmentReference: 'House consignment reference',
      numberOfPackages: 'Number of packages',
      goodsData: 'Goods data',
      commodityDescriptionOfGoods: 'Commodity description of goods',
      commodityHarmonizedSystemSubHeadingCode: 'HS subheading code',
      commodityCombinedNomenclatureCode: 'CN subheading code',
      commodityCodeSearch: 'Type to search…',
      goodsMeasureGrossMass: 'Gross mass',
      goodsMeasureNetMass: 'Net mass',
      countryOfDispatch: 'Country of dispatch',
      countryOfDestination: 'Country of destination',
      dangerousGoods: 'Dangerous goods',
      addNewGoodsItem: 'New goods item',
      packages: 'Packages',
      packages_singular: 'Package',
      packaging: 'Packages',
      typeOfPackages: 'Type of packages',
      shippingMarks: 'Shipping marks',
      addNewPackage: 'New package',
      unNumber: 'UN number',
      dropzoneDescription: 'Drag and drop files here, or click to select files',
      files: 'Files',
      generalInformation: 'General information',
      warnings: 'Warnings',
      declarationTypeSpecified: 'Specified declaration type',
      customsOfficeOfRouting: 'Customs office of routing',
      customsDebt: 'Customs debt',
      aeoDiscount: 'AEO discount',
      numberOfItemsDeclaration: 'Number of items',
      dueDate: 'Due date',
      barriers: 'Barriers',
      noWarnings: 'No warnings',
      transitOperation: 'Transit Operation ID',
      createNew: 'Create new Transit',
      createNewWarning: 'Are you sure that you want to create new ? All unsaved changes will be lost',
      unsavedChangesLostWarning: 'All unsaved changes will be lost!',
      upload_error: 'File upload failed!',
      contactForm: 'Finland contact form',
      notification: 'Notification',
      notificationDescription: 'Notification description',
      notificationReason: 'Reason for contact',
      houseNumber: 'House number',
      finlandCustomsRequestResponse: 'Finland Customs Request Response(FI046A)',
      agreeWithCustoms: 'Do you agree with Customs Decision?',
      goodsItemPrice: 'Price',
      goodsItemPrice_unit: 'EUR',
      price: '$t(declaration.p5.goodsItemPrice)', // for automatic csv import mapping
      goodsItemDutyRate: 'Duty',
      goodsItemDutyRate_unit: '%',
      dutyRate: '$t(declaration.p5.goodsItemDutyRate)',
      goodsItemVatRate: 'VAT',
      goodsItemVatRate_unit: '%',
      vatRate: '$t(declaration.p5.goodsItemVatRate)',
      goodsItemExcise: 'Excise',
      goodsItemExcise_unit: 'EUR',
      excise: '$t(declaration.p5.goodsItemExcise)',
      requestRelease_title: 'Release transit?',
      requestRelease: 'Minor discrepancies found while in control, should request to release transit anyway?',
      errors: {
        title: 'Errors',
        none: 'No errors',
        invalidGrossMass: 'Invalid gross mass in General. ({{rootGrossMass}}) does not equal to Items Gross mass ({{itemGrossMass}})',
        grossMassDifference: 'Gross weights are different. Expected: {{expected}}, actual: {{actual}}',
        maximumNumberOfItemsExceeded: 'Maximum number ({{max}}) of items exceeded',
      },
      typeToSearch: 'Type to search or add new',
      goodsSidebar: {
        title: 'House consignment no. {{houseConsignmentSequenceNumber}} goods',
      },
      goodsWithNumber: 'Goods ({{numberOfGoods}})',
    },
    type: 'Type of declaration',
    place: 'Declaration place',
    specialConditions: 'Special Conditions',
    securityReferenceNumber: 'Security Reference Number',
    shipmentNumber: 'Shipment Number',
    security: 'Security',
    packagesCount: 'Number of packages',
    totalGrossMass: 'Total gross mass',
    dispatchCountry: 'Country of dispatch',
    destinationCountry: 'Country of destination',
    loadingPlace: 'Place of loading',
    unloadingPlace: 'Place of unloading',
    transitCountries: 'Countries of transit',
    guaranteeAmount: 'Guarantee amount',
    nctsProfile: 'Guarantee Profile',
    sanctionedCode: 'HS-codes under sanctions',
    blacklisted: 'Blacklist warning',
    blacklistWarning: 'Trader or truck number is in our blacklist for smuggling illicit goods.',
    sanctionWarning: 'One or more HS-Codes might be under sanctions, please check them',
    createdBy: 'Created by',
    created: 'Created date',
    modified: 'Modified date',
    goodsTradersEnabled: 'Traders set under goods',
    multipleGoodsTraders: 'Multiple set under goods',
    consignor: 'Consignor',
    consignee: 'Consignee',
    carrier: 'Carrier',
    address: 'Address',
    departureTruckNo: 'Truck nr at departure',
    departureNationality: 'Nationality at departure',
    borderCrosserTruckNo: 'Truck nr at the border',
    borderCrosserNationality: 'Nation. crossing border',
    indicatorContainerised: 'Containerised indicator',
    containerNumber: 'Container number',
    borderTransportMode: 'Transport mode at border',
    inlandTransportMode: 'Inland transport mode',
    departureCustomsOffice: 'Customs office dep.',
    destinationCustomsOffice: 'Customs office dest.',
    transitCustomsOffice: 'Transit customs offices',
    eoriId: 'EU EORI',
    reassignOwner: 'Reassign owner',
    currentOwner: 'Current owner',
    selectNewOwner: 'Select a new owner',
    lvGuarantee: 'LV Guarantee Calculator',
    ltGuarantee: 'LT Guarantee Calculator',
    plGuarantee: 'PL Guarantee Calculator',
    eeGuarantee: 'EE Guarantee Calculator',
    latestCustomsUpdate: 'Last customs update',
    transitDateLimit: 'Date limit',
    numberOfGoods: 'Number of goods',
    loadingTime: 'Loading date and time',
    goodsLocation: 'Goods Location',
    warehouseLocation: 'Warehouse Identifier',
    goodsLocationQualifier: 'Goods Location Qualifier',
    seal: 'Seal',
    trader: 'Trader',
    truck: 'Truck with number',
  },
  goodsItem: {
    quantity: 'Quantity',
    kindOfPackage: 'Quantity Unit',
    marksAndNumbers: 'Marks & numbers',
    description: 'Description',
    numberOfPieces: 'Number of Pieces',
    numberOfPackages: 'Number of packages',
    hsCode: 'HS-code',
    hsCodeAudit: 'HS-code requires audit',
    goodsItemPrice: 'Price',
    goodsItemPrice_unit: 'EUR',
    goodsItemDutyRate: 'Duty',
    goodsItemDutyRate_unit: '%',
    goodsItemVatRate: 'VAT',
    goodsItemVatRate_unit: '%',
    grossWeight: 'Gross weight',
    netWeight: 'Net weight',
    destinationCountry: 'Destination country',
    documents: 'Documents (44A)',
    documentType: 'Document type (44A)',
    documentNumber: 'Document number',
    previousDocument: 'Previous document',
    previousDocumentType: 'Document type (40A)',
    previousDocumentProcedure: 'Procedure type (40AB)',
    previousDocumentNumber: 'Document number (40B)',
    previousDocumentOrderNumber: 'Order number',
    documentTypeNumeric: '44A',
    previousDocumentProcedureNumeric: '40AB',
    previousDocumentTypeNumeric: '40A',
    previousDocumentNumberNumeric: '40B',
    packageUnitPlaceholder: 'Select package unit',
    marksPlaceholder: 'Marks',
    descriptionPlaceholder: 'Goods description',
    hsCodePlaceholder: 'Code',
    dispatchCountryPlaceholder: 'Select dispatch',
    destinationCountryPlaceholder: 'Select destination',
  },
  tooltips: {
    security: 'The details of the security sender shall be entered in the general data if there is one\n '
      + 'security sender for the entire shipment',
    goodsTraders: 'If goods have different security consignors or consignees, '
      + 'the details of the security consignor or consignee must be entered later in the goods',
    deleteUser: 'Delete / Archive User',
    enableAccount: 'Enable User',
    blockUser: 'Block User',
    passwordReset: 'Send Password Reset Email',
    editUser: 'Edit User Details',
    confirmationEmail: 'Send Account Confirmation Email',
    // eslint-disable-next-line max-len
    declarationCancelExplanation: 'Declaration can only be canceled when: ',
    declarationCancelExplanation_row1: 'declaration has a MRN and',
    declarationCancelExplanation_row2: 'declaration has not been opened in customs office',
    declarationCancelExplanation_row3: 'Canceling CANNOT be reverted and resubmitted to customs',
    localReject: 'Reject locally',
    pdfExplanation: 'Sends Declaration PDF as an e-mail attachment',
    warehouse: 'Create your company\'s warehouse identifier as\n'
        + 'follows: country code, company\'s own y code and extension R (example FI1234567-8R0001) ',
    warehouseEori: 'The EORI of the operator, in the case of a place approved by Customs, is entered. If goods are to be inspected '
      + 'in a warehouse, the warehouse identifier shall be in the form EORI+3+2.',
    typeOfLocation: 'Select A as type of location if the goods are at the customs office of departure, B if the goods are in a warehouse'
      + ' approved by Customs, C if the goods are in a place approved by Customs, and D - Other if the goods are is some other location.',
    simplified: 'Mandatory field in Simplified procedure (L). Not used in Normal Procedure',
    loading: 'Enter date and time, when loading takes place and goods can be inspected. Data must be entered if agreed location of goods is entered.',
    guaranteeProfileMissing: 'Select guarantee profile to continue',
  },
  pages: {
    page_transits: 'Transits P4',
    page_customers: 'Customers',
    page_settings: 'Settings',
    page_restrictions: 'Restrictions',
    page_audit: 'Audit',
    page_company: 'Company',
    page_privacy: 'Privacy Policy',
    page_notification: 'Notifications',
    page_seo: 'SEO',
    page_customs: 'Customs',
    page_codelist: 'Codelist',
    page_certificates: 'Certificates',
    'page_transit-operations': 'Transit',
    'page_transit-audit': 'Transit Audit',
  },
  customers: {
    title: 'Customers',
    customer_new: 'New customer',
    customer_edit: 'Edit customer',
    companyInfo: 'Company info',
    customsProfile: 'Guarantee profiles',
    registryCode: 'Reg. code',
    address: 'Address',
    users: 'Users',
    accessCode: 'Access code',
    maxGuaranteeAmount: 'Max guarantee amount',
    auditRequiredAmount: 'Audit required amount',
    guaranteeFormula: 'Guarantee formula',
    notes: 'Notes',
    status_ACCEPTED: 'ACCEPTED',
    status_ACTIVE: 'ACTIVE',
    status_BLOCKED: 'BLOCKED',
    status_ARCHIVED: 'ARCHIVED',
    status_INACTIVE: 'INACTIVE',
    documentFilesRequired: 'Document upload required',
    requiresAudit: 'Does user require audit',
    directoClientCode: 'Directo client code',
    apiTokenAccess: 'Access to Client API',
    apiTokenAccessForbidden: 'No access. Contact support to gain access',
    generateApiToken: 'Generate API token',
    apiTokenActions: 'API token actions',
    generateApiToken_description: 'Create a new client API access token. Existing token will be revoked. New token will be shown only once here at the time of creation. Store the token securely before closing the modal. Authentication requires the token with the company reg. code.',
    apiDocs: 'Save API specification',
    id: 'ID',
    modified: 'Modified',
    external: 'Directo',
    companyName: 'Company name',
    companyNameExample: 'Company name has to match exactly with registered EORI.',
    companyZipCode: 'Company ZIP code',
    companyZipCodeExample: 'Company ZIP code has to match exactly with registered EORI.',
    companyStreet: 'Company Street',
    companyCity: 'Company City',
    companyCityExample: 'Company City has to match exactly with registered EORI.',
    companyCountry: 'Company Country',
    companyContactEmail: 'Company email',
    streetExample: 'Company Address has to match exactly with registered EORI.',
    deleted: 'Deleted date',
    lastLogin: 'Login date',
    giveAccess: 'Give Access to T1 profile',
    publicDeclaration: 'T1 Guarantee',
    searchUsingName: 'Search using name',
    searchForInput: 'Search for "{{input}}"',
  },
  certificates: {
    expiry: 'Expiry',
    validTo: 'Valid to',
    name: 'Name',
    issuer: 'Issuer',
    fingerprint: 'Fingerprint',
  },
  user: {
    firstName: 'First name',
    lastName: 'Last name',
    userProfileDetails: 'User profile details',
    password_current: 'Current $t(common.password)',
    password_new: 'New $t(common.password)',
    password_repeat: 'Repeat new $t(common.password)',
    accountCreationSetPassword: 'Set a new password',
    accountCreationCreatePassword: 'Create a password for the account',
    phone: 'Phone number',
    privacyPolicy: 'I have read and agree to the ',
    privacy: 'Privacy Policy',
    otp: 'One Time Password',
    preferences: 'Your Transit table preferences',
  },
  messages: {
    confirmation: 'Are you sure?',
    confirmSaving: 'Are you sure you want to save changes?',
    maxLength: 'name is too long. Maximum length:',
    noGoodsItemsAdded: 'No items have been added',
    confirmSubmissionText: 'Are you sure you want to submit declaration',
    invalidSubmissionText: 'Please check that all required fields are filled:',
    successfulCancellation: 'Declaration canceled',
    nctsSubmitError: 'Error while submitting declaration to customs.',
    nctsValidationError: '{{count}} validation error prevented sending request to customs',
    nctsValidationError_plural: '{{count}} validation errors prevented sending request to customs',
    nctsFormInvalid: 'Validation errors occurred while submitting declaration cancellation to customs office',
    invalidField: 'Invalid field',
    loadingGoodsItemTradersError: 'Warning! There were errors loading goods items traders',
    invalidGuaranteeAmount: 'Guarantee amount is invalid',
    blankTotalGrossMass: 'Declaration total gross mass cannot be blank',
    blankPackagesCount: 'Packages count cannot be blank',
    missingTransitCountry: 'Transit country cannot be blank',
    missingDepartureTrailerSeparator: 'Departure truck must use ; as separator for trailer',
    missingDestinationTrailerSeparator: 'Border truck must use ; as separator for trailer',
    missingContainerNumbers: 'Missing required transport container numbers',
    missingGoodsDocuments: 'Missing required documents for goods',
    missingGoodsDocumentInfo: 'Missing required goods document info in item no. ',
    missingGoodsInfo: 'Missing required goods info in item no.',
    r105Invalid: 'Rule 105: Number of packages ({{0}}) declared in declaration does not match the amount of goods items added ({{1}})',
    formInvalid: 'Form invalid',
    recoverySent: 'Recovery email sent',
    recoverySendingFailed: 'Recovery email sending failed',
    customerEdit_successful: 'Successfully changed customer and it\'s users statuses',
    customerEdit_failed: 'Customer status changing failed',
    customerUsersError: 'Getting customer\'s users failed',
    missingRequiredFields: 'Missing required fields',
    missingRequiredNameOrRegCodeFields: 'Missing required name or registry code',
    savingFailed: 'Saving failed',
    savingSuccess: 'Saved',
    inviteSent: 'Invite sent!',
    delete_successful: 'Deleted!',
    delete_failed: 'Deleting failed',
    duplicate_successful: 'Duplicate created with ID {{id}}',
    duplicate_failed: 'Duplicate creation failed',
    passwordChangeLogoutWarning: 'Changing the password will trigger a mandatory logout',
    activationUrlError: 'Activation url invalid',
    passwordsChanged_successful: 'Password successfully changed. Logout in 3 seconds…',
    passwordsChanged_failed: 'An error occurred while trying to change password',
    passwordsMatchError: 'Passwords do not match',
    passwordsLengthError: 'Password must be at least 7 characters long',
    passwordsLetterError: 'Password must contain at least one - uppercase letter, lowercase letter, number.',
    invalidUrlError: 'Invalid url',
    accountActivationError: 'Account activation failed',
    requiredGoodsDocumentsNotUploaded: 'File upload required for goods item {{0}} documents (325,380,730)',
    duplicateDocumentsMessage: 'Do you wish to include all of the goods item documents and files with the duplicate?',
    deleteConfirmMessage: 'Are you sure you want to delete item \'{{0}}\' ?',
    download_starting: 'Starting your download…',
    popupsBlocked: 'Redirects are blocked by the browser, allow popups for this site to continue',
    archivedCommodityCodes: 'Detected an archived code in one of the goods items, update the code to latest nomenclature',
    noMatches: 'No matches',
    noOfficeResults: 'No results! Offices are restricted by rules and should have appropriate roles. Validate roles in europa.eu -',
    noOfficeResults_viaRefNum: 'via reference number "{{input}}"',
    noOfficeResults_viaUsualName: 'via usual name "{{input}}"',
    directoAddendumWithoutCustomer: 'Directo description addendum is added but no customer is set',
    download_manual: 'Click here if your download did not start',
    emailInvalid: 'This email format is invalid',
    customerSupport: 'Company with that registry code already exists. Please contact Support: t1@tardek.com',
    eoriHelper: 'This field is for autocompleting fields below and is not mandatory',
    // eslint-disable-next-line max-len
    accountRegisteredFirstParagraph: 'Account was registered successfully.',
    checkEmail: 'We have sent you an email to confirm your information. If you have not received an email, please check your Spam folder.',
    userDeleted: 'User deleted successfully',
    profileUpdated: 'User profile updated',
    profileUpdateFailed: 'User profile update failed',
    eoriSearchFailed: 'Please check that your EORI ID is valid.',
    emailInUse: 'This email is already registered',
    accessCodeInvalid: 'Invalid access code',
    profileEmpty: 'Guarantee Profile is empty!',
    bothLanguages: 'Please edit both languages!',
    goodsItemError: 'Please check your HS-Codes for Goods Items!',
    declarationStatus: 'Declaration Status Update',
    declarationStatusChange: 'You are about to change status. This means you cannot edit / update this Transit.',
    emailsEmpty: 'Please add at least one email!',
    notFoundError: '{{entity}} with {{field}}: {{value}} was not found',
    goodsLocationError: 'Goods Location cannot be empty!',
    goodsLocationQualifierError: 'Goods Location Qualifier cannot be empty!',
    useGUAFormulaPlaceholder: 'Use "GUA" to designate guarantee amount',
    forbiddenCharacter: '{{0}} contains Cyrillic characters!',
    // eslint-disable-next-line max-len
    restrictedGoods: 'Your declaration contains 1 or more goods items that are under restrictions. Either change your guarantee profile or change HS-code for goods items that are with red border',
    // eslint-disable-next-line max-len
    invalidQuantityUnit: '{{type}} is invalid quantity unit type! Please check Goods Item with number {{number}}. Please re-select Quantity Unit for Goods Item number {{number}}',
    // eslint-disable-next-line max-len
    destinationCountryWrong: '{{value}} is not valid Country of Destination for Goods Item with order number {{number}}. Please re-select it or contact support!',
    // eslint-disable-next-line max-len
    dispatchCountryWrong: '{{value}} is not valid Country of Dispatch for Goods Item with order number {{number}}. Please re-select it or contact support!',
    blacklistedWarning: '{{field}} {{name}} is suspected of smuggling illicit goods.',
    missingField: '{{field}} cannot be empty',
    goodsItemConsignment: 'Goods Item(s) has missing {{consignment}}',
    confirmConsentUpdate: 'Confirm sending a new consent to Borica B-Trust? It must be accepted within B-Trust Mobile app',
  },
  errors: {
    serverError: 'Server error! Error code {{traceId}}',
    notBlank: 'cannot be blank / empty',
    notNull: 'cannot be null / empty',
    guaranteeFormula: 'evaluation failed',
    pattern: 'Invalid data format',
    invalidClassifier: 'is invalid. Cannot extract classifier. Please re-select it or contact support!',
    codelist: 'invalid code',
    invalidInputText: 'contains invalid characters',
  },
  entity: {
    declaration: 'Declaration',
    customer: 'Customer',
    restriction: 'Restriction',
  },
  restriction: {
    id: 'Id',
    key: 'Key',
    description: 'Description',
    modified: 'Modified',
    created: 'Created',
    type: 'Type',
    commodityCodeType: 'HS-code',
    commodityDescriptionType: 'Description',
    commoditySanctionType: 'Under Sanctions',
    commodityTraderType: 'Trader name',
    commodityTruckType: 'Truck number',
    alreadyExists: 'Restriction already exists. Please use different code / description!',
    importSuccess: 'Import started successfully. Check back in a few minutes',
  },
  audit: {
    id: 'Id',
    declarationId: 'Declaration ID',
    status: 'Status',
    customer: 'Customer Name',
    created: 'Created',
    modified: 'Modified',
    reason: 'Reason',
    reasonForDecline: 'Reason for declining Declaration',
    submitAudit: 'Change Audit status',
    inputError: 'Reason cannot be empty',
    updated: 'Audit updated',
    error: 'Audit update failed',
    declinationReason: 'Declination reason',
  },
  export: {
    title: 'Export transits to excel file',
    queueExport: 'Request export',
    calendarFilter: 'Included dates',
    tutorial: 'Downloadable link to the generated export file will be sent to \'{{0}}\'',
    customerSelect: 'Customer filter',
    queueSubmit_success: 'Export queued',
    queueSubmit_failed: 'Error!',
    statusSelect: 'Status filter',
    dateFilter: 'Date filter',
    profileFilter: 'Profile filter',
  },
  eori: {
    error: 'EORI invalid!',
    error_400: 'Error! Something went wrong',
    error_pattern: 'Error! Check pattern, EORI should start with letters',
    error_404: 'Search did not find any company',
    error_423: 'Search did not find company info, this is often due to private EORI number',
    error_500: 'Error! Connection to search engine failed. If possible, use europa.eu EORI number validation',
    error_503: 'Error! Connection to search engine failed. If possible, use europa.eu EORI number validation',
    error_GB: 'Following the UK withdrawal from the EU the EORI numbers starting with the “GB” code are not supported',
  },
  directo: {
    descriptionAddendum: 'Description addendum',
    customer: 'Customer',
    customerSearch: 'Search for directo customer',
    remoteStaffCode: 'Directo code',
  },
  customsProfile: {
    profile_new: 'Add guarantee profile',
    profile_edit: 'Edit guarantee profile',
    requestSuccess: 'Access Request Successful',
    shareProfile: 'Share profile',
    default: 'Make profile default',
    revoke: 'Revoke Access',
    info: 'Additional information',
  },
  privacyPolicy: {
    editPrivacy: '$t(buttons.change) privacy policy',
    confirm: 'This event will require all user\'s to re-accept privacy policy / terms. Are you sure about updating policy ?',
    language: 'Did you edit both languages?',
  },
  feedback: {
    title: 'Give feedback to declaration',
    title_canceling: 'Cancel with feedback',
    info: 'Feedback info',
    submit: 'Submit feedback',
    isDeclarationCanceled: 'Cancel declaration?',
    includePdf: 'Include PDF',
    listOfEmails: 'Feedback Emails',
    listOfEmailsExplanation: 'List of e-mails that should receive email',
    blindCopyEmail: 'Blind Copy(BCC) Email',
    blindCopyEmailExplanation: 'Email address that should receive blind copy of this feedback. If empty then no blind copy will be sent',
  },
  notes: {
    title: 'Add note to Declaration',
    submit: 'Submit note',
  },
  transportCustomsOffice: {
    limit: 'Customs offices of transit count is limited to 9',
    containsBlanks: 'Transit customs offices can\'t have blank values. Assign a customs office or remove them',
    required: 'At least one customs office of transit is required',
  },
  preferences: {
    updated: 'Preferences Updated!',
    failed: 'Preferences Update Failed!',
    order: 'The list is draggable. If you want to change the order of this list then click on the name and drag it to suitable place',
  },
  configuration: {
    alert: 'Notification',
  },
  notification: {
    send: 'Send notification',
    inputPlaceholder: 'Message to display below navigation',
    placeholder: 'This will send a notification to all active customers and users. Are you sure ?',
    success: 'Notification sent successfully to users and customers',
    update: 'Update Notification Message',
    edit: 'Edit / Update Notification message',
    updateSuccess: 'Notification Message updated successfully!',
  },
  excel: {
    import: 'Goods Item Import',
    importGoods: 'Import Goods',
    save: 'Save Items',
    processingError: 'Error processing file',
    mustBeCsv: 'Uploaded file must be csv',
    mustBeExcel: 'Uploaded file must be Excel(.xlsx)',
    invalidMimeType: 'Unsupported file type',
    invalidMimeType_allowed: 'Unsupported file type, allowed {{0}}',
    // eslint-disable-next-line max-len
    explanation: 'Existing goods items will be overwritten. \n Incorrect, unmapped or unknown fields will be ignored. \n HS-Code format must be 4, 6 or 8 characters in length and without spaces. Example - "4200".  Country code must be 2 characters. Example: "RU", "EE"',
    explanationPhase5: 'New items will be added to the existing list. \n Incorrect, unmapped or unknown fields will be ignored. \n HS-code format must be 6 characters in length. Combined nomenclature when used, must have 2 characters. Country codes, types and such must be 2-3 characters. Example: "PX", "N730"',
    missingFieldMappings: 'Some columns without mappings have been skipped',
    example: 'Download Example',
  },
  cookie: {
    usage: 'By continuing to use this website, you consent to the use of cookies.',
    agree: 'Agree',
  },
  landing: {
    functionalities: 'Functionalities',
    integrations: 'Integrations',
    contact: 'Contact',
    language: 'Language',
    languageEng: 'English',
    languageRus: 'Russian',
    heroText: 'Transit declarations 24/7',
    heroSubText: 'NCTS Software',
    heroBtnText: 'Join',
    transitDeclarationOverview: 'Transit declaration overview',
    differentCustomsProfileCreation: 'Creating customs profiles for different countries',
    sendingDeclarationsForDifferentCountriesCustoms: 'Sending declarations to different countries customs',
    creatingDeclarations: 'Creating declarations',
    declarationPDFs: 'Declaration PDFs',
    companyUserManagement: 'Company user management',
    customsIntegrations: 'Integrations with customs',
    customsMapAltText: 'Integrations',
    privacyPolicy: 'Privacy policy',
    finland: 'Finland',
    estonia: 'Estonia',
    latvia: 'Latvia',
    lithuania: 'Lithuania',
    poland: 'Poland',
    romania: 'Romania',
    turkey: 'Türkiye',
    germany: 'Germany',
    netherlands: 'Netherlands',
    sweden: 'Sweden',
    bulgaria: 'Bulgaria',
    hungary: 'Hungary',
    greatBritain: 'Great Britain',
    greatBritain_split: 'Great Britain',
    agreeBtn: 'I agree',
    termsAndConditions: 'Terms and Conditions',
    aboutUs: 'About Us',
    integratedModule: 'An integrated module for customs declarations will be created, which allows filling out customs\n'
      + '              declarations of all destination countries through interfaces through a single entry environment. With the help of the module,\n'
      + '              the main process of Tardek OÜ is automated and 750 working hours per month, or 9,000 working hours per year, or approximately\n'
      + '              180 000 euros per year are saved. Achieving such efficiency will make it possible to increase the turnover within two years\n'
      + '              after the end of the project (2027) by 80% compared to 2022 and increase the added value per employee by more than 22.5%. the\n'
      + '              amount of support is EUR 299 600',
    support: ' Tardek OÜ received support for the implementation of the digitalization roadmap\n'
      + '              from the support measure for the digital transformation of companies.\n'
      + '              The amount of the grant was 299,600 euros, the project was supported by the\n'
      + '              European Union from NextGenerationEU funds.',
  },
  seo: {
    columnTitle: 'Search engine optimization tags',
    title: 'Site title',
    description: 'Short description',
    keywords: 'Comma separated keywords',
  },
  guaranteeCalculator: {
    preview: 'Preview guarantee calculation',
    pdf: 'Guarantee calculation',
    useResultValue: 'Use as guarantee amount',
    totalPrice: 'Total value',
    price: 'Price',
    excise: 'Excise',
    dutyRate: 'Duty %',
    vatRate: 'VAT %',
    taxableDutyAmount: 'Taxable duty',
    taxableVatAmount: 'Taxable VAT',
    taxableItemPrice: 'Guarantee taxes',
  },
  consignmentItemImport: {
    header: 'Consignment Item Import',
    import: 'Import',
    import_start: 'Start import',
    import_close: 'Close import',
    importErrorsFound: 'Import found errors',
    missingColumnMapping: 'Column in CSV not mapped to goods item, data may be incomplete - {{0}}',
    rowError_columnCount: 'Row {{0}} invalid. File mapped to {{1}} columns. Row has {{2}} columns',
    rowError_commodityCode: 'Row {{0}} invalid. Archived or unknown commodity code {{1}} ',
    explanation: 'New items will be added to the existing list. \n Incorrect, unmapped or unknown fields will be ignored. \n'
      + ' HS-code format must be 6 characters in length. Combined nomenclature when used, must have 2 characters.'
      + ' Country codes, types and such must be 2-3 characters. Example: "PX", "N730"',
    missingFieldMappings: 'Some columns without mappings have been skipped',
    example: 'Download Example',

  },
  codelistImport: {
    title: 'Codelist importer',
    description: 'Codelist must be imported using the "RD_NCTS-P5_XX.xml" files from europa.eu available for NCTS-P5 domain.',
    supportedCodeGroups: 'Codelist groups supported by T1 system:',
    confirmImport: 'Start import of codelists? New codes will be added, codes missing from file will be marked as archived',
    startImport: 'Start import',
    startImport_success: 'Import successful',
    startImport_failed: 'Import failed',
  },
  commodityCodesImport: {
    title: 'Commodity code importer',
    description: 'Goods item commodity codes must be imported using the CN20XX_Structure files from publications from op.europa.eu. Codes missing from the CSV will be archived, except for the 9905 codes, because they missing in the EU files even while in use',
    description_step1: 'From the asset list select combined nomenclature for the correct year',
    description_step2: 'Open downloadable files tab under "Advanced view" -> "Downloads"',
    description_step3: 'Download CN2025_Structure.xlsx or CN2025_Structure.tsv',
    description_step4: 'Convert downloaded XLSX/TSV file to CSV with semicolon (;) separator',
    description_step5: 'Upload converted CSV file',
    confirmImport: 'Start import of goods commodity codes? Import will work in background',
    startImport_started: 'Started import',
    startImport_failed: 'Import failed',
  },
  quickFilters: {
    showReleasedTransitWithLimitDateOverdue: 'Show released transits with limit date overdue',
  },
}

export default TRANSLATIONS_EN
