import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import { excludeDeleted, hasText } from '../../../../common/utils/common-util'

const C0343Validator = {
  validateHouseConsignmentCountryOfDestinationRequirement: (form: DeclarationForm, houseConsignment: HouseConsignmentType): RuleResult => {
    const { countryOfDestination } = form
    if (countryOfDestination.trim().length > 0) {
      return 'NOT_ALLOWED'
    }

    if (houseConsignment.consignmentItem
      .filter(excludeDeleted)
      .some((item) => hasText(item.countryOfDestination))) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
  validateItemCountryOfDestinationRequirement: (form: DeclarationForm, houseConsignment: HouseConsignmentType): RuleResult => {
    const {
      countryOfDestination,
    } = form
    if (countryOfDestination.trim().length > 0) {
      return 'NOT_ALLOWED'
    }

    if (hasText(houseConsignment.countryOfDestination)) {
      return 'NOT_ALLOWED'
    }

    return 'REQUIRED'
  },
} as const

export default C0343Validator
