import React from 'react'
import HouseConsignmentsTable from './HouseConsignmentsTable'

function DeclarationConsignmentTab() {
  return (
    <div className="container-fluid py-3">
      <HouseConsignmentsTable />
    </div>
  )
}

export default DeclarationConsignmentTab

