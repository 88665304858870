import { IClassifier, ISelectOption } from '../types/IClassifier'
import { PageResponse } from '../types/PageResponse'
import { TardekError } from '../types/TardekError'
import { ITransitCountry } from '../types/IDeclaration'

// TODO wrap to useMemo
export function getSelectValue(formFieldValue: string | null, selectOptions: ISelectOption[]) {
  return formFieldValue !== null ? selectOptions.find((item) => item.value === formFieldValue) : null
}

export function isNullOrBlank(field: string | null | undefined | number): boolean {
  if (field === null || field === undefined || field === '') {
    return true
  }

  return typeof field === 'string' ? field.trim().length === 0 : field.toFixed(5) === ''
}

export function rejectError(name: string, message: string): Promise<TardekError> {
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({
    name, message,
  } as TardekError)
}

export function getValue(field: string | undefined | null, trimLeading?: string) {
  if (field === null || field === undefined) {
    return ''
  }
  if (trimLeading !== undefined && field.length > trimLeading.length) {
    return field.substring(trimLeading.length)
  }

  return field
}

export function getNameForLocale<T extends {
  nameEn?: string | null
  nameRu?: string | null
}>(inputObject: IClassifier | ITransitCountry | T, language: string): string {
  if (language === 'ru') {
    return `${inputObject.nameRu ?? inputObject.nameEn}`
  }
  return `${inputObject.nameEn ?? inputObject.nameRu}`
}

export function getClassifier(code: string | null | undefined, classifiers: Array<IClassifier>): IClassifier | undefined {
  if (code === null || code === undefined) {
    return undefined
  }

  return classifiers.find((country) => country.code === code)
}

export function getClassifierName(code: string | null | undefined, classifiers: Array<IClassifier>, language: string): string {
  const result = getClassifier(code, classifiers)
  if (result === undefined) {
    return code ?? ''
  }

  return getNameForLocale(result, language)
}

export function isValuePrecisionValid(inputValue: string | number) {
  return (inputValue as string).match('^\\d*[\\.\\,]?\\d{0,3}$') !== null
}

export function safeTrim(value: string | null) {
  return value !== undefined && value !== null && (typeof value === 'string') ? value.trim() : value
}

export function isMimeTypeImage(mimeType: string) {
  return mimeType === 'image/png' || mimeType === 'image/jpeg' || mimeType === 'image/jpg' || mimeType === 'image/bmp'
}

export function isMimeTypePdf(mimeType: string) {
  return mimeType === 'application/pdf'
}

export function isMimeTypeExcel(mimeType: string) {
  return mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    || mimeType === 'application/vnd.ms-excel'
    || mimeType === 'application/vnd.oasis.opendocument.text'
}

export function isMimeTypeWord(mimeType: string) {
  return mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || mimeType === 'application/msword'
}

export function extractPageResponseContent<T>(response: PageResponse<T>, isMobile: boolean) {
  if (!response.content || response.empty) {
    return []
  }

  if (isMobile) {
    const content = [...response.content]
    for (const newRow of content) {
      // @ts-ignore
      newRow.subRows = [{ ...newRow }]
    }
    return [...content]
  }
  return [...response.content]
}

export function filterCustomsOfficeOptions(searchInput: string, options: Array<ISelectOption>, separator?: string) {
  if (searchInput.length === 0) {
    return options.slice(0, 10)
  }
  const searchQuery = searchInput.toLowerCase()

  const startWithOptions = []
  const containsOptions = []

  // eslint-disable-next-line no-restricted-syntax
  for (const option of options) {
    if (option.label.split(separator ?? ':')[0]) {
      const codeLabel = option.label.split(separator ?? ':')[0].toLowerCase()
      if (codeLabel.startsWith(searchQuery)) {
        startWithOptions.push(option)
      } else if (codeLabel.includes(searchQuery)) {
        containsOptions.push(option)
      }
    }
  }

  // eslint-disable-next-line no-nested-ternary
  return [...startWithOptions.sort((a, b) => ((a.label < b.label) ? -1 : ((a.label > b.label) ? 1 : 0))),
    ...containsOptions]
}

export function sortBySortOrder(a: { sortOrder: number | null }, b: { sortOrder: number | null }) {
  // eslint-disable-next-line no-nested-ternary
  return a.sortOrder !== null && b.sortOrder !== null ? (a.sortOrder < b.sortOrder) ? -1 : ((a.sortOrder > b.sortOrder) ? 1 : 0) : 0
}
export function sortByCodeLabel(a: { codeLabel: string | null }, b: { codeLabel: string | null }) {
  // eslint-disable-next-line no-nested-ternary
  return a.codeLabel !== null && b.codeLabel !== null ? (a.codeLabel < b.codeLabel) ? -1 : ((a.codeLabel > b.codeLabel) ? 1 : 0) : 0
}

const customsOfficeCountryPartStart = 15
const customsOfficeCountryPartEnd = 17
export function getCountryCodeFromCustomsOffice(customsOffice: string) {
  return customsOffice.substring(customsOfficeCountryPartStart, customsOfficeCountryPartEnd)
}

// XI && NI -> true
export function isIrelandProtocol(departureCountry: string, destinationCountry: string) {
  if (departureCountry === 'XI') { // North-Ireland
    return destinationCountry === 'NI'
  }
  if (destinationCountry === 'NI') { // Republic of Ireland
    return departureCountry === 'XI'
  }

  return false
}

export function getRegionalBloc(country: IClassifier | undefined): undefined | string {
  return country?.attributes.find((attr) => attr.key === 'REGIONAL_BLOC')?.value
}

export function minutesToMillis(minutes: number) {
  return minutes * 60 * 1000
}
