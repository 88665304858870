import { z } from 'zod'
import TransportEquipmentSchema, { GoodsReferenceType, SealType, TransportEquipmentType } from './transportEquipmentSchema'
import { CarrierSchema, ConsigneeSchema, ConsignorSchema } from './tradersSchema'
import LocationOfGoodsSchema from './locationOfGoodsSchema'
import { PlaceOfLoadingSchema, PlaceOfUnloadingSchema } from './placesSchemas'
import {
  ActiveBorderTransportMeansSchema,
  ActiveBorderTransportMeansType,
  AdditionalInformation,
  AdditionalInformationSchema,
  AdditionalReference,
  AdditionalReferenceSchema,
  AdditionalSupplyChainActor,
  AdditionalSupplyChainActorSchema,
  Authorisation,
  AuthorisationSchema,
  BooleanSchema,
  CountryOfRoutingOfConsignment,
  CountryOfRoutingOfConsignmentSchema, CustomsOfficeOfExitForTransitDeclared,
  CustomsOfficeOfExitForTransitDeclaredSchema,
  CustomsOfficeOfTransitDeclared,
  CustomsOfficeOfTransitDeclaredSchema,
  DepartureTransportMeansSchema,
  DepartureTransportMeansType,
} from './commonConsignmentSchemas'
import {
  PreviousDocument,
  PreviousDocumentSchema,
  SupportingDocument,
  SupportingDocumentSchema,
  TransportDocument,
  TransportDocumentSchema,
} from './documentSchemas'
import HouseConsignmentSchema, { HouseConsignmentType } from './houseConsignmentSchema'
import GuaranteeSchema, { Guarantee } from './guaranteeSchema'
import { ConsignmentItem, DangerousGoodsType, PackagingType } from './consignmentItemSchema'
import DirectoFormSchema from './directoFormSchema'
import {
  CreateOrUpdateTransitOperationRequestDeclarationTypeEnum,
  CreateOrUpdateTransitOperationRequestSecurityEnum,
} from '../../../common/models'
import { FileType } from './fileSchemas'

export const SecurityEnum = z.enum([
  CreateOrUpdateTransitOperationRequestSecurityEnum.S0,
  CreateOrUpdateTransitOperationRequestSecurityEnum.S1,
  CreateOrUpdateTransitOperationRequestSecurityEnum.S2,
  CreateOrUpdateTransitOperationRequestSecurityEnum.S3,
])
export const DeclarationTypeEnum = z.enum([
  CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T,
  CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T1,
  CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T2,
  CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T2F,
  CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.TIR,
  CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T2SM,
])

const DeclarationFormSchema = z.object({
  id: z.number().nullable(),
  declarationType: DeclarationTypeEnum.default(CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T1),
  deleted: z.boolean().optional(),
  status: z.string().default('DRAFT').nullable(),
  mrn: z.string().nullable(),
  security: SecurityEnum,
  limitDate: z.string().nullable(), // expected date of arrival at destination
  reducedDatasetIndicator: BooleanSchema.default('false'),
  bindingItinerary: BooleanSchema.default('false'),
  additionalDeclarationType: z.string().optional().default('A'),
  lrn: z.string().nullable(),
  countryOfDispatch: z.string(),
  countryOfDestination: z.string(),
  containerIndicator: BooleanSchema.default('false'),
  inlandModeOfTransport: z.string(),
  borderModeOfTransport: z.string(),
  grossMass: z.number().min(0).refine((arg) => /^\d{1,11}(\.\d{1,3})?$/.exec(String(arg))),
  referenceNumberUCR: z.string().nullable(),
  transportChargesMethodOfPayment: z.string().nullable(),
  departureCustomsOffice: z.string(),
  destinationCustomsOffice: z.string().nullable(),
  consignmentId: z.number().nullable(),
  customerId: z.number().nullable(),
  directoId: z.number().nullable(),

  carrier: CarrierSchema,
  consignor: ConsignorSchema,
  consignee: ConsigneeSchema,
  locationOfGoods: LocationOfGoodsSchema.nullable(),
  placeOfLoading: PlaceOfLoadingSchema,
  placeOfUnloading: PlaceOfUnloadingSchema,
  directo: DirectoFormSchema,

  transitCustomsOffices: z.array(CustomsOfficeOfTransitDeclaredSchema),
  customsOfficesOfTransitExit: z.array(CustomsOfficeOfExitForTransitDeclaredSchema),
  guarantees: z.array(GuaranteeSchema),
  authorisations: z.array(AuthorisationSchema),
  additionalSupplyChainActor: z.array(AdditionalSupplyChainActorSchema),
  transportEquipment: z.array(TransportEquipmentSchema),
  departureTransportMeans: z.array(DepartureTransportMeansSchema),
  countryOfRoutingOfConsignment: z.array(CountryOfRoutingOfConsignmentSchema),
  activeBorderTransportMeans: z.array(ActiveBorderTransportMeansSchema),
  previousDocument: z.array(PreviousDocumentSchema),
  supportingDocument: z.array(SupportingDocumentSchema),
  transportDocument: z.array(TransportDocumentSchema),
  additionalReference: z.array(AdditionalReferenceSchema),
  additionalInformation: z.array(AdditionalInformationSchema),
  houseConsignment: z.array(HouseConsignmentSchema),
})

export type DeclarationForm = z.infer<typeof DeclarationFormSchema>
export type DeclarationFormFormattedErrors = z.inferFormattedError<typeof DeclarationFormSchema>
export type DeclarationFormFlattenedErrors = z.inferFlattenedErrors<typeof DeclarationFormSchema>

export type SecurityEnumT = z.infer<typeof SecurityEnum>

export type DeclarationFormSubtype =
  CustomsOfficeOfTransitDeclared |
  CustomsOfficeOfExitForTransitDeclared |
  Guarantee |
  Authorisation |
  AdditionalSupplyChainActor |
  TransportEquipmentType |
  DepartureTransportMeansType |
  CountryOfRoutingOfConsignment |
  ActiveBorderTransportMeansType |
  PreviousDocument |
  SupportingDocument |
  TransportDocument |
  AdditionalReference |
  AdditionalInformation |
  HouseConsignmentType |
  ConsignmentItem |
  DangerousGoodsType |
  GoodsReferenceType |
  PackagingType |
  SealType |
  FileType

export default DeclarationFormSchema
