import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'components/LoadingSpinner'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button'
import { getBaseApiUrl } from 'services/api.service'
import FileUploadDropzone from 'components/FileUploadDropzone'
import ConfirmationModal from '../ConfirmationModal'
import { TabType } from '../index'

interface CodelistTabProps {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line react/no-unused-prop-types
  readonly tabType: TabType
}

function CodelistTab(props: CodelistTabProps) {
  const { t } = useTranslation()
  const {
    loading,
    setLoading,
  } = props

  const [importAction, setImportAction] = useState<'commodity' | 'codelist'>('commodity')
  const [codelistFiles, setCodelistFiles] = useState<File[]>([])
  const [commodityFiles, setCommodityFiles] = useState<File[]>([])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [modalBody, setModalBody] = useState<string>('')

  const uploadCodelistFiles = useMutation({
    mutationFn: async (uploadFiles: File[]) => {
      const fileBytes: Array<PromiseSettledResult<{ name: string; bytes: ArrayBuffer; type: string }>> = await Promise.allSettled(
        uploadFiles.map((file) => file.arrayBuffer()
          .then((bytes) => (
            { name: file.name, bytes, type: file.type }
          ))),
      )
      const formData = new FormData()
      fileBytes.forEach((fileResult) => {
        if (fileResult.status === 'fulfilled') {
          formData.append('files', new Blob([fileResult.value.bytes], { type: fileResult.value.type }), fileResult.value.name)
        }
      })

      return (await axios.post<void>(
        getBaseApiUrl('/v2/import/codelist'),
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      ))
    },
  })

  const uploadCommodityFile = useMutation({
    mutationFn: async (uploadFiles: File[]) => {
      const fileBytes: Array<PromiseSettledResult<{ name: string; bytes: ArrayBuffer; type: string }>> = await Promise.allSettled(
        uploadFiles.map((file) => file.arrayBuffer()
          .then((bytes) => (
            { name: file.name, bytes, type: file.type }
          ))),
      )
      const formData = new FormData()
      fileBytes.forEach((fileResult) => {
        if (fileResult.status === 'fulfilled') {
          formData.append('file', new Blob([fileResult.value.bytes], { type: fileResult.value.type }), fileResult.value.name)
        }
      })
      return (await axios.post<void>(
        getBaseApiUrl('/v2/import/codelist/commodity-codes'),
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      ))
    },
  })

  const startImport = () => {
    setIsConfirmationModalOpen(false)
    setLoading(true)

    if (importAction === 'codelist') {
      uploadCodelistFiles.mutateAsync(codelistFiles)
        .then(() => toast.success(t('codelistImport.startImport', { context: 'success' })))
        .catch(() => toast.error(t('codelistImport.startImport', { context: 'failed' })))
        .finally(() => setLoading(false))
    } else {
      uploadCommodityFile.mutateAsync(commodityFiles)
        .then(() => toast.success(t('commodityCodesImport.startImport', { context: 'started' })))
        .catch(() => toast.error(t('commodityCodesImport.startImport', { context: 'failed' })))
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <div className="section__container overflow-auto">
        <div className="pt-4 px-4 col-12 col-lg-6 mb-5 mb-md-0 ">
          <div className="heading">
            <h3>
              {t('codelistImport.title')}
            </h3>
          </div>
          <p>
            <div>
              {t('codelistImport.description')}
            </div>
            <Button
              href="https://ec.europa.eu/taxation_customs/dds2/rd/rd_download_home.jsp?Lang=en"
              target="_blank"
              rel="noopener noreferrer"
              type="button"
            >
              europa.eu site
            </Button>
          </p>
          <span>
            {t('codelistImport.supportedCodeGroups')}
          </span>
          <div className="d-flex">
            <ul>
              <li>CountryCodesFullList</li>
              <li>TransportModeCode</li>
              <li>TypeOfIdentificationOfMeansOfTransport</li>
              <li>PreviousDocumentType</li>
              <li>SupportingDocumentType</li>
              <li>TransportDocumentType</li>
              <li>KindOfPackages</li>
              <li>Unit</li>
              <li>AuthorisationTypeDeparture</li>
            </ul>
            <ul>
              <li>AdditionalInformation</li>
              <li>AdditionalReference</li>
              <li>TransportChargesMethodOfPayment</li>
              <li>CustomsOffices</li>
              <li>CustomsOfficeTransit</li>
              <li>CustomsOfficeTransitExit</li>
              <li>CustomsOfficeDeparture</li>
              <li>CustomsOfficeDestination</li>
            </ul>
          </div>
          <FileUploadDropzone setAcceptedFiles={setCodelistFiles} />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              disabled={loading || codelistFiles.length === 0}
              className="btn btn-secondary btn-lg d-flex text-primary mt-2"
              onClick={() => {
                setIsConfirmationModalOpen(true)
                setModalBody(t('codelistImport.confirmImport'))
                setImportAction('codelist')
              }}
            >
              {loading ? <LoadingSpinner /> : t('codelistImport.startImport')}
            </button>
          </div>
        </div>
        <div className="pt-4 px-4 col-12 col-lg-6 mb-5 mb-md-0">
          <div className="heading">
            <h3>
              {t('commodityCodesImport.title')}
            </h3>
          </div>
          <p>
            <div>
              {t('commodityCodesImport.description')}
            </div>
            <Button
              href="https://op.europa.eu/en/web/eu-vocabularies/taxonomies?Lang=en"
              target="_blank"
              rel="noopener noreferrer"
              type="button"
            >
              op.europa.eu
            </Button>
          </p>
          <div className="d-flex">
            <ul>
              <li>{t('commodityCodesImport.description_step1')}</li>
              <li>{t('commodityCodesImport.description_step2')}</li>
              <li>{t('commodityCodesImport.description_step3')}</li>
              <li>{t('commodityCodesImport.description_step4')}</li>
              <li>{t('commodityCodesImport.description_step5')}</li>
            </ul>
          </div>
          <FileUploadDropzone setAcceptedFiles={setCommodityFiles} maxFiles={1} />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              disabled={loading || commodityFiles.length === 0}
              className="btn btn-secondary btn-lg d-flex text-primary mt-2"
              onClick={() => {
                setIsConfirmationModalOpen(true)
                setModalBody(t('commodityCodesImport.confirmImport'))
                setImportAction('commodity')
              }}
            >
              {loading ? <LoadingSpinner /> : t('codelistImport.startImport')}
            </button>
          </div>
        </div>
      </div>

      <ConfirmationModal
        loading={loading}
        title={t('common.confirmAction')}
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        submissionConfirmed={() => {
          startImport()
        }}
        modalBody={(<p>{modalBody}</p>)}
      />
    </>
  )
}

export default CodelistTab
