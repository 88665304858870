import { isTransitionalPeriodActive } from '../../../common/utils/ncts-constants'

const E1406Validator = {
  getHouseConsignmentMultiplicity: (): number => {
    if (isTransitionalPeriodActive()) {
      return 1
    }
    return 1999
  },
}

export default E1406Validator
