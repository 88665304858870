import React, {
  ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import DeclarationService from '../services/declaration.service'
import { DeclarationPageResponse } from '../../../types/DeclarationPageResponse'
import { DeclarationAudit } from '../../../types/DeclarationAudit'
import { UserContext } from '../../../context/UserContext'
import { UserRole } from '../../../hooks/useAuth'
import { Document, DocumentFile, DocumentType } from '../../../types/Document'
import DocumentService from '../services/document.service'
import FileService from '../services/file.service'
import { isMimeTypeExcel, isMimeTypeImage } from '../../../helpers'
import { FilePreviewModal } from '../../Transits/TransitsAside/FilePreviewModal'

interface NotesModalProps {
  declarationId: number
  isNotesModalOpen: boolean
  setIsNotesModalOpen: Dispatch<SetStateAction<boolean>>
  originalRow: DeclarationPageResponse | DeclarationAudit | undefined
}

function NotesModal({
  declarationId,
  isNotesModalOpen,
  setIsNotesModalOpen,
  originalRow,
}: NotesModalProps) {
  const { t } = useTranslation()
  const [notes, setNotes] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [isPreviewModalVisible, setPreviewModalVisibility] = useState(false)
  const [previewModalFile, setPreviewModalFile] = useState<DocumentFile>()

  const [document, setDocument] = useState<Document>({
    id: null,
    type: DocumentType.NOTE,
    number: '',
    documentType: 'DOCUMENT_TYPE_NOTE',
    procedureType: null,
    sortOrder: 1337,
    declarationId: null,
    goodsItemId: null,
    deleted: false,
    orderNumber: null,
    transportDocumentId: null,
    files: [],
  })

  const { user } = useContext(UserContext)

  useEffect(() => {
    setDocument({
      id: null,
      type: DocumentType.NOTE,
      number: '',
      documentType: 'DOCUMENT_TYPE_NOTE',
      procedureType: null,
      sortOrder: 1337,
      declarationId: null,
      goodsItemId: null,
      deleted: false,
      orderNumber: null,
      transportDocumentId: null,
      files: [],
    })
    if (declarationId && user?.role === UserRole.ADMIN) {
      setNotes(originalRow?.notes ?? '')
      DocumentService.getAllDocuments(declarationId, DocumentType.NOTE)
        .then((prevDocs) => {
          if (prevDocs.length !== 0) {
            setDocument(prevDocs[0])
            prevDocs.forEach((item) => {
              item.files.forEach((file) => {
                DocumentService.getDocumentFileSignedUrl(file)
                  .then((signedUrl) => {
                    // eslint-disable-next-line no-param-reassign
                    file.url = signedUrl
                  })
              })
            })
          }
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }
  }, [declarationId])

  const handleFileUpload = (savedDocument: Document) => {
    for (const documentFile of document.files) {
      if (documentFile.uuid !== null && documentFile.deleted) {
        DocumentService.deleteDocumentFile(documentFile)
      } else if (documentFile.uuid === null && !documentFile.deleted) {
        DocumentService.postDocumentFile(documentFile, savedDocument.id!, declarationId)
          .then((savedFileUuid) => {
            documentFile.uuid = savedFileUuid
            documentFile.file = null
            documentFile.deleted = false

            return savedFileUuid
          })
      }
    }
  }

  const submitNotes = () => {
    if (!declarationId && notes === '') {
      return
    }
    setLoading(true)

    DeclarationService.addNotes(declarationId, notes).then(() => {
      setTimeout(() => {
        if (originalRow) {
          // eslint-disable-next-line no-param-reassign
          originalRow.notes = notes
        }
        setIsNotesModalOpen(false)
        toast.success(t('messages.savingSuccess'))
        setLoading(false)
      }, 300)
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      setTimeout(() => {
        toast.error(t('messages.savingFailed'))
        setLoading(false)
      }, 300)
    })
    if (document.id === null || document.id === undefined) {
      DocumentService.postDocument(document, declarationId)
        .then((savedDocument) => {
          handleFileUpload(savedDocument)
        })
    } else {
      DocumentService.putDocument(document, declarationId)
        .then((savedDocument) => {
          handleFileUpload(savedDocument)
        })
    }
  }

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  const noteFileRef = useRef<HTMLInputElement>(null)

  const isFilenameUnique = (filename: string) => document.files
    .filter((addedFile) => (addedFile.deleted !== true && (addedFile.filename.trim() === filename))).length === 0

  const generateNameForUploadedFile = (file: string) => {
    let filename = file.trim()

    let isFileNameUnique = isFilenameUnique(filename)
    if (isFileNameUnique) {
      return filename
    }

    let depth = 1
    while (!isFileNameUnique) {
      filename = FileService.addDuplicationNumberToName(filename, depth)
      isFileNameUnique = isFilenameUnique(filename)
      if (!isFileNameUnique) {
        // eslint-disable-next-line no-plusplus
        depth++
      }
    }

    return filename
  }

  function addNewFiles(droppedFiles: FileList) {
    const changedFiles = document.files === undefined ? [] : [...document.files]
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles.item(i)
      if (!file) {
        toast.error('Error accessing file')
      } else if (file.size > 5242880) {
        toast.error(`File '${file.name}' size is too large. Maximum allowed size - ${5242880 / 1024 / 1024} MiB`)
      } else {
        const newFile: DocumentFile = {
          id: null,
          sortOrder: changedFiles.length,
          deleted: false,
          filename: generateNameForUploadedFile(file.name),
          uuid: null,
          mimeType: file.type,
          file,
        }
        changedFiles.push(newFile)
        setDocument({ ...document, files: [...changedFiles] })
      }
    }
  }

  const renderFilePreviewModal = () => {
    if (!previewModalFile) {
      return null
    }

    if (isMimeTypeImage(previewModalFile.mimeType)) {
      return (
        <FilePreviewModal
          type="IMAGE"
          key="filePreviewModal"
          isVisible={isPreviewModalVisible}
          toggleVisibility={() => setPreviewModalVisibility(!isPreviewModalVisible)}
          file={previewModalFile}
        />
      )
    }

    if (isMimeTypeExcel(previewModalFile.mimeType)) {
      return (
        <FilePreviewModal
          type="EXCEL"
          key="filePreviewModal"
          isVisible={isPreviewModalVisible}
          toggleVisibility={() => setPreviewModalVisibility(!isPreviewModalVisible)}
          file={previewModalFile}
        />
      )
    }

    return (
      <FilePreviewModal
        type="PDF"
        key="filePreviewModal"
        isVisible={isPreviewModalVisible}
        toggleVisibility={() => setPreviewModalVisibility(!isPreviewModalVisible)}
        file={previewModalFile}
      />
    )
  }

  const deleteDocument = (changedDocumentFile: DocumentFile) => {
    const changeDoc = {
      ...document,
      files: [...document.files.map((documentFileInState) => {
        const newDocFile = {
          ...documentFileInState,
        }

        if (documentFileInState.filename === changedDocumentFile.filename) {
          newDocFile.deleted = true
        }

        return newDocFile
      })],
    }
    setDocument(changeDoc)
  }

  return (
    <>
      <Modal
        show={isNotesModalOpen}
        className="overflow-hidden"
        dialogClassName="modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          if (!loading) {
            setIsNotesModalOpen(!isNotesModalOpen)
          }
        }}
        animation={false}
        backdropClassName="backdrop-with-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title className="justify-content-between">
            <h2>{t('notes.title')}</h2>
            <button
              type="button"
              className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
              onClick={() => {
                if (!loading) {
                  setIsNotesModalOpen(!isNotesModalOpen)
                }
              }}
            >
              <span>{t('buttons.close')}</span>
              <i className="fal fa-times fa-2x ms-2" />
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-self-center w-100">
            <textarea
              value={notes}
              className="w-100 form-control-lg mb-2"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
            />
          </div>

          <div className="row mb-1">
            <div className="d-none d-md-block col-12 col-md-4" />
            <div className="col-12 col-md-8 d-flex flex-column ">
              { document.files?.filter((doc) => !doc.deleted)
                .map((file) => (
                  <div className="d-flex" key={`document-file-${file.filename}`}>
                    <button
                      className="btn btn-link d-flex overflow-auto"
                      type="button"
                      onClick={() => {
                        setPreviewModalFile(file)
                        setPreviewModalVisibility(!isPreviewModalVisible)
                      }}
                    >
                      <i className="fal fa-file fa-md me-2" />
                      <span className="overflow-ellipsis">{file.filename}</span>
                    </button>
                    <button
                      className="btn btn-link text-decoration-none"
                      type="button"
                      onClick={() => {
                        deleteDocument(file)
                      }}
                    >
                      <i className="fal fa-times me-2 ms-1 ms-md-0" />
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12">
              <div
                role="presentation"
                className="dropdown-area"
                onClick={() => {
                  if (noteFileRef.current) {
                    noteFileRef.current.click()
                  }
                }}
                onDragOver={(event) => {
                  event.currentTarget.classList.add('file-hover')
                  event.preventDefault()
                }}
                onDragLeave={(event) => {
                  event.currentTarget.classList.remove('file-hover')
                }}
                onDropCapture={(event) => {
                  event.currentTarget.classList.remove('file-hover')

                  addNewFiles(event.dataTransfer.files)

                  event.preventDefault()
                  event.stopPropagation()
                }}
              >
                <input
                  className="d-none"
                  type="file"
                  ref={noteFileRef}
                  onChange={(event) => {
                    if (event.target.files) {
                      addNewFiles(event.target.files)
                    }
                    event.preventDefault()
                    event.stopPropagation()
                    if (noteFileRef.current) {
                      noteFileRef.current.value = ''
                    }
                  }}
                />
                <i className="fal fa-cloud-upload me-2" />
                <span>
                  {t('buttons.addFiles')}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-content-end justify-content-end mt-5">
            <button
              type="button"
              disabled={!declarationId && notes === ''}
              className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
              onClick={submitNotes}
            >
              {
                loading && loadingSpinner()
              }
              {
                !loading && (<span>{t('notes.submit')}</span>)
              }
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {
        previewModalFile && renderFilePreviewModal()
      }
    </>
  )
}

export default NotesModal
