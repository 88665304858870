import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DocumentRequestWithFiles, toFileRequest } from './mapper'
import { FileType } from '../../form/schemas/fileSchemas'
import { hasText, isDeletedWithId } from '../../../common/utils/common-util'
import useFileApi, { FileFolder } from '../useFile/api'
import { DocumentRequestTypeEnum, DocumentResponse, FileResponse } from '../../../common/models'
import { UseDocumentApi } from './api'
import { PreviousDocument, SupportingDocument, TransportDocument } from '../../form/schemas/documentSchemas'

export function attachNewDocumentsWithSavedIds(
  documents: DocumentResponse[],
  requests: DocumentRequestWithFiles[],
): DocumentRequestWithFiles[] {
  for (const document of documents) {
    if (document == null) {
      continue
    }

    for (const documentRequest of requests) {
      if (documentRequest.request.type?.toString() === document.type?.toString()
        && (
          document.consignmentId === documentRequest.request.consignmentId
          || document.houseConsignmentId === documentRequest.request.houseConsignmentId
          || document.consignmentItemId === documentRequest.request.consignmentItemId
        )
        && (document.sequenceNumber === documentRequest.request.sequenceNumber || documentRequest.request.id === document.id)
      ) {
        documentRequest.request.id = document.id

        for (const file of documentRequest.files) {
          file.documentId = document.id
        }
      }
    }
  }
  return requests
}

export function findDocumentIndex(
  documentSequenceNumber: number,
  documents: TransitDocument[],
) {
  const documentIndex = documents
    .findIndex((document) => !document.deleted && (document.sequenceNumber === documentSequenceNumber))

  if (documentIndex === undefined) {
    throw Error(`Unable to find index of document with sequence number ${documentSequenceNumber}`)
  }

  return documentIndex
}

// TODO convert to Array.prototype.groupToMap when supported by browsers?
export const groupBy = <T, K, E = T>(list: T[], getKey: (item: T) => K, apply?: (item: T, index: number) => E): Map<K, E[]> => {
  const map = new Map<K, E[]>()
  list.forEach((item, index) => {
    const key = getKey(item)
    if (!map.has(key)) {
      map.set(key, [])
    }
    map.get(key)
      ?.push(apply ? apply(item, index) : item as unknown as E)
  })
  return map
}

export type TransitDocument = TransportDocument | SupportingDocument | PreviousDocument

export interface UseDocumentCommonActionsHelper {
  handleFileUploads: (createRequests: DocumentRequestWithFiles[], updateRequests: DocumentRequestWithFiles[]) => Promise<FileResponse[]>
  handleDocuments: (createRequests: DocumentRequestWithFiles[], updateRequests: DocumentRequestWithFiles[]) => Promise<DocumentResponse[]>
  archiveDeletedDocuments: (documents: TransitDocument[], type: DocumentRequestTypeEnum) => Promise<void>[]
}

function useDocumentCommonActionsHelper(
  transportDocumentApi: UseDocumentApi,
  previousDocumentApi: UseDocumentApi,
  supportingDocumentApi: UseDocumentApi,
) {
  const {
    postDocuments: postTransportDocuments,
    putDocuments: putTransportDocuments,
    deleteDocument: deleteTransportDocument,
  } = transportDocumentApi
  const {
    postDocuments: postPreviousDocuments,
    putDocuments: putPreviousDocuments,
    deleteDocument: deletePreviousDocument,
  } = previousDocumentApi
  const {
    postDocuments: postSupportingDocuments,
    putDocuments: putSupportingDocuments,
    deleteDocument: deleteSupportingDocument,
  } = supportingDocumentApi

  const { t } = useTranslation()
  const {
    postFile,
    deleteFile,
  } = useFileApi()

  const handleFileUploadOrDelete = (
    requests: DocumentRequestWithFiles[],
  ) => requests
    .flatMap((documentWithFiles) => documentWithFiles.files)
    .filter((file) => !(file.deleted && file.id === null))
    .map((file: FileType) => {
      if (file.deleted && hasText(file.uuid)) {
        const splitUuid = file.uuid!.split('/')
        return deleteFile.mutateAsync({
          folder: splitUuid[0] as FileFolder,
          fileUuid: splitUuid[1],
        })
      }
      if (file.documentId !== null && !hasText(file.uuid)) {
        return postFile.mutateAsync(toFileRequest(file, file.documentId))
      }

      return Promise.resolve()
    })

  const submitDocumentsByGroup = (newDocuments: DocumentRequestWithFiles[], method: 'POST' | 'PUT') => {
    const documentsGrouped = groupBy(
      newDocuments,
      (document) => document.request.type,
      (item) => item.request,
    )
    const asyncRequests: Array<Promise<DocumentResponse[]>> = []

    const transportDocuments = documentsGrouped.get(DocumentRequestTypeEnum.TRANSPORT)
    if (transportDocuments !== undefined) {
      const submitDocuments = method === 'POST' ? postTransportDocuments : putTransportDocuments
      asyncRequests.push(submitDocuments.mutateAsync(transportDocuments))
    }
    const previousDocuments = documentsGrouped.get(DocumentRequestTypeEnum.PREVIOUS)
    if (previousDocuments !== undefined) {
      const submitDocuments = method === 'POST' ? postPreviousDocuments : putPreviousDocuments
      asyncRequests.push(submitDocuments.mutateAsync(previousDocuments))
    }
    const supportingDocuments = documentsGrouped.get(DocumentRequestTypeEnum.SUPPORTING)
    if (supportingDocuments !== undefined) {
      const submitDocuments = method === 'POST' ? postSupportingDocuments : putSupportingDocuments
      asyncRequests.push(submitDocuments.mutateAsync(supportingDocuments))
    }

    return asyncRequests
  }

  const archiveDeletedDocuments = (
    documents: TransportDocument[] | SupportingDocument[] | PreviousDocument[],
    type: DocumentRequestTypeEnum,
  ) => documents
    .filter(isDeletedWithId)
    .map((document) => {
      for (const file of document.files) {
        if (hasText(file.uuid)) {
          const splitUuid = file.uuid!.split('/')
          deleteFile.mutateAsync({
            folder: splitUuid[0] as FileFolder,
            fileUuid: splitUuid[1],
          })
            .catch(() => {
              toast.error(`Failed to delete file ${file.fileName} for document`)
            })
        }
      }

      if (type === DocumentRequestTypeEnum.TRANSPORT) {
        return deleteTransportDocument.mutateAsync(document.id!)
      }
      if (type === DocumentRequestTypeEnum.PREVIOUS) {
        return deletePreviousDocument.mutateAsync(document.id!)
      }
      if (type === DocumentRequestTypeEnum.SUPPORTING) {
        return deleteSupportingDocument.mutateAsync(document.id!)
      }

      return Promise.resolve()
    })

  const handleFileUploads = async (createRequests: DocumentRequestWithFiles[], updateRequests: DocumentRequestWithFiles[]) => {
    const asyncFileRequests: Array<Promise<void | FileResponse>> = []

    asyncFileRequests.push(...handleFileUploadOrDelete(createRequests))
    asyncFileRequests.push(...handleFileUploadOrDelete(updateRequests))

    const filePromises = await Promise.allSettled(asyncFileRequests)

    if (filePromises.some((result) => result.status === 'rejected')) {
      toast.warn(t('declaration.p5.upload', { context: 'error' }))
    }

    return filePromises
      .filter((promise): promise is PromiseFulfilledResult<FileResponse> => promise.status !== 'rejected')
      .flatMap((promise) => promise.value)
  }

  const handleDocuments = async (createRequests: DocumentRequestWithFiles[], updateRequests: DocumentRequestWithFiles[]) => {
    const asyncRequests: Array<Promise<DocumentResponse[]>> = []

    asyncRequests.push(...submitDocumentsByGroup(createRequests, 'POST'))
    asyncRequests.push(...submitDocumentsByGroup(updateRequests, 'PUT'))

    const documentPromises = await Promise.allSettled(asyncRequests)
    if (documentPromises.some((result) => result.status === 'rejected')) {
      toast.error(`${t('common.error')}, document saving`)
    }

    return documentPromises
      .filter((promise): promise is PromiseFulfilledResult<DocumentResponse[]> => promise.status !== 'rejected')
      .flatMap((promise) => promise.value)
  }

  return {
    handleFileUploads,
    handleDocuments,
    archiveDeletedDocuments,
  }
}

export default useDocumentCommonActionsHelper
