import Tooltip from 'rc-tooltip'
import React, {
  ChangeEvent, Dispatch, SetStateAction, useState,
} from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { DeclarationDetailed, DeclarationStatusEnum } from '../../../types/IDeclaration'
import DeclarationService from '../services/declaration.service'

interface FeedbackModalProps {
  declarationId: number
  isFeedbackModalOpen: boolean
  setIsFeedbackModalOpen: Dispatch<SetStateAction<boolean>>
  declarationFeedback: string | null
  transit: DeclarationDetailed
}

function FeedbackModal({
  declarationId,
  isFeedbackModalOpen,
  setIsFeedbackModalOpen,
  declarationFeedback,
  transit,
}: FeedbackModalProps) {
  const { t } = useTranslation()
  const [feedback, setFeedback] = useState<string>(declarationFeedback !== null ? declarationFeedback : '')
  const [isDeclarationCanceled, setIsDeclarationCanceled] = useState<boolean>(transit.status === DeclarationStatusEnum.REJECTED)
  const [isPdfIncluded, setIsPdfIncluded] = useState<boolean>(false)
  const [emails, setEmails] = useState<Array<string>>([])
  const [blindCopyEmail, setBlindCopyEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const isCancelButtonVisible = () => {
    let visible = false
    if (transit && transit.status) {
      switch (transit.status) {
        case DeclarationStatusEnum.DRAFT:
        case DeclarationStatusEnum.SUBMITTED:
        case DeclarationStatusEnum.DELETED:
        case DeclarationStatusEnum.GOODS_RELEASED:
        case DeclarationStatusEnum.OPEN:
        case DeclarationStatusEnum.CANCELLED:
        case DeclarationStatusEnum.CANCEL_REQUESTED:
        case DeclarationStatusEnum.TRANSIT_PROCEDURE_COMPLETED:
        case DeclarationStatusEnum.PROCEDURE_ENDED:
          break
        default:
          if (transit.mrn != null && transit.mrn.length > 0) {
            visible = true
          }
          break
      }
    }

    return visible
  }

  const submitFeedback = () => {
    if (emails.length === 0 || emails[0] === '') {
      toast.error(t('messages.emailsEmpty'))
      return
    }

    if (!declarationId && feedback === '') {
      return
    }

    for (const email of emails) {
      if (email.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') === null) {
        return
      }
    }

    setLoading(true)
    DeclarationService.giveFeedback(
      declarationId,
      feedback,
      isDeclarationCanceled,
      isPdfIncluded,
      emails,
      blindCopyEmail,
    ).then(() => {
      setTimeout(() => {
        setIsFeedbackModalOpen(false)
        toast.success(t('messages.savingSuccess'))
        setLoading(false)
      }, 300)
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      setTimeout(() => {
        toast.error(t('messages.savingFailed'))
        setLoading(false)
      }, 300)
    })
  }

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  const emailInputRows = () => {
    const emailRows = []
    if (emails.length === 0) {
      emails.push('')
    }

    // eslint-disable-next-line no-plusplus
    for (let index: number = 0; index < emails.length; index++) {
      emailRows.push(
        <input
          key={`email_${index}`}
          placeholder={t('common.email')}
          type="email"
          name="email"
          className={emails.length > 1 && index !== 0 ? 'form-control px-1 mt-2' : 'form-control px-1'}
          style={{
            border: emails[index] !== ''
            && emails[index].match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') === null
              ? '1px solid red' : '1px solid #d5d5d5',
          }}
          pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
          autoComplete="email"
          value={emails[index]}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const emailAtIndex: Array<string> = [...emails]
            emailAtIndex[index] = event.target.value
            setEmails(emailAtIndex)
          }}
        />,
      )
    }
    return emailRows
  }

  return (
    <Modal
      show={isFeedbackModalOpen}
      size="xl"
      className="overflow-hidden"
      dialogClassName="feedback-modal modal"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        if (!loading) {
          setIsFeedbackModalOpen(!isFeedbackModalOpen)
        }
      }}
      animation={false}
      backdropClassName="backdrop-with-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title className="justify-content-between">
          <h2>{t('feedback.title', { context: `${isDeclarationCanceled ? 'canceling' : ''}` })}</h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => {
              if (!loading) {
                setIsFeedbackModalOpen(!isFeedbackModalOpen)
              }
            }}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-self-center w-100">
          <textarea
            value={feedback}
            className="w-100 form-control-lg mb-2"
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setFeedback(e.target.value)}
          />
        </div>
        {(transit.status !== DeclarationStatusEnum.REJECTED
          && transit.status !== DeclarationStatusEnum.CANCELLED
          && transit.status !== DeclarationStatusEnum.CANCEL_REQUESTED) && (
          <div className="d-flex align-items-center text-black-50">
            <small>{t('feedback.isDeclarationCanceled')}</small>
            <Tooltip
              overlayClassName="tooltip-lg border"
              overlay={
                (
                  <small>
                    <span>{t('tooltips.declarationCancelExplanation')}</span>
                    <ul className="m-1">
                      <li>{t('tooltips.declarationCancelExplanation', { context: 'row1' })}</li>
                      <li>{t('tooltips.declarationCancelExplanation', { context: 'row2' })}</li>
                      <li className="text-danger">{t('tooltips.declarationCancelExplanation', { context: 'row3' })}</li>
                    </ul>
                  </small>
                )
              }
            >
              <button className="btn btn-link btn-gray-700 p-0" type="button">
                <i className="fal fa-info-circle ms-1" />
              </button>
            </Tooltip>
            <div className="form-check form-switch ms-3">
              <input
                disabled={!isCancelButtonVisible()}
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={isDeclarationCanceled}
                onChange={() => setIsDeclarationCanceled((prevState) => !prevState)}
              />
            </div>
          </div>
        )}
        <div className="d-flex align-items-center text-black-50 mt-3">
          <small>{t('feedback.includePdf')}</small>
          <Tooltip
            overlayClassName="tooltip-lg border"
            overlay={
              (
                <small>
                  <span>{t('tooltips.pdfExplanation')}</span>
                </small>
              )
            }
          >
            <button className="btn btn-link btn-gray-700 p-0" type="button">
              <i className="fal fa-info-circle ms-1" />
            </button>
          </Tooltip>
          <div className="form-check form-switch ms-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              checked={isPdfIncluded}
              onChange={() => setIsPdfIncluded((prevState) => !prevState)}
            />
          </div>
        </div>

        <div className="align-items-center text-black-50 mt-3">
          <small>{t('feedback.listOfEmails')}</small>
          <Tooltip
            overlayClassName="tooltip-lg border"
            overlay={(
              <small>
                <span>{t('feedback.listOfEmailsExplanation')}</span>
              </small>
            )}
          >
            <button className="btn btn-link btn-gray-700 p-0" type="button">
              <i className="fal fa-info-circle ms-1" />
            </button>
          </Tooltip>

          <div className="w-50 d-flex mt-2">
            <div className="d-flex flex-column">
              { emailInputRows() }
            </div>
            <button
              type="button"
              className="btn btn-light btn-round ms-1 mb-1"
              onClick={() => {
                setEmails([...emails,
                  ''])
              }}
            >
              <i className="far fa-plus" />
            </button>
            {
              emails.length > 1
                && (
                  <button
                    type="button"
                    className="btn btn-light btn-round ms-1 mb-1"
                    onClick={() => {
                      emails.shift()
                      setEmails([...emails])
                    }}
                  >
                    <i className="far fa-minus" />
                  </button>
                )
            }
          </div>
        </div>

        <div className="align-items-center text-black-50 mt-3">
          <small>{t('feedback.blindCopyEmail')}</small>
          <Tooltip
            overlayClassName="tooltip-lg border"
            overlay={(
              <small>
                <span>{t('feedback.blindCopyEmailExplanation')}</span>
              </small>
            )}
          >
            <button className="btn btn-link btn-gray-700 p-0" type="button">
              <i className="fal fa-info-circle ms-1" />
            </button>
          </Tooltip>

          <div className="w-50 d-flex mt-2">
            <div className="d-flex flex-column">
              <input
                placeholder={t('common.email')}
                className="form-control px-1"
                type="email"
                name="email"
                autoComplete="email"
                style={{
                  border: blindCopyEmail !== ''
                  && blindCopyEmail.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') === null
                    ? '1px solid red' : '1px solid #d5d5d5',
                }}
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                value={blindCopyEmail}
                onChange={(e) => {
                  setBlindCopyEmail(e.target.value)
                }}
              />
            </div>
          </div>
        </div>

        <div className="d-flex align-content-end justify-content-end mt-5">
          <button
            type="button"
            disabled={!declarationId && feedback === ''}
            className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
            onClick={submitFeedback}
          >
            {
              loading && loadingSpinner()
            }
            {
              !loading && (<span>{t('feedback.submit')}</span>)
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FeedbackModal
