import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useTranslation } from 'react-i18next'
import GoodsTable from '../goods/GoodsTable'

interface HouseConsignmentGoodsSidebarProps {
  isVisible: boolean
  houseConsignmentIndex: number
  toggleVisibility: () => void
}

function HouseConsignmentGoodsSidebar({
  isVisible,
  houseConsignmentIndex,
  toggleVisibility,
}: HouseConsignmentGoodsSidebarProps) {
  const { t } = useTranslation()

  return (
    <Offcanvas
      className="extra-wide goods-sidebar"
      show={isVisible}
      onHide={() => {
        toggleVisibility()
      }}
      placement="end"
    >
      <Offcanvas.Header
        className="border-bottom"
        closeButton
      >
        <div className="d-flex align-items-end ">
          <h1 className="text-dark me-3">
            {t('declaration.p5.goodsSidebar.title', { houseConsignmentSequenceNumber: houseConsignmentIndex + 1 })}
          </h1>
        </div>
      </Offcanvas.Header>

      <Offcanvas.Body key="data-body">
        <div className="container-fluid py-3 goods-sidebar__body">
          <GoodsTable houseConsignmentIndex={houseConsignmentIndex} />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default HouseConsignmentGoodsSidebar
