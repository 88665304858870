import FormRow from 'components/FormRow'
import React, {
  ChangeEvent,
  Dispatch, useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import CustomsOfficesNoOptionsMessage from 'components/react-select/CustomsOfficesNoOptionsMessage'
import { getSelectCountryFilter } from '../../../components/react-select/CountryFunctions'
import { AccessLevel, DeclarationContext } from '../../../context/DeclarationContext'
import { filterCustomsOfficeOptions, getCountryCodeFromCustomsOffice, sortBySortOrder } from '../../../helpers'
import { CUSTOMS_OFFICE_PREFIX } from '../../../hooks/useDeclarationMetaData'
import { addTransport } from '../../../redux/actions'
import { IStoreState } from '../../../redux/store'
import ClassifierService from '../../../services/classifier.service'
import { IClassifier, ISelectOption, OfficeRole } from '../../../types/IClassifier'
import { ITransport, ITransportContainer } from '../../../types/IDeclaration'
import { InvalidField } from '../../Transits/TransitsAside/detailView'
import { getInvalidIconData } from '../../Transits/TransitsAside/invalid-icon.helper'
import TransitField from '../../Transits/TransitsAside/transitFields'
import { nonEUContractingParties } from '../hooks/useDeclaration'
import TransportCustomsOffice from '../TransportCustomsOffice'
import Seal from '../Seal'
import { UserContext } from '../../../context/UserContext'
import mapOfficesToSelectOptions from '../../../helpers/classifier.helper'
import { IGoodsItem } from '../../../types/IGoodsItem'
import { removePrefix } from '../../phase5/common/utils/classifier-util'
import { NctsCountry, tardekConfig } from '../../../config'

interface ITransportColumnProps {
  countriesOptions: ISelectOption[]
  countryClassifiers: Array<IClassifier>
  invalidFields: Array<InvalidField>
  truckRestrictions: Array<string>
  goodsItems: Array<IGoodsItem>
}

function TransportColumn(props: ITransportColumnProps) {
  const {
    countriesOptions,
    invalidFields,
    countryClassifiers,
    truckRestrictions,
    goodsItems,
  } = props

  const store = useStore()
  const { t, i18n } = useTranslation()

  const getTransport = () => (store.getState() as IStoreState).transportReducer.transport
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const [transportModeOptions, setTransportModeOptions]: [Array<ISelectOption>, any] = useState([])
  const [departureCustomsOfficesOptions, setDepartureCustomsOfficesOptions]: [Array<ISelectOption>, any] = useState([])
  const [customsOfficesOptions, setCustomsOfficesOptions] = useState<Array<ISelectOption>>([])
  const [transportCustomsOfficesOptions, setTransportCustomsOfficesOptions] = useState<Array<ISelectOption>>([])
  const [transportCustomsOfficesOptionsCache, setTransportCustomsOfficesOptionsCache] = useState<Array<ISelectOption>>([])
  const [transportForm, setTransportForm]: [ITransport, Dispatch<ITransport>] = useState(getTransport())
  const [shouldCleanInvalidTransitCustomsOffices, setShouldCleanInvalidTransitCustomsOffices] = useState(false)
  const [isDepartureCustomsOfficeDisabled, setIsDepartureCustomsOfficeDisabled] = useState(false)

  const {
    metaData: {
      country,
      setDepartureCustomsOffice,
      accessLevel,
    },
    clearPreviousDocumentProcedures,
  } = useContext(DeclarationContext)

  const { user } = useContext(UserContext)

  const getAllowedCountries = (): NctsCountry[] => (process.env.REACT_APP_ENV === 'live' ? tardekConfig.phase4CountriesLive : tardekConfig.phase4CountriesTest)
  const isValidDepartureCountry = (currentDepartureCustomsOfficeCode: string | null, code: string | null) => {
    if (code == null || code.trim().length === 0) {
      return false
    }
    const countryCode = code.trim().substring(0, 2)
    const currentCountryCode = currentDepartureCustomsOfficeCode
      ? removePrefix(currentDepartureCustomsOfficeCode, CUSTOMS_OFFICE_PREFIX) : null
    if (currentCountryCode && currentCountryCode === code) return true

    return getAllowedCountries().includes(countryCode as NctsCountry)
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setTransportForm(getTransport())
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const departureCustomsOfficeCode = removePrefix(transportForm.departureCustomsOffice, CUSTOMS_OFFICE_PREFIX)
    let disableDepartureCustomsOffice = false
    if (departureCustomsOfficeCode) {
      const countryCode = departureCustomsOfficeCode.substring(0, 2)
      disableDepartureCustomsOffice = !getAllowedCountries().includes(countryCode as NctsCountry)
    }
    setIsDepartureCustomsOfficeDisabled(disableDepartureCustomsOffice)

    if (transportForm.destinationCustomsOffice == null) {
      setTransportCustomsOfficesOptions([...transportCustomsOfficesOptionsCache])
      setShouldCleanInvalidTransitCustomsOffices(true)
      return
    }

    // R906
    if (getCountryCodeFromCustomsOffice(transportForm.destinationCustomsOffice) === 'AD') {
      setTransportCustomsOfficesOptions(transportCustomsOfficesOptionsCache.filter((option) => option.value.startsWith('CUSTOMS_OFFICE_AD')))
      setShouldCleanInvalidTransitCustomsOffices(true)
      return
    }

    // R907
    if (getCountryCodeFromCustomsOffice(transportForm.destinationCustomsOffice) === 'SM') {
      setTransportCustomsOfficesOptions(
        transportCustomsOfficesOptionsCache.filter((option) => {
          // eslint-disable-next-line max-len
          const countryOfOffice = countryClassifiers.find((classifier) => classifier.codeLabel === getCountryCodeFromCustomsOffice(option.value))
          return countryOfOffice?.attributes.find((attr) => attr.key === 'REGIONAL_BLOC')?.value === 'EU'
        }),
      )
      setShouldCleanInvalidTransitCustomsOffices(true)
      return
    }

    // R908
    // eslint-disable-next-line max-len
    if (transportForm.departureCustomsOffice !== null
        && nonEUContractingParties.indexOf(getCountryCodeFromCustomsOffice(transportForm.departureCustomsOffice)) !== -1
    && transportForm.transportCustomsOffices.length !== 0) {
      transportForm.transportCustomsOffices.forEach((customsOffice) => {
        if (customsOffice.transitCustomsOffice.startsWith('CUSTOMS_OFFICE_AD')
            || customsOffice.transitCustomsOffice.startsWith('CUSTOMS_OFFICE_SM')) {
          setShouldCleanInvalidTransitCustomsOffices(true)
        }
        // eslint-disable-next-line max-len
        setTransportCustomsOfficesOptions(transportCustomsOfficesOptionsCache.filter((option) => !option.value.startsWith('CUSTOMS_OFFICE_AD')
            && !option.value.startsWith('CUSTOMS_OFFICE_SM')))
      })
    }

    if (transportCustomsOfficesOptions.length < transportCustomsOfficesOptionsCache.length) {
      setTransportCustomsOfficesOptions([...transportCustomsOfficesOptionsCache])
    }
  }, [transportCustomsOfficesOptionsCache.length, transportForm.departureCustomsOffice, transportForm.destinationCustomsOffice])

  useEffect(() => {
    ClassifierService.getClassifier('TRANSPORT_MODES')
      .then((response: IClassifier[]) => (
        [...response].map((item) => ({
          value: item.code,
          label: `${i18n.language === 'ru' ? item.nameRu : item.nameEn} (${item.codeLabel})`,
        }
        ))
      ))
      .then((options: ISelectOption[]) => setTransportModeOptions(options))

    ClassifierService.getCustomsOfficesByRoles([OfficeRole.OFFICE_ROLE_DES]).then((destinationOffices) => {
      setCustomsOfficesOptions(mapOfficesToSelectOptions(destinationOffices, i18n.language))
    })

    ClassifierService.getCustomsOfficesByRoles([OfficeRole.OFFICE_ROLE_TRA]).then((transitOffices) => {
      setTransportCustomsOfficesOptions(mapOfficesToSelectOptions(transitOffices, i18n.language))
      setTransportCustomsOfficesOptionsCache(mapOfficesToSelectOptions(transitOffices, i18n.language))
    })

    ClassifierService.getCustomsOfficesByRoles([OfficeRole.OFFICE_ROLE_DEP]).then((classifiers: IClassifier[]) => {
      // eslint-disable-next-line max-len
      setDepartureCustomsOfficesOptions(mapOfficesToSelectOptions(classifiers.filter((item) => isValidDepartureCountry(transportForm.departureCustomsOffice, item.codeLabel)), i18n.language))
    })
  }, [transportForm.id, transportForm.departureCustomsOffice, i18n.language])

  useEffect(() => {
    store.dispatch(addTransport(transportForm))
    if (transportForm.departureCustomsOffice) {
      /* if (country === DeclarationCountry.ESTONIA
        && DeclarationCountry.ESTONIA !== extractDeclarationCountryFromCustomsOffice(transportForm.departureCustomsOffice)) {
        clearPreviousDocumentProcedures()
      } */
      setDepartureCustomsOffice(transportForm.departureCustomsOffice)
    }
  }, [transportForm])

  const loadOptions = (inputValue: string, loadingCallback: (func: any) => void) => {
    loadingCallback(filterCustomsOfficeOptions(inputValue, customsOfficesOptions))
  }

  const containerNumberInputRows = () => {
    if (!transportForm.isIndicatorContainerised) {
      return null
    }

    if (transportForm.transportContainers.length === 0) {
      store.dispatch(addTransport({
        ...transportForm,
        transportContainers: [{
          id: null,
          containerNumber: null,
          transportId: null,
          sortOrder: 0,
        } as ITransportContainer],
      }))
    }

    const rows = []

    for (const transportContainer of transportForm.transportContainers) {
      rows.push(
        <input
          disabled={accessLevel === AccessLevel.VIEW}
          key={transportContainer.sortOrder}
          className={`form-control mb-1 ${getInvalidIconData([TransitField.GOODS_ITEM_CONTAINERS_CONTAINER_NUMBER,
            TransitField.GOODS_ITEM_CONTAINERS], invalidFields).isInvalid ? 'is-invalid' : ''}`}
          type="text"
          maxLength={17}
          placeholder={t('common.fieldPlaceholder')}
          value={transportContainer.containerNumber !== null
            ? transportContainer.containerNumber : ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const container = { ...transportContainer }
            container.containerNumber = event.target.value

            const newContainers = [container,
              ...transportForm.transportContainers.filter((row) => row.sortOrder !== transportContainer.sortOrder)]
            newContainers.sort(sortBySortOrder)

            store.dispatch(addTransport({
              ...transportForm,
              transportContainers: newContainers,
            }))
          }}
        />,
      )
    }
    return rows
  }

  return (
    <div className="pt-4 px-4 col-12 col-xl-4 border-bottom">
      <div className="heading">
        <h3>
          {t('declaration.headers.transportAndLoading')}
        </h3>
      </div>
      <FormRow title={t('declaration.departureTruckNo')}>
        <input
          disabled={accessLevel === AccessLevel.VIEW}
          className={`form-control ${
            getInvalidIconData([TransitField.HEADER_IDENTITY_OF_MEANS_OF_TRANSPORT_AT_DEPARTURE_EXP_TRANS], invalidFields).isInvalid
            || transportForm.departureTruckNo === null
            || transportForm.departureTruckNo === ''
            || truckRestrictions.filter((restrictions) => transportForm.departureTruckNo?.toLowerCase()
              .includes(restrictions.toLowerCase())).length > 0
              ? 'is-invalid' : ''
          }`}
          type="text"
          maxLength={27}
          placeholder={t('common.fieldPlaceholder')}
          value={transportForm.departureTruckNo != null ? transportForm.departureTruckNo : ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            store.dispatch(addTransport({
              ...transportForm,
              departureTruckNo: event.target.value,
            }))
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.departureNationality')}>
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          options={countriesOptions}
          filterOption={getSelectCountryFilter}
          className={`select ${
            getInvalidIconData([TransitField.HEADER_NATIONALITY_OF_MEANS_OF_TRANSPORT_AT_DEPARTURE], invalidFields).isInvalid
            || transportForm.departureNationality === null
            || transportForm.departureNationality === '' ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          menuPlacement="auto"
          placeholder={t('common.chooseCountry')}
          value={transportForm.departureNationality != null
            ? countriesOptions.find((option) => option.value === transportForm.departureNationality) : null}
          onChange={(option: ISelectOption | null) => {
            store.dispatch(addTransport({
              ...transportForm,
              departureNationality: option?.value,
            }))
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.borderCrosserTruckNo')}>
        <input
          disabled={accessLevel === AccessLevel.VIEW}
          className={`form-control ${
            getInvalidIconData([TransitField.HEADER_IDENTITY_OF_MEANS_OF_TRANSPORT_CROSSING_BORDER], invalidFields).isInvalid
            || transportForm.borderCrosserTruckNo === null
            || transportForm.borderCrosserTruckNo === ''
            || truckRestrictions.filter((restrictions) => transportForm.borderCrosserTruckNo?.toLowerCase()
              .includes(restrictions.toLowerCase())).length > 0
              ? 'is-invalid' : ''
          }`}
          type="text"
          maxLength={27}
          placeholder={t('common.fieldPlaceholder')}
          value={transportForm.borderCrosserTruckNo != null ? transportForm.borderCrosserTruckNo : ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            store.dispatch(addTransport({
              ...transportForm,
              borderCrosserTruckNo: event.target.value,
            }))
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.borderCrosserNationality')}>
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          options={countriesOptions}
          filterOption={getSelectCountryFilter}
          className={`select ${
            getInvalidIconData([TransitField.HEADER_NATIONALITY_OF_MEANS_OF_TRANSPORT_CROSSING_BORDER], invalidFields).isInvalid || transportForm.borderCrosserNationality === null || transportForm.borderCrosserNationality === '' ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          menuPlacement="auto"
          placeholder={t('common.chooseCountry')}
          value={transportForm.borderCrosserNationality != null
            ? countriesOptions.find((option) => option.value === transportForm.borderCrosserNationality) : null}
          onChange={(option: ISelectOption | null) => {
            store.dispatch(addTransport({
              ...transportForm,
              borderCrosserNationality: option?.value,
            }))
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.indicatorContainerised')}>
        <div className="form-row-special-input">
          <div className="form-check d-flex align-items-center me-3">
            <input
              disabled={accessLevel === AccessLevel.VIEW}
              className={`form-check-input ${
                getInvalidIconData([TransitField.HEADER_CONTAINERISED_INDICATOR], invalidFields).isInvalid ? 'is-invalid' : ''
              }`}
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="false"
              checked={!transportForm.isIndicatorContainerised}
              onChange={() => {
                store.dispatch(addTransport({
                  ...transportForm,
                  isIndicatorContainerised: false,
                } as ITransport))
              }}
            />
            <label className="form-check-label text-nowrap ps-1" htmlFor="flexRadioDefault1">
              {`${t('common.no')} (0)`}
            </label>
          </div>
          <div className="form-check d-flex align-items-center">
            <input
              disabled={accessLevel === AccessLevel.VIEW}
              className={`form-check-input ${
                getInvalidIconData([TransitField.HEADER_CONTAINERISED_INDICATOR], invalidFields).isInvalid ? 'is-invalid' : ''
              }`}
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value="true"
              checked={transportForm.isIndicatorContainerised}
              onChange={() => {
                store.dispatch(addTransport({
                  ...transportForm,
                  isIndicatorContainerised: true,
                } as ITransport))
              }}
            />
            <label className="form-check-label text-nowrap ps-1" htmlFor="flexRadioDefault2">
              {`${t('common.yes')} (1)`}
            </label>
          </div>
        </div>
      </FormRow>
      {transportForm.isIndicatorContainerised ? (
        <FormRow title={t('declaration.containerNumber')}>
          <div className="d-flex">
            <div className="w-100">
              {containerNumberInputRows()}
            </div>
            <div className="d-flex align-item-center">
              {
                transportForm.transportContainers.length > 1
                && (
                  <button
                    type="button"
                    className="btn btn-light d-flex justify-content-center align-items-center ms-1 wh-24 rounded-circle p-1 my-1"
                    onClick={() => store.dispatch(addTransport({
                      ...transportForm,
                      transportContainers: [...transportForm.transportContainers
                        .filter((row) => row.sortOrder !== transportForm.transportContainers.length - 1)],
                    }))}
                  >
                    <i className="far fa-minus" />
                  </button>
                )
              }
              <button
                type="button"
                className="btn btn-light d-flex justify-content-center align-items-center ms-1 wh-24 rounded-circle p-1 my-1"
                onClick={() => {
                  store.dispatch(addTransport({
                    ...transportForm,
                    transportContainers: [...transportForm.transportContainers,
                      {
                        id: null,
                        containerNumber: null,
                        transportId: null,
                        sortOrder: transportForm.transportContainers.length,
                        goodsItemSortOrder: null,
                      } as ITransportContainer,
                    ],
                  }))
                }}
              >
                <i className="far fa-plus" />
              </button>
            </div>
          </div>
        </FormRow>
      ) : ''}
      <FormRow
        title={t('declaration.borderTransportMode')}
        hasInfoButton={country === 'FI'}
        tooltipContent={(
          <>
            {t('declaration.finnish.doubleTransportMode')}
          </>
        )}
      >
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          options={transportModeOptions}
          className={`select ${
            getInvalidIconData([TransitField.HEADER_TRANSPORT_MODE_AT_BORDER], invalidFields).isInvalid || (transportForm.borderTransportMode === null) ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          menuPlacement="auto"
          placeholder={t('common.chooseMode')}
          value={transportForm.borderTransportMode != null
            ? transportModeOptions.find((option) => option.value === transportForm.borderTransportMode) : null}
          onChange={(option: ISelectOption | null) => {
            store.dispatch(addTransport({
              ...transportForm,
              borderTransportMode: option?.value,
            }))
          }}
        />
      </FormRow>
      <FormRow
        title={t('declaration.inlandTransportMode')}
        hasInfoButton={country === 'FI'}
        tooltipContent={(
          <>
            {t('declaration.finnish.doubleTransportMode')}
          </>
        )}
      >
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          options={transportModeOptions}
          className={`select ${
            getInvalidIconData([TransitField.HEADER_INLAND_TRANSPORT_MODE], invalidFields).isInvalid || (transportForm.inlandTransportMode === null) ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          menuPlacement="auto"
          placeholder={t('common.chooseMode')}
          value={transportForm.inlandTransportMode != null
            ? transportModeOptions.find((option) => option.value === transportForm.inlandTransportMode) : null}
          onChange={(option: ISelectOption | null) => {
            store.dispatch(addTransport({
              ...transportForm,
              inlandTransportMode: option?.value,
            }))
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.loadingPlace')}>
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          options={countriesOptions}
          className={`select ${
            getInvalidIconData([TransitField.HEADER_PLACE_OF_LOADING_CODE], invalidFields).isInvalid || (transportForm.loadingPlace === null) ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          menuPlacement="auto"
          filterOption={getSelectCountryFilter}
          placeholder={t('common.fieldPlaceholder')}
          onChange={(option: ISelectOption | null) => {
            store.dispatch(addTransport({
              ...transportForm,
              loadingPlace: option?.value.split('_')[1].toString(),
            }))
          }}
          value={transportForm.loadingPlace != null
            ? countriesOptions.find((option) => option.value === `COUNTRY_${transportForm.loadingPlace}`) : null}
        />
      </FormRow>
      <FormRow title={t('declaration.unloadingPlace')} isLast>
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          options={countriesOptions}
          className={`select ${
            getInvalidIconData([TransitField.HEADER_PLACE_OF_UNLOADING_CODE], invalidFields).isInvalid || (transportForm.unloadingPlace === null) ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          menuPlacement="auto"
          filterOption={getSelectCountryFilter}
          placeholder={t('common.fieldPlaceholder')}
          value={transportForm.unloadingPlace != null
            ? countriesOptions.find((option) => option.value === `COUNTRY_${transportForm.unloadingPlace}`) : null}
          onChange={(option: ISelectOption | null) => {
            store.dispatch(addTransport({
              ...transportForm,
              unloadingPlace: option?.value.split('_')[1].toString(),
            }))
          }}
        />
      </FormRow>

      <FormRow title={t('declaration.departureCustomsOffice')}>
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW || isDepartureCustomsOfficeDisabled}
          options={departureCustomsOfficesOptions}
          className={`select ${
            getInvalidIconData([TransitField.DEPARTURE_CUSTOMS_OFFICE_REFERENCE_NUMBER], invalidFields).isInvalid || (transportForm.departureCustomsOffice === null) ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          menuPlacement="auto"
          filterOption={(option: ISelectOption, query: string) => {
            if (option.label.split(':')[0]) {
              return option.label.split(':')[0].toLowerCase().includes(query.toLowerCase())
            }
            return false
          }}
          placeholder={t('common.customsPlaceholder')}
          value={transportForm.departureCustomsOffice != null
            ? departureCustomsOfficesOptions.find((option) => option.value === transportForm.departureCustomsOffice) : null}
          onChange={(option: ISelectOption | null) => {
            if (process.env.REACT_APP_ENV !== 'live') {
              if (option?.value && option?.value === 'CUSTOMS_OFFICE_LTVR4000') {
                store.dispatch(addTransport({
                  ...transportForm,
                  destinationCustomsOffice: 'CUSTOMS_OFFICE_LTVR4000',
                  departureCustomsOffice: option.value,
                }))
              } else {
                store.dispatch(addTransport({
                  ...transportForm,
                  departureCustomsOffice: option?.value,
                }))
              }
            } else {
              store.dispatch(addTransport({
                ...transportForm,
                departureCustomsOffice: option?.value,
              }))
            }
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.destinationCustomsOffice')}>
        <AsyncSelect
          isDisabled={accessLevel === AccessLevel.VIEW}
          components={{
            NoOptionsMessage: (messageProps) => CustomsOfficesNoOptionsMessage(messageProps),
          }}
          className={`select ${
            getInvalidIconData([TransitField.DESTINATION_CUSTOMS_OFFICE_REFERENCE_NUMBER], invalidFields).isInvalid || (transportForm.destinationCustomsOffice === null) ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          menuPlacement="auto"
          defaultOptions={[...customsOfficesOptions.slice(0, 200)]}
          cacheOptions
          loadOptions={loadOptions}
          placeholder={t('common.customsPlaceholder')}
          value={transportForm.destinationCustomsOffice != null
            ? customsOfficesOptions.find((option) => option.value === transportForm.destinationCustomsOffice) : null}
          onChange={(option: ISelectOption | null) => {
            if (process.env.REACT_APP_ENV !== 'live') {
              let office = option?.value
              if (transportForm.departureCustomsOffice && transportForm.departureCustomsOffice === 'CUSTOMS_OFFICE_LTVR4000') {
                office = 'CUSTOMS_OFFICE_LTVR4000'
              }
              store.dispatch(addTransport({
                ...transportForm,
                destinationCustomsOffice: office,
              }))
            } else {
              store.dispatch(addTransport({
                ...transportForm,
                destinationCustomsOffice: option?.value,
              }))
            }
          }}
        />
      </FormRow>

      <TransportCustomsOffice
        customsOfficesOptions={transportCustomsOfficesOptions}
        shouldCleanInvalidTransitCustomsOffices={shouldCleanInvalidTransitCustomsOffices}
        setShouldCleanInvalidTransitCustomsOffices={setShouldCleanInvalidTransitCustomsOffices}
      />
      {user?.role === 'ADMIN' && <Seal transportId={transportForm.id} />}
    </div>
  )
}

export default TransportColumn
