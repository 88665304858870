import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { ZodIssue } from 'zod'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import DeclarationFormSchema, { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import { TransitOperationContext } from '../../hooks/useTransitOperationContext'

function Warnings() {
  const { t } = useTranslation()
  const { getValues } = useFormContext<DeclarationForm>()
  const [fieldErrors, setFieldErrors] = useState<ZodIssue[]>([])
  const { fieldRules } = useContext(TransitOperationContext)

  useEffect(() => {
    const errors: ZodIssue[] = fieldRules.flatMap((field) => field.issues.flatMap((issue: ZodIssue) => ({
      ...issue,
      message: `Invalid field: '${issue.path.join('.')}'. ${issue.message}`,
    })))

    const result = DeclarationFormSchema.safeParse(getValues())

    if (!result.success) {
      errors.push(...result.error.errors.map((issue) => {
        if (issue.code === 'invalid_string' && issue.validation === 'regex') {
          return {
            ...issue,
            message: `Field pattern invalid for '${issue.path.join('.')}'.`,
          }
        }
        return {
          ...issue,
          message: `Invalid field: '${issue.path.join('.')}'. ${issue.message}`,
        }
      }))
    }
    setFieldErrors(errors)
  }, [fieldRules])

  return (
    <section className="py-3">
      <CollapsibleColumn
        columnId="declaration-warnings"
        headingLabel={t('declaration.p5.warnings')}
        key={fieldErrors.length}
      >
        {
          fieldErrors.length !== 0 && fieldErrors.map((item) => (
            <div className="row" key={item.message}>
              <div className="col-12">
                <div className="alert alert-warning d-noxne">
                  <strong className="me-2" />
                  {item.message}
                </div>
              </div>
            </div>
          ))
        }
        {
          fieldErrors.length === 0 && (
            <div className="row">
              <div className="col-12">
                <div className="no-warnings d-block text-center">{t('declaration.p5.noWarnings')}</div>
              </div>
            </div>
          )
        }
      </CollapsibleColumn>
    </section>
  )
}

export default Warnings
