import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { RuleResult } from '../../../../../../types/DeclarationP5'
import { CL147_SECURITY_AGREEMENT_AREA_COUNTRIES, isTransitionalPeriodActive } from '../../../../common/utils/ncts-constants'
import { removePrefix, TRANSIT_OFFICE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { excludeDeleted } from '../../../../common/utils/common-util'
import B1966Validator from '../transitional/B1966'

const C0587Validator = {
  validateTransitExitCustomsOfficeRequirement: (
    formData: DeclarationForm,
  ): RuleResult => {
    if (isTransitionalPeriodActive()) {
      return B1966Validator.validateTransitExitCustomsOfficesRequirement(formData)
    }

    const {
      security,
      transitCustomsOffices,
    } = formData

    if (!['2', '3'].includes(security)) {
      return 'NOT_ALLOWED'
    }

    const isAnyTransitOfficeNotInCountryOfSecurityAgreement = transitCustomsOffices.filter(excludeDeleted)
      .some((transitCustomsOffice) => !CL147_SECURITY_AGREEMENT_AREA_COUNTRIES
        .includes(removePrefix(transitCustomsOffice.referenceNumber, TRANSIT_OFFICE_CODE_PREFIX, 2) ?? ''))

    return isAnyTransitOfficeNotInCountryOfSecurityAgreement ? 'OPTIONAL' : 'NOT_ALLOWED'
  },
} as const

export default C0587Validator
