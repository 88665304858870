import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { RuleResult } from '../../../../../../types/DeclarationP5'
import { excludeDeleted } from '../../../../common/utils/common-util'
import { CL147_SECURITY_AGREEMENT_AREA_COUNTRIES } from '../../../../common/utils/ncts-constants'
import { removePrefix } from '../../../../common/utils/classifier-util'

const B1966Validator = {
  validateTransitExitCustomsOfficesRequirement: (formData: DeclarationForm): RuleResult => {
    const {
      security,
      transitCustomsOffices,
    } = formData

    if (!['2', '3'].includes(security)) {
      return 'NOT_ALLOWED'
    }

    const isAnyTransitOfficeNotInCountryOfSecurityAgreement = transitCustomsOffices.filter(excludeDeleted)
      .some((transitCustomsOffice) => !CL147_SECURITY_AGREEMENT_AREA_COUNTRIES
        .includes(removePrefix(transitCustomsOffice.referenceNumber, 'TRANSIT_OFFICE_', 2) ?? ''))

    return isAnyTransitOfficeNotInCountryOfSecurityAgreement ? 'OPTIONAL' : 'NOT_ALLOWED'
  },
}

export default B1966Validator
