import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import HouseConsignmentsTableRow from './HouseConsignmentsTableRow'

import { blankHouseConsignmentType } from '../../form'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { ArrayScope } from '../../../../../types/DeclarationP5'
import { excludeDeleted } from '../../../common/utils/common-util'
import E1406Validator from '../../form/validators/E1406'
import GoodsImportModal from '../goods/GoodsImportModal'
import useHouseConsignmentItemImport from '../../hooks/useConsignmentItemImport'

function HouseConsignmentsTable() {
  const { t } = useTranslation()

  const scope: ArrayScope = 'houseConsignment'
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<HouseConsignmentType>({
    blankItem: blankHouseConsignmentType,
    name: scope,
  })
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const hiddenAnchor = useRef<HTMLAnchorElement>(null)

  const addImportedRows = (houses: HouseConsignmentType[]) => {
    addAction(...houses)
  }

  const {
    customColumnMap,
    setCustomColumnMap,
    toggleMappingModalVisibility,
    customHeaderMappingModalVisibility,
    parseImportFile,
    startImport,
  } = useHouseConsignmentItemImport({ addImportedRows })

  const handleClick = () => {
    hiddenFileInput.current!.click()
  }
  const downloadExample = () => {
    hiddenAnchor.current!.click()
  }

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const { files } = event.target
    parseImportFile(files).then(() => toggleMappingModalVisibility())
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ''
    }
  }

  const addRow = () => {
    addAction({
      ...blankHouseConsignmentType,
      sequenceNumber: getFields().length,
    })
  }

  const houseConsignmentsMultiplicity = E1406Validator.getHouseConsignmentMultiplicity()
  const isAddButtonDisabled = arrayRule === 'NOT_ALLOWED'
    || getFields().filter(excludeDeleted).length >= houseConsignmentsMultiplicity

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-responsive table-striped table-bordered table-declaration">
              <thead>
                <tr>
                  <th>{t('declaration.p5.sequenceNumber')}</th>
                  <th>{t('declaration.p5.consigneeName')}</th>
                  <th>{t('declaration.p5.consigneeCountry')}</th>
                  <th>{t('declaration.p5.consignorName')}</th>
                  <th>{t('declaration.p5.consignorCountry')}</th>
                  <th className="col-1">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {
                  getFields().map((rowData, index) => !rowData.deleted && (
                    <HouseConsignmentsTableRow
                      index={index}
                      tableRowRemove={(id) => removeAction(id, rowData)}
                      key={rowData.id}
                    />
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-end pb-3">
          <Button
            className="btn btn-sm btn-primary"
            onClick={() => addRow()}
            disabled={isAddButtonDisabled}
          >
            <i className="fa fa-plus me-2" />
            {t('declaration.p5.addRow')}
          </Button>
        </div>
      </div>
      <div className="row pt-1">
        <div className="col-12 text-end">
          <Button
            variant="primary"
            size="sm"
            onClick={handleClick}
          >
            <input
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={(event) => uploadFile(event)}
              type="file"
            />
            <i className="fal fa-md fa-file-csv me-1" />
            <span>
              {t('excel.importGoods')}
            </span>
          </Button>
        </div>
      </div>
      <div className="row pt-1">
        <div className="col-12 text-end">
          <Button
            variant="primary"
            size="sm"
            onClick={downloadExample}
          >
            <a
              className="d-none"
              ref={hiddenAnchor}
              target="_blank"
              href={`${process.env.PUBLIC_URL}/consignmentItems.csv`}
              download="consignmentItems.csv"
              rel="noreferrer"
            >
              {t('consignmentItemImport.example')}
            </a>
            <i className="fal fa-file-csv me-1" />
            <span>
              {t('consignmentItemImport.example')}
            </span>
          </Button>
        </div>
      </div>
      <GoodsImportModal
        customColumnMap={customColumnMap}
        setCustomColumnMap={setCustomColumnMap}
        isVisible={customHeaderMappingModalVisibility}
        toggleVisibility={toggleMappingModalVisibility}
        triggerImport={startImport}
      />
    </>
  )
}

export default HouseConsignmentsTable
