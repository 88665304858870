import { DeclarationForm } from '../schemas/declarationFormSchema'
import {
  excludeDeleted, hasText, nonNullConsignee, nonNullConsignorIgnoringContactPerson,
} from '../../../common/utils/common-util'
import { HouseConsignmentType } from '../schemas/houseConsignmentSchema'

const CustomValidators = {
  hasAnyHouseConsignmentConsigneeNonNull: (formData: DeclarationForm) => formData.houseConsignment.filter(excludeDeleted)
    .some((house) => house.consignee !== null && nonNullConsignee(house.consignee)),

  hasAnyHouseConsignmentConsignorNonNull: (formData: DeclarationForm) => formData.houseConsignment.filter(excludeDeleted)
    .some((house) => house.consignor != null && nonNullConsignorIgnoringContactPerson(house.consignor)),

  hasAnyConsignmentItemConsigneeNonNull: (formData: DeclarationForm) => formData.houseConsignment
    .filter(excludeDeleted)
    .flatMap((house) => house.consignmentItem)
    .filter(excludeDeleted)
    .some((consignmentItem) => consignmentItem.consignee !== null && nonNullConsignee(consignmentItem.consignee)),

  hasAnyHouseConsignmentReferenceNumberUCRNonNull: (formData: DeclarationForm) => formData.houseConsignment
    .filter(excludeDeleted)
    .some((house) => hasText(house.referenceNumberUCR)),
  hasAnyHouseConsignmentCountryOfDispatchNonNull: (formData: DeclarationForm) => formData.houseConsignment
    .filter(excludeDeleted)
    .some((house) => hasText(house.countryOfDispatch)),
  hasAnyHouseConsignmentCountryOfDestinationNonNull: (formData: DeclarationForm) => formData.houseConsignment
    .filter(excludeDeleted)
    .some((house) => hasText(house.countryOfDestination)),

  hasAnyHouseConsignmentItemReferenceNumberUCRNonNull: (houseConsignment: HouseConsignmentType) => houseConsignment.consignmentItem
    .filter(excludeDeleted)
    .some((item) => hasText(item.referenceNumberUCR)),
  hasAnyHouseConsignmentItemCountryOfDispatchNonNull: (houseConsignment: HouseConsignmentType) => houseConsignment.consignmentItem
    .filter(excludeDeleted)
    .some((item) => hasText(item.countryOfDispatch)),
  hasAnyHouseConsignmentItemCountryOfDestinationNonNull: (houseConsignment: HouseConsignmentType) => houseConsignment.consignmentItem
    .filter(excludeDeleted)
    .some((item) => hasText(item.countryOfDestination)),
}

export default CustomValidators
