import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { nonNullConsignorIgnoringContactPerson } from '../../../../common/utils/common-util'

const C0542Validator = {
  validateConsignmentConsignorRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.security === '0' && formData.reducedDatasetIndicator === 'true') {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
  validateHouseConsignmentConsignorRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.security === '0' && formData.reducedDatasetIndicator === 'true') {
      return 'NOT_ALLOWED'
    }

    if (nonNullConsignorIgnoringContactPerson(formData.consignor)) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0542Validator
