import React, {
  useContext, useEffect, useState,
} from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'
import TableRowStatus from 'routes/Transits/Table/TableRowStatus'
import { useLocation, useNavigate } from 'react-router-dom'
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form/dist/types/form'
import { toast } from 'react-toastify'
import { DeclarationStatusEnum } from 'types/IDeclaration'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from 'components/ConfirmationModal'
import PrintService from 'services/print.service'
import { BadRequestPayload } from 'types/Errors'
import { mapReasonToTranslationString } from 'helpers/errors.helper'
import i18n from 'i18n'
import { useObservableState } from 'observable-hooks'
import { debounceTime } from 'rxjs/operators'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { UseDeclarationFormReturn } from './form'
import NavigationBarTabs, { Tab } from './navigation/NavigationBarTabs'
import NavigationBarInfo from './navigation/NavigationBarInfo'
import NavigationBarActions, { Action } from './navigation/NavigationBarActions'
import DeclarationTabs from './tabs'
import { DeclarationForm } from './form/schemas/declarationFormSchema'
import { useFormDraft } from './hooks/useFormDraft'
import useFormAction from './hooks/useFormAction'
import useTransitOperationDuplication from '../TransitOperationTable/hooks/useTransitOperationDuplication'
import CreateNewTransitModal from './components/CreateNewTransitModal'
import FinlandContactFormModal from './components/FinlandContactForm'
import { excludeDeleted, hasText } from '../common/utils/common-util'
import ROUTES from '../../../config/routes'
import FinlandCustomsRequestReplyModal from './components/FinlandCustomsRequestReplyModal'
import { isAxiosError } from '../common/utils/api-util'
import RequestReleaseModal from './components/RequestReleaseModal'
import useRestrictionKeys from '../../Declaration/hooks/useRestrictionKeys'
import SanctionModal from './components/SanctionModal'
import useGuaranteeCalculationApi from './hooks/useGuaranteeCalculator/api'
import invokeValidators from './form/validators'
import updateFormErrors from './form/errors/updateFormErrors'
import LoadingBackdrop from '../../../components/LoadingBackdrop'
import { EachTransitClearedKeys } from './hooks/apiConfig'
import { TransitOperationContext } from './hooks/useTransitOperationContext'
import { FieldGrossMassError, validateGrossMasses, validateNumberOfItemsForDeclaration } from '../common/validators'

interface TransitOperationFormProps {
  form: UseDeclarationFormReturn
}

const saveBlob = (blob: Blob, fileName: string | null) => {
  const link = document.createElement('a')
  link.style.display = 'none'
  document.body.appendChild(link)
  link.href = URL.createObjectURL(blob)
  link.download = fileName ?? 'file'
  link.click()
  document.body.removeChild(link)
}

const MAXIMUM_NUMBER_OF_CONSIGNMENT_ITEMS = 1999

function TransitOperationForm({ form }: TransitOperationFormProps) {
  const {
    control,
    handleSubmit,
    getValues,
  } = form

  const [sanctionedCodes, setSanctionedCodes] = useState<Array<string>>([])

  const [grossMassError, setGrossMassError] = useState<FieldGrossMassError | null>(null)
  const [isGrossMassErrorModalVisible, setIsGrossMassErrorModalVisible] = useState(false)
  const [isSanctionModalVisible, setIsSanctionModalVisible] = useState(false)
  const [confirmationForAction, setConfirmationForAction] = useState<Action | null>(null)
  const [currentTab, setCurrentTab] = useState<Tab>('general')

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setNctsErrors } = useContext(TransitOperationContext)

  const {
    sanctionedKeys,
    loadSanctions,
  } = useRestrictionKeys()
  const { fetchTaxesCalculation } = useGuaranteeCalculationApi()

  const iterateOverSanctionedCodes = () => {
    const keys: Array<string> = []

    form.getValues().houseConsignment.forEach((houseConsignmentValue) => {
      houseConsignmentValue.consignmentItem.forEach((item) => {
        sanctionedKeys.forEach((key) => {
          if (hasText(item.commodityCombinedNomenclatureCode)) {
            const hsCode = item.commodityHarmonizedSystemSubHeadingCode + item.commodityCombinedNomenclatureCode
            if (hsCode?.startsWith(key) && !keys.includes(hsCode)) {
              keys.push(hsCode)
            }
          } else if (item.commodityHarmonizedSystemSubHeadingCode === key
            && !keys.includes(item.commodityHarmonizedSystemSubHeadingCode)) {
            keys.push(item.commodityHarmonizedSystemSubHeadingCode)
          }
        })
      })
    })

    return keys
  }

  const validateSanctionedCodes = () => {
    const keys = iterateOverSanctionedCodes()
    setSanctionedCodes(keys)

    return keys.length === 0
  }

  const validateGrossMass = (values: DeclarationForm) => {
    const error = validateGrossMasses(values)
    setGrossMassError(error)
    if (error) {
      console.error('Gross mass error', error)
    }

    return error === null
  }

  const { saveTransit } = useFormDraft(form)
  const {
    submitTransitOperation,
    cancelTransitOperation,
    changeTransitOperation,
    releaseTransitOperation,
    generateAndDownloadForm,
  } = useFormAction(form)

  useEffect(() => {
    loadSanctions()
  }, [form.getValues('id')])

  const onTabChange = (selectedTab: Tab) => {
    setCurrentTab(selectedTab)
  }
  const loadAndSavePdf = () => {
    const transitOperationId = getValues('id')
    if (transitOperationId == null) {
      toast.error('Unable to load pdf')
      return
    }

    PrintService.getTransitionPDF(transitOperationId).then((response: AxiosResponse) => {
      const objectURL = URL.createObjectURL(response.data)
      const [, filename] = response.headers['content-disposition'].split('filename=')
      const a: HTMLAnchorElement = document.createElement('a')
      a.href = objectURL
      a.download = filename
      a.click()
    })
  }

  const {
    duplicateDeclaration,
    setDuplicationModalVisible,
    isDuplicationModalVisible,
    isDuplicationLoading,
  } = useTransitOperationDuplication()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isHearingModalOpen, setIsHearingModalOpen] = useState(false)
  const [isRequestReleaseModalOpen, setIsRequestReleaseModalOpen] = useState(false)

  const toggleVisibility = () => {
    setIsModalOpen((prevState) => !prevState)
  }

  const toggleFinlandCustomsRequestResponseVisibility = () => {
    setIsHearingModalOpen((prevState) => !prevState)
  }

  async function updateAndValidateForm() {
    form.clearErrors()
    const results = invokeValidators(form.getValues())
    await form.trigger()
    updateFormErrors(results, form)
  }

  const hasErrors = (f: UseDeclarationFormReturn) => Object.keys(f.formState.errors).length > 0

  function validateFormValues(values: DeclarationForm): boolean {
    if (!validateNumberOfItemsForDeclaration(values, MAXIMUM_NUMBER_OF_CONSIGNMENT_ITEMS)) {
      toast.error(t('declaration.p5.errors.maximumNumberOfItemsExceeded', {
        max: MAXIMUM_NUMBER_OF_CONSIGNMENT_ITEMS,
      }))
      return false
    }

    return true
  }

  const onConfirmation = (): Promise<void> => (confirmationForAction === 'submit' ? submitTransitOperation() : changeTransitOperation())

  const onSanctionCodesModalConfirmation = async () => {
    await onConfirmation()
  }

  const onGrossMassErrorConfirmation = async () => {
    setIsGrossMassErrorModalVisible(false)
    if (sanctionedCodes.length) {
      setIsSanctionModalVisible(true)
      return
    }

    await onConfirmation()
  }

  const checkConfirmationRequirement = (action: Action, values: DeclarationForm) => {
    setConfirmationForAction(null)
    const isSanctionCodesValid = validateSanctionedCodes()
    const isGrossMassValid = validateGrossMass(values)
    const isConfirmationRequired = !isSanctionCodesValid || !isGrossMassValid

    if (!isGrossMassValid) {
      setIsGrossMassErrorModalVisible(true)
    } else if (!isSanctionCodesValid) {
      setIsSanctionModalVisible(true)
    }

    if (isConfirmationRequired) {
      setConfirmationForAction(action)
    }

    return isConfirmationRequired
  }

  async function handleActions(event: React.BaseSyntheticEvent | undefined) {
    if (event === undefined || !(event?.nativeEvent instanceof SubmitEvent)) {
      return
    }

    if (event.nativeEvent.submitter?.id === 'saveDraft') {
      try {
        setNctsErrors([])
        await saveTransit(true)
        toast.success(t('messages.savingSuccess'))
        navigate(`${ROUTES.transitP5}/${getValues('id')}`)
      } catch (error) {
        if (isAxiosError(error)) {
          const data = error.response?.data
          if (error.response?.request.status === 400 && (data?.message === 'CONSTRAINT_VIOLATION' || data?.message === 'INVALID_ARGUMENT')) {
            data?.errors.forEach((errorItem: BadRequestPayload) => {
              const translatedString = mapReasonToTranslationString(errorItem)
              if (translatedString !== '' && translatedString !== null && translatedString !== undefined) {
                toast.error(`${t(`translations${i18n.language.toUpperCase()}:${errorItem.field}`)} ${translatedString}`)
              }
            })
          }
        }

        toast.error(t('messages.savingFailed'))
      }
    }
    if (event.nativeEvent.submitter?.id === 'submit') {
      // eslint-disable-next-line no-console
      try {
        await updateAndValidateForm()
        const values = getValues()
        if (!validateFormValues(values)) {
          return
        }

        if (!hasErrors(form)) {
          await saveTransit(false)
          if (checkConfirmationRequirement('submit', values)) {
            return
          }

          await submitTransitOperation()
          toast.success(t('messages.savingSuccess'))
        } else {
          // eslint-disable-next-line no-console
          toast.error(t('common.submit', { context: 'error' }))
          navigate(`${location.pathname}#summary`)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        toast.error('Error saving and submitting')
      }
    }
    if (event.nativeEvent.submitter?.id === 'cancel') {
      // eslint-disable-next-line no-console
      try {
        await saveTransit(false)
        await cancelTransitOperation()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        toast.error('Error saving and submitting')
      }
    }
    if (event.nativeEvent.submitter?.id === 'amend') {
      // eslint-disable-next-line no-console
      try {
        await updateAndValidateForm()
        const values = getValues()
        if (!validateFormValues(values)) {
          return
        }

        await saveTransit(false)
        if (checkConfirmationRequirement('amend', values)) {
          return
        }

        await changeTransitOperation()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        toast.error('Error saving and submitting')
      }
    }
    if (event.nativeEvent.submitter?.id === 'contact') {
      setIsModalOpen((prevState) => !prevState)
    }
    if (event.nativeEvent.submitter?.id === 'invalidationResponse') {
      setIsHearingModalOpen((prevState) => !prevState)
    }
    if (event.nativeEvent.submitter?.id === 'requestRelease') {
      setIsRequestReleaseModalOpen(true)
    }
    if (event.nativeEvent.submitter?.id === 'validate') {
      form.clearErrors()
      form.setValue('id', form.getValues('id')) // Trigger watch useEffect
      await form.trigger()
      await updateAndValidateForm()
      validateFormValues(form.getValues())
    }
    if (event.nativeEvent.submitter?.id === 'duplicate') {
      try {
        setDuplicationModalVisible(!isDuplicationModalVisible)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        toast.error('Error saving and duplicating')
      }
    }
    if (event.nativeEvent.submitter?.id === 'pdf') {
      try {
        await saveTransit(true)
        navigate(`${ROUTES.transitP5}/${getValues('id')}`)
        loadAndSavePdf()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        toast.error('Error saving draft')
      }
    }
    if (event.nativeEvent.submitter?.id === 'generateXml') {
      await updateAndValidateForm()
      if (!hasErrors(form)) {
        await saveTransit(false)
        generateAndDownloadForm()
      } else {
        toast.error(t('messages.formInvalid'))
        navigate(`${location.pathname}#summary`)
      }
    }
    if (event.nativeEvent.submitter?.id === 'taxesPdf' || event.nativeEvent.submitter?.id === 'taxesXlsx') {
      try {
        await saveTransit(true)
        const transitId = getValues('id')
        if (transitId !== null) {
          fetchTaxesCalculation.mutateAsync({
            id: transitId,
            data: event.nativeEvent.submitter?.id === 'taxesXlsx',
          })
            .then(async (response) => {
              const [, filename] = response.headers['content-disposition'].split('filename=')

              saveBlob(response.data, filename)
            })
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        toast.error('Error saving draft')
      }
    }
    if (event.nativeEvent.submitter?.id === 'createNew') {
      setIsConfirmationModalOpen(!isConfirmationModalOpen)
    }
  }

  const toggleDuplicationModalVisibility = () => {
    if (!isDuplicationLoading) {
      setDuplicationModalVisible(!isDuplicationModalVisible)
    }
  }

  const toggleGrossMassErrorModalVisibility = () => {
    setIsGrossMassErrorModalVisible(!isGrossMassErrorModalVisible)
  }

  const toggleCreateNewModalVisibility = () => {
    if (!isDuplicationLoading) {
      setIsConfirmationModalOpen(!isConfirmationModalOpen)
    }
  }

  const onSubmit: SubmitHandler<DeclarationForm> = async (declarationForm, event) => {
    await handleActions(event)
  }

  const onValidationErrors: SubmitErrorHandler<DeclarationForm> = async (fieldErrors, event) => {
    // eslint-disable-next-line no-console
    console.log('Invoked action on invalid form with errors:', fieldErrors)
    if (event === undefined || !(event?.nativeEvent instanceof SubmitEvent)) {
      return
    }

    if (event.nativeEvent.submitter?.id === 'saveDraft') {
      await handleActions(event)
    } else {
      toast.error(t('common.submit', { context: 'error' }))
      navigate(`${location.pathname}#summary`)
    }
  }

  const onAction = async (action: Action) => {
    // eslint-disable-next-line no-console
    console.log('onAction', action)
  }
  const getTotalWeightSum: () => number = () => getValues('houseConsignment')
    .filter(excludeDeleted)
    .flatMap((house) => house.consignmentItem)
    .filter(excludeDeleted)
    .map((item) => (!Number.isNaN(item.goodsMeasureGrossMass) ? item.goodsMeasureGrossMass : 0))
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const getTotalPackagesSum: () => number = () => getValues('houseConsignment')
    .filter(excludeDeleted)
    .flatMap((house) => house.consignmentItem)
    .filter(excludeDeleted)
    .flatMap((item) => item.packaging)
    .filter(excludeDeleted)
    .map((packaging) => (!Number.isNaN(packaging.numberOfPackages) ? Number(packaging.numberOfPackages) : 0))
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const {
    formState: {
      isLoading,
      isSubmitting,
      isValidating,
    },
  } = form

  const queryKeys: string[] = EachTransitClearedKeys.map((value) => value)

  const mutatingQueriesCount = useIsMutating()
  const fetchingQueriesCount = useIsFetching({
    predicate: (query) => queryKeys.includes(query.queryKey[0] as string),
  })

  const [debouncedInteractionLock, setDebouncedInteractionLock] = useObservableState<boolean>((
    event$,
  ) => event$.pipe(debounceTime(200)), true)
  const shouldLockInteractions = isLoading || isSubmitting || isValidating || fetchingQueriesCount > 0 || mutatingQueriesCount > 0
  setDebouncedInteractionLock(shouldLockInteractions)
  const interactionsLocked = shouldLockInteractions || debouncedInteractionLock

  return (
    <>
      <DevTool control={control} placement="top-left" />
      {interactionsLocked && (
        <div className="overlay--backdrop" id="loading">
          <LoadingBackdrop loading={interactionsLocked} isInTable={false} />
        </div>
      )}
      <form
        id="transit-operation-form"
        className="declaration"
        onSubmit={handleSubmit(onSubmit, onValidationErrors)}
        onKeyDown={(onKeyDownEvent) => {
          if (onKeyDownEvent.key === 'Enter') {
            onKeyDownEvent.preventDefault()
          }
        }}
        role="presentation"
      >
        <div className="declaration__header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 declaration__header--left">
                <NavigationBarInfo />
              </div>
              <div className="col-lg-6 declaration__header--center">
                <NavigationBarTabs onTabChange={onTabChange} currentTab={currentTab} />
              </div>
              <div className="col-lg-3 d-none d-lg-block declaration__header--right" />
            </div>
          </div>
        </div>
        <div className="declaration__body pb-5">
          <DeclarationTabs currentTab={currentTab} />
        </div>
        <div className="declaration__footer fixed-bottom border-top bg-light">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-end align-items-center">
                <div className="user-select-none">
                  <small className="me-1">{t('declaration.p5.totalNumberOfPackages')}</small>
                  <small className="me-2">{getTotalPackagesSum()}</small>
                  <small className="me-1">{t('declaration.p5.grossWeight', { context: 'current' })}</small>
                  <small>{getTotalWeightSum().toFixed(3)}</small>
                </div>
                <div className="m-3">
                  <TableRowStatus
                    value={getValues('status')?.toString() ?? DeclarationStatusEnum.DRAFT.toString()}
                  />
                </div>
                <NavigationBarActions onAction={onAction} form={form} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <SanctionModal
        isSanctionModalVisible={isSanctionModalVisible}
        setSanctionModalVisible={setIsSanctionModalVisible}
        sanctionedCodes={sanctionedCodes}
        onConfirmation={onSanctionCodesModalConfirmation}
      />
      { grossMassError !== null && (
        <ConfirmationModal
          title={t('messages.confirmation')}
          messageBody={t('declaration.p5.errors.grossMassDifference', {
            actual: grossMassError.actual.toFixed(3),
            expected: grossMassError.expected.toFixed(3),
          })}
          isVisible={isGrossMassErrorModalVisible}
          toggleVisibility={toggleGrossMassErrorModalVisibility}
          isLoading={false}
          onConfirmation={onGrossMassErrorConfirmation}
          onRejection={() => { setIsGrossMassErrorModalVisible(false) }}
        />
      )}
      <ConfirmationModal
        title={t('buttons.duplicate')}
        messageBody={t('messages.duplicateDocumentsMessage')}
        warning={t('declaration.p5.unsavedChangesLostWarning')}
        isVisible={isDuplicationModalVisible}
        toggleVisibility={toggleDuplicationModalVisibility}
        isLoading={isDuplicationLoading}
        onConfirmation={() => duplicateDeclaration(getValues(('id')), true)}
        onRejection={() => duplicateDeclaration(getValues(('id')), false)}
      />
      <CreateNewTransitModal
        form={form}
        isVisible={isConfirmationModalOpen}
        toggleVisibility={toggleCreateNewModalVisibility}
      />
      <FinlandContactFormModal
        isVisible={isModalOpen}
        toggleVisibility={toggleVisibility}
        transitOperationId={form.getValues().id}
      />
      <RequestReleaseModal
        isVisible={isRequestReleaseModalOpen}
        toggleVisibility={() => setIsRequestReleaseModalOpen((prev) => !prev)}
        transitOperationId={form.getValues().id}
        releaseTransitOperation={releaseTransitOperation}
      />

      <FinlandCustomsRequestReplyModal
        isVisible={isHearingModalOpen}
        toggleVisibility={toggleFinlandCustomsRequestResponseVisibility}
        transitOperationId={form.getValues().id}
      />
    </>
  )
}

export default TransitOperationForm
