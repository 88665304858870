import React from 'react'
import useDocumentApi from '../../../../TransitOperationEditor/hooks/useDocument/api'
import { DocumentRequestTypeEnum, DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarPreviousDocuments from './TransitSidebarPreviousDocuments'

export interface TransitSidebarConsignmentItemPreviousDocumentsProps {
  consignmentId: number | null
  previousDocuments: DocumentResponse[]
}

function TransitSidebarConsignmentPreviousDocuments({
  consignmentId,
  previousDocuments,
}: TransitSidebarConsignmentItemPreviousDocumentsProps) {
  const consignmentPreviousDocuments = previousDocuments
    .filter((transportDocument) => transportDocument.consignmentId === consignmentId)
    .sort(sortBySequenceNumber)

  if (consignmentPreviousDocuments.length === 0) {
    return null
  }

  return (
    <div className="p-2">
      <TransitSidebarPreviousDocuments previousDocuments={consignmentPreviousDocuments} />
    </div>

  )
}

export default TransitSidebarConsignmentPreviousDocuments
