import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { NestedFieldScope } from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import EoriSearch from '../../components/EoriSearch'
import CarrierSearch from '../../components/CarrierSearch'
import Input from '../../components/Input'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

const scope = 'carrier'
type ConsignmentCarrier = NestedFieldScope<typeof scope>

function Carrier() {
  const { t } = useTranslation()
  const { getValues } = useFormContext<DeclarationForm>()
  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-carrier"
        headingLabel={t('declaration.p5.carrier')}
      >
        <FormField labelKey="declaration.p5.carrierIdentificationNr">
          <EoriSearch scope={scope} />
        </FormField>
        <FormField scope={`${scope}.carrierName`} labelKey="declaration.p5.carrierName">
          <CarrierSearch />
        </FormField>
        {
          country === 'LT' && (
            <>
              <FormField labelKey="declaration.p5.contactPersonName">
                <Input
                  <ConsignmentCarrier>
                  field="carrier.contactPerson.name"
                  labelKey="declaration.p5.contactPersonName"
                  type="text"
                  placeholder={t('declaration.p5.contactPersonName')}
                />
              </FormField>
              <FormField labelKey="declaration.p5.contactPersonPhone">
                <Input
                  <ConsignmentCarrier>
                  field="carrier.contactPerson.telephone"
                  labelKey="declaration.p5.contactPersonPhone"
                  type="text"
                  placeholder={t('declaration.p5.contactPersonPhone')}
                />
              </FormField>
              <FormField labelKey="declaration.p5.contactPersonEmail">
                <Input
                  <ConsignmentCarrier>
                  field="carrier.contactPerson.email"
                  labelKey="declaration.p5.contactPersonEmail"
                  type="text"
                  placeholder={t('declaration.p5.contactPersonEmail')}
                />
              </FormField>
            </>
          )
        }
      </CollapsibleColumn>
    </section>
  )
}

export default Carrier
