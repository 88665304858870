import React from 'react'
import { DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarSupportingDocuments from './TransitSidebarSupportingDocuments'

export interface TransitSidebarConsignmentItemSupportingDocumentsProps {
  consignmentItemId: number | null
  supportingDocuments: DocumentResponse[]
}

function TransitSidebarConsignmentItemSupportingDocuments(props: TransitSidebarConsignmentItemSupportingDocumentsProps) {
  const {
    consignmentItemId,
    supportingDocuments,
  } = props

  const itemSupportingDocuments = supportingDocuments
    .filter((transportDocument) => transportDocument.consignmentItemId === consignmentItemId)
    .sort(sortBySequenceNumber)

  if (itemSupportingDocuments.length === 0) {
    return null
  }

  return (
    <div className="col-6 p-2">
      <TransitSidebarSupportingDocuments supportingDocuments={itemSupportingDocuments} />
    </div>
  )
}

export default TransitSidebarConsignmentItemSupportingDocuments
