import axios from 'axios'
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query'
import { ConsignmentItemResponse, CreateOrUpdateConsignmentItemRequest } from '../../../common/models'
import { MutateRequest } from '../request-type'
import TransitApiConfig from '../apiConfig'
import useFailedHouseConsignmentItemsSaveState, { RequestMethod } from './useFailedHouseConsignmentItemsSaveState'

const {
  baseUrl,
  queryKeys: { getConsignmentItemByHouseConsignmentId },
} = TransitApiConfig.paths.consignmentItem

function useConsignmentItemApi(houseConsignmentIds: Array<number | null>, isSubmitting: boolean) {
  const queryClient = useQueryClient()

  const {
    isFailedRequestId,
    removeFromFailedRequests,
    addToFailedRequests,
  } = useFailedHouseConsignmentItemsSaveState()

  function handleCreateOrUpdateConsignmentSuccess(
    requestMethod: RequestMethod,
    request: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>,
  ) {
    const ids = new Set<number>()
    request.data.forEach((item) => {
      ids.add(item.houseConsignmentId)
    })

    removeFromFailedRequests(requestMethod, ids)

    ids.forEach((houseConsignmentId) => queryClient
      .invalidateQueries({ queryKey: getConsignmentItemByHouseConsignmentId(houseConsignmentId) }))
  }

  function handleCreateOrUpdateConsignmentItemError(
    requestMethod: RequestMethod,
    request: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>,
  ) {
    const ids = new Set<number>()
    request.data.forEach((item) => {
      ids.add(item.houseConsignmentId)
    })

    addToFailedRequests(requestMethod, ids)
  }

  const doPostConsignmentItem = useMutation({
    mutationFn: async ({ data }: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>) => (
      await axios.post<ConsignmentItemResponse[]>(baseUrl, data)).data,
    onSuccess: (_, variables) => {
      handleCreateOrUpdateConsignmentSuccess('post', variables)
    },
    onError: (error, variables, context) => {
      handleCreateOrUpdateConsignmentItemError('post', variables)
    },
  })

  const doPostConsignmentItemDraft = useMutation({
    mutationFn: async ({ data }: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>) => (
      await axios.post<ConsignmentItemResponse[]>(`${baseUrl}/draft`, data)).data,
    onSuccess: (_, variables) => {
      handleCreateOrUpdateConsignmentSuccess('post', variables)
    },
    onError: (error, variables, context) => {
      handleCreateOrUpdateConsignmentItemError('post', variables)
    },
  })

  const doPutConsignmentItem = useMutation({
    mutationFn: async ({
      data,
    }: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>) => (await axios
      .put<ConsignmentItemResponse[]>(`${baseUrl}`, data)).data,
    onSuccess: (_, variables) => {
      handleCreateOrUpdateConsignmentSuccess('put', variables)
    },
    onError: (error, variables, context) => {
      handleCreateOrUpdateConsignmentItemError('put', variables)
    },
  })

  const doPutConsignmentItemDraft = useMutation({
    mutationFn: async ({
      data,
    }: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>) => (await axios
      .put<ConsignmentItemResponse[]>(`${baseUrl}/draft`, data)).data,
    onSuccess: (_, variables) => {
      handleCreateOrUpdateConsignmentSuccess('put', variables)
    },
    onError: (error, variables, context) => {
      handleCreateOrUpdateConsignmentItemError('put', variables)
    },
  })

  const postConsignmentItem = (isDraft: boolean) => {
    if (isDraft) {
      return doPostConsignmentItemDraft
    }
    return doPostConsignmentItem
  }

  const putConsignmentItem = (isDraft: boolean) => {
    if (isDraft) {
      return doPutConsignmentItemDraft
    }
    return doPutConsignmentItem
  }

  const deleteConsignmentItem = useMutation({
    mutationFn: async ({
      data: consignmentItemId,
    }: MutateRequest<number>) => (await axios.delete<void>(`${baseUrl}/${consignmentItemId}`)).data,
    onSuccess: (_, variables) => queryClient.invalidateQueries({ queryKey: getConsignmentItemByHouseConsignmentId(variables.id) }),
  })

  const isEnabled = (houseConsignmentId: number | null) : boolean => {
     return !!houseConsignmentId
      && !doPostConsignmentItem.isLoading
      && !doPostConsignmentItemDraft.isLoading
      && !doPutConsignmentItem.isLoading
      && !isFailedRequestId('put', houseConsignmentId)
      && !isFailedRequestId('post', houseConsignmentId)
      && !doPutConsignmentItemDraft.isLoading && !deleteConsignmentItem.isLoading && !isSubmitting
  }

  // useQueries does not accept an abort signal
  //  https://github.com/TanStack/query/issues/5342
  const fetchConsignmentItems = useQueries(
    {
      queries: houseConsignmentIds.map((houseConsignmentId) => ({
        queryKey: ['/v2/consignment-item', houseConsignmentId],
        queryFn: async () => (await axios.get<ConsignmentItemResponse[]>(`${baseUrl}/${houseConsignmentId}`)).data,
        staleTime: Infinity, // never stale
        enabled: isEnabled(houseConsignmentId),
      })),
    },
  )

  return {
    fetchConsignmentItems,
    postConsignmentItem,
    putConsignmentItem,
    deleteConsignmentItem,
  }
}

export default useConsignmentItemApi
