import {
  NotesDocument, PreviousDocument, SupportingDocument, TransportDocument,
} from './schemas/documentSchemas'
import {
  ActiveBorderTransportMeansType,
  AdditionalInformation,
  AdditionalReference,
  AdditionalSupplyChainActor,
  Authorisation,
  ContactPerson,
  CountryOfRoutingOfConsignment,
  CustomsOfficeOfExitForTransitDeclared,
  CustomsOfficeOfTransitDeclared,
  DepartureTransportMeansType,
} from './schemas/commonConsignmentSchemas'
import {
  AmountUnit, ConsignmentItem, DangerousGoodsType, PackagingType,
} from './schemas/consignmentItemSchema'
import { GoodsReferenceType, SealType, TransportEquipmentType } from './schemas/transportEquipmentSchema'
import { HouseConsignmentType } from './schemas/houseConsignmentSchema'
import { Carrier, Consignee, Consignor } from './schemas/tradersSchema'
import { LocationOfGoodsType } from './schemas/locationOfGoodsSchema'
import { PlaceOfLoading, PlaceOfUnloading } from './schemas/placesSchemas'
import { DirectoFormType } from './schemas/directoFormSchema'
import { DirectoEventResponseStatusEnum } from '../../common/models/directo-event-response'

export const blankTransportDocument: TransportDocument = {
  id: null,
  sequenceNumber: 0,
  documentType: '',
  files: [],
  referenceNumber: '',
}
export const blankCustomsOfficeOfTransitDeclared: CustomsOfficeOfTransitDeclared = {
  id: null,
  sequenceNumber: 0,
  arrivalDateAndTime: '',
  referenceNumber: '',
}
export const blankCustomsOfficeOfExitForTransitDeclared: CustomsOfficeOfExitForTransitDeclared = {
  id: null,
  sequenceNumber: 0,
  referenceNumber: '',
}
export const blankActiveBorderTransportMeans: ActiveBorderTransportMeansType = {
  id: null,
  sequenceNumber: 0,
  customsOfficeAtBorderReferenceNumber: '',
  typeOfIdentification: '',
  identificationNumber: '',
  nationality: '',
  conveyanceReferenceNumber: '',
}
export const blankAdditionalInformation: AdditionalInformation = {
  id: null,
  sequenceNumber: 0,
  code: '',
  text: '',
}
export const blankAdditionalReference: AdditionalReference = {
  id: null,
  sequenceNumber: 0,
  type: '',
  referenceNumber: '',
}
export const blankAdditionalSupplyChainActor: AdditionalSupplyChainActor = {
  id: null,
  sequenceNumber: 0,
  role: '',
  identificationNumber: '',
}
export const blankCarrier: Carrier = {
  deleted: false,
  id: null,
  carrierName: '',
  identificationNumber: '',
  target: 'CARRIER' as const,
  contactPerson: {
    id: null,
    deleted: false,
    name: '',
    email: '',
    telephone: '',
  },
}

export const blankContactPerson: ContactPerson = {
  id: null,
  deleted: false,
  name: '',
  email: '',
  telephone: '',
}

export const blankConsignor: Consignor = {
  deleted: false,
  id: null,
  identificationNumber: '',
  name: '',
  target: 'CONSIGNOR' as const,
  address: {
    city: '',
    country: '',
    id: null,
    postcode: '',
    streetAndNumber: '',
  },
  contactPerson: blankContactPerson,
}

export const blankConsignee: Consignee = {
  id: null,
  deleted: false,
  identificationNumber: '',
  name: '',
  target: 'CONSIGNEE' as const,
  address: {
    id: null,
    city: '',
    country: '',
    postcode: '',
    streetAndNumber: '',
  },
}
export const blankLocationOfGoods: LocationOfGoodsType = {
  id: null,
  deleted: false,
  typeOfLocation: '',
  qualifierOfIdentification: '',
  authorisationNumber: '',
  additionalIdentifier: '',
  unLocode: '',
  referenceNumber: '',
  economicOperatorIdentificationNumber: '',
  postcodeAddress: {
    postcode: '',
    houseNumber: '',
    country: '',
  },
  gnss: null,
  address: null,
  contactPerson: null,
}
export const blankPlaceOfLoading: PlaceOfLoading = {
  id: null,
  unLocode: '',
  country: '',
  location: '',
  type: 'LOADING' as const,
  deleted: false,
}
export const blankPlaceOfUnloading: PlaceOfUnloading = {
  id: null,
  unLocode: '',
  country: '',
  location: '',
  type: 'UNLOADING' as const,
  deleted: false,
}

export const blankPackaging: PackagingType = {
  id: null,
  sequenceNumber: 0,
  typeOfPackages: '',
  numberOfPackages: null,
  shippingMarks: '',
}

export const blankConsignmentItem: ConsignmentItem = {
  id: null,
  sequenceNumber: 0,
  declarationGoodsItemNumber: 0,
  countryOfDispatch: '',
  countryOfDestination: '',
  referenceNumberUCR: '',
  commodityDescriptionOfGoods: '',
  commodityHarmonizedSystemSubHeadingCode: '',
  commodityCombinedNomenclatureCode: '',
  goodsMeasureGrossMass: 0,
  goodsMeasureNetMass: 0,
  dangerousGoods: [],
  additionalSupplyChainActor: [],
  previousDocument: [],
  supportingDocument: [],
  transportDocument: [],
  additionalReference: [],
  additionalInformation: [],
  packaging: [blankPackaging],
  consignee: blankConsignee,
  deleted: false,
  price: null,
  dutyRate: null,
  dutyAmount: null,
  dutyRateUnit: AmountUnit.PERCENTAGE,
  vatRate: null,
  excise: null,
  initialPrice: null,
  initialPriceCurrency: 'EUR',
}
export const blankCountryOfRoutingOfConsignment: CountryOfRoutingOfConsignment = {
  id: null,
  sequenceNumber: 0,
  country: '',
}
export const blankDangerousGoods: DangerousGoodsType = {
  id: null,
  sequenceNumber: 0,
  unNumber: '',
}
export const blankGoodsReference: GoodsReferenceType = {
  id: null,
  sequenceNumber: 0,
  deleted: false,
  declarationGoodsItemNumber: 0,
}

export const blankHouseConsignmentType: HouseConsignmentType = {
  id: null,
  sequenceNumber: 0,
  grossMass: 0,
  referenceNumberUCR: '',
  securityIndicatorFromExportDeclaration: '',
  transportChargesMethodOfPayment: '',
  consignor: blankConsignor,
  consignee: blankConsignee,
  countryOfDestination: '',
  countryOfDispatch: '',
  additionalSupplyChainActor: [],
  departureTransportMeans: [],
  previousDocument: [],
  supportingDocument: [],
  transportDocument: [],
  additionalReference: [],
  additionalInformation: [],
  consignmentItem: [blankConsignmentItem],
}
export const blankPreviousDocument: PreviousDocument = {
  id: null,
  sequenceNumber: 0,
  documentType: '',
  referenceNumber: '',
  complementOfInformation: '',
  files: [],
  transportDocumentReferenceNumber: '',
  procedureType: '',
}
export const blankSealType: SealType = {
  id: null,
  sequenceNumber: 0,
  identifier: '',
  deleted: false,
}
export const blankSupportingDocument: SupportingDocument = {
  id: null,
  sequenceNumber: 0,
  documentType: '',
  referenceNumber: '',
  complementOfInformation: '',
  files: [],
  documentLineNumber: null,
}

export const blankNotesDocument: NotesDocument = {
  id: null,
  sequenceNumber: 0,
  documentType: 'NOTE',
  referenceNumber: '',
  complementOfInformation: '',
  transitOperationId: null,
  files: [],
}

export const blankTransportEquipment: TransportEquipmentType = {
  id: null,
  sequenceNumber: 0,
  containerIdentificationNumber: '',
  seals: [],
  deleted: false,
  goodsReferences: [],
}
export const blankItemDepartureTransportMeans: DepartureTransportMeansType = {
  id: null,
  sequenceNumber: 0,
  typeOfIdentification: '',
  identificationNumber: '',
  nationality: '',
}

export const blankDirectoEvent: DirectoFormType = {
  customerId: null,
  descriptionAddendum: '',
  status: DirectoEventResponseStatusEnum.IDLE,
}

export const blankAuthorisations: Authorisation = {
  referenceNumber: '',
  type: '',
  id: null,
  sequenceNumber: 0,
}
