import { HouseConsignmentType } from '../TransitOperationEditor/form/schemas/houseConsignmentSchema'
import { DeclarationForm } from '../TransitOperationEditor/form/schemas/declarationFormSchema'
import { excludeDeleted } from './utils/common-util'
import { roundToNctsScale } from './utils/ncts-constants'

export interface FieldGrossMassError {
  expected: number
  actual: number
  field: string
}

const getHouseConsignmentItemGrossMassesSum = (house: HouseConsignmentType): number => house.consignmentItem.filter(excludeDeleted).map((item) => item.goodsMeasureGrossMass)
  .reduce((previousValue, currentValue) => (!Number.isNaN(previousValue)
  && !Number.isNaN(currentValue) ? previousValue + currentValue : 0), 0)

const areGrossMassesEqual = (first: number, second: number) => !Number.isNaN(first) && !Number.isNaN(second)
    && roundToNctsScale(first) === roundToNctsScale(second)

export const validateGrossMasses = (form: DeclarationForm): FieldGrossMassError | null => {
  const {
    grossMass,
    houseConsignment,
  } = form

  let totalSum = 0
  for (let i = 0; i < houseConsignment.length; i++) {
    const house = houseConsignment[i]
    if (house.deleted) {
      continue
    }

    const houseGrossMass = !Number.isNaN(house.grossMass) ? Number(house.grossMass) : 0
    const sumOfHouseItemsGrossMasses = getHouseConsignmentItemGrossMassesSum(house)

    if (!areGrossMassesEqual(houseGrossMass, sumOfHouseItemsGrossMasses)) {
      return {
        expected: sumOfHouseItemsGrossMasses,
        actual: house.grossMass,
        field: `houseConsignment[${i + 1}].grossMass`,
      }
    }

    totalSum += houseGrossMass
  }

  if (!areGrossMassesEqual(grossMass, totalSum)) {
    return {
      expected: totalSum,
      actual: grossMass,
      field: 'consignment.grossMass',
    }
  }

  return null
}

export const validateNumberOfItemsForDeclaration = (values: DeclarationForm, maximumNumber: number) => {
  const totalNumberOfItems = values.houseConsignment.filter(excludeDeleted)
    .map((house) => house.consignmentItem.filter(excludeDeleted).length)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  return totalNumberOfItems <= maximumNumber
}
