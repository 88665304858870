import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { NctsCountry, tardekConfig } from 'config'
import { ISelectOption } from 'types/IClassifier'
import axios from 'axios'
import TransitApiConfig from 'routes/phase5/TransitOperationEditor/hooks/apiConfig'
import { CodelistGroup, getCountryForOffice, mapSelectOptions } from './helper'
import { CodelistResponse } from '../../models'
import { User } from '../../../../Customer/services/user.service'

const {
  baseUrl,
  queryKeys: { getCodelistGroup },
} = TransitApiConfig.paths.codelist

function getEnabledCountriesForEnvironment() {
  return process.env.REACT_APP_ENV === 'live' ? tardekConfig.phase5CountriesLive : tardekConfig.phase5CountriesTest
}
function isNotAdminOnlyCountry(country: NctsCountry) {
  return !tardekConfig.adminOnlyCountries.includes(country)
}
function isAdminOnlyCountryWhileAdminUser(country: NctsCountry, user?: User | undefined) {
  return tardekConfig.adminOnlyCountries.includes(country) && user?.role === 'ADMIN'
}

function useCodelist(group: CodelistGroup, filterKeyword?: string, user?: User | undefined): [CodelistResponse[], ISelectOption[]] {
  const { i18n } = useTranslation()

  const {
    data,
    isFetching,
  } = useQuery(
    {
      queryKey: getCodelistGroup(group),
      queryFn: async () => (await axios.get<CodelistResponse[]>(`${baseUrl}/group/${group ?? ''}`)).data,
      select: (responseData) => {
        switch (group) {
          case 'DEPARTURE_CUSTOMS_OFFICES':
            return responseData.filter((
              item,
            ) => {
              const officeCountry = getCountryForOffice(item) as NctsCountry
              return (
                getEnabledCountriesForEnvironment().includes(officeCountry)
                && (isNotAdminOnlyCountry(officeCountry) || isAdminOnlyCountryWhileAdminUser(officeCountry, user))
              )
            })
          case 'DESTINATION_CUSTOMS_OFFICES':
            return filterKeyword !== undefined ? responseData.filter((
              item,
            ) => getCountryForOffice(item) === filterKeyword?.substring('COUNTRY_'.length)) : responseData
          case 'TRANSPORT_IDENTIFICATIONS':
            return responseData.filter((
              item,
            ) => item.code?.startsWith(`TYPE_OF_IDENTIFICATION_${filterKeyword?.substring('TRANSPORT_MODE_'.length)}`))
          default:
            return responseData
        }
      },
    },
  )

  const codelistAsOptions = useMemo(() => mapSelectOptions(data, i18n.language), [group, isFetching, filterKeyword])

  return [
    data ?? [],
    codelistAsOptions,
  ]
}

export default useCodelist
