import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-cycle
import SeoService from '../../../../service/seo.service'

export interface SeoTag {
  key: 'SEO_TITLE' | 'SEO_DESCRIPTION' | 'SEO_KEYWORDS'
  value: string
}

function useSeoTags(
  setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>,
  loading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
) {
  const { t } = useTranslation()
  const [titleTag, setTitleTag] = useState('')
  const [descriptionTag, setDescriptionTag] = useState('')
  const [keywordTags, setKeywordTags] = useState('')

  const updateTagsFromList = (tags: SeoTag[]) => {
    for (const tag of tags) {
      switch (tag.key) {
        case 'SEO_TITLE': {
          setTitleTag(tag.value)
          break
        }
        case 'SEO_DESCRIPTION': {
          setDescriptionTag(tag.value)
          break
        }
        default:
          setKeywordTags(tag.value)
          break
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    SeoService.getSeoTags()
      .then((tags) => {
        updateTagsFromList(tags)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const updateSeoTags = () => {
    if (loading) {
      return
    }

    setLoading(true)
    const promises: Array<Promise<SeoTag>> = [
      SeoService.putSeoTag(titleTag, 'SEO_TITLE'),
      SeoService.putSeoTag(descriptionTag, 'SEO_DESCRIPTION'),
      SeoService.putSeoTag(keywordTags, 'SEO_KEYWORDS'),
    ]

    Promise.all(promises)
      .then((tags) => {
        updateTagsFromList(tags)
      })
      .catch(() => {
        toast.error(t('messages.savingFailed'))
      })
      .finally(() => {
        setLoading(false)
        setIsConfirmationModalOpen(false)
      })
  }

  return {
    titleTag,
    setTitleTag,
    descriptionTag,
    setDescriptionTag,
    keywordTags,
    setKeywordTags,
    updateSeoTags,
  }
}

export default useSeoTags
