import { getBaseApiUrl } from 'services/api.service'

const TransitApiConfig = {
  paths:
    {
      additionalInformation: {
        baseUrl: getBaseApiUrl('/v2/additional-information'),
        queryKeys: {
          rootPath: '/v2/additional-information' as const,
          getAdditionalInformationByParent:
            (
              parentId: number | null,
              parentType: 'consignment' | 'houseConsignment' | 'consignmentItem',
            ) => ['/v2/additional-information', parentType, parentId] as const,
        },
      },
      additionalReference: {
        baseUrl: getBaseApiUrl('/v2/additional-reference'),
        queryKeys: {
          rootPath: '/v2/additional-reference' as const,
          getAdditionalReferenceByParent:
            (
              parentId: number | null,
              parentType: 'consignment' | 'houseConsignment' | 'consignmentItem',
            ) => ['/v2/additional-reference', parentType, parentId] as const,
        },
      },
      authorisation: {
        baseUrl: getBaseApiUrl('/v2/authorisation'),
        queryKeys: {
          rootPath: '/v2/authorisation' as const,
          getAuthorisationByTransitOperationId: (transitOperationId: number | null) => ['/v2/authorisation', transitOperationId] as const,
        },
      },
      carrier: {
        baseUrl: getBaseApiUrl('/v2/consignment-detail/carrier'),
        queryKeys: {
          rootPath: '/v2/consignment-detail/carrier' as const,
          getCarrier: (transitOperationId: number | null) => ['/v2/consignment-detail/carrier', transitOperationId] as const,
        },
      },
      codelist: {
        baseUrl: getBaseApiUrl('/v2/codelist'),
        queryKeys: {
          rootPath: '/v2/codelist' as const,
          getCodelistGroup: (group: string) => ['/v2/codelist', group] as const,
          searchCodelistPageWithCodeLabel: (codeLabel: string, pageNumber: number, country: string | null) => ['/v2/codelist/search', pageNumber, codeLabel, country] as const,
        },
      },
      consignment: {
        baseUrl: getBaseApiUrl('/v2/consignment'),
        queryKeys: {
          rootPath: '/v2/consignment' as const,
          getConsignmentByTransitOperationId: (transitOperationId: number | null) => ['/v2/consignment', transitOperationId] as const,
        },
      },
      consignmentDetail: {
        baseUrl: getBaseApiUrl('/v2/consignment-detail'),
        queryKeys: {
          rootPath: '/v2/consignment-detail' as const,
          getConsignmentDetailByTransitOperationId: (transitOperationId: number | null) => ['/v2/consignment-detail', transitOperationId] as const,
        },
      },
      consignmentItem: {
        baseUrl: getBaseApiUrl('/v2/consignment-item'),
        queryKeys: {
          rootPath: '/v2/consignment-item' as const,
          getConsignmentItemByHouseConsignmentId: (houseConsignmentId: number | null) => ['/v2/consignment-item', houseConsignmentId] as const,
          countByHouseConsignmentId: (houseConsignmentId: number | null) => ['/v2/consignment-item/count', houseConsignmentId] as const,
        },
      },
      customsOfficeOfTransit: {
        baseUrl: getBaseApiUrl('/v2/customs-office/transit'),
        queryKeys: {
          rootPath: '/v2/customs-office/transit' as const,
        },
      },
      customsOfficeOfTransitExit: {
        baseUrl: getBaseApiUrl('/v2/customs-office/transit-exit'),
        queryKeys: {
          rootPath: '/v2/customs-office/transit-exit' as const,
        },
      },
      document: {
        baseUrl: getBaseApiUrl('/v2/document'),
        queryKeys: {
          rootPath: '/v2/document' as const,
          getDocumentByOperationIdAndType:
            (
              transitOperationId: number | null,
              documentType: 'PREVIOUS' | 'SUPPORTING' | 'TRANSPORT' | 'NOTE',
            ) => ['/v2/document', transitOperationId, documentType] as const,
        },
      },
      file: {
        baseUrl: getBaseApiUrl('/v2/document-file'),
        queryKeys: {
          rootPath: '/v2/document-file' as const,
          getFileByTransitOperationId: (transitOperationId: number | null) => ['/v2/document-file', transitOperationId] as const,
          getFileByFolderAndUUID: (
            folder: 'TRANSIT-DOCUMENT' | 'DECLARATION-PDF' | 'EXPORT' | 'NOTE',
            uuid: string,
          ) => ['/v2/document-file', folder, uuid] as const,
        },
      },
      directo: {
        baseUrl: getBaseApiUrl('/v2/directo-event'),
        queryKeys: {
          rootPath: '/v2/directo-event' as const,
          getDirectoEvent: (transitOperationId: number | null) => ['/v2/directo-event', transitOperationId] as const,
        },
      },
      houseConsignment: {
        baseUrl: getBaseApiUrl('/v2/house-consignment'),
        queryKeys: {
          rootPath: '/v2/house-consignment' as const,
          getHouseConsignmentByConsignmentId: (consignmentId: number | null) => ['/v2/house-consignment', consignmentId] as const,
        },
      },
      seal: {
        baseUrl: getBaseApiUrl('/v2/seal'),
        queryKeys: {
          rootPath: '/v2/seal' as const,
        },
      },
      transitOperation: {
        baseUrl: getBaseApiUrl('/v2/transit-operation'),
        queryKeys: {
          rootPath: '/v2/transit-operation' as const,
          getTransitOperationById: (transitOperationId: number | null) => ['/v2/transit-operation', transitOperationId] as const,
        },
      },
      transitOperationAudit: {
        baseUrl: getBaseApiUrl('/v2/transit-operation-audit'),
        queryKeys: {
          rootPath: '/v2/transit-operation-audit' as const,
        },
      },
      transitOperationLog: {
        baseUrl: getBaseApiUrl('/v2/transit-operation-log'),
        queryKeys: {
          rootPath: '/v2/transit-operation-log' as const,
          getTransitOperationLog: (transitOperationId: number | null) => ['/v2/transit-operation-log/transit-operation', transitOperationId] as const,
        },
      },
      transitOperationInfo: {
        baseUrl: getBaseApiUrl('/v2/transit-operation-info'),
        queryKeys: {
          rootPath: '/v2/transit-operation-info' as const,
          getTransitOperationInfoByTransitOperationId: (transitOperationId: number | null) => ['/v2/transit-operation-info', transitOperationId] as const,
        },
      },
      transportEquipment: {
        baseUrl: getBaseApiUrl('/v2/transport-equipment'),
        queryKeys: {
          rootPath: '/v2/transport-equipment' as const,
        },
      },
      nctsProfile: {
        baseUrl: getBaseApiUrl('/v1/ncts-profile'),
        queryKeys: {
          rootPath: '/v1/ncts-profile' as const,
          nctsProfilesByCustomerId: (customerId: number | null) => ['/v1/ncts-profile/declaration-profile', customerId] as const,
        },
      },
      userPreferences: {
        baseUrl: getBaseApiUrl('/v2/user-preference'),
        queryKeys: {
          rootPath: '/v2/user-preference' as const,
          getUserPreferences: () => ['/v2/user-preference'] as const,
        },
      },
      guarantee: {
        baseUrl: getBaseApiUrl('/v2/guarantee'),
        queryKeys: {
          rootPath: '/v2/guarantee' as const,
          getGuaranteeByTransitOperationId: (transitOperationId: number | null) => ['/v2/guarantee', transitOperationId] as const,
        },
      },
      guaranteeCalculation: {
        baseUrl: getBaseApiUrl('/v2/guarantee-calculation'),
        queryKeys: {
          rootPath: '/v2/guarantee-calculation' as const,
          getGuaranteeCalculationByTransitOperationId: (transitOperationId: number | null) => ['/v2/guarantee-calculation', transitOperationId] as const,
        },
      },
      certificatesInfo: {
        baseUrl: getBaseApiUrl('/v2/certificate-info'),
        queryKeys: {
          rootPath: () => ['/v2/certificate-info'] as const,
        },
      },
    },
}
const {
  additionalInformation: { queryKeys: { rootPath: additionalInformationBaseKey } },
  additionalReference: { queryKeys: { rootPath: additionalReferenceBaseKey } },
  authorisation: { queryKeys: { rootPath: authorisationBaseKey } },
  carrier: { queryKeys: { rootPath: carrierBaseKey } },
  consignment: { queryKeys: { rootPath: consignmentBaseKey } },
  consignmentDetail: { queryKeys: { rootPath: consignmentDetailBaseKey } },
  consignmentItem: { queryKeys: { rootPath: consignmentItemBaseKey } },
  customsOfficeOfTransit: { queryKeys: { rootPath: customsOfficeOfTransitBaseKey } },
  document: { queryKeys: { rootPath: documentBaseKey } },
  file: { queryKeys: { rootPath: fileBaseKey } },
  directo: { queryKeys: { rootPath: directoBaseKey } },
  houseConsignment: { queryKeys: { rootPath: houseConsignmentBaseKey } },
  seal: { queryKeys: { rootPath: sealBaseKey } },
  transitOperation: { queryKeys: { rootPath: transitOperationBaseKey } },
  transitOperationAudit: { queryKeys: { rootPath: transitOperationAuditBaseKey } },
  transitOperationLog: { queryKeys: { rootPath: transitOperationLogBaseKey } },
  transitOperationInfo: { queryKeys: { rootPath: transitOperationInfoBaseKey } },
  transportEquipment: { queryKeys: { rootPath: transportEquipmentBaseKey } },
  nctsProfile: { queryKeys: { rootPath: nctsProfileBaseKey } },
  guarantee: { queryKeys: { rootPath: guaranteeBaseKey } },
  guaranteeCalculation: { queryKeys: { rootPath: guaranteeCalculationBaseKey } },
} = TransitApiConfig.paths

export const EachTransitClearedKeys = [
  additionalInformationBaseKey,
  additionalReferenceBaseKey,
  authorisationBaseKey,
  carrierBaseKey,
  consignmentBaseKey,
  consignmentDetailBaseKey,
  consignmentItemBaseKey,
  customsOfficeOfTransitBaseKey,
  documentBaseKey,
  fileBaseKey,
  directoBaseKey,
  houseConsignmentBaseKey,
  sealBaseKey,
  transitOperationBaseKey,
  transitOperationAuditBaseKey,
  transitOperationLogBaseKey,
  transitOperationInfoBaseKey,
  transportEquipmentBaseKey,
  nctsProfileBaseKey,
  guaranteeBaseKey,
  guaranteeCalculationBaseKey,
]
export default TransitApiConfig
