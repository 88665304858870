import { RuleResult } from 'types/DeclarationP5'

import { hasText } from '../../../../common/utils/common-util'
import { CL505_WITHOUT_ZIP_COUNTRIES } from '../../../../common/utils/ncts-constants'
import { removePrefix } from '../../../../common/utils/classifier-util'

const C0505Validator = {
  validatePostcodeRequirement: (country: string | null | undefined): RuleResult => {
    if (country != null && hasText(country) && CL505_WITHOUT_ZIP_COUNTRIES.includes(removePrefix(country, 'COUNTRY_') ?? '')) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default C0505Validator
