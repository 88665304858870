import {
  useContext, useEffect, useMemo,
} from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { mapReasonToTranslationString } from 'helpers/errors.helper'
import { BadRequestPayload } from 'types/Errors'
import i18n from 'i18n'
import { blankConsignmentItem, UseDeclarationFormReturn } from '../../form'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import useConsignmentItemApi from './api'
import { parseCreateOrUpdateConsignmentItemResponse, toCreateOrUpdateConsignmentItemRequest } from './mapper'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { ConsignmentItemResponse, CreateOrUpdateConsignmentItemRequest } from '../../../common/models'
import { MutateRequest } from '../request-type'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'
import {
  adjustToHumanReadableField,
  excludeDeleted,
  hasText, isDeletedWithId,
  replaceListSquareBracketsWithDotDelimiter,
} from '../../../common/utils/common-util'
import { ConsignmentItem } from '../../form/schemas/consignmentItemSchema'
import { NctsError, TransitOperationContext } from '../useTransitOperationContext'

const consignmentItemScope = /houseConsignment\.\d+\.consignmentItem\.\d+/g
export const isConsignmentItemScope = (scope: string): scope is `houseConsignment.${number}.consignmentItem.${number}` => (
  Array.from(scope.matchAll(consignmentItemScope))?.length ?? 0) > 0

const ERROR_FIELD_REASONS = ['CommodityCode', 'ValidInputText']

function adjustFieldToScopePath(fieldPath: string) {
  let path = fieldPath
  if (path.endsWith('null')) {
    path = path.replace('null', 'commodityHarmonizedSystemSubHeadingCode')
  } else if (path.endsWith('descriptionOfGoods')) {
    path = path.replace('descriptionOfGoods', 'commodityDescriptionOfGoods')
  }
  return path
}

function prependConsignmentItemScope(houseConsignmentIndex: number, fieldPath: string) {
  return `houseConsignment.${houseConsignmentIndex}.consignmentItem${fieldPath}`
}

function useConsignmentItem(form: UseDeclarationFormReturn) {
  const {
    reset,
    trigger,
    setValue,
    getValues,
    formState: {
      isValid,
      isSubmitting,
    },
  } = form
  const { t } = useTranslation()
  const { nctsErrors, setNctsErrors } = useContext(TransitOperationContext)

  const {
    fetchConsignmentItems,
    postConsignmentItem,
    deleteConsignmentItem,
    putConsignmentItem,
  } = useConsignmentItemApi(getValues('houseConsignment')
    .filter((house) => house.id && !house.deleted)
    .map((house) => house.id), isSubmitting)

  const populateFormConsignmentItems = () => {
    if (fetchConsignmentItems.some((itemQuery) => (itemQuery.isLoading || itemQuery.isFetching)) || isSubmitting) {
      return
    }

    if (nctsErrors.length > 0 && fetchConsignmentItems.length === 0) {
      return
    }
    const formClone: DeclarationForm = structuredClone(getValues())

    if (formClone.houseConsignment.length !== fetchConsignmentItems.length) {
      return
    }

    const houseConsignmentItemsById: Map<number, ConsignmentItem[]> = new Map<number, ConsignmentItem[]>()

    formClone.houseConsignment.forEach((house, idx) => {
      const responses = fetchConsignmentItems[idx]?.data ?? []

      houseConsignmentItemsById.set(house.id!, responses
        .map((response, index) => parseCreateOrUpdateConsignmentItemResponse(response, index))
        .sort(sortBySequenceNumber))
    })

    const getHouseConsignmentItems = (formHouseConsignment: HouseConsignmentType) => {
      const itemResponses: ConsignmentItem[] = houseConsignmentItemsById.get(formHouseConsignment.id!) ?? []

      const items: ConsignmentItem[] = []

      itemResponses.forEach((consignmentItemResponse) => {
        const formConsignmentItem = formHouseConsignment.consignmentItem
          .find((item) => !item.deleted && ((item.id === consignmentItemResponse.id)
            || (item.sequenceNumber === consignmentItemResponse.sequenceNumber)))

        items.push({
          ...blankConsignmentItem,
          ...formConsignmentItem,
          ...consignmentItemResponse,
        })
      })

      return items
    }

    reset({
      ...formClone,
      houseConsignment: formClone.houseConsignment.map((formHouseConsignment) => ({
        ...formHouseConsignment, // This is needed so that transitional fields are not written as invalid undefined values
        consignmentItem: [...getHouseConsignmentItems(formHouseConsignment)],
      })),
    })
  }

  const consignmentItems = useMemo(
    () => fetchConsignmentItems.map((value) => value.data),
    [!fetchConsignmentItems.some((value) => value.isFetching)],
  )

  useEffect(() => {
    populateFormConsignmentItems()
  }, [consignmentItems])

  function refreshSavedIds(response: ConsignmentItemResponse[]) {
    const houseConsignments = getValues('houseConsignment')
    houseConsignments.forEach((houseConsignment, houseConsignmentIndex) => {
      if (houseConsignment.deleted) {
        return
      }

      houseConsignment.consignmentItem.forEach((consignmentItem, consignmentItemIndex) => {
        if (consignmentItem.deleted) {
          return
        }

        const savedItem = response.find((responseItem) => responseItem.goodsItemNumber === consignmentItem.sequenceNumber
            && responseItem.houseConsignmentId === houseConsignment.id)
        if (savedItem) {
          setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${consignmentItemIndex}.id`, savedItem.id)
        }
      })
    })
  }

  function getResponseErrors(
    houseConsignmentIndex: number,
    requests: CreateOrUpdateConsignmentItemRequest[],
    response: PromiseRejectedResult,
  ) {
    const responseErrorMessages: string[] = []
    const responseNctsErrors: NctsError[] = []

    const rejectedData = response.reason?.response?.data
    if (rejectedData?.message === 'CONSTRAINT_VIOLATION') {
      (rejectedData?.errors ?? []).forEach((errorItem: BadRequestPayload) => {
        const translatedString = mapReasonToTranslationString(errorItem)

        if (hasText(translatedString)) {
          let errorField = errorItem.field
          if (ERROR_FIELD_REASONS.includes(errorItem.reason)) {
            errorField = adjustFieldToScopePath(errorField)

            const indexMatches = errorField.match(/\[(.*)]/)
            const itemIndex: number = (indexMatches?.length ? Number(indexMatches[1]) : 0)
            const goodsSequenceNumber = requests[itemIndex].goodsItemNumber
            let path = errorField.replace(/\[(.+?)]/g, `[${goodsSequenceNumber}]`)
            path = replaceListSquareBracketsWithDotDelimiter(path)

            const fullPath = prependConsignmentItemScope(houseConsignmentIndex, path)
            if (isConsignmentItemScope(fullPath)) {
              responseNctsErrors.push(
                {
                  field: fullPath,
                  description: errorItem.message ?? 'Invalid field',
                },
              )
            }
          }

          responseErrorMessages.push(`Field ${t(`translations${i18n.language.toUpperCase()}:${
            adjustToHumanReadableField(errorField)
          }`)} ${translatedString}`)
        } else {
          let errorField = errorItem.field
          if (ERROR_FIELD_REASONS.includes(errorItem.reason)) {
            errorField = adjustFieldToScopePath(errorField)

            responseErrorMessages.push(`Field ${adjustToHumanReadableField(errorField)} ${errorItem.message}`)
          } else {
            responseErrorMessages.push(`Field ${adjustToHumanReadableField(errorField)} ${errorItem.message}`)
          }
        }
      })
    }

    return {
      responseErrorMessages,
      responseNctsErrors,
    }
  }

  const createOrUpdateConsignmentItems = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const houseConsignments = getValues('houseConsignment')

    const createRequestsForHouseConsignment: Array<MutateRequest<CreateOrUpdateConsignmentItemRequest[]>> = []
    const updateRequestsForHouseConsignment: Array<MutateRequest<CreateOrUpdateConsignmentItemRequest[]>> = []

    let goodsItemCumulativeSequence: number = 0

    houseConsignments
      .filter(excludeDeleted)
      .sort(sortBySequenceNumber)
      .forEach((houseConsignment) => {
        const houseConsignmentId = houseConsignment.id

        if (houseConsignmentId === null) throw Error('Missing required house consignment id for consignment item')
        const newConsignmentItems: CreateOrUpdateConsignmentItemRequest[] = []
        const updatedConsignmentItems: CreateOrUpdateConsignmentItemRequest[] = []

        houseConsignment.consignmentItem.sort(sortBySequenceNumber)
        houseConsignment
          .consignmentItem
          .filter(excludeDeleted)
          .map((item: ConsignmentItem) => (
            /* eslint-disable no-plusplus */
            { ...item, declarationGoodsItemNumber: goodsItemCumulativeSequence++ }
          ))
          .map((consignmentItem) => toCreateOrUpdateConsignmentItemRequest(
            consignmentItem,
            houseConsignmentId,
          ))
          .forEach((request) => {
            if (request.id == null) {
              newConsignmentItems.push(request)
            } else {
              updatedConsignmentItems.push(request)
            }
          })

        if (newConsignmentItems.length > 0) {
          createRequestsForHouseConsignment.push({
            id: houseConsignmentId,
            data: newConsignmentItems,
          })
        }
        if (updatedConsignmentItems.length > 0) {
          updateRequestsForHouseConsignment.push({
            id: houseConsignmentId,
            data: updatedConsignmentItems,
          })
        }
      })

    const createRequests: Array<Promise<ConsignmentItemResponse[]>> = []
    createRequestsForHouseConsignment.forEach((request) => createRequests.push(postConsignmentItem(isDraft).mutateAsync(request)))

    const updateRequests: Array<Promise<ConsignmentItemResponse[]>> = []
    updateRequestsForHouseConsignment.forEach((request) => updateRequests.push(putConsignmentItem(isDraft).mutateAsync(request)))

    const allResponseNctsErrors: NctsError[] = []
    const errorMessages: string[] = []

    const createResponses = await Promise.allSettled(createRequests)
    createResponses.forEach((response, index) => {
      const { id, data } = createRequestsForHouseConsignment[index]
      const houseConsignmentIndex = houseConsignments.findIndex((house) => house.id === id)
      if (response.status === 'fulfilled') {
        refreshSavedIds(response.value)
      }
      if (response.status === 'rejected') {
        const { responseErrorMessages, responseNctsErrors } = getResponseErrors(houseConsignmentIndex, data, response)
        allResponseNctsErrors.push(...responseNctsErrors)
        errorMessages.push(...responseErrorMessages)
      }
    })

    const updateResponses = await Promise.allSettled(updateRequests)
    updateResponses.forEach((response, index) => {
      const { id, data } = updateRequestsForHouseConsignment[index]
      const houseConsignmentIndex = houseConsignments.findIndex((house) => house.id === id)
      if (response.status === 'fulfilled') {
        refreshSavedIds(response.value)
      }
      if (response.status === 'rejected') {
        const { responseErrorMessages, responseNctsErrors } = getResponseErrors(houseConsignmentIndex, data, response)
        allResponseNctsErrors.push(...responseNctsErrors)
        errorMessages.push(...responseErrorMessages)
      }
    })

    setNctsErrors(allResponseNctsErrors)
    errorMessages.forEach((errorMessage) => toast.error(errorMessage))

    const anyCreateError = createResponses.find((promise) => promise.status === 'rejected')
    const anyUpdateError = updateResponses.find((promise) => promise.status === 'rejected')
    if (anyCreateError?.status === 'rejected') {
      throw Error(anyCreateError?.reason)
    }
    if (anyUpdateError?.status === 'rejected') {
      throw Error(anyUpdateError?.reason)
    }
  }

  const archiveConsignmentItems = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const houseConsignments = getValues('houseConsignment')

    type ConsignmentItemId = number
    const archivedItems: Array<MutateRequest<ConsignmentItemId>> = []

    houseConsignments
      .forEach((houseConsignment) => {
        const houseConsignmentId = houseConsignment.id
        if (houseConsignmentId === null) throw Error('Missing required house consignment id for consignment item')
        const deletedItem = houseConsignment
          .consignmentItem
          .filter(isDeletedWithId)
          .map((consignmentItem) => ({
            id: houseConsignmentId!,
            data: consignmentItem.id!,
          }))

        archivedItems.push(...deletedItem)
      })

    await Promise.allSettled(archivedItems.map((request) => deleteConsignmentItem.mutateAsync(request)))
  }

  return {
    createOrUpdateConsignmentItems,
    archiveConsignmentItems,
  }
}

export default useConsignmentItem
