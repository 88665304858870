import { useEffect } from 'react'
import { useIsFetching } from '@tanstack/react-query'
import { UseDeclarationFormReturn } from '../../form'
import useDocumentApi from './api'
import { parsePreviousDocumentResponse, parseSupportingDocumentResponse, parseTransportDocumentResponse } from './mapper'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import { DocumentRequestTypeEnum } from '../../../common/models'
import { PreviousDocument, SupportingDocument, TransportDocument } from '../../form/schemas/documentSchemas'
import TransitApiConfig from '../apiConfig'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'
import useDocumentCommonActionsHelper, { groupBy } from './common'
import useConsignmentDocument from './useConsignmentDocument'
import useHouseConsignmentDocument from './useHouseConsignmentDocument'
import useConsignmentItemDocument from './useConsignmentItemDocument'

const {
  queryKeys: { rootPath },
} = TransitApiConfig.paths.consignmentItem

function useDocument(form: UseDeclarationFormReturn) {
  const {
    getValues,
    watch,
    formState: {
      isSubmitting,
    },
    reset,
  } = form

  const isFetching = useIsFetching({ queryKey: [rootPath] })
  const transitOperationId: number | null = watch('id')

  const transportDocumentApi = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.TRANSPORT, isSubmitting)
  const {
    getDocuments: getTransportDocuments,
  } = transportDocumentApi
  const previousDocumentApi = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.PREVIOUS, isSubmitting)
  const {
    getDocuments: getPreviousDocuments,
  } = previousDocumentApi
  const supportingDocumentApi = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.SUPPORTING, isSubmitting)
  const {
    getDocuments: getSupportingDocuments,
  } = supportingDocumentApi

  const commonDocumentActions = useDocumentCommonActionsHelper(transportDocumentApi, previousDocumentApi, supportingDocumentApi)

  const { createOrUpdateConsignmentDocuments, archiveConsignmentDocuments } = useConsignmentDocument(form, commonDocumentActions)
  const { createOrUpdateHouseConsignmentDocuments, archiveHouseConsignmentDocuments } = useHouseConsignmentDocument(form, commonDocumentActions)
  const { createOrUpdateConsignmentItemDocuments, archiveConsignmentItemDocuments } = useConsignmentItemDocument(form, commonDocumentActions)

  const populateFormConsignmentItems = () => {
    if (getTransportDocuments.isFetching || getPreviousDocuments.isFetching || getSupportingDocuments.isFetching || isSubmitting) {
      return
    }

    const consignmentPreviousDocuments: PreviousDocument[] = []
    const consignmentTransportDocuments: TransportDocument[] = []
    const consignmentSupportingDocuments: SupportingDocument[] = []
    let housePreviousDocumentsById: Map<number, PreviousDocument[]> = new Map<number, PreviousDocument[]>()
    let houseTransportDocumentsById: Map<number, TransportDocument[]> = new Map<number, TransportDocument[]>()
    let houseSupportingDocumentsById: Map<number, SupportingDocument[]> = new Map<number, SupportingDocument[]>()
    let consignmentItemTransportDocuments: Map<number, TransportDocument[]> = new Map<number, TransportDocument[]>()
    let consignmentItemPreviousDocuments: Map<number, PreviousDocument[]> = new Map<number, PreviousDocument[]>()
    let consignmentItemSupportingDocuments: Map<number, SupportingDocument[]> = new Map<number, SupportingDocument[]>()

    if (getTransportDocuments.data?.length) {
      consignmentItemTransportDocuments = groupBy(
        getTransportDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parseTransportDocumentResponse,
      )

      houseTransportDocumentsById = groupBy(
        getTransportDocuments.data.filter((document) => document.houseConsignmentId !== null),
        (document) => document.houseConsignmentId!,
        parseTransportDocumentResponse,
      )

      consignmentTransportDocuments.push(...getTransportDocuments.data
        .filter((document) => document.consignmentId !== null)
        .map(parseTransportDocumentResponse))
    }

    if (getPreviousDocuments.data?.length) {
      consignmentItemPreviousDocuments = groupBy(
        getPreviousDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parsePreviousDocumentResponse,
      )

      housePreviousDocumentsById = groupBy(
        getPreviousDocuments.data.filter((document) => document.houseConsignmentId !== null),
        (document) => document.houseConsignmentId!,
        parsePreviousDocumentResponse,
      )

      consignmentPreviousDocuments.push(...getPreviousDocuments.data
        .filter((document) => document.consignmentId !== null)
        .map(parsePreviousDocumentResponse))
    }

    if (getSupportingDocuments.data?.length) {
      consignmentItemSupportingDocuments = groupBy(
        getSupportingDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parseSupportingDocumentResponse,
      )

      houseSupportingDocumentsById = groupBy(
        getSupportingDocuments.data.filter((document) => document.houseConsignmentId !== null),
        (document) => document.houseConsignmentId!,
        parseSupportingDocumentResponse,
      )

      consignmentSupportingDocuments.push(...getSupportingDocuments.data
        .filter((document) => document.consignmentId !== null)
        .map(parseSupportingDocumentResponse))
    }

    const formClone: DeclarationForm = structuredClone(getValues())

    reset({
      ...formClone,
      previousDocument: consignmentPreviousDocuments.sort(sortBySequenceNumber),
      transportDocument: consignmentTransportDocuments.sort(sortBySequenceNumber),
      supportingDocument: consignmentSupportingDocuments.sort(sortBySequenceNumber),
      houseConsignment: formClone.houseConsignment.map((formHouseConsignment) => {
        const housePreviousDocuments = formHouseConsignment.id !== null ? housePreviousDocumentsById.get(formHouseConsignment.id) : []
        const houseTransportDocuments = formHouseConsignment.id !== null ? houseTransportDocumentsById.get(formHouseConsignment.id) : []
        const houseSupportingDocuments = formHouseConsignment.id !== null ? houseSupportingDocumentsById.get(formHouseConsignment.id) : []

        return {
          ...formHouseConsignment,
          previousDocument: housePreviousDocuments?.sort(sortBySequenceNumber) ?? [],
          transportDocument: houseTransportDocuments?.sort(sortBySequenceNumber) ?? [],
          supportingDocument: houseSupportingDocuments?.sort(sortBySequenceNumber) ?? [],
          consignmentItem: formHouseConsignment.consignmentItem
            .map((consignmentItem) => {
              const consignmentItemId = consignmentItem.id
              if (consignmentItemId === null) return consignmentItem

              const itemTransportDocuments = consignmentItemTransportDocuments.get(consignmentItemId)
              const itemPreviousDocuments = consignmentItemPreviousDocuments.get(consignmentItemId)
              const itemSupportingDocuments = consignmentItemSupportingDocuments.get(consignmentItemId)

              return ({
                ...consignmentItem,
                transportDocument: itemTransportDocuments?.sort(sortBySequenceNumber) ?? [],
                previousDocument: itemPreviousDocuments?.sort(sortBySequenceNumber) ?? [],
                supportingDocument: itemSupportingDocuments?.sort(sortBySequenceNumber) ?? [],
              })
            }),
        }
      }),
    })
  }

  useEffect(() => {
    populateFormConsignmentItems()
  }, [getTransportDocuments.data, getPreviousDocuments.data, getSupportingDocuments.data, isFetching])

  return {
    createOrUpdateConsignmentDocuments,
    archiveConsignmentDocuments,
    createOrUpdateHouseConsignmentDocuments,
    archiveHouseConsignmentDocuments,
    createOrUpdateConsignmentItemDocuments,
    archiveConsignmentItemDocuments,
  }
}

export default useDocument
