
import { toast } from 'react-toastify'

import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useCsvParser from '../useCsvParser'

import { CustomColumnMap, isKnownColumnKey, parseCsvRow } from './mapper'

import ParseCsvRowResult from './mapper/parseCsvRowResult'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { blankConsignee, blankConsignor, blankHouseConsignmentType } from '../../form'
import { hasText } from '../../../common/utils/common-util'
import { PreviousDocument, SupportingDocument } from '../../form/schemas/documentSchemas'
import useConsignmentItemsImportDiff from './useConsignmentItemsImportDiff'

interface UseHouseConsignmentItemImportProps {
  addImportedRows: (houseConsignment: HouseConsignmentType[]) => void
}

function nonNullTransportDocument(transportDocument: { documentType: string }): boolean {
  return hasText(transportDocument.documentType)
}

function nonNullPreviousDocument(previousDocument: PreviousDocument): boolean {
  return hasText(previousDocument.documentType) || hasText(previousDocument.procedureType)
}

function nonNullSupportingDocument(supportingDocument: SupportingDocument): boolean {
  return hasText(supportingDocument.documentType)
}

function mapToHouseConsignment(parseResult: ParseCsvRowResult) {
  return {
    ...blankHouseConsignmentType,
    countryOfDispatch: parseResult.countryOfDispatch,
    countryOfDestination: parseResult.countryOfDestination,
    grossMass: parseResult.grossMass,
    consignee: parseResult.consignee ?? blankConsignee,
    consignor: parseResult.consignor ?? blankConsignor,
    previousDocument: parseResult.previousDocument.filter(nonNullPreviousDocument),
    transportDocument: parseResult.transportDocument.filter(nonNullTransportDocument),
    supportingDocument: parseResult.supportingDocument.filter(nonNullSupportingDocument),
    consignmentItem: [],
  }
}

function useHouseConsignmentItemImport(props: UseHouseConsignmentItemImportProps) {
  const { t } = useTranslation()

  const {
    addImportedRows,
  } = props

  const { parseCsv } = useCsvParser()
  const [csvRows, setCsvRows] = useState<string[][]>([])
  const [rawHeaders, setRawHeaders] = useState<ReadonlyArray<string>>([])
  const [customHeaderMappingModalVisibility, setCustomHeaderMappingModalVisibility] = useState(false)
  const [customColumnMap, setCustomColumnMap] = useState<CustomColumnMap>(new Map())

  const { createOrUpdateDiff, updateHouseConsignmentFromDiff } = useConsignmentItemsImportDiff()

  const toggleMappingModalVisibility = () => setCustomHeaderMappingModalVisibility(!customHeaderMappingModalVisibility)

  const startImport = () => {
    if (Array.from(customColumnMap.values()).some((value) => value === null || value === undefined)) {
      const blankColumns: string[] = []
      customColumnMap.forEach((value, key) => {
        if (value === null || value === undefined) {
          blankColumns.push(key)
        }
      })

      toast.warning(t('consignmentItemImport.missingColumnMapping', { 0: blankColumns }), { autoClose: false })
    }

    const rowParsePromises: Array<Promise<ParseCsvRowResult>> = []
    csvRows.forEach((rowFields, index) => {
      rowParsePromises.push(
        parseCsvRow({
          headerFields: rawHeaders, rowFields, index, customColumnMap,
        }),
      )
    })

    Promise.allSettled(rowParsePromises).then((results) => {
      const houseConsignmentBySequenceNumber: Map<number, HouseConsignmentType> = new Map<number, HouseConsignmentType>()

      const rejectedResults = results.filter((result): result is PromiseRejectedResult => result.status === 'rejected')
      if (rejectedResults.length) {
        toast.warn(`${rejectedResults[0].reason}`)
        return
      }

      const parseResults: ParseCsvRowResult[] = results
        .filter((result): result is PromiseFulfilledResult<ParseCsvRowResult> => result.status === 'fulfilled')
        .map((result) => result.value)

      parseResults.forEach((parseResult) => {
        const houseSequenceNumber = parseResult.sequenceNumber
        if (houseSequenceNumber == null) return

        let houseConsignment = houseConsignmentBySequenceNumber.get(parseResult.sequenceNumber)
        if (!houseConsignment) {
          houseConsignment = mapToHouseConsignment(parseResult)
          houseConsignmentBySequenceNumber.set(parseResult.sequenceNumber, houseConsignment)
        }

        createOrUpdateDiff(houseSequenceNumber, parseResult)

        const consignmentItemSequenceNumber = houseConsignment.consignmentItem.length
        houseConsignment.consignmentItem.push({
          ...parseResult.consignmentItem,
          previousDocument: parseResult.consignmentItem.previousDocument.filter(nonNullPreviousDocument),
          supportingDocument: parseResult.consignmentItem.supportingDocument.filter(nonNullSupportingDocument),
          sequenceNumber: consignmentItemSequenceNumber,
        })
      })

      houseConsignmentBySequenceNumber.forEach((value: HouseConsignmentType, key: number) => {
        updateHouseConsignmentFromDiff(key, value)
      })

      addImportedRows(Array.from(houseConsignmentBySequenceNumber.values()))

      toggleMappingModalVisibility()
    })
  }

  const isHouseConsignmentHeader = (headerParts: string[]) => headerParts.length === 4
    && headerParts[0] === 'h'
    && !Number.isNaN(headerParts[3])
  const isConsignmentItemHeader = (headerParts: string[]) => headerParts.length === 3 && !Number.isNaN(headerParts[2])
  const getHouseConsignmentColumnKey = (headerParts: string[]) => `h_${headerParts[1]}_${headerParts[2]}`
  const getConsignmentItemColumnKey = (headerParts: string[]) => `${headerParts[0]}_${headerParts[1]}`

  const parseImportFile = async (files: FileList | null) => {
    if (files && files[0] !== null) {
      try {
        const parsedCsvRows = await parseCsv(files[0]) as string[][]
        const headers = parsedCsvRows.shift() as ReadonlyArray<string>
        if (headers) {
          setCustomColumnMap(new Map(headers.map((csvHeader) => {
            if (isKnownColumnKey(csvHeader)) {
              return [csvHeader, csvHeader]
            }

            const headerParts = csvHeader.split('_')

            if (isHouseConsignmentHeader(headerParts)) {
              const columnKey = getHouseConsignmentColumnKey(headerParts)
              if (isKnownColumnKey(columnKey)) {
                return [csvHeader, columnKey]
              }
              return [csvHeader, null]
            }

            if (isConsignmentItemHeader(headerParts)) {
              const columnKey = getConsignmentItemColumnKey(headerParts)
              if (isKnownColumnKey(columnKey)) {
                return [csvHeader, columnKey]
              }
              return [csvHeader, null]
            }

            return [csvHeader, null]
          })))
        }
        if (parsedCsvRows.at(parsedCsvRows.length - 1)?.length === 1) {
          parsedCsvRows.pop()
        }

        setRawHeaders(headers)
        setCsvRows(parsedCsvRows)
      } catch (e) {
        toast.error(t('excel.processingError'))
        setCsvRows([])
        setRawHeaders([])
      }
    }
  }

  return {
    customHeaderMappingModalVisibility,
    toggleMappingModalVisibility,
    customColumnMap,
    setCustomColumnMap,
    parseImportFile,
    startImport,
  }
}

export default useHouseConsignmentItemImport
