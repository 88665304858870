
import { CustomsOfficeOfExitForTransitDeclared } from '../../schemas/commonConsignmentSchemas'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import {
  DESTINATION_OFFICE_CODE_PREFIX,
  removePrefix,
  TRANSIT_EXIT_OFFICE_CODE_PREFIX,
  TRANSIT_OFFICE_CODE_PREFIX,
} from '../../../../common/utils/classifier-util'
import { excludeDeleted } from '../../../../common/utils/common-util'

const R0103Validator = {
  isTransitExitCustomsOfficeReferenceNumberValid: (
    formData: DeclarationForm,
    transitExitCustomsOffice: CustomsOfficeOfExitForTransitDeclared,
  ): boolean => {
    const office = removePrefix(transitExitCustomsOffice.referenceNumber, TRANSIT_EXIT_OFFICE_CODE_PREFIX)
    if (!office) {
      return true
    }

    const {
      transitCustomsOffices,
      destinationCustomsOffice,
    } = formData

    const destinationOffice = removePrefix(destinationCustomsOffice, DESTINATION_OFFICE_CODE_PREFIX)

    return office !== destinationOffice && !transitCustomsOffices.filter(excludeDeleted)
      .some((transitCustomsOffice) => removePrefix(transitCustomsOffice.referenceNumber, TRANSIT_OFFICE_CODE_PREFIX) === office)
  },
}

export default R0103Validator
