import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { CL112_CTC_COUNTRIES } from '../../../../common/utils/ncts-constants'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { ConsignmentItem } from '../../schemas/consignmentItemSchema'
import { excludeDeleted } from '../../../../common/utils/common-util'

const C0035Validator = {
  isPreviousDocumentRequired: (
    {
      declarationType,
      departureCustomsOffice,
      previousDocument,
    }: DeclarationForm,
  ): RuleResult => {
    if (declarationType !== 'T2' && declarationType !== 'T2F') {
      return 'OPTIONAL'
    }

    const departureOfficeCountry = removePrefix(departureCustomsOffice, 'DEPARTURE_OFFICE_', 2)

    if ((declarationType === 'T2' || declarationType === 'T2F')
      && departureOfficeCountry !== null && CL112_CTC_COUNTRIES.includes(departureOfficeCountry)
      && previousDocument.filter(excludeDeleted).length === 0) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
  isPreviousDocumentForConsignmentItemRequired: (
    {
      departureCustomsOffice,
    }: DeclarationForm,
    consignmentItem: ConsignmentItem,
  ): RuleResult => {
    const { previousDocument } = consignmentItem
    const departureOfficeCountry = removePrefix(departureCustomsOffice, 'DEPARTURE_OFFICE_', 2)
    if ((consignmentItem.declarationType === 'T2' || consignmentItem.declarationType === 'T2F')) {
      if (departureOfficeCountry !== null && CL112_CTC_COUNTRIES.includes(departureOfficeCountry)
      && previousDocument.filter(excludeDeleted).length === 0) {
        return 'OPTIONAL'
      }

      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0035Validator
