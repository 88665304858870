import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConsignmentItemResponse, DocumentResponse } from '../../models'
import TransitSidebarField from './TransitSidebarField'
import TransitSidebarArrayField from './TransitSidebarArrayField'
import useCodelist from '../../hooks/useCodelist'
import { hasText } from '../../utils/common-util'
import { sortBySequenceNumber } from '../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarConsignmentItemTransportDocuments from './documents/TransitSidebarConsignmentItemTransportDocuments'
import TransitSidebarConsignmentItemSupportingDocuments from './documents/TransitSidebarConsignmentItemSupportingDocuments'
import TransitSidebarConsignmentItemPreviousDocuments from './documents/TransitSidebarConsignmentItemPreviousDocuments'

export interface TransitSidebarGoodsItemRowProps {
  consignmentItem: ConsignmentItemResponse
  initialOpenState: boolean
  previousDocuments: DocumentResponse[]
  transportDocuments: DocumentResponse[]
  supportingDocuments: DocumentResponse[]
}
function TransitSidebarGoodsItemRow({
  consignmentItem,
  initialOpenState,
  previousDocuments,
  transportDocuments,
  supportingDocuments,
}: TransitSidebarGoodsItemRowProps) {
  const { t } = useTranslation()
  const [isItemOpen, toggleItemOpen] = useState<boolean>(initialOpenState)
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [, packagesOptions] = useCodelist('PACKAGES')

  const {
    id,
    goodsItemNumber,
    descriptionOfGoods,
    combinedNomenclatureCode,
    harmonizedSystemSubHeadingCode,
  } = consignmentItem

  return (
    <div
      className="col-12 row m-0"
      key={`goods_item_row_wrapper_${consignmentItem.id}`}
    >
      <div
        key={`goods_item_row_header_${consignmentItem.id}`}
        className="heading mb-2"
        data-bs-toggle="collapse"
        data-bs-target={`#${id}`}
        aria-expanded={isItemOpen}
        aria-controls={`${id}`}
        role="button"
        tabIndex={isItemOpen ? -1 : 0}
        onClick={() => toggleItemOpen(!isItemOpen)}
        onKeyDown={() => toggleItemOpen(!isItemOpen)}
      >
        <h4 className="col-11 d-flex">
          <span className="col-1">
            <span>{t('declaration.p5.sequenceNumber')}</span>
            <span className="px-1">
              {(goodsItemNumber ?? 0) + 1}
            </span>
          </span>
          <span className="col-10 pe-1">
            {descriptionOfGoods}
          </span>
          <span className="col-2 px-1">
            {`${harmonizedSystemSubHeadingCode} ${combinedNomenclatureCode}`}
          </span>
        </h4>
        <i className={`me-2 fa ${isItemOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </div>
      <div
        key={`goods_item_row_data_${consignmentItem.id}`}
        className={`collapse ${isItemOpen ? 'show mb-3' : ''}`}
        id={`${id}`}
      >
        <div className="d-flex flex-wrap">
          {isItemOpen && (
            <>
              <div className="col-6">
                <TransitSidebarField
                  label={t('declaration.p5.commodityHarmonizedSystemSubHeadingCode')}
                  value={consignmentItem.harmonizedSystemSubHeadingCode ?? ''}
                />
                <TransitSidebarField
                  label={t('declaration.p5.commodityCombinedNomenclatureCode')}
                  value={hasText(consignmentItem.combinedNomenclatureCode) ? consignmentItem.combinedNomenclatureCode ?? '' : '-'}
                />
                <TransitSidebarField
                  label={t('declaration.p5.goodsMeasureGrossMass')}
                  value={`${consignmentItem.grossMass}`}
                />
                <TransitSidebarField
                  label={t('declaration.p5.goodsMeasureNetMass')}
                  value={`${consignmentItem.netMass}`}
                />
                {
                  consignmentItem.consignee !== null && consignmentItem.consignee.id !== null && (
                    <TransitSidebarArrayField
                      label={t('declaration.p5.consignee')}
                      items={[
                        {
                          value: consignmentItem.consignee.name,
                          parenthesesValue: consignmentItem.consignee?.identificationNumber ?? null,
                        },
                        {
                          value: consignmentItem.consignee?.address?.street,
                          parenthesesValue: consignmentItem.consignee?.address?.city,
                        },
                        {
                          value: countriesOptions.find((option) => option.value === consignmentItem.consignee?.address?.country)?.label,
                          parenthesesValue: consignmentItem.consignee?.address?.postcode,
                        },
                      ]}
                    />
                  )
                }
                <TransitSidebarField
                  label={t('declaration.p5.price')}
                  value={consignmentItem.price ? `${consignmentItem.price} EUR` : '-'}
                />
                <TransitSidebarField
                  label={t('declaration.p5.vatRate')}
                  value={consignmentItem.vatRate ? `${consignmentItem.vatRate}%` : '-'}
                />
                <TransitSidebarField
                  label={t('declaration.p5.dutyRate')}
                  value={consignmentItem.dutyRate ? `${consignmentItem.dutyRate}%` : '-'}
                />
                <TransitSidebarField
                  label={t('declaration.p5.excise')}
                  value={consignmentItem.excise ? `${consignmentItem.excise} EUR` : '-'}
                />
              </div>
              <div className="col-6">
                {
                  consignmentItem.packages.sort(sortBySequenceNumber).map((itemPackage, index) => (
                    <div
                      key={`package_${itemPackage.sequenceNumber}`}
                      className={`${(index + 1) !== consignmentItem.packages.length ? 'mb-4' : ''} mb-1 `}
                    >
                      <TransitSidebarField
                        label={`${t('declaration.p5.packages', { context: 'singular' })} ${t('declaration.p5.sequenceNumber')}`}
                        value={`${(itemPackage.sequenceNumber ?? 0) + 1 ?? '-'}`}
                      />
                      <TransitSidebarField
                        label={t('declaration.p5.typeOfPackages')}
                        value={`${packagesOptions.find((option) => option.value === itemPackage.typeOfPackages)?.label}`}
                      />
                      <TransitSidebarField
                        label={t('declaration.p5.numberOfPackages')}
                        value={`${itemPackage.numberOfPackages ?? '-'}`}
                      />
                      <TransitSidebarField
                        label={t('declaration.p5.shippingMarks')}
                        value={`${itemPackage.shippingMarks}`}
                      />
                    </div>
                  ))
                }
              </div>
              <TransitSidebarConsignmentItemTransportDocuments
                consignmentItemId={consignmentItem.id}
                transportDocuments={transportDocuments}
              />
              <TransitSidebarConsignmentItemSupportingDocuments
                consignmentItemId={consignmentItem.id}
                supportingDocuments={supportingDocuments}
              />
              <TransitSidebarConsignmentItemPreviousDocuments
                consignmentItemId={consignmentItem.id}
                previousDocuments={previousDocuments}
              />
            </>
          )}
        </div>
      </div>

    </div>
  )
}

export default TransitSidebarGoodsItemRow
