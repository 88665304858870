export const NctsCountries = ['EE', 'LV', 'LT', 'PL', 'FI', 'RO', 'GB', 'XI', 'SE', 'DE', 'NL', 'BG', 'TR', 'HU', 'UA'] as const
export type NctsCountry = typeof NctsCountries[number]

export interface TardekConfig {
  phase5CountriesLive: NctsCountry[]
  phase5CountriesTest: NctsCountry[]
  adminOnlyCountries: NctsCountry[]
  phase4CountriesLive: NctsCountry[]
  phase4CountriesTest: NctsCountry[]
}

export const tardekConfig: TardekConfig = {
  phase5CountriesLive: ['EE', 'FI', 'LV', 'RO', 'GB', 'PL', 'SE', 'LT', 'HU', 'UA'],
  phase5CountriesTest: ['EE', 'FI', 'LV', 'RO', 'GB', 'PL', 'SE', 'LT', 'HU', 'UA', 'BG'],
  adminOnlyCountries: ['UA'],
  phase4CountriesLive: [],
  phase4CountriesTest: [],
}
