import React from 'react'
import { DocumentResponse, HouseConsignmentResponse } from '../../models'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import TransitSidebarHouseConsignmentRow from './TransitSidebarHouseConsignmentRow'

interface TransitSidebarHouseConsignmentsProps {
  transitOperationId: number
  houseConsignments: HouseConsignmentResponse[]
  previousDocuments: DocumentResponse[]
  transportDocuments: DocumentResponse[]
  supportingDocuments: DocumentResponse[]
  isLoading: boolean
  initialOpenState: boolean
}

function TransitSidebarHouseConsignments({
  transitOperationId,
  houseConsignments,
  previousDocuments,
  transportDocuments,
  supportingDocuments,
  isLoading,
  initialOpenState,
}: TransitSidebarHouseConsignmentsProps) {
  return (
    <div className="col-12">
      {
        isLoading && (
          <div className="position-relative mt-2">
            <LoadingSpinner />
          </div>
        )
      }
      {
        houseConsignments.length > 0
        && houseConsignments
          .map((house) => (
            <TransitSidebarHouseConsignmentRow
              key={house.id}
              transitOperationId={transitOperationId}
              houseConsignment={house}
              previousDocuments={previousDocuments}
              transportDocuments={transportDocuments}
              supportingDocuments={supportingDocuments}
              isLoading={isLoading}
              initialOpenState={initialOpenState}
            />
          ))
      }
    </div>
  )
}

export default TransitSidebarHouseConsignments
