import React, { Dispatch, SetStateAction } from 'react'
import FormRow from 'components/FormRow'
import { useTranslation } from 'react-i18next'

interface SeoTagsEditorProps {
  loading: boolean
  useSeoTagsState:
  {
    titleTag: string
    descriptionTag: string
    keywordTags: string
    setTitleTag: Dispatch<SetStateAction<string>>
    setDescriptionTag: Dispatch<SetStateAction<string>>
    setKeywordTags: Dispatch<SetStateAction<string>>
  }
}

function SeoTagsEditor({
  useSeoTagsState: {
    descriptionTag,
    keywordTags,
    setDescriptionTag,
    setKeywordTags,
    setTitleTag,
    titleTag,
  },
  loading,
}: SeoTagsEditorProps) {
  const { t } = useTranslation()

  return (
    <div className="section__container overflow-auto">
      <div className="pt-4 px-4 col-12 col-xl-4 mb-5 mb-md-0">
        <div className="heading">
          <h3>
            {t('seo.columnTitle')}
          </h3>
        </div>
        <FormRow title={t('seo.title', { context: 'capitalized' })}>
          <input
            className="form-control"
            type="text"
            disabled={loading}
            maxLength={158}
            placeholder={t('common.fieldPlaceholder')}
            value={titleTag}
            onChange={(event) => {
              setTitleTag(event.target.value)
            }}
          />
        </FormRow>
        <FormRow title={t('seo.description', { context: 'capitalized' })}>
          <input
            className="form-control"
            type="text"
            disabled={loading}
            maxLength={158}
            placeholder={t('common.fieldPlaceholder')}
            value={descriptionTag}
            onChange={(event) => {
              setDescriptionTag(event.target.value)
            }}
          />
        </FormRow>
        <FormRow title={t('seo.keywords', { context: 'capitalized' })}>
          <input
            className="form-control"
            type="text"
            disabled={loading}
            maxLength={158}
            placeholder={t('common.fieldPlaceholder')}
            value={keywordTags}
            onChange={(event) => {
              setKeywordTags(event.target.value)
            }}
          />
        </FormRow>
      </div>
    </div>
  )
}

export default SeoTagsEditor
