import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import { TransitOperationContext } from '../../hooks/useTransitOperationContext'

function NctsErrors() {
  const { t } = useTranslation()
  const { nctsErrors } = useContext(TransitOperationContext)

  return (
    <section className="py-3">
      <CollapsibleColumn
        columnId="declaration-errors"
        headingLabel={t('declaration.p5.errors.title')}
        key={nctsErrors.length}
      >
        {
          nctsErrors.length !== 0 && nctsErrors.map((item) => (
            <div className="row" key={`${item.field}_${item.description}`}>
              <div className="col-12">
                <div className="alert alert-danger d-noxne">
                  <span>{item.field}</span>
                  <strong className="me-2" />
                  <span>{item.description}</span>
                </div>
              </div>
            </div>
          ))
        }
        {
          nctsErrors.length === 0 && (
            <div className="row">
              <div className="col-12">
                <div className="no-warnings d-block text-center">{t('declaration.p5.errors.none')}</div>
              </div>
            </div>
          )
        }
      </CollapsibleColumn>
    </section>
  )
}

export default NctsErrors
