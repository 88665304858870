import { hasText } from 'routes/phase5/common/utils/common-util'
import { UseDeclarationFormReturn } from '../../form'
import useCarrierApi from './api'
import { toCarrierRequest } from './mapper'

function useCarrier(form: UseDeclarationFormReturn) {
  const {
    getValues,
    getFieldState,
    setValue,
    watch,
  } = form

  const transitOperationId: number | null = watch('id')
  const { postCarrier } = useCarrierApi(transitOperationId)

  const createCarrier = async (isDraft: boolean) => {
    if (!isDraft && getFieldState('carrier').invalid) return

    const carrier = getValues('carrier')
    if (carrier === null || (isDraft && !hasText(carrier.carrierName))) return
    const newTransitOperationId = getValues('id')
    if (newTransitOperationId === null) throw Error('Missing transitOperationId')

    const response = await postCarrier.mutateAsync(toCarrierRequest(carrier, newTransitOperationId))

    setValue('carrier.id', response.id)
  }

  return { createCarrier }
}

export default useCarrier
