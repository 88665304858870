import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'

const C0823Validator = {
  validateTransportEquipmentRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.containerIndicator === 'true') {
      return 'REQUIRED'
    }
    return 'OPTIONAL'
  },
} as const

export default C0823Validator
