import { DefaultValues, useForm, UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { FieldPathRule } from 'types/DeclarationP5'
import DeclarationFormSchema, { DeclarationForm, DeclarationTypeEnum, SecurityEnum } from './schemas/declarationFormSchema'
import {
  blankCarrier,
  blankConsignee,
  blankConsignor,
  blankDirectoEvent,
  blankHouseConsignmentType,
  blankPlaceOfLoading,
  blankPlaceOfUnloading,
} from './blankItems'

export const initialFormData: DefaultValues<DeclarationForm> = {
  id: null,
  deleted: false,
  status: 'DRAFT',
  mrn: null,
  security: SecurityEnum.Enum['0'],
  limitDate: '',
  declarationType: DeclarationTypeEnum.Enum.T1,
  additionalDeclarationType: 'A',
  lrn: null,
  countryOfDispatch: '',
  countryOfDestination: '',
  containerIndicator: 'false',
  inlandModeOfTransport: '',
  borderModeOfTransport: '',
  bindingItinerary: 'false',
  grossMass: 0,
  customerId: 0,
  referenceNumberUCR: null,
  transportChargesMethodOfPayment: null,
  departureCustomsOffice: '',
  destinationCustomsOffice: null,
  consignmentId: null,
  carrier: blankCarrier,
  consignor: blankConsignor,
  consignee: blankConsignee,
  locationOfGoods: null, // blankLocationOfGoods,
  placeOfLoading: blankPlaceOfLoading,
  placeOfUnloading: blankPlaceOfUnloading,
  directoId: null,

  transitCustomsOffices: [],
  customsOfficesOfTransitExit: [],
  guarantees: [
    {
      id: null,
      deleted: false,
      profileName: null,
      amount: 0,
      sequenceNumber: 0,
    },
  ],
  authorisations: [],
  additionalSupplyChainActor: [],
  transportEquipment: [],
  departureTransportMeans: [],
  countryOfRoutingOfConsignment: [],
  activeBorderTransportMeans: [],
  previousDocument: [],
  supportingDocument: [],
  transportDocument: [],
  additionalReference: [],
  additionalInformation: [],
  houseConsignment: [
    blankHouseConsignmentType,
  ],
  directo: blankDirectoEvent,
}

export type UseDeclarationFormReturn = UseFormReturn<DeclarationForm, FieldPathRule[]>
function useDeclarationForm() {
  const form: UseDeclarationFormReturn = useForm<DeclarationForm, FieldPathRule[]>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialFormData,
    // possible access to context and options with refs to element inputs
    resolver(values, context, options) {
      return zodResolver(DeclarationFormSchema)(values, context, options)
    },

    // context: {}, // https://github.com/react-hook-form/react-hook-form/issues/9787
  })

  useEffect(() => {
  }, [])

  return { form }
}

export default useDeclarationForm
export {
  blankTransportDocument,
  blankCustomsOfficeOfTransitDeclared,
  blankActiveBorderTransportMeans,
  blankAdditionalInformation,
  blankAdditionalReference,
  blankAdditionalSupplyChainActor,
  blankCarrier,
  blankConsignor,
  blankConsignee,
  blankLocationOfGoods,
  blankPlaceOfLoading,
  blankPlaceOfUnloading,
  blankConsignmentItem,
  blankCountryOfRoutingOfConsignment,
  blankDangerousGoods,
  blankGoodsReference,
  blankHouseConsignmentType,
  blankPackaging,
  blankPreviousDocument,
  blankSealType,
  blankSupportingDocument,
  blankTransportEquipment,
  blankItemDepartureTransportMeans,
  blankAuthorisations,
} from './blankItems'
