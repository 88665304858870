import { useState } from 'react'

export type RequestMethod = 'post' | 'put'

function useFailedHouseConsignmentItemsSaveState() {
  const [failedHouseConsignmentIds, setFailedHouseConsignmentIds] = useState<Map<RequestMethod, Set<number>>>(new Map())

  function isFailedRequestId(method: RequestMethod, id: number) {
    return !!failedHouseConsignmentIds.get(method)?.has(id)
  }

  function removeFromFailedRequests(method: RequestMethod, ids: Set<number>) {
    setFailedHouseConsignmentIds((prevState) => {
      const newMap = new Map(prevState)

      const updatedIds = newMap.get(method) ?? new Set()
      ids.forEach(updatedIds.delete.bind(updatedIds))
      newMap.set(method, updatedIds)

      return newMap
    })
  }

  function addToFailedRequests(method: RequestMethod, ids: Set<number>) {
    setFailedHouseConsignmentIds((prevState) => {
      const newMap = new Map(prevState)
      const updatedIds = newMap.get(method) ?? new Set()
      ids.forEach(updatedIds.add.bind(updatedIds))
      newMap.set(method, updatedIds)

      return newMap
    })
  }

  return {
    isFailedRequestId,
    removeFromFailedRequests,
    addToFailedRequests,
  }
}

export default useFailedHouseConsignmentItemsSaveState
