import { COUNTRY_CODE_PREFIX, removePrefix } from '../../../../common/utils/classifier-util'
import { CL009_COUNTRY_CODES_COMMON_TRANSIT, isTransitionalPeriodActive } from '../../../../common/utils/ncts-constants'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { excludeDeleted, nonNullConsignee } from '../../../../common/utils/common-util'
import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import B1823Validator from '../transitional/B1823'

const C0001Validator = {
  validateConsignmentConsigneeRequirement: (formData: DeclarationForm) => {
    if (isTransitionalPeriodActive()) {
      return B1823Validator.validateConsignmentConsigneeRequirement(formData)
    }

    if (formData.additionalInformation
      .filter(excludeDeleted)
      .some((item) => item.code === '30600')) return 'NOT_ALLOWED'
    if (formData.houseConsignment
      .filter(excludeDeleted)
      .flatMap((houseConsignment) => houseConsignment.additionalInformation)
      .some((item) => item.code === '30600')) {
      return 'NOT_ALLOWED'
    }

    return 'REQUIRED'
  },
  validateConsigneeRequirementForHouseConsignment: (formData: DeclarationForm, houseConsignment: HouseConsignmentType) => {
    const {
      countryOfDestination,
      security,
      consignee,
      additionalInformation,
    } = formData

    if (nonNullConsignee(consignee)) {
      return 'NOT_ALLOWED'
    }

    const country = removePrefix(countryOfDestination, COUNTRY_CODE_PREFIX)
    if (country !== null && CL009_COUNTRY_CODES_COMMON_TRANSIT.includes(country)) {
      return 'REQUIRED'
    }

    const houseDestinationCountry = removePrefix(houseConsignment.countryOfDestination, COUNTRY_CODE_PREFIX)
    if (houseDestinationCountry !== null && CL009_COUNTRY_CODES_COMMON_TRANSIT.includes(houseDestinationCountry)) {
      return 'REQUIRED'
    }

    const consignmentItems = houseConsignment.consignmentItem.filter(excludeDeleted)
    if (consignmentItems.some((consignmentItem) => {
      const consignmentItemDestinationCountry = removePrefix(consignmentItem.countryOfDestination, COUNTRY_CODE_PREFIX)
      return consignmentItemDestinationCountry !== null && CL009_COUNTRY_CODES_COMMON_TRANSIT.includes(consignmentItemDestinationCountry)
    })) {
      return 'REQUIRED'
    }

    if (['0', '1'].includes(security)) {
      return 'OPTIONAL'
    }

    if (additionalInformation.some((item) => item.code === '30600')) return 'NOT_ALLOWED'
    if (houseConsignment.additionalInformation.some((item) => item.code === '30600')) return 'NOT_ALLOWED'

    return 'OPTIONAL'
  },
} as const

export default C0001Validator
