import { useFieldArray, useFormContext } from 'react-hook-form'
import { ArrayScope, RuleResult } from 'types/DeclarationP5'
import { useContext, useMemo } from 'react'
import { DeclarationForm, DeclarationFormSubtype } from '../form/schemas/declarationFormSchema'

import { excludeDeleted } from '../../common/utils/common-util'
import { TransitOperationContext } from '../hooks/useTransitOperationContext'

export type DeclarationFormCommon = Pick<DeclarationForm,
'transitCustomsOffices'
| 'guarantees'
| 'authorisations'
| 'additionalSupplyChainActor'
| 'transportEquipment'
| 'departureTransportMeans'
| 'countryOfRoutingOfConsignment'
| 'activeBorderTransportMeans'
| 'previousDocument'
| 'supportingDocument'
| 'transportDocument'
| 'additionalReference'
| 'additionalInformation'
| 'houseConsignment'
>

interface FormFieldActionHelperInput<T> {
  name: ArrayScope
  blankItem: T
}

export function sortBySequenceNumber(a: { sequenceNumber: number | null }, b: { sequenceNumber: number | null }) {
  // eslint-disable-next-line max-len, no-nested-ternary
  return a.sequenceNumber !== null && b.sequenceNumber !== null ? (a.sequenceNumber < b.sequenceNumber) ? -1 : ((a.sequenceNumber > b.sequenceNumber) ? 1 : 0) : 0
}

// When using useFieldArray id: number will be replaced with a UUID key
export type FieldItem<I> = Record<'id', string> & Omit<I, 'id'>

interface FormFieldActionHelperOutput<T> {
  addAction: (...modifiedBlankItem: T[]) => void
  removeAction: (
    removeIndex: number,
    removedItem: FieldItem<T>
  ) => void
  moveAction: (fromIndex: number, toIndex: number) => void
  getFields: () => FieldItem<T>[]
  arrayRule: RuleResult
}

export default function useFieldArrayActionHelper<T extends DeclarationFormSubtype>({
  name,
  blankItem,
}: FormFieldActionHelperInput<T>): FormFieldActionHelperOutput<T> {
  const { control, getValues } = useFormContext<DeclarationForm>()
  type FieldArrayItemForScope = Extract<ArrayScope, typeof name>
  const {
    fields, append, update, remove, move,
  } = useFieldArray<DeclarationForm, FieldArrayItemForScope, 'id'>({
    control,
    name,
  })
  const { fieldRules } = useContext(TransitOperationContext)
  const arrayRule = useMemo(() => fieldRules.find((fieldRule) => fieldRule.path === name)?.ruleResult ?? 'OPTIONAL', [fieldRules])

  /*
  * Data within should not be used for conditions and such, elements within are out of date. Use getValues
  */
  const getFields = () => (fields as unknown as FieldItem<T>[])

  const removeAction = (
    removeIndex: number,
    removedItem: FieldItem<T>,
  ) => {
    //   TODO instead of deep cloning just switch deleted boolean and increment sequences - reading with getValues is fast
    //     const itemPath = `houseConsignment.${houseConsignmentIndex}.consignmentItem.${index}` as const
    //     if (getValues(`${itemPath}.id`) !== null) {
    //       setValue(`${itemPath}.deleted`, true)
    //     } else {
    //       remove(index)
    //     }
    const unsavedDeletedItemIndexes: number[] = []
    getValues(name).forEach((item, index) => {
      if (!item.deleted && !(item.sequenceNumber < removedItem.sequenceNumber)) {
        const clone = structuredClone(item)

        if (clone.sequenceNumber === removedItem.sequenceNumber) {
          if (clone.id !== null) {
            update(index, {
              ...clone,
              deleted: true,
            })
          } else {
            unsavedDeletedItemIndexes.push(index)
          }
        } else {
          update(index, {
            ...clone,
            sequenceNumber: clone.sequenceNumber - 1,
          })
        }
      }
    })
    if (unsavedDeletedItemIndexes.length > 0) {
      remove(unsavedDeletedItemIndexes)
    }
  }
  const addAction = (...modifiedBlankItem: T[]) => {
    const addedItems = modifiedBlankItem.length ? modifiedBlankItem : [blankItem]
    const currentSequence = getFields().filter(excludeDeleted).length

    append(addedItems
      .map((item, index) => ({ ...structuredClone(item), sequenceNumber: currentSequence + index })))
  }

  const moveAction = (fromIndex: number, toIndex: number) => {
    const startIndex = (fromIndex < toIndex ? fromIndex : toIndex)
    let { sequenceNumber } = getValues(name).at(startIndex) as T

    move(fromIndex, toIndex)

    getValues(name).forEach((item, index) => {
      if (!item.deleted && startIndex <= index) {
        update(index, {
          ...structuredClone(item),
          sequenceNumber,
        })

        sequenceNumber += 1
      }
    })
  }

  return {
    addAction,
    removeAction,
    moveAction,
    getFields,
    arrayRule,
  }
}
