import React from 'react'
import { DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarTransportDocuments from './TransitSidebarTransportDocuments'

export interface TransitSidebarConsignmentItemTransportDocumentsProps {
  consignmentItemId: number | null
  transportDocuments: DocumentResponse[]
}

function TransitSidebarConsignmentItemTransportDocuments(props: TransitSidebarConsignmentItemTransportDocumentsProps) {
  const {
    consignmentItemId,
    transportDocuments,
  } = props

  const itemTransportDocuments = transportDocuments
    .filter((transportDocument) => transportDocument.consignmentItemId === consignmentItemId)
    .sort(sortBySequenceNumber)

  if (itemTransportDocuments.length === 0) {
    return null
  }

  return (
    <div className="col-6 p-2">
      <TransitSidebarTransportDocuments transportDocuments={itemTransportDocuments} />
    </div>
  )
}

export default TransitSidebarConsignmentItemTransportDocuments
