/* eslint-disable no-case-declarations */
import { GroupBase } from 'react-select/dist/declarations/src/types'
import { ISelectOption } from 'types/IClassifier'
import { ConsignmentItem } from '../../../form/schemas/consignmentItemSchema'
import {
  blankConsignee,
  blankConsignmentItem,
  blankConsignor,
  blankHouseConsignmentType,
  blankPackaging,
  blankPreviousDocument,
  blankSupportingDocument,
  blankTransportDocument,
} from '../../../form'
import { hasText } from '../../../../common/utils/common-util'
import mapPackaging from './packaging'
import mapPreviousDocument from './previousDocument'
import mapSupportingDocument from './supportingDocument'
import mapTransportDocument from './transportDocument'
import { sortBySequenceNumber } from '../../../services/useFieldArrayActionHelper'
import { COUNTRY_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import ParseCsvRowResult from './parseCsvRowResult'
import mapConsignee from './consignee'
import mapConsignor from './consignor'

const ConsignmentItemObjectsFields = ['packaging', 'previousDocument', 'supportingDocument'] as const
type ConsignmentItemObjectField = typeof ConsignmentItemObjectsFields[number]
type ConsignmentItemObjects = Pick<ConsignmentItem, ConsignmentItemObjectField>

const ConsignmentItemPrimitiveFields = ['id', 'deleted', 'sequenceNumber', 'declarationType',
  'countryOfDestination', 'countryOfDispatch', 'referenceNumberUCR', 'commodityDescriptionOfGoods', 'declarationGoodsItemNumber',
  'commodityHarmonizedSystemSubHeadingCode', 'commodityCombinedNomenclatureCode', 'goodsMeasureGrossMass', 'goodsMeasureNetMass',
  'price', 'vatRate', 'dutyRate', 'excise', 'dutyAmount',
] as const
type ConsignmentItemPrimitiveField = typeof ConsignmentItemPrimitiveFields[number]
type ConsignmentItemPrimitives = Pick<ConsignmentItem, ConsignmentItemPrimitiveField>

export type CustomColumnMap = Map<string, string | undefined | null>

function isConsignmentItemObjectField(header: string): header is ConsignmentItemObjectField {
  return ConsignmentItemObjectsFields.includes(header as ConsignmentItemObjectField)
}

function isConsignmentItemPrimitiveField(header: string): header is ConsignmentItemPrimitiveField {
  return ConsignmentItemPrimitiveFields.includes(header as ConsignmentItemPrimitiveField)
}

function isHouseConsignmentPrimitiveField(header: string): boolean {
  return ['sequenceNumber', 'grossMass', 'countryOfDispatch', 'countryOfDestination'].includes(header)
}

function isHouseConsignmentObjectField(header: string): boolean {
  return ['consignee', 'consignor', 'previousDocument', 'transportDocument', 'supportingDocument'].includes(header)
}

function isTraderObjectField(header: string): boolean {
  return ['consignee', 'consignor'].includes(header)
}

function isConsignorContactPersonObjectField(objectField: string, objectSubField: string): boolean {
  return objectField === 'consignor' && objectSubField === 'contactPerson'
}

function isTransportDocumentObjectField(header: string): boolean {
  return header === 'transportDocument'
}

const HOUSE_CONSIGNMENT_OBJECT_PREFIX = 'h'
const CONSIGNOR_CONTACT_PERSON_OBJECT_FIELD_NAME = 'contactPerson'

export const BLACKLIST_KEYS = [
  'id',
  'sequenceNumber',
  'target',
  'deleted',
  'declarationGoodsItemNumber',
  'dangerousGoods',
  'additionalSupplyChainActor',
  'previousDocument',
  'supportingDocument',
  'transportDocument',
  'additionalReference',
  'additionalInformation',
  'packaging',
  'address',
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_id`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_consignmentItem`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_securityIndicatorFromExportDeclaration`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_transportChargesMethodOfPayment`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_additionalSupplyChainActor`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_departureTransportMeans`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_additionalReference`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_additionalInformation`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_previousDocument_id`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_previousDocument_sequenceNumber`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_previousDocument_files`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_supportingDocument_id`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_supportingDocument_files`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_transportDocument_id`,
  `${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_transportDocument_files`,
  `${CONSIGNOR_CONTACT_PERSON_OBJECT_FIELD_NAME}_id`,
  `${CONSIGNOR_CONTACT_PERSON_OBJECT_FIELD_NAME}_deleted`,

]
export const ALL_KEYS = new Map([
  [HOUSE_CONSIGNMENT_OBJECT_PREFIX, Object.keys(blankHouseConsignmentType)],
  [`${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_previousDocument`, Object.keys(blankPreviousDocument)],
  [`${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_supportingDocument`, Object.keys(blankSupportingDocument)],
  [`${HOUSE_CONSIGNMENT_OBJECT_PREFIX}_transportDocument`, Object.keys(blankTransportDocument)],
  ['consignmentItem', Object.keys(blankConsignmentItem)],
  // E1301 ['dangerousGoods', Object.keys(blankDangerousGoods)],
  // TODO: not used
  // ['additionalSupplyChainActor', Object.keys(blankAdditionalSupplyChainActor)],
  ['previousDocument', Object.keys(blankPreviousDocument)],
  ['transportDocument', Object.keys(blankTransportDocument)],
  ['supportingDocument', Object.keys(blankSupportingDocument)],
  // TODO: not used
  // ['additionalReference', Object.keys(blankAdditionalReference)],
  // ['additionalInformation', Object.keys(blankAdditionalInformation)],
  ['packaging', Object.keys(blankPackaging)],
  ['consignor', [
    ...Object.keys(blankConsignor),
    ...Object.keys(blankConsignor.address ?? {}),
    ...Object.keys(blankConsignor.contactPerson ?? {}).map((key) => `${CONSIGNOR_CONTACT_PERSON_OBJECT_FIELD_NAME}_${key}`),
  ]],
  ['consignee', [...Object.keys(blankConsignee), ...Object.keys(blankConsignee.address ?? {})]],
])

export const COLUMN_KEY_OPTIONS: Array<GroupBase<ISelectOption>> = []
ALL_KEYS.forEach((fields, key) => {
  const options = fields
    .map((field) => {
      let value = `${key}_${field}`
      if (key === 'consignmentItem') {
        value = field
      }
      return { label: `${field}`, value }
    }).filter((option) => !BLACKLIST_KEYS.includes(option.value))

  COLUMN_KEY_OPTIONS.push(
    {
      label: key,
      options,
    },
  )
})

export function isKnownColumnKey(key: string) {
  return COLUMN_KEY_OPTIONS
    .flatMap((group) => group.options)
    .some((option) => option.value === key)
}

const blankParseRowResult: ParseCsvRowResult = {
  consignmentItem: blankConsignmentItem,
  grossMass: 0,
  sequenceNumber: 1,
  consignee: blankConsignee,
  consignor: blankConsignor,
  countryOfDispatch: '',
  countryOfDestination: '',
  previousDocument: [],
  transportDocument: [],
  supportingDocument: [],
}

interface ParseCsvRowProps {
  headerFields: readonly string[]
  rowFields: string[]
  index: number
  customColumnMap: CustomColumnMap
}

export function parseCsvRow(props: ParseCsvRowProps): Promise<ParseCsvRowResult> {
  return new Promise((resolve, reject) => {
    const {
      headerFields,
      rowFields,
      index,
      customColumnMap,
    } = props

    if (headerFields.length !== rowFields.length) {
      reject(Error('ROW_COLUMN_COUNT_MISMATCH', { cause: { 0: index + 1, 1: headerFields.length, 2: rowFields.length } }))

      return
    }

    const newImportedItem: ConsignmentItem = structuredClone<ConsignmentItem>(blankConsignmentItem)
    newImportedItem.sequenceNumber = index // initial row index for errors, later overwritten to adjust the offset from existing items

    const parseResult: ParseCsvRowResult = structuredClone<ParseCsvRowResult>(blankParseRowResult)

    headerFields.forEach((header, headerIndex) => {
      const headerMappingToItem = customColumnMap.get(header)

      if (headerMappingToItem === null || headerMappingToItem === undefined) {
        return
      }

      const originalColumnHeader: string = headerMappingToItem
      const cellValue = rowFields.at(headerIndex)?.trim() ?? ''
      const consignmentItemField = customColumnMap.get(header) ?? originalColumnHeader
      const consignmentItemFieldParts = consignmentItemField.split('_')
      const consignmentItemObjectField = consignmentItemFieldParts[0]
      const consignmentItemObjectSubfield = consignmentItemFieldParts[1]

      if (isConsignmentItemPrimitiveField(consignmentItemField)) {
        switch (consignmentItemField) {
          case 'deleted':
            newImportedItem[consignmentItemField] = false
            break
          case 'goodsMeasureGrossMass':
          case 'goodsMeasureNetMass':
          case 'price':
          case 'vatRate':
          case 'dutyRate':
          case 'dutyAmount':
          case 'excise':
            if (hasText(cellValue)) {
              newImportedItem[consignmentItemField] = Number.isNaN(cellValue) ? 0 : Number(cellValue.replaceAll(',', '.'))
            } else {
              newImportedItem[consignmentItemField] = 0
            }
            break
          case 'declarationType':
          case 'referenceNumberUCR':
          case 'commodityDescriptionOfGoods':
            newImportedItem[consignmentItemField] = cellValue
            break
          case 'commodityHarmonizedSystemSubHeadingCode':
            let hsCode = cellValue.replaceAll(' ', '')
            hsCode = hsCode.length > 6 ? hsCode.substring(0, 6) : hsCode
            newImportedItem[consignmentItemField] = hsCode
            break
          case 'commodityCombinedNomenclatureCode':
            let cnCode = cellValue.replaceAll(' ', '')
            cnCode = cnCode.length > 6 && cnCode.length <= 8 ? cnCode.substring(6, 8) : cnCode
            newImportedItem[consignmentItemField] = cnCode
            break
          case 'countryOfDestination':
          case 'countryOfDispatch':
            newImportedItem[consignmentItemField] = hasText(cellValue) ? COUNTRY_CODE_PREFIX + cellValue : ''
            break
          default:
            throw Error('Unknown field')
        }
      } else if (consignmentItemFieldParts.length > 0 && isConsignmentItemObjectField(consignmentItemObjectField)) {
        const reoccurrenceIndicator = Number(header.split('_')[2] ?? NaN) ?? 1
        const newSequenceNumber = reoccurrenceIndicator - 1
        switch (consignmentItemObjectField) {
          case 'packaging':
            mapPackaging(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          case 'previousDocument':
            mapPreviousDocument(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          case 'supportingDocument':
            mapSupportingDocument(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          default:
            throw Error(`Unknown ${consignmentItemObjectField}`)
        }
      } else if (isTraderObjectField(consignmentItemObjectField)) {
        const objectSubfield = consignmentItemField.replace(`${consignmentItemObjectField}_`, '')
        switch (consignmentItemObjectField) {
          case 'consignee':
            mapConsignee(
              parseResult[consignmentItemObjectField],
              objectSubfield,
              cellValue,
            )
            break
          case 'consignor':
            mapConsignor(
              parseResult[consignmentItemObjectField],
              objectSubfield,
              cellValue,
            )
            break
          default:
            throw Error(`Unknown trader field ${consignmentItemObjectField}`)
        }
      } else if (isTransportDocumentObjectField(consignmentItemObjectField)) {
        const reoccurrenceIndicator = Number(header.split('_')[2] ?? NaN) ?? 1
        const newSequenceNumber = reoccurrenceIndicator - 1
        switch (consignmentItemObjectField) {
          case 'transportDocument':
            mapTransportDocument(
              parseResult[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          default:
            throw Error(`Unknown transport document field ${consignmentItemObjectField}`)
        }
      } else if (consignmentItemFieldParts[0] === HOUSE_CONSIGNMENT_OBJECT_PREFIX) {
        const houseConsignmentObjectField = consignmentItemFieldParts[1]
        const houseConsignmentSubField = consignmentItemFieldParts[2]
        if (isHouseConsignmentPrimitiveField(houseConsignmentObjectField)) {
          switch (houseConsignmentObjectField) {
            case 'sequenceNumber':
              parseResult[houseConsignmentObjectField] = Number(cellValue)
              break
            case 'grossMass':
              if (hasText(cellValue)) {
                parseResult[houseConsignmentObjectField] = Number.isNaN(cellValue) ? 0 : Number(cellValue.replaceAll(',', '.'))
              } else {
                parseResult[houseConsignmentObjectField] = 0
              }
              break
            default:
              throw Error(`Unknown house consignment field ${houseConsignmentObjectField}`)
          }
        } else if (isHouseConsignmentObjectField(houseConsignmentObjectField)) {
          const reoccurrenceIndicator = Number(header.split('_')[3] ?? NaN) ?? 1
          const newSequenceNumber = reoccurrenceIndicator - 1

          switch (houseConsignmentObjectField) {
            case 'previousDocument':
              mapPreviousDocument(
                parseResult[houseConsignmentObjectField],
                houseConsignmentSubField,
                cellValue,
                newSequenceNumber,
              )
              break
            case 'transportDocument':
              mapTransportDocument(
                parseResult[houseConsignmentObjectField],
                houseConsignmentSubField,
                cellValue,
                newSequenceNumber,
              )
              break
            case 'supportingDocument':
              mapSupportingDocument(
                parseResult[houseConsignmentObjectField],
                houseConsignmentSubField,
                cellValue,
                newSequenceNumber,
              )
              break
            default:
              throw Error(`Unknown house consignment field ${houseConsignmentObjectField}`)
          }
        }
      }
    })

    newImportedItem.additionalReference.sort(sortBySequenceNumber)
    newImportedItem.additionalInformation.sort(sortBySequenceNumber)
    newImportedItem.additionalSupplyChainActor.sort(sortBySequenceNumber)
    newImportedItem.packaging.sort(sortBySequenceNumber)
    newImportedItem.previousDocument.sort(sortBySequenceNumber)
    newImportedItem.supportingDocument.sort(sortBySequenceNumber)
    newImportedItem.transportDocument.sort(sortBySequenceNumber)

    parseResult.consignmentItem = newImportedItem

    resolve(parseResult)
  })
}

export default parseCsvRow
