import { useObservableState } from 'observable-hooks'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import CustomerTableService from 'routes/CustomerTable/services/customer-table.service'
import { Observable } from 'rxjs'
import { debounceTime, switchMap, tap } from 'rxjs/operators'
import { extractPageResponseContent } from '../../../../helpers'
import AuthenticationService from '../../../../services/auth.service'
import { PageFilterRequest, PageResponse, TableFilter } from '../../../../types/PageResponse'
// eslint-disable-next-line import/no-cycle
import { CustomerCountsData } from '../../index'
import { CustomerPageResponse } from '../../services/customer-table.service'

export interface CustomersPageProps {
  pageIndex: number
  pageSize: number
  gotoPage: (index: number) => void
}

export function useCustomersPage(props: CustomersPageProps) {
  const {
    gotoPage,
  } = props
  const [data, setData] = useState<Array<CustomerPageResponse>>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(props.pageSize)
  const [currentPageIndex, setCurrentPageIndex] = useState(props.pageIndex)
  const [customerCountsData, setCustomerCountsData] = useState<Array<CustomerCountsData>>([] as Array<CustomerCountsData>)

  const typeFilterState = useState('')
  const filtersState = useState<Array<TableFilter>>([])
  const [filters] = filtersState
  const [typeFilter, setTypeFilter] = typeFilterState

  const fetchPage = (requestEvent: PageFilterRequest) => {
    AuthenticationService.eagerlyLogoutWhenTokenExpireDateImpending()
    return CustomerTableService.getCustomerPage(requestEvent)
  }

  const invokeFetch = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    invokeFetchPage({
      pageIndex: currentPageIndex,
      pageSize: currentPageSize,
      filters,
      typeFilter,
    })
  }

  const refreshPage = () => {
    invokeFetch()
  }
  const [pageResponse, invokeFetchPage] = useObservableState(
    (event$: Observable<PageFilterRequest>) => event$.pipe(
      debounceTime(200),
      tap(() => setLoading(true)),
      switchMap((requestEvent) => fetchPage(requestEvent)),
    ),
    () => ({} as PageResponse<CustomerPageResponse>),
  )

  useEffect(() => {
    setData([])
    if (currentPageIndex === 0) {
      invokeFetch()
    }
    gotoPage(0)
  }, [filters, typeFilter])

  const loadCountMetaData = async (customers: Array<CustomerPageResponse>) => {
    for (const customer of customers) {
      if (customer.external === true) {
        // eslint-disable-next-line no-continue
        continue
      }
      const customerId = customer.id
      const usersCountPromise: Promise<number> = CustomerTableService.getCustomerUsersCount(customerId)
      const declarationsCountPromise: Promise<number> = CustomerTableService.getCustomerDeclarationsCount(customerId)

      // eslint-disable-next-line no-await-in-loop
      const metaData = await Promise.all([usersCountPromise, declarationsCountPromise])
      setCustomerCountsData((currentArray) => [
        {
          customerId,
          usersCount: metaData[0],
          declarationsCount: metaData[1],
          loading: false,
        },
        ...currentArray.filter((item) => item.customerId !== customerId),
      ])
    }
  }
  useEffect(() => {
    setTotalPages(pageResponse.totalPages)
    setData(extractPageResponseContent(pageResponse, isMobile))
    if (pageResponse.content) {
      setCustomerCountsData(pageResponse.content
        .filter((customer) => customer.external !== true)
        .map((customer) => ({
          customerId: customer.id,
          loading: true,
        })))
      setTimeout(() => {
        loadCountMetaData(pageResponse.content)
      }, 100)
    }
    setLoading(false)
  }, [pageResponse])

  useEffect(() => {
    invokeFetch()
  }, [currentPageSize, currentPageIndex])

  return {
    data,
    setData,
    totalPages,
    setTotalPages,
    filtersState,
    typeFilter,
    setTypeFilter,
    refreshPage,
    loading,
    setCurrentPageSize,
    setCurrentPageIndex,
    customerCountsData,
  }
}
