import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { TransportDocument } from '../../../form/schemas/documentSchemas'
import { blankTransportDocument } from '../../../form'
import { TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { hasText } from '../../../../common/utils/common-util'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted'

function isTransportDocumentField(field: string): field is Exclude<ObjectKeys<TransportDocument>, NotImportableFields> {
  return field in blankTransportDocument
}

export default function parseTransportDocument(
  newImportedItemObject: TransportDocument[],
  consignmentItemObjectSubfield: string,
  cellValue: string,
  newSequenceNumber: number,
) {
  if (!isTransportDocumentField(consignmentItemObjectSubfield)) {
    return
  }

  let transportDocument = newImportedItemObject
    .find((doc) => doc.sequenceNumber === newSequenceNumber)

  if (transportDocument === undefined) {
    transportDocument = {
      ...blankTransportDocument,
      sequenceNumber: newSequenceNumber,
    }
    newImportedItemObject.push(transportDocument)
  }

  switch (consignmentItemObjectSubfield) {
    case 'documentType':
      transportDocument[consignmentItemObjectSubfield] = hasText(cellValue) ? TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX + cellValue : ''
      break
    case 'referenceNumber':
      transportDocument[consignmentItemObjectSubfield] = cellValue
      break
    default:
      throw Error(`Unknown transport document field ${consignmentItemObjectSubfield}`)
  }
}
