import React from 'react'
import { DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarPreviousDocuments from './TransitSidebarPreviousDocuments'

export interface TransitSidebarHouseConsignmentPreviousDocumentsProps {
  houseConsignmentId: number | null
  previousDocuments: DocumentResponse[]
}

function TransitSidebarHouseConsignmentPreviousDocuments({
  houseConsignmentId,
  previousDocuments,
}: TransitSidebarHouseConsignmentPreviousDocumentsProps) {
  const houseConsignmentPreviousDocuments = previousDocuments
    .filter((transportDocument) => transportDocument.houseConsignmentId === houseConsignmentId)
    .sort(sortBySequenceNumber)

  if (houseConsignmentPreviousDocuments.length === 0) {
    return null
  }

  return (
    <div className="p-2">
      <TransitSidebarPreviousDocuments previousDocuments={houseConsignmentPreviousDocuments} />
    </div>
  )
}
export default TransitSidebarHouseConsignmentPreviousDocuments
