import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { CreateHouseConsignmentRequest, HouseConsignmentResponse, UpdateHouseConsignmentRequest } from '../../../common/models'
import {
  parseAdditionalSupplyChainActorResponse, parseDepartureTransportMeansResponse,
  toCreateOrUpdateAdditionalSupplyChainActorRequest, toCreateOrUpdateDepartureTransportMeansRequest,
} from '../useConsignment/mapper'
import {
  excludeDeleted, nonNullConsignee, nonNullConsignorIgnoringContactPerson,
} from '../../../common/utils/common-util'
import { parseConsigneeConsignmentDetailResponse, parseConsignorConsignmentDetailResponse } from '../useConsignmentDetail/mapper'

export function toCreateHouseConsignmentRequest(
  houseConsignment: HouseConsignmentType,
  consignmentId: number | null,
): CreateHouseConsignmentRequest {
  if (consignmentId === null) throw Error('Missing consignmentId')

  return {
    consignmentId,
    countryOfDispatch: houseConsignment.countryOfDispatch,
    countryOfDestination: houseConsignment.countryOfDestination,
    additionalSupplyChainActors: houseConsignment.additionalSupplyChainActor
      .filter(excludeDeleted)
      .map(toCreateOrUpdateAdditionalSupplyChainActorRequest),
    consigneeId: houseConsignment.consignee !== null && nonNullConsignee(houseConsignment.consignee)
      ? (houseConsignment.consignee.id ?? null)
      : null,
    consignorId: houseConsignment.consignor !== null && nonNullConsignorIgnoringContactPerson(houseConsignment.consignor)
      ? (houseConsignment.consignor.id ?? null)
      : null,
    departureTransportMeans: [],
    grossMass: houseConsignment.grossMass,
    referenceNumberUcr: houseConsignment.referenceNumberUCR,
    sequenceNumber: houseConsignment.sequenceNumber,
  }
}
export function toUpdateHouseConsignmentRequest(
  houseConsignment: HouseConsignmentType,
): UpdateHouseConsignmentRequest {
  if (houseConsignment.id === null) throw Error('Missing house consignment id')

  return {
    countryOfDispatch: houseConsignment.countryOfDispatch,
    countryOfDestination: houseConsignment.countryOfDestination,
    additionalSupplyChainActors: houseConsignment.additionalSupplyChainActor
      .filter(excludeDeleted)
      .map(toCreateOrUpdateAdditionalSupplyChainActorRequest),
    consigneeId: houseConsignment.consignee?.id && nonNullConsignee(houseConsignment.consignee)
      ? houseConsignment.consignee.id
      : undefined,
    consignorId: houseConsignment.consignor?.id && nonNullConsignorIgnoringContactPerson(houseConsignment.consignor)
      ? houseConsignment.consignor.id
      : undefined,
    departureTransportMeans: houseConsignment.departureTransportMeans.filter(excludeDeleted)
      .map(toCreateOrUpdateDepartureTransportMeansRequest),
    grossMass: houseConsignment.grossMass,
    id: houseConsignment.id,
    referenceNumberUcr: houseConsignment.referenceNumberUCR,
    sequenceNumber: houseConsignment.sequenceNumber,
  }
}

export type TransitionalHouseConsignment =
  'id'
  | 'additionalSupplyChainActor'
  | 'grossMass'
  | 'referenceNumberUCR'
  | 'sequenceNumber'
  | 'consignee'
  | 'consignor'
  | 'countryOfDestination'
  | 'countryOfDispatch'
  | 'departureTransportMeans'

export function parseHouseConsignmentResponse(
  response: HouseConsignmentResponse,
  index: number,
): Pick<HouseConsignmentType, TransitionalHouseConsignment> {
  return {
    id: response.id,
    countryOfDestination: response?.countryOfDestination ?? '',
    countryOfDispatch: response?.countryOfDispatch ?? '',
    grossMass: response.grossMass ?? 0,
    sequenceNumber: response.sequenceNumber ?? index,
    referenceNumberUCR: response.referenceNumberUcr ?? '',
    consignee: parseConsigneeConsignmentDetailResponse(response?.consignee),
    consignor: parseConsignorConsignmentDetailResponse(response?.consignor),
    additionalSupplyChainActor: response?.additionalSupplyChainActors.map(parseAdditionalSupplyChainActorResponse) ?? [],
    departureTransportMeans: response?.departureTransportMeans.map(parseDepartureTransportMeansResponse) ?? [],
  }
}
