import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../../common/utils/classifier-util'
import {
  ANDORRA, BULGARIA, CL112_CTC_COUNTRIES,
} from '../../../../common/utils/ncts-constants'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { excludeDeleted } from '../../../../common/utils/common-util'

const C0030Validator = {
  validateOfficeOfTransitRequirement: (formData: DeclarationForm): RuleResult => {
    const {
      declarationType,
      departureCustomsOffice,
      destinationCustomsOffice,
      countryOfRoutingOfConsignment,
      houseConsignment,
    } = formData

    if (declarationType === 'TIR') {
      return 'NOT_ALLOWED'
    }

    const departureOfficeCountry = removePrefix(departureCustomsOffice, 'DEPARTURE_OFFICE_', 2)
    const destinationOfficeCountry = removePrefix(destinationCustomsOffice, 'DESTINATION_OFFICE_', 2)

    if (departureOfficeCountry === BULGARIA && destinationOfficeCountry === BULGARIA) return 'NOT_ALLOWED'

    if (departureOfficeCountry !== null && destinationOfficeCountry !== null && departureOfficeCountry === destinationOfficeCountry
      && CL112_CTC_COUNTRIES.includes(departureOfficeCountry) && CL112_CTC_COUNTRIES.includes(destinationOfficeCountry)) {
      return 'OPTIONAL'
    }
    if (declarationType === 'T2') {
      return 'REQUIRED'
    }

    if (declarationType === 'T' && houseConsignment.filter(excludeDeleted)
      .some((house) => house.consignmentItem.filter(excludeDeleted)
        .some((item) => item.declarationType === 'T2'))) {
      return 'REQUIRED'
    }

    if ((departureOfficeCountry !== null && CL112_CTC_COUNTRIES.includes(departureOfficeCountry))
      || (destinationOfficeCountry !== null && CL112_CTC_COUNTRIES.includes(destinationOfficeCountry))) {
      return 'REQUIRED'
    }

    if (countryOfRoutingOfConsignment.some((routeCountry) => CL112_CTC_COUNTRIES.includes(
      removePrefix(routeCountry.country, 'COUNTRY_') ?? '',
    ))) {
      return 'REQUIRED'
    }

    if (ANDORRA === departureOfficeCountry || ANDORRA === destinationOfficeCountry) {
      return 'REQUIRED'
    }
    // FIXME OFFICE OF EXIT not implemented, unable to check

    return 'OPTIONAL'
  },

} as const

export default C0030Validator
