import { toast } from 'react-toastify'
import { UseDeclarationFormReturn } from '../../form'
import { attachNewDocumentsWithSavedIds, findDocumentIndex, UseDocumentCommonActionsHelper } from './common'
import { DocumentRequestTypeEnum, DocumentResponse, DocumentResponseTypeEnum } from '../../../common/models'
import { DocumentRequestWithFiles, toConsignmentItemPreviousDocumentRequest, toConsignmentItemSupportingDocumentRequest } from './mapper'
import { excludeDeleted } from '../../../common/utils/common-util'
import { PreviousDocument, SupportingDocument } from '../../form/schemas/documentSchemas'

function useConsignmentItemDocument(form: UseDeclarationFormReturn, actionsHelper: UseDocumentCommonActionsHelper) {
  const {
    setValue,
    getValues,
    formState: {
      isValid,
    },
    trigger,
  } = form
  const {
    handleDocuments,
    handleFileUploads,
    archiveDeletedDocuments,
  } = actionsHelper

  const refreshConsignmentItemDocumentIds = (documentResponse: DocumentResponse) => {
    if (documentResponse == null || documentResponse.sequenceNumber == null) {
      return
    }

    const houseConsignments = getValues('houseConsignment')
    for (let i = 0; i < houseConsignments.length; i++) {
      const houseConsignment = houseConsignments[i]
      if (houseConsignment.deleted === true) {
        continue
      }
      const consignmentItems = houseConsignment.consignmentItem
      for (let j = 0; j < consignmentItems.length; j++) {
        const consignmentItem = consignmentItems[j]
        if (consignmentItem.deleted === true || documentResponse.consignmentItemId !== consignmentItem.id) {
          continue
        }

        switch (documentResponse.type) {
          case DocumentResponseTypeEnum.PREVIOUS: {
            const previousDocumentIndex = findDocumentIndex(documentResponse.sequenceNumber, consignmentItem.previousDocument)
            setValue(`houseConsignment.${i}.consignmentItem.${j}.previousDocument.${previousDocumentIndex}.id`, documentResponse.id)
            break
          }
          case DocumentResponseTypeEnum.SUPPORTING: {
            const supportingDocumentIndex = findDocumentIndex(documentResponse.sequenceNumber, consignmentItem.supportingDocument)
            setValue(`houseConsignment.${i}.consignmentItem.${j}.supportingDocument.${supportingDocumentIndex}.id`, documentResponse.id)
            break
          }
          default:
            break
        }
      }
    }
  }

  function getConsignmentItemDocumentsRequests(transitId: number) {
    const createConsignmentItemDocumentRequests: DocumentRequestWithFiles[] = []
    const updateConsignmentItemDocumentRequests: DocumentRequestWithFiles[] = []

    const houseConsignments = getValues('houseConsignment').filter(excludeDeleted)
    for (const houseConsignment of houseConsignments) {
      const consignmentItems = houseConsignment.consignmentItem.filter(excludeDeleted)
      for (const consignmentItem of consignmentItems) {
        const consignmentItemId = consignmentItem.id
        if (consignmentItemId === null) {
          throw Error('Missing required consignment item id for documents')
        }

        const previousDocuments: PreviousDocument[] = consignmentItem.previousDocument.filter(excludeDeleted)
        const supportingDocuments: SupportingDocument[] = consignmentItem.supportingDocument.filter(excludeDeleted)

        for (const previousDocument of previousDocuments) {
          const request = toConsignmentItemPreviousDocumentRequest(previousDocument, transitId, consignmentItemId)
          if (previousDocument.id === null) {
            createConsignmentItemDocumentRequests.push(request)
          } else {
            updateConsignmentItemDocumentRequests.push(request)
          }
        }

        for (const supportingDocument of supportingDocuments) {
          const request = toConsignmentItemSupportingDocumentRequest(supportingDocument, transitId, consignmentItemId)
          if (supportingDocument.id === null) {
            createConsignmentItemDocumentRequests.push(request)
          } else {
            updateConsignmentItemDocumentRequests.push(request)
          }
        }
      }
    }

    return {
      createConsignmentItemDocumentRequests,
      updateConsignmentItemDocumentRequests,
    }
  }

  const archiveConsignmentItemDocuments = async () => {
    const houseConsignments = getValues('houseConsignment')

    const asyncRequests: Array<Promise<void>> = []

    for (const houseConsignment of houseConsignments) {
      for (const consignmentItem of houseConsignment.consignmentItem) {
        asyncRequests.push(...archiveDeletedDocuments(consignmentItem.previousDocument, DocumentRequestTypeEnum.PREVIOUS))
        asyncRequests.push(...archiveDeletedDocuments(consignmentItem.supportingDocument, DocumentRequestTypeEnum.SUPPORTING))
      }
    }

    const archivedDocumentPromises = await Promise.allSettled(asyncRequests)
    if (archivedDocumentPromises.some((result) => result.status === 'rejected')) {
      toast.warn('Some consignment item documents deletion failed ')
    }
  }

  const createOrUpdateConsignmentItemDocuments = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) throw Error('Missing required transit operation id for documents')

    const {
      createConsignmentItemDocumentRequests,
      updateConsignmentItemDocumentRequests,
    } = getConsignmentItemDocumentsRequests(currentTransitOperationId)

    const responses = await handleDocuments(createConsignmentItemDocumentRequests, updateConsignmentItemDocumentRequests)

    for (const response of responses) {
      refreshConsignmentItemDocumentIds(response)
    }
    const createRequestsUpdatedWithSavedIds = attachNewDocumentsWithSavedIds(responses, createConsignmentItemDocumentRequests)

    await handleFileUploads(createRequestsUpdatedWithSavedIds, updateConsignmentItemDocumentRequests)
  }

  return {
    createOrUpdateConsignmentItemDocuments,
    archiveConsignmentItemDocuments,
  }
}

export default useConsignmentItemDocument
