import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentResponse, HouseConsignmentResponse } from '../../models'
import TransitSidebarHouseConsignmentTransportDocuments from './documents/TransitSidebarHouseConsignmentTransportDocuments'
import TransitSidebarHouseConsignmentPreviousDocuments from './documents/TransitSidebarHouseConsignmentPreviousDocuments'
import TransitSidebarHouseConsignmentSupportingDocuments from './documents/TransitSidebarHouseConsignmentSupportingDocuments'
import TransitSidebarGoodsItems from './TransitSidebarGoodsItems'
import TransitSidebarSection from './TransitSidebarSection'
import useConsignmentItemCountApi from '../../../TransitOperationEditor/hooks/useConsignmentItemCount/api'

interface TransitSidebarHouseConsignmentRowProps {
  transitOperationId: number
  houseConsignment: HouseConsignmentResponse
  isLoading: boolean
  initialOpenState: boolean
  previousDocuments: DocumentResponse[]
  transportDocuments: DocumentResponse[]
  supportingDocuments: DocumentResponse[]
}

function TransitSidebarHouseConsignmentRow({
  transitOperationId,
  houseConsignment,
  isLoading,
  initialOpenState,
  previousDocuments,
  transportDocuments,
  supportingDocuments,
}: TransitSidebarHouseConsignmentRowProps) {
  const { t } = useTranslation()

  const {
    id: houseConsignmentId,
  } = houseConsignment

  const [isItemOpen, setIsItemOpen] = useState<boolean>(initialOpenState)

  const ids = houseConsignment.id ? [houseConsignment.id] : []
  const [consignmentItemsCount, setConsignmentItemsCount] = useState<number | undefined>()
  const { fetchConsignmentItemsCount } = useConsignmentItemCountApi(ids, isLoading)

  useEffect(() => {
    setConsignmentItemsCount(fetchConsignmentItemsCount.filter((result) => result.data)
      .map((result) => Number(result.data))
      .reduce((sum, count) => sum + count, 0))
  }, [fetchConsignmentItemsCount.reduce((sum, query) => sum + query.dataUpdatedAt, 0)])

  return (
    <div className="col-12 row m-0 bg-body-tertiary border-bottom">
      <div
        key={`goods_item_row_header_${houseConsignmentId}`}
        className="heading m-0 pb-1 pt-1"
        data-bs-toggle="collapse"
        data-bs-target={`#${houseConsignmentId}`}
        aria-expanded={isItemOpen}
        aria-controls={`${houseConsignmentId}`}
        role="button"
        tabIndex={isItemOpen ? -1 : 0}
        onClick={() => setIsItemOpen(!isItemOpen)}
        onKeyDown={() => setIsItemOpen(!isItemOpen)}
      >
        <h4 className="col-11 d-flex">
          <span className="col-1">
            <span>{t('declaration.p5.sequenceNumber')}</span>
            <span className="px-1">
              {(houseConsignment.sequenceNumber ?? 0) + 1}
            </span>
          </span>
        </h4>
        <i className={`p-1 me-2 fa ${isItemOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </div>
      <div
        key={`house_consignment_row_data_${houseConsignmentId}`}
        className={`bg-white collapse ${isItemOpen ? 'show' : ''}`}
        id={`${houseConsignmentId}`}
      >
        <div className="d-flex flex-wrap">
          <div className="col-6">
            <TransitSidebarHouseConsignmentTransportDocuments
              houseConsignmentId={houseConsignmentId}
              transportDocuments={transportDocuments}
            />
          </div>
          <div className="col-6">
            <TransitSidebarHouseConsignmentPreviousDocuments
              houseConsignmentId={houseConsignmentId}
              previousDocuments={previousDocuments}
            />
            <TransitSidebarHouseConsignmentSupportingDocuments
              houseConsignmentId={houseConsignmentId}
              supportingDocuments={supportingDocuments}
            />
          </div>
        </div>
        <TransitSidebarSection
          columnId="goods"
          headingLabel={`${t('declaration.navbar.goods')} ${(consignmentItemsCount !== undefined ? `(${consignmentItemsCount})` : '')}`}
          initialSectionState={false}
          delayedOpenSection={consignmentItemsCount !== undefined && consignmentItemsCount < 5}
        >
          <TransitSidebarGoodsItems
            houseConsignmentIds={ids}
            transitOperationId={transitOperationId}
            previousDocuments={previousDocuments}
            transportDocuments={transportDocuments}
            supportingDocuments={supportingDocuments}
            isLoading={isLoading}
          />

        </TransitSidebarSection>
      </div>
    </div>

  )
}

export default TransitSidebarHouseConsignmentRow
