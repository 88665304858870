import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import TransitApiConfig from '../apiConfig'
import { CustomsOfficeOfTransitExitResponse } from '../../../common/models/customs-office-of-transit-exit-response'

const {
  baseUrl,
} = TransitApiConfig.paths.customsOfficeOfTransitExit

function useCustomsOfficeOfTransitExitApi() {
  const deleteCustomsOfficeOfTransitExit = useMutation({
    mutationFn: async (id: number) => (await axios.delete<CustomsOfficeOfTransitExitResponse>(`${baseUrl}/${id}`)).data,
  })

  return {
    deleteCustomsOfficeOfTransitExit,
  }
}

export default useCustomsOfficeOfTransitExitApi
