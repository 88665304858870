import { z } from 'zod'
import { AdditionalInformationSchema, AdditionalReferenceSchema, AdditionalSupplyChainActorSchema } from './commonConsignmentSchemas'
import { PreviousDocumentSchema, SupportingDocumentSchema, TransportDocumentSchema } from './documentSchemas'
import { ConsigneeSchema } from './tradersSchema'
import { withoutForbiddenCharacters } from '../refinements'
import { emptyNumberValueToNull } from './helpers'

export enum AmountUnit {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  SUPPLEMENTARY = 'SUPPLEMENTARY',
}

const DangerousGoodsSchema = z.object({
  id: z.number().nullable(),
  deleted: z.boolean().optional(),
  sequenceNumber: z.number().nonnegative(),
  unNumber: z.string(),
})
const PackagingSchema = z.object({
  id: z.number().nullable(),
  deleted: z.boolean().optional(),
  sequenceNumber: z.number().nonnegative(),
  typeOfPackages: z.string(),
  numberOfPackages: emptyNumberValueToNull(z.number().nullable()),
  shippingMarks: z.string(),
})

const ConsignmentItemSchema = z.object({
  id: z.number().nullable(),
  deleted: z.boolean().optional(),
  sequenceNumber: z.number().nonnegative(),
  declarationType: z.string().optional(), // TODO
  declarationGoodsItemNumber: z.number(),
  vatRate: z.number().or(z.nan()).nullable(),
  dutyRate: z.number().or(z.nan()).nullable(),
  dutyAmount: z.number().or(z.nan()).nullable(),
  dutyRateUnit: z.enum([
    AmountUnit.AMOUNT,
    AmountUnit.PERCENTAGE,
    AmountUnit.SUPPLEMENTARY,
  ]).nullable(),
  price: z.number().or(z.nan()).nullable(),
  initialPrice: z.number().or(z.nan()).nullable(),
  initialPriceCurrency: z.string().nullable(),
  excise: z.number().or(z.nan()).nullable(),
  countryOfDispatch: z.string(),
  countryOfDestination: z.string(),
  referenceNumberUCR: z.string(),
  commodityDescriptionOfGoods: z.string().superRefine(withoutForbiddenCharacters),
  commodityHarmonizedSystemSubHeadingCode: z.string(),
  commodityCombinedNomenclatureCode: z.string(),
  commodityCodeArchived: z.boolean().optional(),
  goodsMeasureGrossMass: z.number().nonnegative(),
  goodsMeasureNetMass: z.number().nonnegative(),
  consignee: ConsigneeSchema.nullable(),
  dangerousGoods: z.array(DangerousGoodsSchema),
  additionalSupplyChainActor: z.array(AdditionalSupplyChainActorSchema),
  packaging: z.array(PackagingSchema),
  previousDocument: z.array(PreviousDocumentSchema),
  supportingDocument: z.array(SupportingDocumentSchema),
  transportDocument: z.array(TransportDocumentSchema),
  additionalReference: z.array(AdditionalReferenceSchema),
  additionalInformation: z.array(AdditionalInformationSchema),
})

export type DangerousGoodsType = z.infer<typeof DangerousGoodsSchema>
export type PackagingType = z.infer<typeof PackagingSchema>
export type ConsignmentItem = z.infer<typeof ConsignmentItemSchema>

export default ConsignmentItemSchema
