import { useEffect } from 'react'
import { blankHouseConsignmentType, UseDeclarationFormReturn } from '../../form'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import useHouseConsignmentApi from './api'
import { parseHouseConsignmentResponse, toCreateHouseConsignmentRequest, toUpdateHouseConsignmentRequest } from './mapper'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { CreateHouseConsignmentRequest, HouseConsignmentResponse, UpdateHouseConsignmentRequest } from '../../../common/models'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'
import useConsignmentDetail from '../useConsignmentDetail'
import {
  excludeDeleted,
  isDeletedWithId, nonNullConsignee, nonNullConsignorIgnoringContactPerson,
} from '../../../common/utils/common-util'

function useHouseConsignment(form: UseDeclarationFormReturn) {
  const {
    trigger,
    getValues,
    setValue,
    formState: {
      isValid,
      isSubmitting,
    },
    reset,
  } = form
  const consignmentId: number | null = getValues('consignmentId')

  const {
    fetchHouseConsignments,
    postHouseConsignment,
    putHouseConsignment,
    putHouseConsignmentDraft,
    deleteHouseConsignment,
  } = useHouseConsignmentApi(consignmentId, isSubmitting)

  const { createHouseConsignmentTrader } = useConsignmentDetail(form)

  const populateFormHouseConsignments = () => {
    if (fetchHouseConsignments.isFetching || isSubmitting) {
      return
    }

    const responses = fetchHouseConsignments.data?.map(parseHouseConsignmentResponse)?.sort(sortBySequenceNumber) ?? []
    const formClone: DeclarationForm = structuredClone(getValues())

    reset({
      ...formClone,
      houseConsignment: responses.map((houseConsignmentResponse) => {
        const existing = formClone.houseConsignment.filter(excludeDeleted)
          .find((item) => (item.id === houseConsignmentResponse.id)
            || (item.sequenceNumber === houseConsignmentResponse.sequenceNumber))

        return {
          ...blankHouseConsignmentType,
          ...existing,
          ...houseConsignmentResponse,
        }
      }),
    })
  }
  useEffect(() => {
    populateFormHouseConsignments()
  }, [fetchHouseConsignments.data])

  const getHouseConsignment = async () => fetchHouseConsignments.data

  function refreshSavedIds(houseConsignments: HouseConsignmentType[], response: HouseConsignmentResponse[]) {
    houseConsignments.forEach((item, index) => {
      if (item.deleted) return
      const savedItem = response.find((responseItem) => responseItem.sequenceNumber === item.sequenceNumber)
      if (savedItem) setValue(`houseConsignment.${index}.id`, savedItem.id)
    })
  }

  async function createHouseConsignmentTraders(houseConsignments: HouseConsignmentType[], isDraft: boolean) {
    const houseConsignmentTraderPromises: Array<Promise<void>> = []
    houseConsignments.forEach((houseConsignment, index) => {
      if (houseConsignment.consignor && nonNullConsignorIgnoringContactPerson(houseConsignment.consignor)) {
        houseConsignmentTraderPromises.push(createHouseConsignmentTrader(isDraft, 'consignor', index))
      }
      if (houseConsignment.consignee && nonNullConsignee(houseConsignment.consignee)) {
        houseConsignmentTraderPromises.push(createHouseConsignmentTrader(isDraft, 'consignee', index))
      }
    })

    await Promise.allSettled(houseConsignmentTraderPromises)
  }

  const createHouseConsignments = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) return

    const savedConsignmentId = getValues('consignmentId')
    const houseConsignments = getValues('houseConsignment')
    await createHouseConsignmentTraders(houseConsignments, isDraft)

    const houseConsignmentRequests = houseConsignments
      .filter(excludeDeleted)
      .map((item) => toCreateHouseConsignmentRequest(item, savedConsignmentId))
    const response = await postHouseConsignment.mutateAsync(houseConsignmentRequests)

    refreshSavedIds(houseConsignments, response)
  }

  const updateHouseConsignments = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) return
    const savedConsignmentId = getValues('consignmentId')
    if (savedConsignmentId === null) return

    const houseConsignments = getValues('houseConsignment')
    const updateRequests: UpdateHouseConsignmentRequest[] = []
    const createRequests: CreateHouseConsignmentRequest[] = []

    await createHouseConsignmentTraders(houseConsignments, isDraft)

    houseConsignments.filter(excludeDeleted).forEach((houseConsignment) => {
      if (houseConsignment.id === null) {
        createRequests.push(toCreateHouseConsignmentRequest(houseConsignment, savedConsignmentId))
      } else {
        updateRequests.push(toUpdateHouseConsignmentRequest(houseConsignment))
      }
    })

    const responses: HouseConsignmentResponse[] = []
    responses.push(...await postHouseConsignment.mutateAsync(createRequests))

    if (isDraft) {
      responses.push(...await putHouseConsignmentDraft.mutateAsync(updateRequests))
    } else {
      responses.push(...await putHouseConsignment.mutateAsync(updateRequests))
    }

    refreshSavedIds(houseConsignments, responses)
  }

  const archiveHouseConsignments = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const houseConsignments = getValues('houseConsignment')

    const houseConsignmentIds = houseConsignments
      .filter(isDeletedWithId)
      .map((houseConsignment) => houseConsignment.id!)

    await Promise.allSettled(houseConsignmentIds.map((houseConsignmentId) => deleteHouseConsignment.mutateAsync(houseConsignmentId)))
  }

  return {
    getHouseConsignment,
    createHouseConsignments,
    updateHouseConsignments,
    archiveHouseConsignments,
  }
}

export default useHouseConsignment
