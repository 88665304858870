import { UseDeclarationFormReturn } from '../../form'
import useSubmitAction from './useSubmitAction'
import useCancelAction from './useCancelAction'
import useChangeAction from './useChangeAction'
import useRequestReleaseAction from './useRequestReleaseAction'
import useGenerateSubmissionFormAction from './useGenerateSubmissionFormAction'

function useFormAction(form: UseDeclarationFormReturn) {
  const { submitTransitOperation } = useSubmitAction(form)
  const { cancelTransitOperation } = useCancelAction(form)
  const { changeTransitOperation } = useChangeAction(form)
  const { releaseTransitOperation } = useRequestReleaseAction(form)
  const { generateAndDownloadForm } = useGenerateSubmissionFormAction(form)

  return {
    submitTransitOperation,
    cancelTransitOperation,
    changeTransitOperation,
    releaseTransitOperation,
    generateAndDownloadForm,
  }
}

export default useFormAction
