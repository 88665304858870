import React from 'react'
import { useTranslation } from 'react-i18next'
import FormField from '../../components/FormField'
import Input from '../../components/Input'
import FormSelect from '../../components/FormSelect'
import { NestedFieldScope, ObjectScope, ScopeProps } from '../../../../../types/DeclarationP5'
import useCodelist from '../../../common/hooks/useCodelist'

const expectedScope = /(houseConsignment\.\d+)/g
const isHouseConsignmentScope = (scope: ObjectScope): scope is `houseConsignment.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0
type HouseConsignmentScope = NestedFieldScope<`houseConsignment.${number}`>

function HouseConsignmentDetails({ scope }: ScopeProps<ObjectScope>) {
  if (!isHouseConsignmentScope(scope)) throw Error('Unable to narrow, invalid scope')

  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')

  return (
    <>
      <FormField labelKey="declaration.p5.referenceNumberUCR">
        <Input<HouseConsignmentScope>
          field={`${scope}.referenceNumberUCR`}
          labelKey="declaration.p5.referenceNumberUCR"
          type="text"
          placeholder={t('declaration.p5.referenceNumberUCR')}
        />
      </FormField>
      <FormField labelKey="declaration.p5.countryOfDispatch">
        <FormSelect<HouseConsignmentScope>
          field={`${scope}.countryOfDispatch`}
          labelKey="declaration.p5.countryOfDispatch"
          options={countriesOptions}
          type="sync"
        />
      </FormField>
      <FormField labelKey="declaration.p5.countryOfDestination">
        <FormSelect<HouseConsignmentScope>
          field={`${scope}.countryOfDestination`}
          labelKey="declaration.p5.countryOfDestination"
          options={countriesOptions}
          type="sync"
        />
      </FormField>
    </>
  )
}

export default HouseConsignmentDetails
