import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'

const C0822Validator = {
  validateContainerIndicatorRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.additionalDeclarationType === 'D') return 'OPTIONAL'
    return 'REQUIRED'
  }

  ,
} as const

export default C0822Validator
