import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import React, { useContext, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { useObservableState } from 'observable-hooks'
import { debounceTime } from 'rxjs/operators'
import { useFormContext } from 'react-hook-form'
import { UserContext } from 'context/UserContext'
import LoadingSpinner from 'components/LoadingSpinner'
import { EachTransitClearedKeys } from '../hooks/apiConfig'
import { hasText } from '../../common/utils/common-util'
import { DeclarationForm } from '../form/schemas/declarationFormSchema'
import { UseDeclarationFormReturn } from '../form'
import { isAmendButtonDisabled, isCancelButtonDisabled, isSubmitButtonDisabled } from '../../common/utils/button-validation'

// const allActions: ReadonlyArray<string> = ['submit', 'amend', 'cancel', 'logs', 'summary', 'duplicate', 'pdf', 'reset', 'saveDraft'] as const
// export type Action = typeof allActions[number]
export type Action =
  'submit'
  | 'amend'
  | 'cancel'
  | 'logs'
  | 'summary'
  | 'duplicate'
  | 'pdf'
  | 'reset'
  | 'saveDraft'
  | 'validate'
  | 'contact'
  | 'invalidationResponse'
  | 'requestRelease'
  | 'taxesPdf'
  | 'taxesXlsx'
  | 'generateXml'

const formSubmitActions = ['submit', 'amend', 'cancel', 'duplicate', 'pdf', 'saveDraft', 'validate', 'createNew', 'contact', 'invalidationResponse', 'requestRelease', 'taxesPdf', 'taxesXlsx', 'generateXml'] as const
const customActions = ['logs', 'summary', 'reset'] as const
export interface NavigationBarActionsProps {
  onAction: (eventKey: Action) => void
  form: UseDeclarationFormReturn
}

function isFormSubmitAction(key: string) {
  return formSubmitActions.find((known) => known === key) ?? false
}

function isCustomAction(key: string) {
  return customActions.find((known) => known === key) ?? false
}

const queryKeys: string[] = EachTransitClearedKeys.map((value) => value)

export default function NavigationBarActions({
  onAction,
  form,
}: Readonly<NavigationBarActionsProps>) {
  const { t } = useTranslation()
  const {
    formState: {
      isLoading,
      isSubmitting,
      isValidating,
    },
  } = form
  const { user } = useContext(UserContext)
  const submitButton = useRef<HTMLButtonElement>(null)
  const mutatingQueriesCount = useIsMutating()
  const fetchingQueriesCount = useIsFetching({
    predicate: (query) => queryKeys.includes(query.queryKey[0] as string),
  })

  // somewhat reduces the times buttons flicker between disabled and enabled
  const [debouncedInteractionLock, setDebouncedInteractionLock] = useObservableState<boolean>((
    event$,
  ) => event$.pipe(debounceTime(200)), true)
  const shouldLockInteractions = isLoading || isSubmitting || isValidating || fetchingQueriesCount > 0 || mutatingQueriesCount > 0
  setDebouncedInteractionLock(shouldLockInteractions)
  const interactionsLocked = shouldLockInteractions || debouncedInteractionLock

  const onSelect = (key: string | null) => {
    if (key == null) throw new Error(`Unknown action: ${key}`)

    const submitAction = isFormSubmitAction(key)

    if (submitAction) {
      submitButton.current!.id = submitAction
      submitButton.current!.click()
    } else {
      const customAction = isCustomAction(key)
      if (customAction) {
        onAction(customAction)
      }
    }
  }

  const { getValues } = useFormContext<DeclarationForm>()
  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])

  const hasMRN = hasText(form.getValues().mrn)

  // const isLockedForNonAdmins = removePrefix(form.getValues().departureCustomsOffice, DEPARTURE_OFFICE_CODE_PREFIX)?.startsWith('PL') ? true : isSubmitButtonDisabled

  return (
    <div className="d-flex justify-content-end p-2">
      <Button ref={submitButton} id="submit" type="submit" hidden />
      <Dropdown align="end" className="me-3" as={ButtonGroup} onSelect={onSelect}>
        <Dropdown.Toggle id="dropdown-customs" variant="primary" size="lg" disabled={interactionsLocked}>
          {!interactionsLocked && <i className="fal fa-reply fa-flip-horizontal" />}
          {interactionsLocked && <LoadingSpinner />}
          <span className="text-uppercase ms-2 pe-1">{t('buttons.customs')}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="shadow">
          <Dropdown.Item
            id="submit"
            eventKey="submit"
            disabled={isSubmitButtonDisabled(form, country, user?.role)}
          >
            <i className="fal fa-file-alt me-2 fa-md" />
            <span className="text-uppercase">{t('buttons.submit')}</span>
          </Dropdown.Item>
          <Dropdown.Item id="amend" eventKey="amend" disabled={isAmendButtonDisabled(form, country, user?.role)}>
            <i className="fal fa-file-edit me-2 fa-md" />
            <span className="text-uppercase">{t('buttons.amend')}</span>
          </Dropdown.Item>
          <Dropdown.Item id="cancel" eventKey="cancel" disabled={isCancelButtonDisabled(form, country, user?.role)}>
            <i className="fal fa-file-times me-2 fa-md" />
            <span className="text-uppercase">{t('buttons.cancel')}</span>
          </Dropdown.Item>
          {country === 'FI' ? (
            <Dropdown.Item id="contact" eventKey="contact" disabled={!hasMRN}>
              <i className="fal fa-address-book me-2 fa-md" />
              <span className="text-uppercase">Contact</span>
            </Dropdown.Item>
          ) : null}
          {country === 'FI' && form.getValues().status === 'CUSTOMS_REQUEST' ? (
            <Dropdown.Item id="invalidationResponse" eventKey="invalidationResponse">
              <i className="fal fa-reply me-2 fa-md" />
              <span className="text-uppercase">Customs Response (FI046A)</span>
            </Dropdown.Item>
          ) : null}
          {country === 'RO' && form.getValues().status === 'CONTROL_DECISION' ? (
            <Dropdown.Item id="requestRelease" eventKey="requestRelease">
              <i className="fal fa-reply me-2 fa-md" />
              <span className="text-uppercase">Decide release (IE054)</span>
            </Dropdown.Item>
          ) : null}
          <Dropdown.Divider />
          <Dropdown.Item id="validate" eventKey="validate">
            <i className="fal fa-clipboard-check me-2 fa-md" />
            <span className="text-uppercase">{t('declaration.headers.validate')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ButtonGroup>
        <Button
          id="saveDraft"
          variant="secondary"
          className="text-primary"
          type="submit"
          disabled={interactionsLocked}
        >
          {interactionsLocked && <LoadingSpinner />}
          <span className="ms-1">
            {t('buttons.saveDraft')}
          </span>
        </Button>
        <Dropdown align="end" as={ButtonGroup} onSelect={onSelect}>
          <Dropdown.Toggle
            id="dropdown-actions"
            variant="outline-secondary"
            size="lg"
            disabled={interactionsLocked}
          />
          <Dropdown.Menu className="shadow">
            <Dropdown.Item eventKey="duplicate" disabled={form.getValues('id') === null}>
              <i className="fal fa-file-plus me-2 fa-md" />
              <span className="text-uppercase">{t('buttons.duplicate')}</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="pdf" disabled={form.getValues('id') === null}>
              <i className="fal fa-file-pdf me-2 fa-md" />
              <span className="text-uppercase">{t('buttons.downloadPdf')}</span>
            </Dropdown.Item>
            {country === 'LT' && (
              <Dropdown.Item eventKey="generateXml" disabled={form.getValues('id') === null}>
                <i className="fal fa-file-pdf me-2 fa-md" />
                <span className="text-uppercase">{t('buttons.generateXml')}</span>
              </Dropdown.Item>
            )}
            <Dropdown.Item eventKey="taxesPdf" disabled={form.getValues('id') === null}>
              <i className="fal fa-file-pdf me-2 fa-md" />
              <span className="text-uppercase">{t('buttons.downloadTaxesPdf')}</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="taxesXlsx" disabled={form.getValues('id') === null}>
              <i className="fal fa-file-pdf me-2 fa-md" />
              <span className="text-uppercase">{t('buttons.downloadTaxesXlsx')}</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="createNew">
              <i className="fal fa-file me-2 fa-md" />
              <span className="text-uppercase">{t('buttons.createNew')}</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ButtonGroup>
    </div>
  )
}
