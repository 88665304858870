import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'
import { Button } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DeclarationTabLayout from '../../layout/DeclarationTabLayout'
import HouseConsignment from './HouseConsignment'
import AdditionalSupplyChainActors from '../transitParties/AdditionalSupplyChainActors'
import Consignor from '../transitParties/Consignor'
import Consignee from '../transitParties/Consignee'
import DepartureTransportMeans from '../general/DepartureTransportMeans'
import TransportDocuments from '../../components/TransportDocuments'
import PreviousDocuments from '../../components/PreviousDocuments'
import SupportingDocuments from '../../components/SupportingDocuments'
import AdditionalReferences from '../../components/AdditionalReferences'
import AdditionalInformations from '../../components/AdditionalInformations'
import { TransitOperationContext } from '../../hooks/useTransitOperationContext'
import useCodelist from '../../../common/hooks/useCodelist'
import { Address } from '../../form/schemas/commonConsignmentSchemas'
import { excludeDeleted } from '../../../common/utils/common-util'
import HouseConsignmentGoodsSidebar from './HouseConsignmentGoodsSidebar'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

interface HouseConsignmentTableRowProps {
  index: number
  tableRowRemove: (index: number) => void
}

function HouseConsignmentsTableRow({
  index,
  tableRowRemove,
}: HouseConsignmentTableRowProps) {
  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [isRowClosed, setIsRowClosed] = useState<boolean>(true)

  const [isTransitSidebarOpen, setIsTransitSidebarOpen] = useState(false)
  const [houseConsignmentIndexForGoodsModal, setHouseConsignmentIndexForGoodsModal] = useState<number | null>(null)

  const houseConsignmentScope = `houseConsignment.${index}` as const

  const toggleSidebarOpen = () => {
    setIsTransitSidebarOpen(!isTransitSidebarOpen)
  }

  const showGoodsSidebar = (targetId: number | null) => {
    setHouseConsignmentIndexForGoodsModal(targetId)
    toggleSidebarOpen()
  }

  const { getValues } = useFormContext<DeclarationForm>()

  const { nctsErrors } = useContext(TransitOperationContext)

  const hasError = useMemo(() => {
    const fieldError = nctsErrors
      .find((error) => error.field?.includes(houseConsignmentScope) ?? false)
    return fieldError ?? false
  }, [nctsErrors])

  const houseConsignment = getValues(houseConsignmentScope)

  const getCountry = (address: Address): string | undefined => {
    const {
      country,
    } = address

    return countriesOptions.find((countryOption) => countryOption.value === country)?.label
  }

  return (
    <>
      <tr className={hasError ? 'invalid-row' : ''}>
        <td className="sequence-nr">
          {houseConsignment.sequenceNumber + 1}
        </td>
        <td>
          {houseConsignment.consignee !== null && houseConsignment.consignee.name}
        </td>
        <td>
          {
            houseConsignment.consignee !== null
            && houseConsignment.consignee.address !== null
            && getCountry(houseConsignment.consignee.address)
          }
        </td>
        <td>
          {houseConsignment.consignor !== null && houseConsignment.consignor.name}
        </td>
        <td>
          {
            houseConsignment.consignor !== null
            && houseConsignment.consignor.address !== null
            && getCountry(houseConsignment.consignor.address)
          }
        </td>
        <td className="text-end">
          <Button
            type="button"
            className="btn-sm me-2"
            variant="primary font-size-sm"
            onClick={() => showGoodsSidebar(index)}
            title="goods"
          >
            <i className="fas fa-cube" />
            {' '}
            {t('declaration.p5.goodsWithNumber', { numberOfGoods: houseConsignment.consignmentItem.filter(excludeDeleted).length })}
          </Button>
          <Button
            type="button"
            className="btn-sm me-2"
            data-bs-toggle="collapse"
            data-bs-target={`#row_${index}`}
            aria-expanded={isRowClosed}
            aria-controls={`row_${index}`}
            tabIndex={0}
            variant="dark"
            onClick={() => setIsRowClosed(!isRowClosed)}
            onKeyDown={() => setIsRowClosed(!isRowClosed)}
          >
            <i className="fa fa-pencil" />
          </Button>
          <Button
            type="button"
            className="btn-sm"
            variant="danger"
            onClick={() => tableRowRemove(index)}
          >
            <i className="fa fa-trash" />
          </Button>
        </td>
      </tr>
      <tr
        className={`collapse ${isRowClosed ? '' : 'show'}`}
        id={`row_${index}`}
        key={`houseConsignmentRow_${houseConsignment.sequenceNumber}`}
      >
        <td colSpan={6}>
          { !isRowClosed ? (
            <table className="w-100">
              <tbody>
                <tr>
                  <td colSpan={7}>
                    <DeclarationTabLayout
                      start={(
                        <>
                          <HouseConsignment scope={houseConsignmentScope} />
                          <DepartureTransportMeans scope={`${houseConsignmentScope}.departureTransportMeans`} />
                          <AdditionalSupplyChainActors scope={`${houseConsignmentScope}.additionalSupplyChainActor`} />
                          <AdditionalReferences scope={`${houseConsignmentScope}.additionalReference`} />
                          <AdditionalInformations scope={`${houseConsignmentScope}.additionalInformation`} />
                        </>
                      )}
                      centerLeft={(
                        <>
                          <Consignor scope={`${houseConsignmentScope}.consignor`} />
                          <Consignee scope={`${houseConsignmentScope}.consignee`} />
                        </>
                      )}
                      centerRight={(
                        <SupportingDocuments scope={`${houseConsignmentScope}.supportingDocument`} />
                      )}
                      end={(
                        <>
                          <TransportDocuments scope={`${houseConsignmentScope}.transportDocument`} />
                          <PreviousDocuments scope={`${houseConsignmentScope}.previousDocument`} />
                        </>
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </td>
      </tr>
      {
        houseConsignmentIndexForGoodsModal !== null && (
          <HouseConsignmentGoodsSidebar
            houseConsignmentIndex={index}
            isVisible={isTransitSidebarOpen}
            toggleVisibility={toggleSidebarOpen}
          />
        )
      }
    </>
  )
}

export default HouseConsignmentsTableRow
