import { toast } from 'react-toastify'
import { UseDeclarationFormReturn } from '../../form'
import { DocumentRequestTypeEnum, DocumentResponse, DocumentResponseTypeEnum } from '../../../common/models'
import { findDocumentIndex, attachNewDocumentsWithSavedIds, UseDocumentCommonActionsHelper } from './common'
import {
  DocumentRequestWithFiles,
  toConsignmentPreviousDocumentRequest,
  toConsignmentSupportingDocumentRequest,
  toConsignmentTransportDocumentRequest,
} from './mapper'
import { excludeDeleted } from '../../../common/utils/common-util'

function useConsignmentDocument(form: UseDeclarationFormReturn, actionsHelper: UseDocumentCommonActionsHelper) {
  const {
    setValue,
    getValues,
    formState: {
      isValid,
    },
    trigger,
  } = form
  const {
    handleDocuments,
    handleFileUploads,
    archiveDeletedDocuments,
  } = actionsHelper

  const refreshConsignmentDocumentIds = (consignmentId: number, documentResponse: DocumentResponse) => {
    if (documentResponse == null || documentResponse.sequenceNumber == null || documentResponse.consignmentId !== consignmentId) {
      return
    }

    switch (documentResponse.type) {
      case DocumentResponseTypeEnum.PREVIOUS: {
        const previousDocumentIndex = findDocumentIndex(documentResponse.sequenceNumber, getValues('previousDocument'))
        setValue(`previousDocument.${previousDocumentIndex}.id`, documentResponse.id)
        break
      }
      case DocumentResponseTypeEnum.SUPPORTING: {
        const supportingDocumentIndex = findDocumentIndex(documentResponse.sequenceNumber, getValues('supportingDocument'))
        setValue(`supportingDocument.${supportingDocumentIndex}.id`, documentResponse.id)
        break
      }
      case DocumentResponseTypeEnum.TRANSPORT: {
        const transportDocumentIndex = findDocumentIndex(documentResponse.sequenceNumber, getValues('transportDocument'))
        setValue(`transportDocument.${transportDocumentIndex}.id`, documentResponse.id)
        break
      }
      default:
        break
    }
  }

  function getConsignmentDocumentsRequests(transitId: number, consignmentId: number | null) {
    const createConsignmentDocumentRequests: DocumentRequestWithFiles[] = []
    const updateConsignmentDocumentRequests: DocumentRequestWithFiles[] = []

    const previousDocuments = getValues('previousDocument')
      .filter(excludeDeleted)
    const transportDocuments = getValues('transportDocument')
      .filter(excludeDeleted)
    const supportingDocuments = getValues('supportingDocument')
      .filter(excludeDeleted)

    for (const previousDocument of previousDocuments) {
      const request = toConsignmentPreviousDocumentRequest(previousDocument, transitId, consignmentId)
      if (previousDocument.id === null) {
        createConsignmentDocumentRequests.push(request)
      } else {
        updateConsignmentDocumentRequests.push(request)
      }
    }

    for (const transportDocument of transportDocuments) {
      const request = toConsignmentTransportDocumentRequest(transportDocument, transitId, consignmentId)
      if (transportDocument.id === null) {
        createConsignmentDocumentRequests.push(request)
      } else {
        updateConsignmentDocumentRequests.push(request)
      }
    }

    for (const supportingDocument of supportingDocuments) {
      const request = toConsignmentSupportingDocumentRequest(supportingDocument, transitId, consignmentId)
      if (supportingDocument.id === null) {
        createConsignmentDocumentRequests.push(request)
      } else {
        updateConsignmentDocumentRequests.push(request)
      }
    }

    return {
      createConsignmentDocumentRequests,
      updateConsignmentDocumentRequests,
    }
  }

  const archiveConsignmentDocuments = async () => {
    const previousDocument = getValues('previousDocument')
    const transportDocument = getValues('transportDocument')
    const supportingDocument = getValues('supportingDocument')

    const asyncRequests: Array<Promise<void>> = []

    asyncRequests.push(...archiveDeletedDocuments(transportDocument, DocumentRequestTypeEnum.TRANSPORT))
    asyncRequests.push(...archiveDeletedDocuments(previousDocument, DocumentRequestTypeEnum.PREVIOUS))
    asyncRequests.push(...archiveDeletedDocuments(supportingDocument, DocumentRequestTypeEnum.SUPPORTING))

    const archivedDocumentPromises = await Promise.allSettled(asyncRequests)
    if (archivedDocumentPromises.some((result) => result.status === 'rejected')) {
      toast.warn('Some consignment documents deletion failed ')
    }
  }

  const createOrUpdateConsignmentDocuments = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) throw Error('Missing required transit operation id for documents')
    const consignmentId = getValues('consignmentId')
    if (consignmentId === null) throw Error('Missing required consignment id for documents')

    const {
      createConsignmentDocumentRequests,
      updateConsignmentDocumentRequests,
    } = getConsignmentDocumentsRequests(currentTransitOperationId, consignmentId)

    const responses = await handleDocuments(createConsignmentDocumentRequests, updateConsignmentDocumentRequests)

    for (const response of responses) {
      refreshConsignmentDocumentIds(consignmentId, response)
    }
    const createRequestsUpdatedWithSavedIds = attachNewDocumentsWithSavedIds(responses, createConsignmentDocumentRequests)

    await handleFileUploads(createRequestsUpdatedWithSavedIds, updateConsignmentDocumentRequests)
  }

  return {
    createOrUpdateConsignmentDocuments,
    archiveConsignmentDocuments,
  }
}

export default useConsignmentDocument
