import React from 'react'
import GoodsTable from './GoodsTable'

function DeclarationGoodsTab() {
  return (
    <div className="container-fluid py-3">
      <GoodsTable houseConsignmentIndex={0} />
    </div>
  )
}

export default DeclarationGoodsTab
