import React from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentResponse } from '../../../models'
import TransitSidebarDocumentSection from './TransitSidebarDocumentSection'
import { removePrefix, SUPPORTING_DOCUMENT_TYPE_CODE_PREFIX } from '../../../utils/classifier-util'
import TransitSidebarField from '../TransitSidebarField'

interface TransitSidebarSupportingDocumentsProps {
  supportingDocuments: DocumentResponse[]
}

function TransitSidebarSupportingDocuments({
  supportingDocuments,
}: TransitSidebarSupportingDocumentsProps) {
  const { t } = useTranslation()

  return (
    <div className="card p-2">
      <h5 className="text-secondary mb-2">
        {
          t('declaration.p5.supportingDocuments')
        }
      </h5>
      {
        supportingDocuments.map((document) => (
          <TransitSidebarDocumentSection
            key={document.id}
            document={document}
            documentTypePrefix={SUPPORTING_DOCUMENT_TYPE_CODE_PREFIX}
          >
            <div className="col-12">
              <TransitSidebarField
                label={t('declaration.p5.supportingDocumentType')}
                value={removePrefix(document.documentType, SUPPORTING_DOCUMENT_TYPE_CODE_PREFIX) ?? ''}
              />
              <TransitSidebarField
                label={t('declaration.p5.supportingDocumentReferenceNumber')}
                value={document.referenceNumber ?? ''}
              />
              <TransitSidebarField
                label={t('declaration.p5.complementOfInformation')}
                value={document.complementOfInformation ?? ''}
              />
              <TransitSidebarField
                label={t('declaration.p5.itemNumber')}
                value={document.documentLineItemNumber ?? ''}
              />
            </div>
          </TransitSidebarDocumentSection>
        ))
      }
    </div>
  )
}

export default TransitSidebarSupportingDocuments
