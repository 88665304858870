import React from 'react'
import { DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarTransportDocuments from './TransitSidebarTransportDocuments'

export interface TransitSidebarHouseConsignmentTransportDocumentsProps {
  houseConsignmentId: number | null
  transportDocuments: DocumentResponse[]
}

function TransitSidebarHouseConsignmentTransportDocuments({
  houseConsignmentId,
  transportDocuments,
}: TransitSidebarHouseConsignmentTransportDocumentsProps) {
  const houseConsignmentSupportingDocuments = transportDocuments
    .filter((transportDocument) => transportDocument.houseConsignmentId === houseConsignmentId)
    .sort(sortBySequenceNumber)

  if (houseConsignmentSupportingDocuments.length === 0) {
    return null
  }

  return (
    <div className="p-2">
      <TransitSidebarTransportDocuments transportDocuments={houseConsignmentSupportingDocuments} />
    </div>
  )
}

export default TransitSidebarHouseConsignmentTransportDocuments
