import { RuleResult } from 'types/DeclarationP5'
import { hasText } from '../../../../common/utils/common-util'

const C0250Validator = {
  validateNameAndAddressRequirement: (identificationNumber: string | undefined): RuleResult => {
    if (hasText(identificationNumber)) {
      return 'NOT_ALLOWED'
    }

    return 'REQUIRED'
  },

} as const

export default C0250Validator
