import {
  NotesDocument, PreviousDocument, SupportingDocument, TransportDocument,
} from '../../form/schemas/documentSchemas'
import {
  DocumentRequest, DocumentRequestTypeEnum, DocumentResponse, FileRequest, FileResponse,
} from '../../../common/models'
import { FileType } from '../../form/schemas/fileSchemas'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'

export interface DocumentRequestWithFiles {
  request: DocumentRequest
  files: FileType[]
}

export function toFileRequest(file: FileType, documentId: number): FileRequest {
  return {
    multipartFile: file.fileBlob,
    filename: file.fileName ?? '',
    mimeType: file.mimeType ?? '',
    sortOrder: file.sequenceNumber,
    documentId,
  }
}
export function parseFileResponse(file: FileResponse, documentId: number): FileType {
  return {
    id: file.id,
    fileName: file.filename,
    mimeType: file.mimeType,
    sequenceNumber: file.sortOrder,
    deleted: false,
    uuid: file.uuid,
    file: null,
    fileBlob: null,
    url: null,
    documentId,
  }
}

export function toNoteDocumentRequest(
  document: NotesDocument,
  transitOperationId: number,
): DocumentRequest {
  return {
    id: document.id,
    documentType: '',
    sequenceNumber: document.sequenceNumber,
    type: DocumentRequestTypeEnum.NOTE,
    transitOperationId,
    consignmentItemId: null,
  }
}

export function toConsignmentPreviousDocumentRequest(
  document: PreviousDocument,
  transitOperationId: number,
  consignmentId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.PREVIOUS,
      referenceNumber: document.referenceNumber,
      complementOfInformation: document.complementOfInformation,
      transportDocumentReferenceNumber: document.transportDocumentReferenceNumber,
      documentType: document.documentType,
      transitOperationId,
      consignmentId,
      procedureType: document.procedureType ?? undefined,
    },
    files: document.files,
  }
}

export function toConsignmentTransportDocumentRequest(
  document: TransportDocument,
  transitOperationId: number,
  consignmentId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      documentType: document.documentType,
      referenceNumber: document.referenceNumber,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.TRANSPORT,
      transitOperationId,
      consignmentId,
    },
    files: document.files,
  }
}

export function toConsignmentSupportingDocumentRequest(
  document: SupportingDocument,
  transitOperationId: number,
  consignmentId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      complementOfInformation: document.complementOfInformation,
      documentLineItemNumber: Number.isNaN(Number(document.documentLineNumber)) ? undefined : `${document.documentLineNumber}`,
      documentType: document.documentType,
      referenceNumber: document.referenceNumber,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.SUPPORTING,
      transitOperationId,
      consignmentId,
    },
    files: document.files,
  }
}

export function toConsignmentItemSupportingDocumentRequest(
  document: SupportingDocument,
  transitOperationId: number,
  consignmentItemId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      complementOfInformation: document.complementOfInformation,
      documentLineItemNumber: Number.isNaN(Number(document.documentLineNumber)) ? undefined : `${document.documentLineNumber}`,
      documentType: document.documentType,
      referenceNumber: document.referenceNumber,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.SUPPORTING,
      transitOperationId,
      consignmentItemId,
    },
    files: document.files,
  }
}

export function toConsignmentItemTransportDocumentRequest(
  document: TransportDocument,
  transitOperationId: number,
  consignmentItemId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      documentType: document.documentType,
      referenceNumber: document.referenceNumber,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.TRANSPORT,
      transitOperationId,
      consignmentItemId,
    },
    files: document.files,
  }
}

export function toConsignmentItemPreviousDocumentRequest(
  document: PreviousDocument,
  transitOperationId: number,
  consignmentItemId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.PREVIOUS,
      referenceNumber: document.referenceNumber,
      complementOfInformation: document.complementOfInformation,
      transportDocumentReferenceNumber: document.transportDocumentReferenceNumber,
      documentType: document.documentType,
      transitOperationId,
      consignmentItemId,
      procedureType: document.procedureType ?? undefined,
    },
    files: document.files,
  }
}

export function toHouseConsignmentSupportingDocumentRequest(
  document: SupportingDocument,
  transitOperationId: number,
  houseConsignmentId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      complementOfInformation: document.complementOfInformation,
      documentLineItemNumber: Number.isNaN(Number(document.documentLineNumber)) ? undefined : `${document.documentLineNumber}`,
      documentType: document.documentType,
      referenceNumber: document.referenceNumber,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.SUPPORTING,
      transitOperationId,
      houseConsignmentId,
    },
    files: document.files,
  }
}

export function toHouseConsignmentTransportDocumentRequest(
  document: TransportDocument,
  transitOperationId: number,
  houseConsignmentId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      documentType: document.documentType,
      referenceNumber: document.referenceNumber,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.TRANSPORT,
      transitOperationId,
      houseConsignmentId,
    },
    files: document.files,
  }
}

export function toHouseConsignmentPreviousDocumentRequest(
  document: PreviousDocument,
  transitOperationId: number,
  houseConsignmentId: number | null,
): DocumentRequestWithFiles {
  return {
    request: {
      id: document.id,
      sequenceNumber: document.sequenceNumber,
      type: DocumentRequestTypeEnum.PREVIOUS,
      referenceNumber: document.referenceNumber,
      complementOfInformation: document.complementOfInformation,
      transportDocumentReferenceNumber: document.transportDocumentReferenceNumber,
      documentType: document.documentType,
      transitOperationId,
      houseConsignmentId,
      procedureType: document.procedureType ?? undefined,
    },
    files: document.files,
  }
}

export function parseSupportingDocumentResponse(
  response: DocumentResponse,
  index: number,
): SupportingDocument {
  return {
    id: response?.id,
    sequenceNumber: response?.sequenceNumber ?? index,
    documentType: response?.documentType ?? '',
    files: response.files.map((fileResponse) => parseFileResponse(fileResponse, response.id!)).sort(sortBySequenceNumber),
    referenceNumber: response?.referenceNumber ?? '',
    complementOfInformation: response?.complementOfInformation ?? '',
    documentLineNumber: Number.isNaN(Number(response?.documentLineItemNumber)) ? null : Number(response?.documentLineItemNumber),
    deleted: false,
  }
}

export function parseTransportDocumentResponse(
  response: DocumentResponse,
  index: number,
): TransportDocument {
  return {
    id: response?.id,
    sequenceNumber: response?.sequenceNumber ?? index,
    documentType: response?.documentType ?? '',
    files: response.files.map((fileResponse) => parseFileResponse(fileResponse, response.id!)).sort(sortBySequenceNumber),
    referenceNumber: response?.referenceNumber ?? '',
    deleted: false,
  }
}

export function parsePreviousDocumentResponse(
  response: DocumentResponse,
  index: number,
): PreviousDocument {
  return {
    id: response?.id,
    sequenceNumber: response?.sequenceNumber ?? index,
    documentType: response.documentType ?? '',
    referenceNumber: response?.referenceNumber ?? '',
    complementOfInformation: response?.complementOfInformation ?? '',
    transportDocumentReferenceNumber: response?.transportDocumentReferenceNumber ?? '',
    files: response.files.map((fileResponse) => parseFileResponse(fileResponse, response.id!)).sort(sortBySequenceNumber),
    deleted: false,
    procedureType: response.procedureType ?? null,
  }
}
