import { toast } from 'react-toastify'
import { UseDeclarationFormReturn } from '../../form'
import { attachNewDocumentsWithSavedIds, UseDocumentCommonActionsHelper, findDocumentIndex } from './common'
import { DocumentRequestTypeEnum, DocumentResponse, DocumentResponseTypeEnum } from '../../../common/models'
import {
  DocumentRequestWithFiles,
  toHouseConsignmentPreviousDocumentRequest,
  toHouseConsignmentSupportingDocumentRequest,
  toHouseConsignmentTransportDocumentRequest,

} from './mapper'
import { excludeDeleted } from '../../../common/utils/common-util'
import { PreviousDocument, SupportingDocument, TransportDocument } from '../../form/schemas/documentSchemas'

function useHouseConsignmentDocument(form: UseDeclarationFormReturn, actionsHelper: UseDocumentCommonActionsHelper) {
  const {
    setValue,
    getValues,
    formState: {
      isValid,
    },
    trigger,
  } = form
  const {
    handleDocuments,
    handleFileUploads,
    archiveDeletedDocuments,
  } = actionsHelper

  const refreshHouseConsignmentDocumentIds = (
    documentResponse: DocumentResponse,
  ) => {
    if (documentResponse == null || documentResponse.sequenceNumber == null) {
      return
    }

    const houseConsignments = getValues('houseConsignment')

    for (let i = 0; i < houseConsignments.length; i++) {
      const houseConsignment = houseConsignments[i]
      if (houseConsignment.deleted === true || documentResponse.houseConsignmentId !== houseConsignment.id) {
        continue
      }

      switch (documentResponse.type) {
        case DocumentResponseTypeEnum.PREVIOUS: {
          const previousDocumentIndex = findDocumentIndex(documentResponse.sequenceNumber, houseConsignment.previousDocument)
          setValue(`houseConsignment.${i}.previousDocument.${previousDocumentIndex}.id`, documentResponse.id)
          break
        }
        case DocumentResponseTypeEnum.SUPPORTING: {
          const supportingDocumentIndex = findDocumentIndex(documentResponse.sequenceNumber, houseConsignment.supportingDocument)
          setValue(`houseConsignment.${i}.supportingDocument.${supportingDocumentIndex}.id`, documentResponse.id)
          break
        }
        case DocumentResponseTypeEnum.TRANSPORT: {
          const transportDocumentIndex = findDocumentIndex(documentResponse.sequenceNumber, houseConsignment.transportDocument)
          setValue(`houseConsignment.${i}.transportDocument.${transportDocumentIndex}.id`, documentResponse.id)
          break
        }
        default:
          break
      }
    }
  }

  function getHouseConsignmentDocumentsRequests(transitId: number) {
    const createHouseConsignmentDocumentRequests: DocumentRequestWithFiles[] = []
    const updateHouseConsignmentDocumentRequests: DocumentRequestWithFiles[] = []

    const houseConsignments = getValues('houseConsignment').filter(excludeDeleted)
    for (const houseConsignment of houseConsignments) {
      const houseId = houseConsignment.id
      if (houseId === null) {
        throw Error('Missing required house consignment id for documents')
      }

      const previousDocuments: PreviousDocument[] = houseConsignment.previousDocument.filter(excludeDeleted)
      const transportDocuments: TransportDocument[] = houseConsignment.transportDocument.filter(excludeDeleted)
      const supportingDocuments: SupportingDocument[] = houseConsignment.supportingDocument.filter(excludeDeleted)

      for (const previousDocument of previousDocuments) {
        const request = toHouseConsignmentPreviousDocumentRequest(previousDocument, transitId, houseId)
        if (previousDocument.id === null) {
          createHouseConsignmentDocumentRequests.push(request)
        } else {
          updateHouseConsignmentDocumentRequests.push(request)
        }
      }

      for (const transportDocument of transportDocuments) {
        const request = toHouseConsignmentTransportDocumentRequest(transportDocument, transitId, houseId)
        if (transportDocument.id === null) {
          createHouseConsignmentDocumentRequests.push(request)
        } else {
          updateHouseConsignmentDocumentRequests.push(request)
        }
      }

      for (const supportingDocument of supportingDocuments) {
        const request = toHouseConsignmentSupportingDocumentRequest(supportingDocument, transitId, houseId)
        if (supportingDocument.id === null) {
          createHouseConsignmentDocumentRequests.push(request)
        } else {
          updateHouseConsignmentDocumentRequests.push(request)
        }
      }
    }

    return {
      createHouseConsignmentDocumentRequests,
      updateHouseConsignmentDocumentRequests,
    }
  }

  const archiveHouseConsignmentDocuments = async () => {
    const asyncRequests: Array<Promise<void>> = []
    const houseConsignments = getValues('houseConsignment')

    for (const houseConsignment of houseConsignments) {
      asyncRequests.push(...archiveDeletedDocuments(houseConsignment.transportDocument, DocumentRequestTypeEnum.TRANSPORT))
      asyncRequests.push(...archiveDeletedDocuments(houseConsignment.previousDocument, DocumentRequestTypeEnum.PREVIOUS))
      asyncRequests.push(...archiveDeletedDocuments(houseConsignment.supportingDocument, DocumentRequestTypeEnum.SUPPORTING))
    }

    const archivedDocumentPromises = await Promise.allSettled(asyncRequests)
    if (archivedDocumentPromises.some((result) => result.status === 'rejected')) {
      toast.warn('Some house consignment documents deletion failed ')
    }
  }

  const createOrUpdateHouseConsignmentDocuments = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) throw Error('Missing required transit operation id for documents')

    const {
      createHouseConsignmentDocumentRequests,
      updateHouseConsignmentDocumentRequests,
    } = getHouseConsignmentDocumentsRequests(currentTransitOperationId)

    const responses = await handleDocuments(createHouseConsignmentDocumentRequests, updateHouseConsignmentDocumentRequests)

    for (const response of responses) {
      refreshHouseConsignmentDocumentIds(response)
    }
    const createRequestsUpdatedWithSavedIds = attachNewDocumentsWithSavedIds(responses, createHouseConsignmentDocumentRequests)

    await handleFileUploads(createRequestsUpdatedWithSavedIds, updateHouseConsignmentDocumentRequests)
  }

  return {
    createOrUpdateHouseConsignmentDocuments,
    archiveHouseConsignmentDocuments,
  }
}

export default useHouseConsignmentDocument
