import React from 'react'
import { DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarSupportingDocuments from './TransitSidebarSupportingDocuments'

export interface TransitSidebarHouseConsignmentSupportingDocumentsProps {
  houseConsignmentId: number | null
  supportingDocuments: DocumentResponse[]
}

function TransitSidebarHouseConsignmentSupportingDocuments({
  houseConsignmentId,
  supportingDocuments,
}: TransitSidebarHouseConsignmentSupportingDocumentsProps) {
  const houseConsignmentPreviousDocuments = supportingDocuments
    .filter((transportDocument) => transportDocument.houseConsignmentId === houseConsignmentId)
    .sort(sortBySequenceNumber)

  if (houseConsignmentPreviousDocuments.length === 0) {
    return null
  }

  return (
    <div className="p-2">
      <TransitSidebarSupportingDocuments supportingDocuments={houseConsignmentPreviousDocuments} />
    </div>
  )
}
export default TransitSidebarHouseConsignmentSupportingDocuments
