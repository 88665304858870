import { hasText } from './common-util'
import { UseDeclarationFormReturn } from '../../TransitOperationEditor/form'

export const isSubmitButtonDisabled = (form: UseDeclarationFormReturn, country: string, role: string | undefined): boolean | undefined => {
  const {
    status,
    mrn,
  } = form.getValues()
  if (status === null || status === 'SUBMITTED' || status === 'DELETED') {
    return true
  }

  if (status === 'DRAFT') {
    return false
  }
  if (status === 'REJECTED') {
    return hasText(mrn)
  }
  if (status === 'IN_REVIEW') {
    return role !== 'ADMIN'
  }

  return true
}

export const isAmendButtonDisabled = (form: UseDeclarationFormReturn, country: string, role: string | undefined): boolean | undefined => {
  const {
    status,
    mrn,
  } = form.getValues()

  if (status === null || status === 'SUBMITTED' || status === 'DRAFT' || status === 'DELETED') {
    return true
  }

  if (country === 'SE') return true

  if (status === 'IN_REVIEW') {
    return role !== 'ADMIN'
  }

  if (country === 'RO' && status === 'SUBMISSION_CONFIRMED') return false

  if (status === 'DELETED' || status === 'TRANSIT_OPEN' || status === 'PROCEDURE_ENDED' || status === 'T1_TRANSIT_CLOSED' || status === 'CANCELED' || status === 'TRANSIT_OPEN' || status === 'TRANSIT_OPEN') {
    return true
  }

  return !hasText(mrn)
}

export const isCancelButtonDisabled = (form: UseDeclarationFormReturn, country: string, role: string | undefined): boolean | undefined => {
  const {
    status,
    mrn,
  } = form.getValues()

  if (status === null || status === 'SUBMITTED' || status === 'DRAFT' || status === 'DELETED') {
    return true
  }
  if (status === 'IN_REVIEW') {
    return role !== 'ADMIN'
  }

  if (country === 'RO' && status === 'SUBMISSION_CONFIRMED') return false

  if (status === 'DELETED' || status === 'TRANSIT_OPEN' || status === 'PROCEDURE_ENDED' || status === 'T1_TRANSIT_CLOSED' || status === 'CANCELED' || status === 'TRANSIT_OPEN' || status === 'TRANSIT_OPEN') {
    return true
  }

  return !hasText(mrn)
}
