import React from 'react'
import { DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import TransitSidebarSupportingDocuments from './TransitSidebarSupportingDocuments'

export interface TransitSidebarConsignmentSupportingDocumentsProps {
  consignmentId: number | null
  supportingDocuments: DocumentResponse[]
}

function TransitSidebarConsignmentSupportingDocuments({
  consignmentId,
  supportingDocuments,
}: TransitSidebarConsignmentSupportingDocumentsProps) {
  const consignmentSupportingDocuments = supportingDocuments
    .filter((transportDocument) => transportDocument.consignmentId === consignmentId)
    .sort(sortBySequenceNumber)

  if (consignmentSupportingDocuments.length === 0) {
    return null
  }

  return (
    <div className="p-2">
      <TransitSidebarSupportingDocuments supportingDocuments={consignmentSupportingDocuments} />
    </div>
  )
}

export default TransitSidebarConsignmentSupportingDocuments
