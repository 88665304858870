const TRANSLATIONS_RU = {
  buttons: {
    transits: 'Транзиты',
    customers: 'Клиенты',
    settings: 'Настройки',
    duplicate: 'Копировать',
    viewLogs: '$t(common.view) логи',
    viewLogs_close: '$t(buttons.close) логи',
    reassignOwner: 'Переназначить владельца',
    delete: 'Удалить',
    profile: 'Профиль',
    logOut: 'Выход',
    logIn: 'Войти',
    register: 'Pегистр',
    startPasswordRecovery: 'Забыли логин/пароль?',
    resetPassword: 'Сбросить пароль',
    useDefault: 'Использовать стандарт',
    recoveryEmail: 'Восстановить пароль на почту',
    backToLogin: 'Вернуться для входа',
    addFiles: 'Добавить файл',
    remove: 'Удалить',
    addNew: 'Добавить',
    addNewExternal: 'Добавить (directo)',
    addGoods: 'Добавить товар',
    addUser: 'Добавить пользователя',
    addProfile: 'Добавить гарантийный профиль',
    saveGoods: 'Сохранить',
    saveDraft: 'Сохранить как черновик',
    save: 'Сохранить',
    send: 'Отправить',
    submitCustoms: 'в таможню',
    submitReview: 'для обзора',
    amendmentCustoms: 'Редактировать',
    cancelCustoms: 'Отменить',
    customs: 'Таможня',
    confirm: 'Подтвердить',
    submit: 'Предоставить',
    createNew: 'Создавать новое',
    amend: 'Изменять',
    summary: 'Краткое содержание',
    cancel: 'Отменить',
    change: 'Измененить',
    editDeclaration: '$t(buttons.change) декларацию',
    cancelDeclaration: '$t(buttons.cancel) декларацию',
    block: 'Блок',
    activate: 'Активировать',
    close: 'Закрыть',
    sendInvite: 'Отправить приглашение',
    rotate_left: '$t(common.rotate)  $t(common.left)',
    rotate_right: '$t(common.rotate) $t(common.right)',
    decideAudit: 'Решить',
    decideAuditHeader: 'Определить статус декларации',
    export: 'Экспорт',
    next: 'Следующий шаг',
    previous: 'Предыдущий шаг',
    activateUser: 'Активировать аккаунт',
    companyInfo: 'информация о компании',
    requestAccess: 'Запрос доступа',
    accept: 'Принять условия',
    feedback: 'Обратная связь',
    t1Closed: 'Изменить статус на Т1 Транзит закрыт',
    notes: 'Примечания',
    filter: 'Табличные фильтры',
    filter_clear: 'Очистить фильтры',
    downloadPdf: 'Скачать PDF',
    downloadXml: 'Скачать XML',
    downloadTaxesPdf: 'НАЛОГИ PDF',
    downloadTaxesXlsx: 'НАЛОГИ XLSX',
    generateXml: 'Загрузить форму заявки (IE015)',
    importRestriction: 'Ограничение на импорт',
    quickFiltersWithCount_zero: 'Быстрые фильтры',
    quickFiltersWithCount_one: 'Быстрые фильтры ({{count}})',
    quickFiltersWithCount_other: 'Быстрые фильтры ({{count}})',
  },
  notes: {
    title: 'Добавить примечание к декларации',
    submit: 'Отправить заметку',
  },
  transit: {
    title: 'транзит',
    id: 'ID',
    mrnAndLrn: '$t(common.mrn)/$t(common.lrn)',
    consignor: 'Отправитель',
    consignee: 'Получатель',
    carrier: 'Перевозчик',
    transport: 'Транспорт',
    departureCustomsOffice: 'Код таможни отправления',
    destinationCustomsOffice: 'Код таможни назначения',
    customer: 'Клиент',
    status: 'Статус',
    createdByName: 'Создано',
    created: 'Создания',
    modified: 'Изменено',
    reviewer: 'Рецензент',
    lastReview: 'Последний отзыв',
    guaranteeAmount: 'Гарантии',
    directoStatus: '$t(declaration.headers.directoEvent) $t(transit.status)',
    directoClient: 'Directo клиент',
  },
  common: {
    new: 'Новый',
    edit: 'Редактировать',
    view: 'Посмотреть',
    date: 'Дата',
    type: 'Тип',
    message: 'Сообщение',
    user: 'Пользователь',
    yes: 'Да',
    no: 'Нет',
    chooseCountry: 'Выберите страну',
    chooseField: 'Выберите поле',
    chooseProfile: 'Выбрать профиль',
    chooseGoodsItem: 'Выбрать товар',
    chooseMode: 'Выберите тип',
    fieldPlaceholder: 'Пожалуйста заполните форму',
    customsPlaceholder: 'Поиск таможни',
    name: 'имя',
    name_capitalized: 'Имя',
    typeToSearch: 'Введите для поиска {{0}} или добавить новый',
    loading: 'Загрузка…',
    saving: 'Сохранение…',
    street: 'Улица',
    city: 'Город',
    zip: 'Индекс',
    grnCode: 'ГРН код',
    guaranteeType: 'Тип гарантии',
    value: 'Значение',
    select: 'Выбор',
    confirmAction: 'Подтвердить действие',
    error: 'Ошибка!',
    email: 'Электронная почта',
    password: 'Пароль',
    confirmPassword: 'Подтвердите пароль',
    phone: 'Телефон',
    mrn: 'MRN',
    lrn: 'LRN',
    log: 'лог',
    review_approved: 'утвердить',
    review_decline: 'отклонить',
    rotate: 'Повернуть',
    left: 'налево',
    right: 'вправо',
    show: 'Показать',
    page: 'Страница',
    pageSeparator: 'из',
    goToPage: 'Перейти на страницу',
    userInformation: 'Информация об аккаунте',
    companyInformation: 'Информация о компании',
    registerSuccess: 'Вы успешно зарегистрировались',
    customsProfileName: 'Имя профиля',
    customsProfileAuthority: 'Офис власти',
    customsProfileCountry: 'Страна полномочий',
    customsProfileGrn: 'ГРН',
    submit_success: 'Отправка успешна!',
    submit_error: 'Отправка не удалась!',
    submit_service_unavailable: 'Отправка ошибка! Возможные проблемы с национальной таможенной системой NCTS страны отправления. Повторите попытку позже!',
  },
  declaration: {
    navbar: {
      general: 'Общий',
      consignment: 'Суб-путевая накладная',
      transitParties: 'Участники транзита',
      houseConsignments_zero: 'House consignments',
      houseConsignments: 'House consignments ({{count}})',
      goods: 'Товары',
      directo: 'Directo',
      summary: 'Краткое содержание',
      logs: 'Журналы событий',
      additional: 'Важная информация!',
    },
    headers: {
      info: 'Информация о транзите',
      declarationInfo: 'Инфо о декларации',
      goods: 'Товары',
      goodsItem: 'Кол-во товаров',
      traders: 'Отправители/Получатели',
      carrier: 'Перевозчик',
      transport: 'Транспорт',
      transportAndLoading: 'Транспорт и загрузка',
      metaData: 'Метаданные',
      goodsItems: 'Кол-во товаров',
      logs: 'Логи',
      validate: 'подтверждать',
      goodsInfo: 'Информация о товарах',
    },
    finnish: {
      geographical_location: 'Географическое положение',
      zip_code: 'Почтовый индекс',
      warehouse_identifier: 'Идентификатор склада',
      customs_office: 'Таможня',
      doubleTransportMode: 'Финская таможня принимает только однозначные виды транспорта',
      arexNumber: 'Номер заказа Арекс',
      transportDocumentId: 'Идентификатор транспортного документа',
      error: {
        transitDateLimit: 'Отсутствует ограничение по дате транзита',
        transitLoadingDate: 'Отсутствует дата погрузки транзита',
        transportDocument: 'Транспортный документ всегда должен быть задекларирован с первой партией товара.',
        transportDocumentLength: 'Длина номера транспортного документа может быть только от 1 до 20 символов.',
        previousDocumentMandatory: 'Предыдущие документы обязательны для финских деклараций! Если предыдущих документов нет, выберите Тип документа (40A) 00: Нет предыдущей процедуры и введите 00 в поле Номер документа (40B).',
      },
    },
    status: {
      draft: 'Черновик',
      rejected: 'Выброшенный',
      accepted: 'Принятый',
      confirmed: 'Представление подтверждено',
      open: 'Транзит открыт',
      declined: 'Отказывать',
      canceled: 'Отменено',
      ended: 'Транзит закончился',
      arrived: 'IE006 прибыл',
      t1Closed: 'Транзит T1 завершен',
      checkOk: 'Проверить не в порядке',
      control: 'Контрольное решение',
      discrepancies: 'Расхождения',
      cancelRequested: 'Запрошена отмена',
      goodsReleaseNotification: 'Уведомление о выпуске товара',
    },
    p5: {
      sequenceNumber: '№',
      mrn: 'MRN',
      lrn: 'LRN',
      name: 'Имя',
      declarationType: 'Тип объявления',
      security: 'Защитные данные',
      additionalDeclarationType: 'Тип дополнительной декларации',
      bindingItinerary: 'Обязательный маршрут',
      reducedDatasetIndicator: 'Уменьшенный набор данных',
      limitDate: 'Крайний срок',
      dispatchCountry: 'Страна отправления',
      departureCustomsOffice: 'Код таможни отправления',
      destinationCustomsOffice: 'Код таможни назначения',
      destinationCountry: 'Страна назначения',
      totalNumberOfPackages: 'Общее количество упаковок',
      grossWeight: 'Брутто вес',
      grossWeight_current: 'Текущий вес товара',
      containerisedIndicator: 'Товары в контейнерах',
      transportEquipment: 'Транспортное оборудование',
      containers: 'контейнеры',
      containerNumber: 'Идентификационный номер',
      addNewContainer: 'Добавить контейнер',
      seals: 'Морские котики',
      addNewSeal: 'Добавить печать',
      numberOfSeals: 'Количество пломб',
      sealIdentifier: 'Идентификатор печати',
      goodsReferences: 'Ссылки на товары',
      addNewGoodsReferences: 'Добавить ссылку на товар',
      declarationGoodsItemNumber: 'Товарные позиции №. в декларации',
      transport: 'Транспорт',
      borderTransportMode: 'Тип транспорта на границе',
      inlandTransportMode: 'Внутренний тип транспорта',
      departureTransportMeans: 'Транспортное средство отправления',
      typeOfIdentification: 'Тип идентификации транспортных средств',
      identificationNumber: 'Номер идентификации транспортного средства',
      nationality: 'Страна',
      conveyanceReferenceNumber: 'Справочный номер транспортного средства',
      customsOfficeAtTheBorder: 'Таможня на границе',
      borderTransportMeans: 'Пограничные транспортные средства',
      addNewTransportMeans: 'Новые транспортные средства',
      placeOfLoading: 'Место загрузки',
      placeOfUnLoading: 'Место выгрузки',
      locationOfGoods: 'Местоположение товара',
      typeOfLocation: 'Тип местоположения',
      locationOfGoodsReferenceNumber: 'Таможня',
      qualifierOfIdentification: 'Квалификатор идентификации',
      authorisations: 'Разрешения',
      addNewAuthorisation: 'Добавить авторизации',
      authorisationNumber: 'Номер авторизации',
      authorisationType: 'Тип авторизации',
      authorisationReferenceNumber: 'Справочный номер авторизации',
      additionalIdentifier: 'Дополнительный идентификатор',
      economicOperatorIdentificationNumber: 'Идентификационный номер экономического оператора (EORI)',
      unLocode: 'UN/LOCODE',
      placeOfLoadingLocation: 'расположение места погрузки',
      placeOfUnloadingLocation: 'расположение места разгрузки',
      countriesOfRouting: 'Страны маршрута',
      countryOfRouting: 'Страна маршрутизации',
      addNewRoutingCountry: 'Добавить новую страну',
      customsOfficeOfTransit: 'Таможни транзита',
      transitExitCustomsOffices: 'Таможня транзитного выезда',
      referenceNumber: 'Справочный номер',
      arrivalDateAndTime: 'Дата и время прибытия',
      addNewCustomsOfficeOfTransit: 'Новая таможня транзита',
      addNewCustomsOfficeOfExitForTransit: 'Новая выездная транзитная таможня',
      transportDocuments: 'Транспортные документы',
      transportDocuments_singular: 'Транспортные документ',
      transportDocumentType: 'Тип транспортного документа',
      transportDocumentReferenceNumber: 'Справочный номер транспортного документа',
      addNewDocument: 'Новый документ',
      previousDocuments: 'Предыдущие документы',
      previousDocuments_singular: 'Предыдущие документ',
      previousDocumentType: 'Тип документа',
      procedureType: 'Тип процедуры',
      previousDocumentReferenceNumber: 'Справочный номер',
      complementOfInformation: 'Дополнение информации',
      supportingDocuments: 'Подтверждающие документы',
      supportingDocuments_singular: 'Подтверждающие документ',
      supportingDocumentType: 'Тип документа',
      supportingDocumentReferenceNumber: 'Справочный номер',
      documentType: 'Тип документа',
      referenceType: 'Тип ссылки',
      type: 'Тип',
      code: 'Код',
      text: 'Текст',
      itemNumber: 'Номер предмета',
      additionalReferences: 'Дополнительные ссылки',
      additionalReferenceType: 'Тип ссылки',
      additionalReferenceNumber: 'Справочный номер',
      addNewReference: 'Новая ссылка',
      additionalInformations: 'Дополнительная информация',
      additionalInformationCode: 'Код дополнительной информации',
      additionalInformationText: 'Текст дополнительной информации',
      transitProcedureHolder: 'Держатель транзитной процедуры',
      procedureIdentificationNumber: 'Идентификационный номер',
      transitProcedureName: 'Имя',
      country: 'Страна',
      address: 'Адрес',
      city: 'Город',
      postcode: 'Почтовый индекс',
      contactPersonName: 'Имя контактного лица',
      contactPersonEmail: 'Электронная почта контактного лица',
      contactPersonPhone: 'Телефон контактного лица',
      representative: 'Представитель',
      representativeIdentificationNr: 'Идентификационный номер представителя',
      representativeStatus: 'Представительский статус',
      guarantees: 'Гарантии',
      addNewGuarantee: 'Добавить новую гарантию',
      guaranteeType: 'Тип гарантии',
      guaranteeReference: 'Ссылка на гарантию',
      grn: 'Номер гарантии (GRN)',
      accessCode: 'Код доступа',
      amountToBeCovered: 'Сумма, подлежащая покрытию',
      currency: 'Валюта',
      consignor: 'Отправитель',
      consignorIdentificationNr: 'Идентификационный номер отправителя',
      consignorName: 'Имя отправитель',
      consignorCountry: 'Страна отправитель',
      consignee: 'Получатель',
      consigneeIdentificationNr: 'Идентификационный номер получатель',
      consigneeName: 'Имя получатель',
      consigneeCountry: 'Страна получатель',
      carrier: 'Перевозчик',
      carrierIdentificationNr: 'Идентификационный номер перевозчик',
      carrierName: 'Имя оператора связи',
      additionalSupplyChainActor: 'Дополнительный участник цепочки поставок',
      role: 'Роль',
      actorIdentificationNr: 'Идентификационный номер',
      addNewActor: 'Новый участник',
      addRow: 'Новая строка',
      houseConsignment: 'House consignment',
      grossMass: 'Полная масса',
      referenceNumberUCR: 'Справочный номер/UCR',
      descriptionOfGoods: 'Описание товаров',
      commodityCode: 'Код товара',
      houseConsignmentReference: 'House consignment reference',
      numberOfPackages: 'Количество упаковок',
      goodsData: 'Данные о товарах',
      commodityDescriptionOfGoods: 'Товарное описание товара',
      commodityHarmonizedSystemSubHeadingCode: 'Код подзаголовка Гармонизированной системы',
      commodityCombinedNomenclatureCode: 'Комбинированный номенклатурный код',
      commodityCodeSearch: 'Введите для поиска…',
      goodsMeasureGrossMass: 'Полная масса',
      goodsMeasureNetMass: 'Масса нетто',
      countryOfDispatch: 'Страна отправки',
      countryOfDestination: 'Страна назначения',
      dangerousGoods: 'Опасные грузы',
      addNewGoodsItem: 'Новый товар',
      packages: 'Пакеты',
      packages_singular: 'Паковка',
      packaging: 'Пакеты',
      typeOfPackages: 'Тип упаковки',
      shippingMarks: 'Маркировочные знаки',
      addNewPackage: 'Новый пакет',
      unNumber: 'Номер ООН',
      dropzoneDescription: 'Перетащите файлы сюда или нажмите, чтобы выбрать файлы',
      files: 'Файлы',
      generalInformation: 'Общая информация',
      warnings: 'Предупреждения',
      declarationTypeSpecified: 'Указанный тип объявления',
      customsOfficeOfRouting: 'таможня маршрута',
      customsDebt: 'Таможенный долг',
      aeoDiscount: 'AEO скидка',
      numberOfItemsDeclaration: 'Количество предметов',
      dueDate: 'Срок оплаты',
      barriers: 'Барьеры',
      noWarnings: 'Нет предупреждений',
      createNew: 'Создать новый транзит',
      createNewWarning: 'Вы уверены, что хотите создать новый? Все несохраненные изменения будут потеряны.',
      unsavedChangesLostWarning: 'Все несохраненные изменения будут потеряны!!',
      upload_error: 'Загрузка файла не удалась!!',
      contactForm: 'Контактная форма Финляндии',
      notification: 'Уведомление',
      notificationDescription: 'Описание уведомления',
      notificationReason: 'Причина обращения',
      // ToDo: Correct RU translation
      houseNumber: 'номер дома',
      finlandCustomsRequestResponse: 'Ответ таможни Финляндии на запрос(FI046A)',
      agreeWithCustoms: 'Согласны ли вы с решением таможни?',
      requestRelease_title: 'Освободить транзит?',
      requestRelease: 'Незначительные несоответствия, обнаруженные во время контроля, следует ли в любом случае запрашивать разрешение на транзит?',
      errors: {
        title: 'Ошибки',
        none: 'Нет ошибок',
        invalidGrossMass: 'Недопустимая масса брутто в целом. ({{rootGrossMass}}) не равен массе брутто предмета ({{itemGrossMass}})',
        grossMassDifference: 'Массы брутто разные. Ожидаемая: {{expected}}, фактическая: {{actual}}',
        maximumNumberOfItemsExceeded: 'Максимальное количество ({{max}}) товаров превышено.',
      },
      typeToSearch: 'Введите текст для поиска или добавьте новый',
      goodsSidebar: {
        title: 'House consignment no. {{houseConsignmentSequenceNumber}} goods',
      },
      goodsWithNumber: 'Товары ({{numberOfGoods}})',
    },
    type: 'Тип декларации',
    place: 'Место декларации',
    specialConditions: 'Особые условия',
    securityReferenceNumber: 'Особые условия',
    shipmentNumber: 'Номер отправления',
    security: 'Защитные данные',
    packagesCount: 'Кол-во упаковки',
    totalGrossMass: 'Общий вес',
    dispatchCountry: 'Страна отправления',
    destinationCountry: 'Страна назначения',
    loadingPlace: 'Место загрузки',
    unloadingPlace: 'Место выгрузки',
    transitCountries: 'Страны пересечения',
    guaranteeAmount: 'Сумма гарантии',
    nctsProfile: 'Гарантийный профиль',
    sanctionedCode: 'HS-коды под санкциями',
    blacklisted: 'Предупреждение о черном списке',
    blacklistWarning: 'Номер торговца или грузовика находится в нашем черном списке за контрабанду незаконных товаров.',
    sanctionWarning: 'Один или несколько HS-кодов могут быть под санкциями, пожалуйста, проверьте их',
    createdBy: 'Создано',
    created: 'Дата создания',
    modified: 'Дата изменения',
    goodsTradersEnabled: 'Разные отправители/получатели',
    multipleGoodsTraders: 'Множественный, набор под товар',
    consignor: 'Отправитель',
    consignee: 'Получатель',
    carrier: 'Перевозчик',
    address: 'Адрес',
    departureTruckNo: 'Номер авто на загрузке',
    departureNationality: 'Страна авто на загрузке',
    borderCrosserTruckNo: 'Номер авто на границе',
    borderCrosserNationality: 'Страна авто на границе',
    indicatorContainerised: 'Контейнер',
    containerNumber: 'Номер контейнера',
    borderTransportMode: 'Тип транспорта на границе',
    inlandTransportMode: 'Внутренний тип транспорта',
    departureCustomsOffice: 'Код таможни отправления',
    destinationCustomsOffice: 'Код таможни назначения',
    transitCustomsOffice: 'Таможни транзита',
    eoriId: 'EU ЕОРИ',
    reassignOwner: 'Переназначить владельца',
    currentOwner: 'Текущий владелец',
    selectNewOwner: 'Выберите нового владельца',
    lvGuarantee: 'Калькулятор гарантии LV / LT',
    ltGuarantee: 'Калькулятор гарантии LT',
    plGuarantee: 'Калькулятор гарантии PL',
    eeGuarantee: 'Калькулятор гарантии EE',
    latestCustomsUpdate: 'Последнее таможенное обновление',
    transitDateLimit: 'Ограничение по дате',
    numberOfGoods: 'Количество товаров',
    loadingTime: 'Дата и время загрузки',
    goodsLocation: 'Местонахождение товара',
    warehouseLocation: 'Идентификатор склада',
    goodsLocationQualifier: 'Классификатор местоположения товара',
    seal: 'Печать',
    trader: 'Трейдер',
    truck: 'Грузовик с номером',
  },
  goodsItem: {
    quantity: 'Количество',
    kindOfPackage: 'Единица количества',
    marksAndNumbers: 'Пометки и числа',
    description: 'Описание товара',
    numberOfPieces: 'Кол-во штук',
    numberOfPackages: 'Кол-во упаковок',
    hsCode: 'Код товара',
    hsCodeAudit: 'HS-код требует аудита',
    goodsItemPrice: 'Цена товара',
    goodsItemPrice_unit: 'EUR',
    price: '$t(declaration.p5.goodsItemPrice)', // for automatic csv import mapping
    goodsItemDutyRate: 'Ставка пошлины',
    goodsItemDutyRate_unit: '%',
    dutyRate: '$t(declaration.p5.goodsItemDutyRate)',
    goodsItemVatRate: 'НДС',
    goodsItemVatRate_unit: '%',
    vatRate: '$t(declaration.p5.goodsItemVatRate)',
    goodsItemExcise: 'Акциз',
    goodsItemExcise_unit: 'EUR',
    grossWeight: 'Брутто вес',
    netWeight: 'Вес нетто',
    dispatchCountry: 'Страна отправления',
    destinationCountry: 'Страна назначения',
    documents: 'Сопроводительные документы (44A)',
    documentType: 'Тип документа (44A)',
    documentNumber: 'Номер документа',
    previousDocument: 'Предыдущий документ',
    previousDocumentType: 'Тип документа (40A)',
    previousDocumentProcedure: 'Тип процедуры (40AB)',
    previousDocumentNumber: 'Номер документа (40B)',
    previousDocumentOrderNumber: 'Порядковый номер',
    documentTypeNumeric: '44A',
    previousDocumentProcedureNumeric: '40AB',
    previousDocumentTypeNumeric: '40A',
    previousDocumentNumberNumeric: '40B',
    packageUnitPlaceholder: 'Выберите единицу упаковки',
    marksPlaceholder: 'Пометки',
    descriptionPlaceholder: 'Описание товара',
    hsCodePlaceholder: 'Код',
    dispatchCountryPlaceholder: 'Выберите отправление',
    destinationCountryPlaceholder: 'Выберите назначение',
    houseNumber: 'Номер дома',
  },
  tooltips: {
    security: 'Данные безопасности должны быть внесены в общие данные если есть хотябы один\n '
      + 'защищенный отправитель для всего отправления',
    goodsTraders: 'Если товары имеют разных отправителей и получателей, '
      + 'данные о грузоотправителе/грузополучателе должны быть указаны в товарах',
    deleteUser: 'Удалить/Архивировать пользователя',
    enableAccount: 'Включить пользователя',
    blockUser: 'Заблокировать пользователя',
    passwordReset: 'Отправить электронное письмо для сброса пароля',
    editUser: 'Изменить данные пользователя',
    confirmationEmail: 'Отправить электронное письмо с подтверждением учетной записи',
    // eslint-disable-next-line max-len
    declarationCancelExplanation: 'Декларация может быть аннулирована только тогда, когда: ',
    declarationCancelExplanation_row1: 'декларация имеет MRN и',
    declarationCancelExplanation_row2: 'транзитная декларация не открыта на таможне',
    declarationCancelExplanation_row3: 'Аннулирование НЕ МОЖЕТ быть отозвано и повторно представлено таможне',
    localReject: 'Отклонить локально',
    pdfExplanation: 'Отправляет декларацию в формате PDF в виде вложения электронной почты',
    warehouse: 'Создайте идентификатор склада вашей компании как\n'
      + 'следует: код страны, собственный код y компании и расширение R (пример FI1234567-8R0001)',
    warehouseEori: 'The EORI of the operator, in the case of a place approved by Customs, is entered. If goods are to be inspected in a warehouse, the warehouse identifier shall be in the form EORI+3+2.',
    typeOfLocation: 'Select A as type of location if the goods are at the customs office of departure, B if the goods are in a warehouse'
      + ' approved by Customs, C if the goods are in a place approved by Customs, and D - Other if the goods are is some other location.',
    simplified: 'Обязательное поле в упрощенной процедуре (L). Не используется в обычной процедуре',
    loading: 'Введите дату и время, когда происходит погрузка и товар может быть осмотрен. Данные должны быть введены, если введено согласованное местонахождение товара.',
    guaranteeProfileMissing: 'Выберите профиль гарантии, чтобы продолжить',
  },
  pages: {
    page_transits: 'Транзиты П4',
    page_customers: 'Клиенты',
    page_settings: 'Настройки',
    page_restrictions: 'Ограничения',
    page_audit: 'Аудит',
    page_company: 'Компания',
    page_privacy: 'Политика конфиденциальности',
    page_notification: 'Уведомления',
    page_seo: 'SEO',
    page_customs: 'таможенная конфигурация',
    page_codelist: 'список кодов',
    page_certificates: 'Сертификаты',
    'page_transit-operations': 'транзит',
    'page_transit-audit': 'транзитный аудит',
  },
  customers: {
    title: 'Клиенты',
    customer_new: 'Новый клиент',
    customer_edit: 'Редактировать клиента',
    companyInfo: 'Информация о кампании',
    customsProfile: 'Гарантийный профиль',
    registryCode: 'Регистрационный номер',
    address: 'Адрес',
    users: 'Пользователи',
    accessCode: 'Код доступа',
    maxGuaranteeAmount: 'Максимальная сумма гарантии',
    auditRequiredAmount: 'Требуемая сумма аудита',
    guaranteeFormula: 'Формула гарантии',
    notes: 'Заметки',
    status_ACCEPTED: 'АКЦЕПТИРОВАН',
    status_ACTIVE: 'АКТИВНЫЙ',
    status_BLOCKED: 'ЗАБЛОКИРОВАН',
    status_ARCHIVED: 'АРХИВИРОВАН',
    status_INACTIVE: 'НЕАКТИВНЫЙ',
    documentFilesRequired: 'Требуется загрузка документа',
    requiresAudit: 'Требуется ли пользователю аудит',
    companyName: 'Название компании',
    companyNameExample: 'Название компании должно точно совпадать с зарегистрированным EORI.',
    companyZipCode: 'Почтовый индекс компании',
    companyZipCodeExample: 'Почтовый индекс компании должен точно совпадать с зарегистрированным EORI.',
    companyStreet: 'Компания Улица',
    companyStreetExample: 'Улица компании должна точно совпадать с зарегистрированным EORI.',
    companyCity: 'Компания Город',
    companyCityExample: 'Город компании должен точно соответствовать зарегистрированному EORI.',
    companyCountry: 'Страна компании',
    streetExample: 'Адрес компании должен точно совпадать с зарегистрированным EORI.',
    companyContactEmail: 'Электронная почта компании',
    deleted: 'Дата удаления',
    lastLogin: 'Дата входа',
    directoClientCode: 'Directo клиентский код',
    apiTokenAccess: 'Доступ к клиентскому API',
    apiTokenAccessForbidden: 'Отсутствует доступ. Контактная поддержка',
    generateApiToken: 'Создать токен API',
    apiTokenActions: 'Действия токена API',
    generateApiToken_description: 'Создайте новый токен доступа к клиентскому API. Существующий токен будет отозван. Новый токен будет показан здесь только один раз во время создания. Надежно сохраните токен перед закрытием модального окна. Для аутентификации требуется токен с регистром компании. код.',
    apiDocs: 'Сохранить спецификацию API',
    id: 'ID',
    modified: 'Изменено',
    external: 'Directo',
    giveAccess: 'Дайте доступ к профилю T1',
    publicDeclaration: 'Гарантия Т1',
    searchUsingName: 'Поиск по имени',
    searchForInput: 'Найдите «{{input}}»',
  },
  certificates: {
    expiry: 'Срок действия',
    validTo: 'Действителен до',
    name: 'Имя',
    issuer: 'Эмитент',
    fingerprint: 'Fingerprint',
  },
  user: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    userProfileDetails: 'Детали профиля пользователя',
    password_current: 'Текущий пароль $t(common.password)',
    password_new: 'Новый пароль $t(common.password)',
    password_repeat: 'Повторить новый пароль $t(common.password)',
    accountCreationSetPassword: 'Новый пароль',
    accountCreationCreatePassword: 'Создать пароль для пользователя',
    phone: 'Телефонный номер',
    privacyPolicy: 'Я прочитал и согласен с ',
    privacy: 'Политика конфиденциальности',
    otp: 'Одноразовый пароль',
    preferences: 'Ваши предпочтения в таблице общественного транспорта',
  },
  messages: {
    confirmation: 'Вы уверены?',
    confirmSaving: 'Вы уверены, что хотите сохранить изменения?',
    maxLength: 'Имя слишком длинное. Максимальная длина: ',
    noGoodsItemsAdded: 'Ничего не добавлено',
    confirmSubmissionText: 'Подтвердить, отправку декларации',
    invalidSubmissionText: 'Пожалуйста, проверьте, что все обязательные поля заполнены:',
    successfulCancellation: 'Декларация отменена',
    nctsSubmitError: 'Ошибка при подачи декларации в таможню',
    nctsValidationError: '{{count}} ошибка валидации, декларация не отправлена',
    nctsValidationError_plural: '{{count}} ошибка валидации, декларация не отправлена',
    nctsFormInvalid: 'При подаче аннулирования декларации на таможню произошли ошибки',
    invalidField: 'Неверное поле',
    loadingGoodsItemTradersError: 'Внимание! Произошла ошибка загрузки товарной позиции',
    invalidGuaranteeAmount: 'Сумма гарантии недействительна',
    blankTotalGrossMass: 'Поле общего веса товара не должно быть пустым',
    blankPackagesCount: 'Поле упаковки не может быть пустым ',
    missingTransitCountry: 'Страны пересечения не могут быть пустыми',
    missingDepartureTrailerSeparator: 'Выезд грузовик должен использовать ; как разделитель для прицепа',
    missingDestinationTrailerSeparator: 'Пограничный грузовик должен использовать ; как разделитель для прицепа',
    missingContainerNumbers: 'Отсутствует номер контейнера',
    missingGoodsDocuments: 'Отсутствуют необходимые документы для товаров',
    missingGoodsDocumentInfo: 'Отсутствует необходимый документ в позиции номер.',
    missingGoodsInfo: 'Отсутствует необходимая информация о товарк в позиции номер.',
    r105Invalid: 'Правило 105: Заявленное количество товаров ({{0}}) в декларации не соответствует количеству добавленных товаров ({{1}}) ',
    formInvalid: 'Форма недействительна',
    recoverySent: 'Письмо для восстановления отправлено',
    recoverySendingFailed: 'Не удалось отправить резервное письмо',
    customerEdit_successful: 'Успешно изменены статусы клиента и его пользователей',
    customerEdit_failed: 'Не удалось изменить статус клиента',
    customerUsersError: 'Не удалось получить пользователей клиента',
    missingRequiredFields: 'Отсутствуют обязательные поля',
    missingRequiredNameOrRegCodeFields: 'Отсутствует необходимое имя или регистрационный код',
    savingFailed: 'Не удалось сохранить',
    savingSuccess: 'Сохранено',
    inviteSent: 'Приглашение отправлено!',
    delete_successful: 'Удалено!',
    delete_failed: 'Не удалось удалить',
    duplicate_successful: '`Дубликат создан с ИД {{id}} ',
    duplicate_failed: 'Не удалось создать дубликат',
    passwordChangeLogoutWarning: 'Смена пароля приведет к обязательному выходу из системы',
    activationUrlError: 'URL активации недействителен',
    passwordsChanged_successful: 'Пароль успешно изменен. Выйти через 3 секунды… ',
    passwordsChanged_failed: 'Произошла ошибка при попытке изменить пароль',
    passwordsMatchError: 'Пароли не соответствуют',
    passwordsLengthError: 'Пароль должен состоять не менее чем из 7 символов.',
    passwordsLetterError: 'Пароль должен содержать хотя бы одну - букву большого регистра, маленького регистра и цифру.',
    invalidUrlError: 'Неправильный url',
    accountActivationError: 'Не удалось активировать аккаунт',
    requiredGoodsDocumentsNotUploaded: 'Для документов товарной позиции {{0}} требуется загрузка файла (325,380,730)',
    duplicateDocumentsMessage: 'Желаете ли вы скопировать декларацию вместе с документами?',
    deleteConfirmMessage: 'Вы уверены, что хотите удалить строку \'{{0}}\' ?',
    download_starting: 'Начинаем скачивание…',
    emailInvalid: 'Этот формат электронной почты недействителен',
    customerSupport: 'Компания с таким кодом реестра уже существует. Обратитесь в службу поддержки: t1@tardek.com',
    eoriHelper: 'Это поле предназначено для автозаполнения полей ниже и не является обязательным.',
    accountRegisteredFirstParagraph: 'Аккаунт успешно зарегистрирован.',
    // eslint-disable-next-line max-len
    checkEmail: 'Мы отправили вам электронное письмо, чтобы подтвердить вашу информацию. Если вы не получили электронное письмо, проверьте папку со спамом.',
    userDeleted: 'Пользователь успешно удален',
    profileUpdated: 'Профиль пользователя обновлен',
    profileUpdateFailed: 'Не удалось обновить профиль пользователя',
    eoriSearchFailed: 'Убедитесь, что ваш EORI ID действителен.',
    emailInUse: 'Эта электронная почта уже зарегистрирована',
    download_manual: 'Нажмите здесь, если загрузка не началась',
    popupsBlocked: 'Перенаправления заблокированы браузером, разрешите всплывающие окна для этого сайта продолжать',
    archivedCommodityCodes: 'Обнаружен архивный код в одной из товарных позиций, обновите код до актуальной номенклатуры',
    noMatches: 'Нет совпадений',
    noOfficeResults: 'Нет результатов! Офисы ограничены правилами и должны иметь соответствующие роли. Проверка ролей на europa.eu -',
    noOfficeResults_viaRefNum: 'через ссылочный номер "{{input}}"',
    noOfficeResults_viaUsualName: 'через обычное имя "{{input}}"',
    directoAddendumWithoutCustomer: 'Directo дополнение к описанию добавлено, но клиент не указан',
    accessCodeInvalid: 'Неверный код доступа',
    profileEmpty: 'Гарантия Профиль пуст!',
    bothLanguages: 'Пожалуйста, отредактируйте оба языка!',
    goodsItemError: 'Пожалуйста, проверьте свои HS-коды для товаров!',
    declarationStatus: 'Обновление статуса декларации',
    declarationStatusChange: 'Вы собираетесь изменить статус. Это означает, что вы не можете редактировать/обновлять этот Транзит.',
    emailsEmpty: 'Пожалуйста, добавьте хотя бы одно электронное письмо!',
    notFoundError: '{{entity}} с {{field}}: {{value}} не найдено',
    goodsLocationError: 'Местоположение товара не может быть пустым!',
    goodsLocationQualifierError: 'Классификатор местоположения товара не может быть пустым!',
    useGUAFormulaPlaceholder: 'Используйте «GUA» для обозначения суммы гарантии',
    forbiddenCharacter: '{{0}} содержит кириллические символы!',
    // eslint-disable-next-line max-len
    restrictedGoods: 'Ваша декларация содержит 1 или более товарных позиций, на которые распространяются ограничения. Либо измените свой гарантийный профиль, либо измените код ТН ВЭД для товарных позиций с красной рамкой.',
    // eslint-disable-next-line max-len
    invalidQuantityUnit: '{{type}} является недопустимым типом единицы измерения количества! Пожалуйста, проверьте Товар с номером {{number}}. Пожалуйста, повторно выберите единицу количества для товара с номером позиции {{number}}',
    // eslint-disable-next-line max-len
    destinationCountryWrong: '{{value}} недействительна Страна назначения для Товарной позиции с номером заказа {{number}}. Пожалуйста, выберите его повторно или обратитесь в службу поддержки!',
    // eslint-disable-next-line max-len
    dispatchCountryWrong: '{{value}} недействительна страна отправки для товара с номером заказа {{number}}. Пожалуйста, выберите его повторно или обратитесь в службу поддержки!',
    blacklistedWarning: '{{name}} {{field}} подозревается в контрабанде запрещенных товаров.',
    goodsItemConsignment: 'Товар(ы) отсутствует {{consignment}}',
    confirmConsentUpdate: 'Подтвердить отправку нового согласия в Borica B-Trust? Его необходимо принять в мобильном приложении B-Trust',
  },
  errors: {
    serverError: 'Ошибка сервера! Код {{traceId}}',
    notBlank: 'не может быть пустым',
    notNull: 'не может быть нулевым / пустым',
    guaranteeFormula: 'оценка не удалась',
    pattern: 'Неверный формат данных',
    // eslint-disable-next-line max-len
    invalidClassifier: 'является недействительным. Невозможно извлечь классификатор. Пожалуйста, выберите его повторно или обратитесь в службу поддержки!',
    codelist: 'неверный код',
    invalidInputText: 'содержит недопустимые символы',
  },
  entity: {
    declaration: 'Декларация',
    customer: 'Покупатель',
    restriction: 'Ограничение',
  },
  restriction: {
    id: 'ID',
    key: 'Ключ',
    description: 'Описание',
    modified: 'Изменено',
    created: 'Созданный',
    type: 'Тип',
    commodityCodeType: 'Код товара',
    commodityDescriptionType: 'Описание',
    commoditySanctionType: 'Под санкциями',
    commodityTraderType: 'Имя трейдера',
    commodityTruckType: 'Номер грузовика',
    alreadyExists: 'Ограничение уже есть. Пожалуйста, используйте другой код/описание!',
    importSuccess: 'Импорт запущен успешно. Повторите попытку через несколько минут.',
  },
  audit: {
    id: 'Id',
    declarationId: 'Декларации ID',
    status: 'Cтатус',
    customer: 'имя покупателя',
    created: 'Созданный',
    modified: 'Изменено',
    reason: 'Причина',
    reasonForDecline: 'Причина отклонения декларации',
    submitAudit: 'Причина отклонения декларации',
    inputError: 'Причина не может быть пустой',
    updated: 'Аудит обновлен',
    error: 'Ошибка обновления аудита',
    declinationReason: 'Причина склонения',
  },
  export: {
    title: 'Экспортные транзиты',
    queueExport: 'Запросить экспорт',
    calendarFilter: 'Включенные даты',
    tutorial: 'Ссылка для скачивания на созданный файл экспорта будет отправлена по адресу \'{{0}}\'',
    customerSelect: 'Фильтр клиентов',
    queueSubmit_success: 'Экспорт в очереди',
    queueSubmit_failed: 'Ошибка!',
    statusSelect: 'Фильтр статуса',
    dateFilter: 'Фильтр даты',
    profileFilter: 'Фильтр профиля',
  },
  eori: {
    error: 'EORI недействителен!',
    error_400: 'Ошибка! Что-то пошло не так',
    error_pattern: 'Ошибка! Проверьте шаблон, EORI должен начинаться с букв',
    error_404: 'Поиск не нашел ни одной компании',
    error_423: 'Поиск не нашел информацию о компании, часто это связано с закрытым номером EORI',
    error_500: 'Ошибка! Не удалось подключиться к поисковой системе. Если возможно, используйте проверку номера EORI на europa.eu',
    error_503: 'Ошибка! Не удалось подключиться к поисковой системе. Если возможно, используйте проверку номера EORI на europa.eu',
    error_GB: 'После выхода Великобритании из ЕС номера EORI, начинающиеся с кода «GB», не поддерживаются',
  },
  directo: {
    descriptionAddendum: 'Дополнение к описанию',
    customer: 'Клиенты',
    customerSearch: 'Ищи directo клиенты',
    remoteStaffCode: 'Directo код',
    shareProfile: 'Поделиться профилем',
  },
  customsProfile: {
    profile_new: 'Добавить гарантийный профиль',
    profile_edit: 'Изменить гарантийный профиль',
    remoteStaffCode: 'Directo код',
    default: 'Сделать профиль по умолчанию',
    revoke: 'Отменить доступ',
    info: 'Дополнительная информация',
  },
  privacyPolicy: {
    editPrivacy: '$t(buttons.change) Политика конфиденциальности',
    // eslint-disable-next-line max-len
    confirm: 'Это событие потребует от всех пользователей повторного принятия политики/условий конфиденциальности. Вы уверены, что хотите обновить политику?',
    language: 'Вы редактировали оба языка?',
  },
  feedback: {
    title: 'Оставить отзыв на декларацию',
    title_canceling: 'Отменить с обратной связью',
    info: 'Информация обратной связи',
    submit: 'Отправить отзыв',
    isDeclarationCanceled: 'Отменить декларацию?',
    includePdf: 'Включить PDF',
    listOfEmails: 'Письма с обратной связью',
    listOfEmailsExplanation: 'Список адресов электронной почты, на которые должна поступать электронная почта',
    blindCopyEmail: 'Слепая копия (BCC) Электронная почта',
    // eslint-disable-next-line max-len
    blindCopyEmailExplanation: 'Адрес электронной почты, на который должна быть отправлена скрытая копия этого отзыва. Если пусто, то скрытая копия не будет отправлена',
  },
  transportCustomsOffice: {
    limit: 'Количество транзитных таможен ограничено 9',
    containsBlanks: 'Транзитные таможни не могут иметь пустых значений. Назначить таможню или удалить их',
    required: 'Требуется хотя бы одна транзитная таможня',
  },
  preferences: {
    updated: 'Настройки обновлены!',
    failed: 'Ошибка обновления настроек!',
    order: 'Список можно перетаскивать. Если вы хотите изменить порядок этого списка, щелкните имя и перетащите его в подходящее место.',
  },
  configuration: {
    alert: 'Уведомление',
  },
  notification: {
    send: 'Отправить уведомление',
    inputPlaceholder: 'Сообщение для отображения под навигацией',
    placeholder: 'Это отправит уведомление всем активным клиентам и пользователям. Ты уверен ?',
    success: 'Уведомление успешно отправлено пользователям и клиентам',
    update: 'Уведомление об обновлении',
    edit: 'Изменить/обновить уведомление',
    updateSuccess: 'Уведомление успешно обновлено!',
  },
  excel: {
    import: 'Импорт товаров',
    importGoods: 'Импорт товаров',
    save: 'Сохранить элементы',
    processingError: 'Ошибка обработки файла',
    mustBeCsv: 'Загружаемый файл должен быть в формате CSV',
    mustBeExcel: 'Загружаемый файл должен быть Excel(.xlsx)',
    invalidMimeType: 'Неподдерживаемый тип файла',
    // eslint-disable-next-line max-len
    explanation: 'Существующие позиции товаров будут перезаписаны.\n Неверные, несопоставленные или неизвестные поля будут игнорироваться.\n Формат HS-Code должен состоять из 4, 6 или 8 символов без пробелов. Пример - "4200". Код страны должен состоять из 2 символов. Пример: "RU", "EE"',
    explanationPhase5: 'Новые позиции будут добавлены в существующий список. \n  Неправильные, несопоставленные или неизвестные поля будут игнорироваться. \n  Формат HS-кода должен состоять из 6 символов. Комбинированная номенклатура (CN) при использовании должна состоять из 2 символов. Коды стран, типы и т. д. должны состоять из 2–3 символов. Пример: «ПХ», «N730».',
    missingFieldMappings: 'Некоторые столбцы без сопоставлений пропущены.',
    example: 'Скачать пример',
  },
  cookie: {
    usage: 'Продолжая использовать этот веб-сайт, вы соглашаетесь на использование файлов cookie.',
    agree: 'Соглашаться',
  },
  landing: {
    functionalities: 'Функциональность',
    integrations: 'Интеграции',
    contact: 'Контакт',
    language: 'Язык',
    languageEng: 'English',
    languageRus: 'Русский',
    heroText: 'Транзитные декларации 24/7',
    heroSubText: 'Программное обеспечение NCTS',
    heroBtnText: 'Присоединиться',
    transitDeclarationOverview: 'Обзор транзитных деклараций',
    differentCustomsProfileCreation: 'Создание таможенных профилей для разных стран',
    sendingDeclarationsForDifferentCountriesCustoms: 'Отправка деклараций в таможню разных стран',
    creatingDeclarations: 'Создание деклараций',
    declarationPDFs: 'Декларации в PDF',
    companyUserManagement: 'Управление пользователями компании',
    customsIntegrations: 'Интеграция с таможней',
    customsMapAltText: 'Интеграции',
    privacyPolicy: 'Политика конфиденциальности',
    finland: 'Финляндия',
    estonia: 'Эстония',
    latvia: 'Латвия',
    lithuania: 'Литва',
    poland: 'Польша',
    romania: 'Румыния',
    turkey: 'Ту́рция',
    germany: 'Германия',
    netherlands: 'Нидерла́нды',
    sweden: 'Шведский',
    bulgaria: 'Болга́рия',
    hungary: 'Ве́нгрия',
    greatBritain: 'Великобритания',
    greatBritain_split: 'Велико- британия',
    agreeBtn: 'Я согласен',
    termsAndConditions: 'Условия и положения',
    aboutUs: 'О нас',
    integratedModule: 'Будет создан интегрированный модуль таможенных деклараций, позволяющий заполнять таможенные декларации\n'
      + ' декларации всех стран назначения через интерфейсы через единую среду входа. С помощью модуля\n'
      + ' основной процесс Tardek OÜ автоматизирован и требует 750 рабочих часов в месяц или 9000 рабочих часов в год или примерно\n'
      + ' Экономия 180 000 евро в год. Достижение такой эффективности позволит увеличить оборот в течение двух лет\n'
      + ' после окончания проекта (2027 г.) на 80% по сравнению с 2022 г. и увеличить добавленную стоимость на одного работника более чем на 22,5%. затем'
      + ' сумма поддержки составляет 299 600 евро.',
    support: 'Tardek OÜ получила поддержку для реализации дорожной карты цифровизации\n'
      + ' из меры поддержки цифровой трансформации компаний.\n'
      + ' Сумма гранта составила 299 600 евро, проект поддержал\n'
      + ' Европейский Союз из фондов NextGenerationEU.',
  },
  seo: {
    columnTitle: 'Поисковая оптимизация',
    title: 'Заголовок сайта',
    description: 'Краткое описание',
    keywords: 'Ключевые слова, разделенные запятыми',
  },
  guaranteeCalculator: {
    preview: 'Предварительный расчет гарантии',
    pdf: 'Расчет гарантии',
    useResultValue: 'Используйте в качестве гарантии',
    totalPrice: 'Общая стоимость',
    price: 'Цена',
    excise: 'Акциз',
    dutyRate: 'Пошлины %',
    vatRate: 'НДС %',
    taxableDutyAmount: 'Облагаемые налогом пошлины',
    taxableVatAmount: 'Облагаемый НДС',
    taxableItemPrice: 'Гарантийные налоги',
  },
  consignmentItemImport: {
    header: 'Импорт консигнационного товара',
    import: 'Импортировать',
    import_start: 'Начать импорт',
    import_close: 'Закрыть импорт',
    importErrorsFound: 'Импортировать найденные ошибки',
    missingColumnMapping: 'Столбец в CSV не сопоставлен с товаром, данные могут быть неполными. - {{0}}',
    rowError_columnCount: 'Строка {{0}} недействительна. Файл сопоставлен с {{1}} столбцами. В строке есть {{2}} столбцов.',
    rowError_commodityCode: 'Строка {{0}} недействительна. Архивированный или неизвестный товарный код {{1}}',
    explanation: 'Новые позиции будут добавлены в существующий список.\n'
      + ' Неправильные, несопоставленные или неизвестные поля будут игнорироваться.\n'
      + ' Формат HS-кода должен состоять из 6 символов. Комбинированная номенклатура при использовании должна состоять из 2 символов. '
      + 'Коды стран, типы и т. д. должны состоять из 2–3 символов. Пример: «ПХ», «N730».',
    example: 'Скачать пример',
  },
  codelistImport: {
    title: 'Импортер кодов',
    description: 'Список кодов необходимо импортировать с использованием файлов «RD_NCTS-P5_XX.xml» с сайта europa.eu, доступных для домена NCTS-P5.',
    supportedCodeGroups: 'Группы кодов, поддерживаемые системой T1:',
    confirmImport: 'Начать импорт списков кодов? Будут добавлены новые коды, отсутствующие в файле коды будут помечены как заархивированные.',
    startImport: 'Начать импорт',
    startImport_success: 'Импорт выполнен',
    startImport_failed: 'Импорт не удался',
  },
  quickFilters: {
    showReleasedTransitWithLimitDateOverdue: 'Показывать открытые транзиты с просроченной датой лимита',
  },
}

export default TRANSLATIONS_RU

