import { RuleResult } from 'types/DeclarationP5'
import { CL181_BULK_KIND_OF_PACKAGES, CL182_UNPACKED_KIND_OF_PACKAGES } from '../../../../common/utils/ncts-constants'

const C0060Validator = {
  validatePackagingShippingMarksRequirement: (packageType: string | null): RuleResult => {
    if (packageType != null
      && (CL181_BULK_KIND_OF_PACKAGES.includes(packageType) || CL182_UNPACKED_KIND_OF_PACKAGES.includes(packageType))) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
  validatePackagingNumberOfPackagesRequirement: (packageType: string | null): RuleResult => {
    if (packageType != null && CL181_BULK_KIND_OF_PACKAGES.includes(packageType)) {
      return 'NOT_ALLOWED'
    }

    return 'REQUIRED'
  },

} as const

export default C0060Validator
