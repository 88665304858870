import ParseCsvRowResult from './mapper/parseCsvRowResult'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { blankConsignmentItemsImportDiff, ConsignmentItemsImportDiff } from './consignmentItemsImportDIff'

function updateDispatchCountriesDiff(data: ConsignmentItemsImportDiff, parseResult: ParseCsvRowResult) {
  const diff = data
  if (!diff.isDifferentDispatchCountries) {
    if (diff.currentCountryOfDispatch == null) {
      diff.currentCountryOfDispatch = parseResult.consignmentItem.countryOfDispatch
    } else {
      diff.isDifferentDispatchCountries = diff.currentCountryOfDispatch !== parseResult.consignmentItem.countryOfDispatch
      diff.currentCountryOfDispatch = parseResult.consignmentItem.countryOfDispatch
    }
  }
}

function updateDestinationCountriesDiff(data: ConsignmentItemsImportDiff, parseResult: ParseCsvRowResult) {
  const diff = data
  if (!diff.isDifferentDestinationCountries) {
    if (diff.currentCountryOfDestination == null) {
      diff.currentCountryOfDestination = parseResult.consignmentItem.countryOfDestination
    } else {
      diff.isDifferentDestinationCountries = diff.currentCountryOfDestination !== parseResult.consignmentItem.countryOfDestination
      diff.currentCountryOfDestination = parseResult.consignmentItem.countryOfDestination
    }
  }
}

function updateReferenceNumberDiff(data: ConsignmentItemsImportDiff, parseResult: ParseCsvRowResult) {
  const diff = data
  if (!diff.isDifferentReferenceNumberUCRs) {
    if (diff.currentReferenceNumberUCR == null) {
      diff.currentReferenceNumberUCR = parseResult.consignmentItem.referenceNumberUCR
    } else {
      diff.isDifferentReferenceNumberUCRs = diff.currentReferenceNumberUCR !== parseResult.consignmentItem.referenceNumberUCR
      diff.currentReferenceNumberUCR = parseResult.consignmentItem.referenceNumberUCR
    }
  }
}

function useConsignmentItemsImportDiff() {
  const importedItemsDiffBySequenceNumber: Map<number, ConsignmentItemsImportDiff> = new Map<number, ConsignmentItemsImportDiff>()

  function updateDiff(data: ConsignmentItemsImportDiff, parseResult: ParseCsvRowResult) {
    updateDispatchCountriesDiff(data, parseResult)
    updateDestinationCountriesDiff(data, parseResult)
    updateReferenceNumberDiff(data, parseResult)
  }

  function createOrUpdateDiff(houseConsignmentSequenceNumber: number, parseResult: ParseCsvRowResult) {
    let diff = importedItemsDiffBySequenceNumber.get(houseConsignmentSequenceNumber)
    if (!diff) {
      diff = structuredClone(blankConsignmentItemsImportDiff)
      importedItemsDiffBySequenceNumber.set(parseResult.sequenceNumber, diff)
    }

    updateDiff(diff, parseResult)

    return diff
  }

  function updateHouseConsignmentFromDiff(houseConsignmentSequenceNumber: number, data: HouseConsignmentType) {
    const diff = importedItemsDiffBySequenceNumber.get(houseConsignmentSequenceNumber)
    if (!diff) {
      return
    }

    const house = data

    if (!diff.isDifferentDispatchCountries) {
      let countryOfDispatch = ''
      house.consignmentItem.forEach((itemValue) => {
        const item = itemValue
        if (!countryOfDispatch) {
          countryOfDispatch = item.countryOfDispatch
        }
        item.countryOfDispatch = ''
      })

      house.countryOfDispatch = countryOfDispatch
    }

    if (!diff.isDifferentDestinationCountries) {
      let countryOfDestination = ''
      house.consignmentItem.forEach((itemValue) => {
        const item = itemValue
        if (!countryOfDestination) {
          countryOfDestination = item.countryOfDestination
        }
        item.countryOfDestination = ''
      })

      house.countryOfDestination = countryOfDestination
    }

    if (!diff.isDifferentReferenceNumberUCRs) {
      let referenceNumberUCR = ''
      house.consignmentItem.forEach((itemValue) => {
        const item = itemValue
        if (!referenceNumberUCR) {
          referenceNumberUCR = item.referenceNumberUCR
        }
        item.referenceNumberUCR = ''
      })
      house.referenceNumberUCR = referenceNumberUCR
    }
  }

  return {
    createOrUpdateDiff,
    updateHouseConsignmentFromDiff,
  }
}

export default useConsignmentItemsImportDiff
