import B1904Validator from '../transitional/B1904'
import { CustomsOfficeOfTransitDeclared } from '../../schemas/commonConsignmentSchemas'

const R0005Validator = {
  isArrivalDateAndTimeEstimatedValid(transitCustomsOffice: CustomsOfficeOfTransitDeclared): boolean {
    if (!B1904Validator.isValidationRequired()) {
      return true
    }

    if (!transitCustomsOffice.arrivalDateAndTime) {
      return true
    }

    const now = new Date()
    const arrivalDateAndTime = new Date(transitCustomsOffice.arrivalDateAndTime)

    return now < arrivalDateAndTime
  },
}

export default R0005Validator
