export interface ConsignmentItemsImportDiff {
  isDifferentDispatchCountries: boolean
  isDifferentDestinationCountries: boolean
  isDifferentReferenceNumberUCRs: boolean
  currentCountryOfDispatch: string | null
  currentCountryOfDestination: string | null
  currentReferenceNumberUCR: string | null
}

export const blankConsignmentItemsImportDiff: ConsignmentItemsImportDiff = {
  isDifferentDestinationCountries: false,
  isDifferentDispatchCountries: false,
  isDifferentReferenceNumberUCRs: false,
  currentCountryOfDispatch: null,
  currentCountryOfDestination: null,
  currentReferenceNumberUCR: null,
}
