import React, { useEffect, useState } from 'react'

interface SidebarSectionProps {
  columnId: string
  headingLabel: string
  initialSectionState?: boolean
  delayedOpenSection?: boolean
}

function TransitSidebarSection({
  columnId,
  headingLabel,
  children,
  initialSectionState,
  delayedOpenSection,
}: React.PropsWithChildren<SidebarSectionProps>) {
  const [isSectionOpen, toggleSection] = useState<boolean>(initialSectionState ?? true)

  useEffect(() => {
    if (delayedOpenSection === true) {
      toggleSection(true)
    }
  }, [delayedOpenSection])

  return (
    <section className="pb-3">
      <div
        className={
          `declaration__column-heading d-flex justify-content-between ${isSectionOpen ? 'mb-2' : 'mb-0'}`
        }
        data-bs-toggle="collapse"
        data-bs-target={`#${columnId}`}
        aria-expanded={isSectionOpen}
        aria-controls={columnId}
        role="button"
        tabIndex={isSectionOpen ? -1 : 0}
        onClick={() => toggleSection(!isSectionOpen)}
        onKeyDown={() => toggleSection(!isSectionOpen)}
      >
        <h3>
          {headingLabel}
        </h3>
        <i className={`me-2 fa ${isSectionOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </div>
      <div className={`collapse ${isSectionOpen ? 'show' : ''}`} id={columnId}>
        <div className="d-flex justify-content-around m-0">
          {isSectionOpen && children}
        </div>
      </div>
    </section>
  )
}

TransitSidebarSection.defaultProps = {
  initialSectionState: undefined,
  delayedOpenSection: undefined,
}

export default TransitSidebarSection
