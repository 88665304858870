import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { Consignor } from '../../../form/schemas/tradersSchema'
import { Address, ContactPerson } from '../../../form/schemas/commonConsignmentSchemas'
import { blankConsignor } from '../../../form'
import { hasText } from '../../../../common/utils/common-util'
import { COUNTRY_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { blankContactPerson } from '../../../form/blankItems'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted' | 'address'

function isConsignorField(
  field: string,
): field is Exclude<ObjectKeys<Consignor>, NotImportableFields> | Exclude<ObjectKeys<Address>, NotImportableFields> {
  return field in blankConsignor || field in blankConsignor.address!
}

function isConsignorContactPersonField(
  field: string,
): field is Exclude<ObjectKeys<ContactPerson>, NotImportableFields> {
  return field in blankContactPerson
}

export function mapConsignorContactPerson(
  newImportedItemObject: ContactPerson | null,
  consignmentItemObjectSubfield: string,
  cellValue: string,
) {
  if (!isConsignorContactPersonField(consignmentItemObjectSubfield)) {
    return
  }

  let contactPerson = newImportedItemObject
  if (contactPerson === null) {
    contactPerson = structuredClone(blankContactPerson)
  }

  switch (consignmentItemObjectSubfield) {
    case 'name':
    case 'telephone':
    case 'email':
      contactPerson[consignmentItemObjectSubfield] = cellValue
      break
    default:
      throw Error(`Unknown consignor contact person field ${consignmentItemObjectSubfield}`)
  }
}

export default function mapConsignor(
  newImportedItemObject: Consignor | null,
  consignmentItemObjectSubfield: string,
  cellValue: string,
) {
  let objectField = consignmentItemObjectSubfield
  let objectSubField = null
  if (consignmentItemObjectSubfield.includes('_')) {
    [objectField, objectSubField] = consignmentItemObjectSubfield.split('_')
  }

  if (!isConsignorField(objectField)) {
    return
  }

  let consignor = newImportedItemObject
  if (consignor === null) {
    consignor = structuredClone(blankConsignor)
  }
  let consignorAddress = consignor.address
  if (consignorAddress === null) {
    consignorAddress = {
      id: null,
      city: '',
      country: '',
      postcode: '',
      streetAndNumber: '',
    }
  }

  switch (objectField) {
    case 'name':
    case 'identificationNumber':
      consignor[objectField] = cellValue
      break
    case 'target':
      consignor[objectField] = 'CONSIGNOR'
      break
    case 'country':
      consignorAddress[objectField] = hasText(cellValue) ? COUNTRY_CODE_PREFIX + cellValue : ''
      break
    case 'city':
    case 'streetAndNumber':
    case 'postcode':
      consignorAddress[objectField] = cellValue
      break
    case 'contactPerson':
      if (objectSubField) {
        mapConsignorContactPerson(
          consignor.contactPerson,
          objectSubField,
          cellValue,
        )
      }
      break
    default:
      throw Error(`Unknown consignor field ${consignmentItemObjectSubfield}`)
  }
}
