import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  NestedFieldScope,
  ObjectScope,
} from 'types/DeclarationP5'
import { getSelectCountryFilter } from 'components/react-select/CountryFunctions'
import { useFormContext } from 'react-hook-form'
import TraderSearch from '../../components/TraderSearch'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import useCodelist from '../../../common/hooks/useCodelist'
import E1104Validator from '../../form/validators/E1104'
import E1102Validator from '../../form/validators/E1102'
import EoriSearch from '../../components/EoriSearch'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

const expectedScope = /(^consignor$)|(houseConsignment\.\d+\.consignor)/g
const isConsignorScope = (scope: ObjectScope): scope is 'consignor' | `houseConsignment.${number}.consignor` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

type ConsignmentConsignor = NestedFieldScope<'consignor'>
type HouseConsignor = NestedFieldScope<`houseConsignment.${number}.consignor`>
type ConsignorScope = ConsignmentConsignor | HouseConsignor

interface ConsignorProps {
  scope: ObjectScope
  initialIsSectionOpen?: boolean
}

function Consignor({ scope, initialIsSectionOpen }: ConsignorProps) {
  if (!isConsignorScope(scope)) throw Error('Unable to narrow, invalid scope')
  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')

  const { getValues } = useFormContext<DeclarationForm>()
  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-consignor"
        headingLabel={t('declaration.p5.consignor')}
        initialIsSectionOpen={initialIsSectionOpen}
      >
        <FormField labelKey="declaration.p5.consignorIdentificationNr">
          <EoriSearch scope={scope} />
        </FormField>
        <FormField scope={`${scope}.name`} labelKey="declaration.p5.consignorName">
          <TraderSearch
            scope={scope}
            maxLength={E1104Validator.getNameOrStreetMaxLength()}
            isConsignee={false}
          />
        </FormField>
        <FormField labelKey="declaration.p5.country">
          <FormSelect
            <ConsignorScope>
            field={`${scope}.address.country`}
            labelKey="declaration.p5.country"
            options={countriesOptions}
            customFilterOption={getSelectCountryFilter}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.address">
          <Input
            <ConsignorScope>
            field={`${scope}.address.streetAndNumber`}
            autoComplete={`${scope}.address.streetAndNumber`}
            labelKey="declaration.p5.address"
            type="text"
            maxLength={E1104Validator.getNameOrStreetMaxLength()}
            placeholder={t('declaration.p5.address')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.city">
          <Input
            <ConsignorScope>
            field={`${scope}.address.city`}
            autoComplete={`${scope}.address.city`}
            labelKey="declaration.p5.city"
            maxLength={35}
            type="text"
            placeholder={t('declaration.p5.city')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.postcode">
          <Input
            <ConsignorScope>
            field={`${scope}.address.postcode`}
            autoComplete={`${scope}.address.postcode`}
            labelKey="declaration.p5.postcode"
            maxLength={E1102Validator.getPostcodeMaxLength()}
            type="text"
            placeholder={t('declaration.p5.postcode')}
          />
        </FormField>

        {
          country === 'LT' && (
            <>
              <FormField labelKey="declaration.p5.contactPersonName">
                <Input
                  <ConsignorScope>
                  field={`${scope}.contactPerson.name`}
                  autoComplete={`${scope}.contactPerson.name`}
                  labelKey="declaration.p5.contactPersonName"
                  maxLength={70}
                  type="text"
                  placeholder={t('declaration.p5.contactPersonName')}
                />
              </FormField>
              <FormField labelKey="declaration.p5.contactPersonPhone">
                <Input
                  <ConsignorScope>
                  field={`${scope}.contactPerson.telephone`}
                  autoComplete={`${scope}.contactPerson.telephone`}
                  labelKey="declaration.p5.contactPersonPhone"
                  maxLength={35}
                  type="text"
                  placeholder={t('declaration.p5.contactPersonPhone')}
                />
              </FormField>
              <FormField labelKey="declaration.p5.contactPersonEmail">
                <Input
                  <ConsignorScope>
                  field={`${scope}.contactPerson.email`}
                  autoComplete={`${scope}.contactPerson.email`}
                  labelKey="declaration.p5.contactPersonEmail"
                  maxLength={256}
                  type="text"
                  placeholder={t('declaration.p5.contactPersonEmail')}
                />
              </FormField>
            </>
          )
        }
      </CollapsibleColumn>
    </section>
  )
}

Consignor.defaultProps = {
  initialIsSectionOpen: true,
}

export default Consignor
