import React from 'react'
import { Document, DocumentFile } from '../../../types/Document'
import { AccessLevel } from '../../../context/DeclarationContext'
import DocumentService from '../services/document.service'

interface DocumentFileNamesProps {
  document: Document
  documents: Document[]
  accessLevel: AccessLevel
  setDocuments: (updatedDocs: Array<Document>) => void
}

function DocumentFileNames(props: DocumentFileNamesProps) {
  const {
    document, accessLevel, setDocuments, documents,
  } = props

  const deleteDocument = (changedDocumentFile: DocumentFile, parentDocumentSortOrder: number | null) => {
    const changedDocuments = []
    for (const currentDoc of documents) {
      if (currentDoc.sortOrder === parentDocumentSortOrder) {
        const changeDoc = {
          ...currentDoc,
          files: [...currentDoc.files.map((documentFileInState: DocumentFile) => {
            const newDocFile = {
              ...documentFileInState,
            }

            if (documentFileInState.filename === changedDocumentFile.filename) {
              newDocFile.deleted = true
            }

            return newDocFile
          })],
        }
        changedDocuments.push(changeDoc)
      } else {
        changedDocuments.push(currentDoc)
      }
    }
    setDocuments(changedDocuments)
  }

  return (
    <div className="row mb-1" key="document-files">
      <div className="d-none d-md-block col-12 col-md-4" />
      <div className="col-12 col-md-8 d-flex flex-column ">
        {
          document.files?.filter((doc) => !doc.deleted)
            .map((file) => (
              <div className="d-flex" key={`document-file-${file.id}-${file.sortOrder}`}>
                <button
                  disabled={accessLevel === AccessLevel.VIEW}
                  className="btn btn-link d-flex overflow-auto"
                  type="button"
                  onClick={() => DocumentService.downloadFile(file)}
                >
                  <i className="fal fa-file fa-md me-2" />
                  <span className="overflow-ellipsis">{file.filename}</span>
                </button>
                <button
                  disabled={accessLevel === AccessLevel.VIEW}
                  className="btn btn-link text-decoration-none"
                  type="button"
                  onClick={() => deleteDocument(file, document.sortOrder)}
                >
                  <i className="fal fa-times me-2 ms-1 ms-md-0" />
                </button>
              </div>
            ))
        }
      </div>
    </div>
  )
}

export default DocumentFileNames
