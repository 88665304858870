import { RuleResult } from '../../../../../../types/DeclarationP5'
import { excludeDeleted, hasText } from '../../../../common/utils/common-util'
import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import { isTransitionalPeriodActive } from '../../../../common/utils/ncts-constants'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import B1965Validator from '../transitional/B1965'

const C0909Validator = {
  validateCountryOfDispatchForHouseConsignmentRequirement: (
    formData: DeclarationForm,
    houseConsignment: HouseConsignmentType,
  ): RuleResult => {
    const {
      countryOfDispatch,
    } = formData

    if (isTransitionalPeriodActive()) {
      return B1965Validator.getCountryOfDispatchForHouseConsignmentRequirement(formData, houseConsignment)
    }

    if (hasText(countryOfDispatch)) return 'NOT_ALLOWED'
    if (houseConsignment.consignmentItem.filter(excludeDeleted)
      .some((item) => hasText(item.countryOfDispatch))) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
  validateCountryOfDispatchForConsignmentItemRequirement: (
    formData: DeclarationForm,
    houseConsignment: HouseConsignmentType,
  ): RuleResult => {
    const {
      countryOfDispatch,
    } = formData

    if (isTransitionalPeriodActive()) {
      return B1965Validator.getCountryOfDispatchForConsignmentItemRequirement(formData, houseConsignment)
    }

    if (hasText(countryOfDispatch)) return 'NOT_ALLOWED'
    if (hasText(houseConsignment.countryOfDispatch)) return 'NOT_ALLOWED'

    return 'REQUIRED'
  },

} as const

export default C0909Validator
