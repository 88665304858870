import React from 'react'
import DeclarationTabLayout from '../../layout/DeclarationTabLayout'
import GeneralSection from './GeneralSection'
import TransportEquipment from './transportEquipment'
import TransportSection from './TransportSection'
import DepartureTransportMeans from './DepartureTransportMeans'
import BorderTransportMeans from './BorderTransportMeans'
import PlaceOfLoading from './PlaceOfLoading'
import PlaceOfUnloading from './PlaceOfUnloading'
import CountriesOfRouting from './CountriesOfRouting'
import CustomsOfficeOfTransit from './CustomsOfficeOfTransit'
import LocationOfGoods from './LocationOfGoods'
import Authorisations from './Authorisations'
import Guarantees from './Guarantees'
import TransportDocuments from '../../components/TransportDocuments'
import PreviousDocuments from '../../components/PreviousDocuments'
import SupportingDocuments from '../../components/SupportingDocuments'
import CustomsOfficeOfExitForTransit from './CustomsOfficeOfExitForTransit'

function DeclarationGeneralTab() {
  return (
    <DeclarationTabLayout
      start={(
        <>
          <GeneralSection />
          <Guarantees />
          <CountriesOfRouting />
        </>
      )}
      centerLeft={(
        <>
          <TransportSection />
          <DepartureTransportMeans scope="departureTransportMeans" />
          <BorderTransportMeans />
        </>
      )}
      centerRight={(
        <>
          <PlaceOfLoading />
          <PlaceOfUnloading />
          <Authorisations />
          <TransportDocuments scope="transportDocument" />
          <PreviousDocuments scope="previousDocument" />
          <SupportingDocuments scope="supportingDocument" />
        </>
      )}
      end={(
        <>
          <TransportEquipment />
          <CustomsOfficeOfTransit scope="transitCustomsOffices" />
          <CustomsOfficeOfExitForTransit scope="customsOfficesOfTransitExit" />
          {/* <AdditionalReferences scope="additionalReference" /> */}
          {/* { isTransitionalPeriodActive() ? <AdditionalInformations scope="additionalInformation" /> : null } */}
          <LocationOfGoods />
        </>
      )}
    />
  )
}

export default DeclarationGeneralTab
