import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { blankConsignee } from '../../../form'
import { COUNTRY_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { Consignee } from '../../../form/schemas/tradersSchema'
import { Address } from '../../../form/schemas/commonConsignmentSchemas'
import { hasText } from '../../../../common/utils/common-util'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted' | 'address'
function isConsigneeField(
  field: string,
): field is Exclude<ObjectKeys<Consignee>, NotImportableFields> | Exclude<ObjectKeys<Address>, NotImportableFields> {
  return field in blankConsignee || field in blankConsignee.address!
}

export default function mapConsignee(
  newImportedItemObject: Consignee | null,
  consignmentItemObjectSubfield: string,
  cellValue: string,
) {
  let objectField = consignmentItemObjectSubfield
  if (consignmentItemObjectSubfield.includes('_')) {
    [objectField] = consignmentItemObjectSubfield.split('_')
  }

  if (!isConsigneeField(objectField)) {
    return
  }

  let consignee = newImportedItemObject
  if (consignee === null) {
    consignee = structuredClone(blankConsignee)
  }
  let consigneeAddress = consignee.address
  if (consigneeAddress === null) {
    consigneeAddress = {
      id: null,
      city: '',
      country: '',
      postcode: '',
      streetAndNumber: '',
    }
  }

  switch (objectField) {
    case 'name':
    case 'identificationNumber':
      consignee[objectField] = cellValue
      break
    case 'target':
      consignee[objectField] = 'CONSIGNEE'
      break
    case 'country':
      consigneeAddress[objectField] = hasText(cellValue) ? COUNTRY_CODE_PREFIX + cellValue : ''
      break
    case 'city':
    case 'streetAndNumber':
    case 'postcode':
      consigneeAddress[objectField] = cellValue
      break
    default:
      throw Error(`Unknown consignee field ${objectField}`)
  }
}
